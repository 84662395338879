import { errorType, successType } from "../../../util/redux-observable-helpers"

export const PRODUCT_SEARCH_CLOSE = "@product-search-widget/CLOSE"
export const PRODUCT_SEARCH_SET = "@product-search-widget/SET"

export const PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY =
  "@product-search-widget/REF_PRODUCT_LIST_BY_QUERY"
export const PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY_SUCCESS = successType(
  PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY,
)
export const PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY_ERROR = errorType(
  PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY,
)

export const PRODUCT_SEARCH_LIST_BY_QUERY = "@product-search-widget/LIST_BY_QUERY"
export const PRODUCT_SEARCH_LIST_BY_QUERY_SUCCESS = successType(
  PRODUCT_SEARCH_LIST_BY_QUERY,
)
export const PRODUCT_SEARCH_LIST_BY_QUERY_ERROR = errorType(
  PRODUCT_SEARCH_LIST_BY_QUERY,
)

export const PRODUCT_SEARCH_GET_SEARCH_FILTERS =
  "@product-search-widget/GET_SEARCH_FILTERS"
export const PRODUCT_SEARCH_GET_SEARCH_FILTERS_SUCCESS = successType(
  PRODUCT_SEARCH_GET_SEARCH_FILTERS,
)
export const PRODUCT_SEARCH_GET_SEARCH_FILTERS_ERROR = errorType(
  PRODUCT_SEARCH_GET_SEARCH_FILTERS,
)

export const PRODUCT_SEARCH_LIST_MATCHES = "@product-search-widget/LIST_MATCHES"
export const PRODUCT_SEARCH_LIST_MATCHES_SUCCESS = successType(
  PRODUCT_SEARCH_LIST_MATCHES,
)
export const PRODUCT_SEARCH_LIST_MATCHES_ERROR = errorType(PRODUCT_SEARCH_LIST_MATCHES)

export const PRODUCT_SEARCH_SET_SELECTED_FILTERS =
  "@product-search-widget/SET_SELECTED_FILTERS"
export const PRODUCT_SEARCH_CLEAR_SELECTED_FILTERS =
  "@product-search-widget/CLEAR_SELECTED_FILTERS"

export const PRODUCT_SEARCH_SET_QUERY = "@product-search-widget/SET_QUERY"
export const PRODUCT_SEARCH_SET_TOTAL = "@product-search-widget/SET_TOTAL"
export const PRODUCT_SEARCH_SET_REF_PRODUCT_PAGE =
  "@product-search-widget/SET_REF_PRODUCT_PAGE"

export const PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY =
  "@product-search-widget/GET_REF_PRODUCT_SUMMARY"
export const PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY_SUCCESS = successType(
  PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY,
)
export const PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY_ERROR = errorType(
  PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY,
)
