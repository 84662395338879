import PropTypes from "prop-types"
import React from "react"
import ReactCountryFlag from "react-country-flag"
import useShop from "../../../../hooks/use-shop"

import styles from "./AssortmentAnalysisColumnHeaderShop.module.scss"

function AssortmentAnalysisColumnHeaderShop({ country, id }) {
  const { logo, name } = useShop(id)

  return (
    <div className={styles.container}>
      {logo ? <img className={styles.logo} src={logo} alt={`${name} logo`} /> : null}
      <span className={styles.name}>{name}</span>
      <ReactCountryFlag className={styles.flag} countryCode={country} />
    </div>
  )
}

AssortmentAnalysisColumnHeaderShop.propTypes = {
  country: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}
AssortmentAnalysisColumnHeaderShop.defaultProps = {}

export { AssortmentAnalysisColumnHeaderShop }
