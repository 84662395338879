import { Logger } from "../../../util/log"
import { buildEpic } from "../../../util/redux-observable-helpers"
import * as api from "../../../api/service"
import { REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID } from "./action-types"

const _log = new Logger("ducks:repositories:reference-products:epics")

// epics
const getOneReferenceProductByIdEpic = buildEpic(
  REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID,
  ({ id, invalidateCache }) =>
    api.getReferenceProducts([
      {
        id,
        invalidateCache,
      },
    ]),
)

export default [getOneReferenceProductByIdEpic]
