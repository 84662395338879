import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
  ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_STEP_KEY,
} from "../constants"
import { assortmentAnalysisURLState } from "../helpers"

export function handleReadAssortmentAnalysisFromPreviousState(state) {
  return { ...state }
}

export function handleReadAssortmentAnalysisURLState(state, payload) {
  const { defaultFilters, defaultSorts, defaulfFilterOptions } = payload
  const { filters, sorts, selectedFilters } = assortmentAnalysisURLState(
    defaultFilters,
    defaultSorts,
    defaulfFilterOptions,
  )

  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY]: {
      ...state[ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY],
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: filters.dimension,
      [ASSORTMENT_ANALYSIS_PAGE_STEP_KEY]: filters.step,
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]: filters.productGroup,
    },
    [ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY]: selectedFilters,
    [ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY]: {
      ...state[ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY],
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: sorts.dimension,
    },
    [ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY]: {
      ...state[ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY],
      page: filters.page,
      pageSize: filters.pageSize,
    },
  }
}
