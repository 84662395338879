import PropTypes from "prop-types"
import React, { forwardRef } from "react"
import * as RUIDialog from "@radix-ui/react-dialog"
import { ButtonX } from "../../Button"

const BaseDialogCloseX = forwardRef(({ title, ...props }, forwardedRef) => (
  <RUIDialog.Close asChild>
    <ButtonX {...props} ref={forwardedRef} title={`${title} dialog`} />
  </RUIDialog.Close>
))

BaseDialogCloseX.displayName = "BaseDialogCloseX"

BaseDialogCloseX.propTypes = {
  title: PropTypes.string.isRequired,
}

BaseDialogCloseX.defaultProps = {}

export { BaseDialogCloseX }
