import PropTypes from "prop-types"
import React, { forwardRef } from "react"

import { Button } from "./Button"

import { CheckIcon } from "../Icon"

const ButtonCheck = forwardRef(({ title, ...props }, forwardedRef) => (
  <Button
    aria-label={title}
    {...props}
    border="none"
    color="success"
    height="regular"
    squared
    title={title}
    variant="solid"
    ref={forwardedRef}
  >
    <CheckIcon label={title} size="xs" />
  </Button>
))

ButtonCheck.displayName = "ButtonCheck"

ButtonCheck.propTypes = {
  title: PropTypes.string.isRequired,
}

export { ButtonCheck }
