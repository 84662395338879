import history from "../conf/browser-history"

class ReducerFactory {
  static create(initialState, handlerMap) {
    return (state = initialState, { type, ...action }) => {
      const enhancedState = {
        ...initialState,
        ...state,
      }

      if (handlerMap[type]) {
        const newState = handlerMap[type](enhancedState, action)

        if (newState === undefined) {
          throw new Error(`Reducer for action type "${type}" returned 'undefined'.`)
        }

        return newState
      }

      return enhancedState
    }
  }
}

function setSearchParams(params, replaceSearch = true) {
  const searchParams = new URLSearchParams(replaceSearch ? "" : history.location.search)

  Object.entries(params).forEach(([key, value]) => {
    if (value === null || value === undefined || value === "") {
      searchParams.delete(key)
    } else if (Array.isArray(value)) {
      searchParams.delete(key)

      value.forEach((el) => {
        if (el !== null && el !== undefined && el !== "") {
          searchParams.append(key, el.toString())
        }
      })
    } else {
      searchParams.set(key, value.toString())
    }
  })

  history.push({ search: searchParams.toString() })
}

function toAsyncSlice(data, status, error) {
  return { data, status, error }
}

function toAsyncErrorSlice(data, error) {
  return toAsyncSlice(data, "rejected", error)
}

function toAsyncSuccessSlice(data) {
  return toAsyncSlice(data, "resolved", null)
}

function toAsyncLoadingSlice(data) {
  return toAsyncSlice(data, "pending", null)
}

function toAsyncInitialSlice() {
  return toAsyncSlice(undefined, "idle", undefined)
}

export {
  ReducerFactory,
  setSearchParams,
  toAsyncSlice,
  toAsyncErrorSlice,
  toAsyncInitialSlice,
  toAsyncLoadingSlice,
  toAsyncSuccessSlice,
}
