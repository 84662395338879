import { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import useMatchesOverviewFilters from "./use-matches-overview-filters"
import { stageFilterReferenceProductsFamily } from "../../../ducks/pages/matches-overview/action-creators"
import {
  filterSearchParamsApplied,
  getActiveSearchParams,
} from "../../../ducks/pages/matches-overview/helpers"

export default function useMatchesOverviewReferenceProductFamilies() {
  const dispatch = useDispatch()

  const { data = [] } = useMatchesOverviewFilters(["outline", "families"])

  const filters = useMatchesOverviewFilters(["referenceProducts", "families"])
  const active = useMemo(() => getActiveSearchParams(filters), [filters])
  const applied = useMemo(() => filterSearchParamsApplied(filters), [filters])

  const families = useMemo(
    () =>
      data.map((family) => ({
        display: family,
        ticked: active.includes(family),
        value: family,
      })),
    [active, data],
  )

  const setFamilies = useCallback(
    (...familiesArgs) => {
      familiesArgs.forEach(({ value, state }) => {
        dispatch(stageFilterReferenceProductsFamily({ value, state }))
      })
    },
    [dispatch],
  )

  return { appliedFamilies: applied, families, setFamilies }
}
