import _PropTypes from "prop-types"
import React, { forwardRef } from "react"
import * as RUIDialog from "@radix-ui/react-dialog"

const BaseDialogClose = forwardRef((props, forwardedRef) => (
  <RUIDialog.Close asChild {...props} ref={forwardedRef} />
))

BaseDialogClose.displayName = "BaseDialogClose"
BaseDialogClose.propTypes = {}
BaseDialogClose.defaultProps = {}

export { BaseDialogClose }
