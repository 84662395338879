import { ReducerFactory } from "../../../util/redux-helpers"
import {
  REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID,
  REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID_ERROR,
  REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID_SUCCESS,
} from "./action-types"
import {
  handleGetOneReferenceProductById,
  handleGetOneReferenceProductByIdError,
  handleGetOneReferenceProductByIdSuccess,
} from "./handlers"

const initialState = {
  documents: {},
}

const reducer = ReducerFactory.create(initialState, {
  [REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID]: handleGetOneReferenceProductById,
  [REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID_ERROR]: handleGetOneReferenceProductByIdError,
  [REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID_SUCCESS]:
    handleGetOneReferenceProductByIdSuccess,
})

export default reducer
