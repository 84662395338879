import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_FLAGGED,
  STATUS_REVIEW,
} from "../../../../util/match-status"
import {
  QP_FAMILY,
  QP_MATCH_COUNT,
  QP_PAGE,
  QP_PAGE_SIZE,
  QP_STATUS,
  QP_CATEGORIES,
  QP_SHOP,
  QP_COUNTRY,
  QP_QUERY,
  QP_QUERY_FACET,
  stringQuery,
  numberQuery,
  arrayQuery,
} from "../../../../util/query-param"

/* -------------------------------------------------------------------------- */
/*                    helpers for filters applied on demand                   */
/* -------------------------------------------------------------------------- */
const SearchParamStatus = {
  applied: "APPLIED",
  staged: "STAGED",
}

const SearchParamStagedActions = {
  none: "NONE",
  add: "ADD",
  remove: "REMOVE",
}

function isApplied(searchParam) {
  return searchParam.status === SearchParamStatus.applied
}

function isSearchParamActive(x) {
  return isApplied(x) || isStagedForAddition(x)
}

function isStagedForAddition(searchParam) {
  return (
    searchParam.status === SearchParamStatus.staged &&
    searchParam.action === SearchParamStagedActions.add
  )
}

function filterSearchParamsActive(rawFilterState) {
  return rawFilterState.filter((x) => isSearchParamActive(x))
}

function filterSearchParamsApplied(rawFilterState) {
  return rawFilterState.filter((x) => isApplied(x))
}

function filterSearchParamsStaged(rawFilterState) {
  return rawFilterState.filter((x) => !isApplied(x))
}

function getActiveSearchParams(rawFilterState) {
  return filterSearchParamsActive(rawFilterState).map((x) => x.value)
}

function getSearchParamValue(searchParamWithMeta) {
  return searchParamWithMeta.value
}

function getValuesOfSearchParams(maybeArray) {
  return (maybeArray || []).map(getSearchParamValue)
}

function isStatefulSearchParam(obj) {
  return (
    obj !== null &&
    typeof obj === "object" &&
    "value" in obj &&
    "status" in obj &&
    "action" in obj
  )
}

function setSearchParamApplied(str) {
  return {
    value: str,
    status: SearchParamStatus.applied,
    action: SearchParamStagedActions.none,
  }
}

function setSearchParamStagedForAddition(str) {
  return {
    value: str,
    status: SearchParamStatus.staged,
    action: SearchParamStagedActions.add,
  }
}

function setSearchParamStagedForRemoval(str) {
  return {
    value: str,
    status: SearchParamStatus.staged,
    action: SearchParamStagedActions.remove,
  }
}

/* -------------------------------------------------------------------------- */
/*                               string queries                               */
/* -------------------------------------------------------------------------- */

function getUrlSearchFacet() {
  return stringQuery(QP_QUERY_FACET, "")
}

function getUrlSearchTerm() {
  return stringQuery(QP_QUERY, "")
}

/* -------------------------------------------------------------------------- */
/*                               number queries                               */
/* -------------------------------------------------------------------------- */

function getUrlPage() {
  return numberQuery(QP_PAGE, 1)
}

function getUrlPageSize() {
  return numberQuery(QP_PAGE_SIZE, 25)
}

function getUrlReferenceProductMatchesCount() {
  return numberQuery(QP_MATCH_COUNT, undefined)
}

/* -------------------------------------------------------------------------- */
/*                                array queries                               */
/* -------------------------------------------------------------------------- */

const SupportMatchStatus = new Set([
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
  STATUS_FLAGGED,
])

function getUrlMatchStatus(fallback = []) {
  const query = arrayQuery(QP_STATUS, fallback).filter((x) => SupportMatchStatus.has(x))

  if (query.length === 0) {
    return fallback
  }

  return query
}

function getUrlCategories(fallback = []) {
  return arrayQuery(QP_CATEGORIES, fallback).map(setSearchParamApplied)
}

function getUrlFamilies(fallback = []) {
  return arrayQuery(QP_FAMILY, fallback).map(setSearchParamApplied)
}

function getUrlCountries(fallback = []) {
  return arrayQuery(QP_COUNTRY, fallback).map(setSearchParamApplied)
}

function getUrlShops(fallback = []) {
  return arrayQuery(QP_SHOP, fallback).map(setSearchParamApplied)
}

/* -------------------------------------------------------------------------- */
/*                                miscellaneous                               */
/* -------------------------------------------------------------------------- */

function matchesOverviewURLState(defaultValues = {}) {
  return {
    categories: getUrlCategories(),
    countries: getUrlCountries(),
    families: getUrlFamilies(),
    matchStatuses: getUrlMatchStatus(defaultValues.matchStatuses || []),
    page: getUrlPage(defaultValues.page || 1),
    pageSize: getUrlPageSize(defaultValues.pageSize || 25),
    referenceProductMatchesCount: getUrlReferenceProductMatchesCount(),
    searchFacet: getUrlSearchFacet(),
    searchTerm: getUrlSearchTerm(),
    shops: getUrlShops(),
  }
}

export {
  filterSearchParamsActive,
  filterSearchParamsApplied,
  filterSearchParamsStaged,
  getActiveSearchParams,
  getSearchParamValue,
  getUrlCategories,
  getUrlCountries,
  getUrlFamilies,
  getUrlMatchStatus,
  getUrlPage,
  getUrlPageSize,
  getUrlReferenceProductMatchesCount,
  getUrlSearchFacet,
  getUrlSearchTerm,
  getUrlShops,
  getValuesOfSearchParams,
  isSearchParamActive,
  isStatefulSearchParam,
  SearchParamStagedActions,
  SearchParamStatus,
  setSearchParamApplied,
  setSearchParamStagedForAddition,
  setSearchParamStagedForRemoval,
  matchesOverviewURLState,
}
