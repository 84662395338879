import PropTypes from "prop-types"
import React from "react"
import ReactPlaceholder from "react-placeholder"
import Tooltip from "@mui/material/Tooltip"
import clsx from "clsx"

import { useDispatch } from "react-redux"

import styles from "./ProductComparisonTableHeaderControls.module.scss"

import { ProductComparisonTableHeaderContainer } from "./ProductComparisonTableHeaderContainer"

import { ButtonAdd, ButtonCheck, ButtonCross } from "../../ui"

import * as MatchActions from "../../../ducks/matches/action-creators"
import useMatchedProduct from "../../../hooks/use-matched-product"
import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
} from "../../../util/match-status"

function Placeholder() {
  return (
    <ReactPlaceholder
      className="rounded"
      color="#f5f5f5"
      showLoadingAnimation
      style={{ width: "6rem", height: "2rem" }}
      type="rect"
    />
  )
}

function useMatchActions() {
  const dispatch = useDispatch()

  function approve({ match, daltixId }) {
    dispatch(
      MatchActions.approveMatch({
        source: match.source,
        referenceProductId: match.ref_product_id,
        daltixProductId: daltixId,
      }),
    )
  }

  function discard({ match, daltixId }) {
    dispatch(
      MatchActions.discardMatch({
        source: match.source,
        referenceProductId: match.ref_product_id,
        daltixProductId: daltixId,
      }),
    )
  }

  function restore({ match, daltixId }) {
    dispatch(
      MatchActions.restoreMatch({
        source: match.source,
        referenceProductId: match.ref_product_id,
        daltixProductId: daltixId,
        status: STATUS_DISCARDED,
      }),
    )
  }

  function unapprove({ match, daltixId }) {
    dispatch(
      MatchActions.removeMatchApproval({
        source: match.source,
        referenceProductId: match.ref_product_id,
        daltixProductId: daltixId,
      }),
    )
  }

  return { approve, discard, restore, unapprove }
}

function MatchControls({ daltixId, matchId, outdated }) {
  const { data: match, isFetching, isReady, run } = useMatchedProduct(matchId || "")
  const { approve, discard, restore, unapprove } = useMatchActions()

  if (!isReady && !isFetching) {
    run()
  }

  if (!isReady) {
    return <Placeholder />
  }

  let Controls

  switch (match?.status) {
    case STATUS_APPROVED:
      Controls = (
        <Tooltip title="Disapprove match">
          <ButtonCross
            data-testid="disapprove-match"
            onClick={() => unapprove({ match, daltixId })}
          />
        </Tooltip>
      )
      break
    case STATUS_DISCARDED:
      Controls = (
        <Tooltip title="Restore match">
          <ButtonAdd
            data-testid="restore-match"
            onClick={() => restore({ match, daltixId })}
          />
        </Tooltip>
      )
      break
    case STATUS_REVIEW:
      Controls = (
        <>
          <Tooltip title="Approve match">
            <ButtonCheck
              data-testid="approve-match"
              onClick={() => approve({ match, daltixId })}
            />
          </Tooltip>
          <Tooltip title="Discard match">
            <ButtonCross
              data-testid="discard-match"
              onClick={() => discard({ match, daltixId })}
            />
          </Tooltip>
        </>
      )
      break
    default:
      Controls = null
  }

  return (
    <div className={clsx(styles.controls, outdated && styles.outdated)}>{Controls}</div>
  )
}

MatchControls.propTypes = {
  daltixId: PropTypes.string.isRequired,
  matchId: PropTypes.string.isRequired,
  outdated: PropTypes.bool.isRequired,
}

function ReferenceProductControls({ productId: _ }) {
  return <div className={styles.controls} />
}

ReferenceProductControls.propTypes = {
  productId: PropTypes.string.isRequired,
}

function ProductComparisonTableHeaderControls({
  daltixId,
  isMatch,
  matchId,
  productId,
  outdated,
}) {
  return (
    <ProductComparisonTableHeaderContainer testID="product-comparison-control">
      {isMatch ? (
        <MatchControls daltixId={daltixId} matchId={matchId} outdated={outdated} />
      ) : (
        <ReferenceProductControls productId={productId} />
      )}
    </ProductComparisonTableHeaderContainer>
  )
}

ProductComparisonTableHeaderControls.propTypes = {
  daltixId: PropTypes.string,
  isMatch: PropTypes.bool.isRequired,
  matchId: PropTypes.string,
  productId: PropTypes.string.isRequired,
  outdated: PropTypes.bool,
}

ProductComparisonTableHeaderControls.defaultProps = {
  daltixId: "",
  matchId: "",
  outdated: false,
}

export { ProductComparisonTableHeaderControls }
