import React from "react"
import PropTypes from "prop-types"
import { Box, Divider } from "@mui/material"
import AppCard from "../app-card"

import "./style.sass"

function AppCardGroup({ apps, last }) {
  return (
    <>
      <Box className="app-card-group">
        {apps.map((app) => (
          <AppCard key={app.id} {...app} />
        ))}
      </Box>
      {!last && <Divider />}
    </>
  )
}

AppCardGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  apps: PropTypes.arrayOf(PropTypes.object).isRequired,
  last: PropTypes.bool.isRequired,
}

export default AppCardGroup
