import { get } from "lodash"
import useAsyncSlice from "../../../hooks/use-async-slice"
import { triggerGetReferenceProductsMatchesByShop } from "../../../ducks/pages/matches-overview/action-creators"
import { MATCHES_OVERVIEW_PAGE_REQUEST_KEY } from "../../../ducks/pages/matches-overview/constants"

const action = triggerGetReferenceProductsMatchesByShop()

function selector(state) {
  return get(state, ["pages", "MatchesOverviewPage", MATCHES_OVERVIEW_PAGE_REQUEST_KEY])
}

export default function useMatchesOverviewTable() {
  return useAsyncSlice({
    action,
    selector,
  })
}
