import { get } from "lodash"
import { useSelector } from "react-redux"
import {
  ASSORTMENT_ANALYSIS_PAGE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

export function useSelectorSeletedOptions() {
  const selectedFilters = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY,
    ]),
  )

  return { selectedFilters }
}
