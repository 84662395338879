import React from "react"
import PropTypes from "prop-types"
import { YandexMetrika } from "./yandex-metrika"

function ExternalScripts({ children }) {
  return <YandexMetrika>{children}</YandexMetrika>
}

ExternalScripts.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ExternalScripts
