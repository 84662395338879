import PropTypes from "prop-types"
import React from "react"
import useRestricted, {
  defaultRequiredFeatureFlags,
  defaultRequiredFeatures,
  defaultRequiredPermissions,
} from "../../hooks/use-restricted"

function RestrictedContent({
  children,
  requiredFeatures,
  requiredPermissions,
  requiredFeatureFlags,
}) {
  const { hasAccess } = useRestricted({
    requiredFeatures,
    requiredPermissions,
    requiredFeatureFlags,
  })

  if (!hasAccess) return null

  return children
}

RestrictedContent.propTypes = {
  children: PropTypes.node.isRequired,
  requiredFeatureFlags: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    ),
  ]),
  requiredFeatures: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    ),
  ]),
  requiredPermissions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    ),
  ]),
}

RestrictedContent.defaultProps = {
  requiredFeatureFlags: defaultRequiredFeatureFlags,
  requiredFeatures: defaultRequiredFeatures,
  requiredPermissions: defaultRequiredPermissions,
}

export default RestrictedContent
