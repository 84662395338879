/* eslint-disable import/prefer-default-export */

export const handleListProductMatches = (state) => ({
  ...state,
})

export const handleListProductMatchesSuccess = (
  state,
  {
    payload: {
      matches,
      meta: { sourceId },
    },
  },
) => {
  const { matches: currentMatches } = state
  const newMatches = {}

  matches.forEach(({ id, daltix_id: daltixId }) => {
    if (!currentMatches[id]) {
      newMatches[id] = {
        id,
        ref_product_id: sourceId,
        daltix_id: daltixId,
      }
    }
  })

  const matchIdIternalNewEntries = {}
  matches.forEach(({ id, daltix_id: daltixId }) => {
    matchIdIternalNewEntries[id] = {
      ref_product_id: sourceId,
      daltix_id: daltixId,
    }
  })

  const { matchIdInternalMap } = state

  return {
    ...state,
    matchIdInternalMap: {
      ...matchIdInternalMap,
      ...matchIdIternalNewEntries,
    },
    matches: {
      ...currentMatches,
      ...newMatches,
    },
  }
}

export const handleListProductMatchesError = (state) => ({
  ...state,
})
