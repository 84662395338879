import {
  PRODUCT_SEARCH_GET_SEARCH_FILTERS,
  PRODUCT_SEARCH_LIST_BY_QUERY,
  PRODUCT_SEARCH_LIST_MATCHES,
  PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY,
  PRODUCT_SEARCH_SET_SELECTED_FILTERS,
} from "./action-types"

export function listProductsByQuery(query, options) {
  return {
    type: PRODUCT_SEARCH_LIST_BY_QUERY,
    query,
    options: {
      ...options,
      fields: ["_id"],
    },
  }
}

export function getProductsSearchFilters(query) {
  return {
    type: PRODUCT_SEARCH_GET_SEARCH_FILTERS,
    query,
  }
}

export function listExistingMatchesById(referenceProductId, options) {
  return {
    type: PRODUCT_SEARCH_LIST_MATCHES,
    referenceProductId,
    options,
  }
}

export function setSelectedFilters(filters) {
  return {
    type: PRODUCT_SEARCH_SET_SELECTED_FILTERS,
    filters,
  }
}

export function getReferenceProductSummary(referenceProductId) {
  return {
    type: PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY,
    referenceProductId,
  }
}
