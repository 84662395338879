import React from "react"
import PropTypes from "prop-types"

import clsx from "clsx"
import styles from "./TableCellItemPrice.module.scss"
import { EUR_SYMBOL } from "../../../util/constants"

/**
 * Given the Matched Product is 500ml and the Reference Product has 700ml.
 * Then factor should be (500/700) = ~0.714
 * The left component (A) would be the buying `price` of the Matched Product.
 * The right component (B) should be A * 0.714 := `price` * `factor`
 *
 * Thus, if the matched product has more quantity/volume than the refence product
 * it is expected that B < A. If the match product has less quantity/volume
 * it is expected that A > B
 */
function TableCellItemPrice({ factor, price }) {
  const enforcedFactor = factor ?? 1
  const factoredPrice = enforcedFactor * price

  return (
    <div className={styles.container}>
      {enforcedFactor !== 1 && (
        <span className={clsx(styles.price, "text-strikethrough")}>
          {EUR_SYMBOL}
          {price.toFixed(2)}
        </span>
      )}
      <span className={clsx(styles.price, "text-bold")}>
        {EUR_SYMBOL}
        {factoredPrice.toFixed(2)}
      </span>
    </div>
  )
}

TableCellItemPrice.propTypes = {
  price: PropTypes.number.isRequired,
  factor: PropTypes.number,
}

TableCellItemPrice.defaultProps = {
  factor: 1,
}

export default TableCellItemPrice
