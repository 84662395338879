import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {[number | undefined, ({ id }) => void]} the currently selected product group id and a setter function
 */
export function useProductGroupFilter() {
  const dispatch = useDispatch()

  const productGroupId = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
      ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
    ]),
  )

  function setProductGroupId({ id }) {
    dispatch(Actions.setAssortmentAnalysisProductGroupFilter({ id }))
  }

  return [productGroupId, setProductGroupId]
}
