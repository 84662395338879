import clsx from "clsx"
import PropTypes from "prop-types"
import React, { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import {
  setMatchesOverviewPage,
  setMatchesOverviewPageSize,
} from "../../ducks/pages/matches-overview/action-creators"
import Image from "../img/Image"
import { LoaderSpinner, Table } from "../ui"
import MatchesOverviewReferenceProduct from "./MatchesOverviewReferenceProduct"
import MatchesOverviewShop from "./MatchesOverviewShop"
import MatchesOverviewShopMatchesList from "./MatchesOverviewShopMatchesList"

import styles from "./MatchesOverviewTable.module.scss"
import useMatchesOverviewPageMeta from "./hooks/use-matches-overview-page-meta"
import useMatchesOverviewPagination from "./hooks/use-matches-overview-pagination"
import useMatchesOverviewTable from "./hooks/use-matches-overview-table"

function useTanstackPaginationAdapter() {
  const dispatch = useDispatch()

  const { page, pageCount, pageSize, resultsCount } = useMatchesOverviewPagination()

  /**
   * Redux state page and pageCount are in user-land (e.g.: 1 through 17)
   * However, react table is in programmer-land, (e.g.: 0 through 16)
   *
   * Hence we create pageIndex
   *
   * Note: pageCount nd pageSize are universal
   */
  const pageIndex = useMemo(() => page - 1, [page])

  const paginationState = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageIndex, pageSize],
  )

  const paginationStateSetter = useCallback(
    (updater) => {
      const nextState = updater(paginationState)

      if (paginationState.pageIndex !== nextState.pageIndex) {
        // Convert pageIndex to user-land page
        dispatch(setMatchesOverviewPage(nextState.pageIndex + 1))
      }

      if (paginationState.pageSize !== nextState.pageSize) {
        dispatch(setMatchesOverviewPageSize(nextState.pageSize))
      }
    },
    [dispatch, paginationState],
  )

  return {
    pageCount,
    paginationState,
    paginationStateSetter,
    resultsCount,
  }
}

function NoResultsDisplay({ children }) {
  return (
    <div role="alert" className={styles["no-reference-products-container"]}>
      <Image
        className="table-cell-image"
        alt="no-results-image"
        src="/assets/images/undraw_empty_re_opql.svg"
      />
      <h1 className={styles["no-reference-products-message"]}>{children}</h1>
    </div>
  )
}

NoResultsDisplay.propTypes = {
  children: PropTypes.string.isRequired,
}

function MatchesOverviewTable() {
  const { data } = useMatchesOverviewTable()
  const { hasProducts, hasShops } = useMatchesOverviewPageMeta()
  const { pageCount, paginationState, paginationStateSetter, resultsCount } =
    useTanstackPaginationAdapter()

  const columns = useMemo(() => {
    if (!data) {
      return undefined
    }

    if (data.length === 0) {
      return []
    }

    const { shops } = data[0]
    const dinamicColumns = Object.entries(shops).map(
      ([countryAndCode, { country, shop }]) => ({
        accessorKey: countryAndCode,
        header: () => <MatchesOverviewShop country={country} id={shop} />,
        footer: null,
        cell: (c) => (
          <MatchesOverviewShopMatchesList
            matches={c.getValue().matches}
            matchedReferenceProductId={c.row.getValue("productId")}
            shopKey={countryAndCode}
            shop={shop}
            country={country}
          />
        ),
      }),
    )

    const refProductColumn = {
      accessorKey: "productId",
      header: null,
      footer: null,
      cell: (c) => <MatchesOverviewReferenceProduct productId={c.getValue()} />,
    }

    return [refProductColumn, ...dinamicColumns]
  }, [data])

  const adaptedData = useMemo(
    () => data?.map(({ productId, shops }) => ({ productId, ...shops })),
    [data],
  )

  const isLoading = !data || !columns
  if (isLoading) {
    return <LoaderSpinner />
  }

  const isEmpty = !hasProducts || columns.length === 0
  if (isEmpty) {
    return (
      <NoResultsDisplay>
        It the appears we have no reference products to display, using the currently
        active criteria (filters).
      </NoResultsDisplay>
    )
  }

  if (!hasShops) {
    return (
      <NoResultsDisplay>
        It the appears we have no shops to display, using the currently active criteria
        (filters).
      </NoResultsDisplay>
    )
  }

  return (
    <div className={styles.container}>
      <Table.Root
        className={clsx("grow", styles.table)}
        columns={columns}
        data={adaptedData}
        data-testid="matches-overview-table"
        pageCount={pageCount}
        paginationState={paginationState}
        paginationStateSetter={paginationStateSetter}
        resultsCount={resultsCount}
        stickyXHeader
        stickyYHeader
        stripedColumns
        xHeaderId="productId"
      />
    </div>
  )
}

export default MatchesOverviewTable
