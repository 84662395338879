// Action Types

const URL_UPDATE_FROM_STATE = "@url/UPDATE_FROM_STATE"

const actionTypes = {
  URL_UPDATE_FROM_STATE,
}

// Action Creators

const updateUrlFromState = () => ({
  type: URL_UPDATE_FROM_STATE,
})

const actionCreators = {
  updateUrlFromState,
}

// Epics

const epics = []

// reducer

const initalState = {}

const reducer = (state = initalState, { type }) => {
  switch (type) {
    default:
      return state
  }
}

// exports

export { actionTypes, actionCreators, epics }

export default reducer
