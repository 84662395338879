import { oneOf, string } from "prop-types"
import React, { memo } from "react"
import { useSelector } from "react-redux"

import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
  STATUS_UNMATCHED,
} from "../../util/match-status"
import { DXIcon } from "../dx-icon/DXIcon"

const MatchStatusIcon = memo(({ status, matchId, testID }) => {
  const updatedStatus = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.status,
  )

  const effectiveStatus = updatedStatus || status

  if (effectiveStatus === STATUS_APPROVED) {
    return <DXIcon testID={testID} type={effectiveStatus} />
  }

  if (effectiveStatus === STATUS_DISCARDED) {
    return <DXIcon testID={testID} type={effectiveStatus} />
  }

  if (effectiveStatus === STATUS_REVIEW) {
    return <DXIcon testID={testID} type={effectiveStatus} />
  }

  if (effectiveStatus === STATUS_UNMATCHED) {
    return <DXIcon testID={testID} type={effectiveStatus} />
  }

  return null
})

export function useStatusTooltip(status, matchId) {
  let statusTooltip

  const updatedStatus = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.status,
  )

  const effectiveStatus = updatedStatus || status

  switch (effectiveStatus) {
    case STATUS_APPROVED:
      statusTooltip = "Approved"
      break
    case STATUS_DISCARDED:
      statusTooltip = "Discarded"
      break
    case STATUS_UNMATCHED:
      statusTooltip = "Unmatched"
      break
    case STATUS_REVIEW:
      statusTooltip = "In Review"
      break

    default:
      break
  }

  return statusTooltip
}

MatchStatusIcon.propTypes = {
  testID: string,
  status: oneOf([STATUS_APPROVED, STATUS_DISCARDED, STATUS_REVIEW, STATUS_UNMATCHED]),
  matchId: string,
}

MatchStatusIcon.defaultProps = {
  testID: "",
  status: "",
  matchId: "",
}

export { MatchStatusIcon }
