import PropTypes from "prop-types"
import React from "react"
import ReactPlaceholder from "react-placeholder"
import clsx from "clsx"

import styles from "./ProductComparisonTableHeaderImage.module.scss"

import { ProductComparisonTableHeaderContainer } from "./ProductComparisonTableHeaderContainer"

import Image from "../../img/Image"
import useReferenceProduct from "../../../hooks/use-reference-product"
// import useDaltixProduct from "../../../hooks/use-daltix-product"
import { ComparisonData } from "./ComparisonDataPropType"

function Placeholder() {
  return (
    <ReactPlaceholder
      className="rounded"
      color="#f5f5f5"
      showLoadingAnimation
      style={{ width: "6rem", height: "6rem" }}
      type="rect"
    />
  )
}

function MatchImage({ daltixId: _daltixId, matchData, matchId: _, outdated }) {
  // const { data, isReady } = useDaltixProduct(daltixId)
  const isReady = matchData?.isReady
  const data = matchData?.data?.match

  if (!isReady) {
    return <Placeholder />
  }

  return (
    <main className={clsx(styles.frame, outdated && styles.outdated)}>
      <Image className={styles.image} alt={data.name} src={data.images} />
    </main>
  )
}

MatchImage.propTypes = {
  daltixId: PropTypes.string.isRequired,
  matchData: ComparisonData.isRequired,
  matchId: PropTypes.string.isRequired,
  outdated: PropTypes.bool.isRequired,
}

function ReferenceProductImage({ productId }) {
  const { data, isReady } = useReferenceProduct(productId)

  if (!isReady) {
    return <Placeholder />
  }

  return (
    <div className={styles.frame}>
      <Image className={styles.image} alt={data.name} src={data.images} />
    </div>
  )
}

ReferenceProductImage.propTypes = {
  productId: PropTypes.string.isRequired,
}

function ProductComparisonTableHeaderImage({
  daltixId,
  isMatch,
  matchData,
  matchId,
  productId,
  outdated,
}) {
  return (
    <ProductComparisonTableHeaderContainer>
      {isMatch ? (
        <MatchImage
          daltixId={daltixId}
          matchData={matchData?.match}
          matchId={matchId}
          outdated={outdated}
        />
      ) : (
        <ReferenceProductImage productId={productId} />
      )}
    </ProductComparisonTableHeaderContainer>
  )
}

ProductComparisonTableHeaderImage.propTypes = {
  daltixId: PropTypes.string,
  isMatch: PropTypes.bool.isRequired,
  matchData: ComparisonData.isRequired,
  matchId: PropTypes.string,
  productId: PropTypes.string.isRequired,
  outdated: PropTypes.bool,
}

ProductComparisonTableHeaderImage.defaultProps = {
  daltixId: "",
  matchId: "",
  outdated: false,
}

export { ProductComparisonTableHeaderImage }
