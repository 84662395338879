import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import queryString from "query-string"
import MatchingPanelPresenter from "./MatchingPanelPresenter"
import { PAGE_SIZE } from "../../ducks/reference-products/common"
import { actionCreators as refProductActions } from "../../ducks/reference-products"
import { actionCreators as dxProductActions } from "../../ducks/daltix-product"
// import { actionCreators as categoryActions } from '../../ducks/category';
import {
  MATCHES_PREFETCHING_DELAY,
  SELECTED_PRODUCT_MATCHES_PREFETCHING_DELAY,
} from "../../util/constants"
import { Logger } from "../../util/log"
import useFeatureRoute from "../../hooks/use-feature-route"
import { QP_ID, QP_QUERY } from "../../util/query-param"

const log = new Logger("ui:MatchingPanel")

function MatchingPanel({
  allPagesList,
  referenceProducts,
  page,
  offset,
  productIndex,
  productId,
  loadingExistingMatches,
  searchResultsMatchingStatus,
  matchingViewEnabled = true,
  // actions
  triggerListReferenceProducts,
  listReferenceProducts,
  listExistingMatches,
  clearNewSearchMatches,
  // location
  location,
}) {
  const dispatch = useDispatch()

  const query = queryString.parse(location.search)
  const queryProductId = query[QP_ID]
  const productQuery = query[QP_QUERY]
  const prevProductId =
    productIndex !== undefined &&
    allPagesList.length > 0 &&
    allPagesList[productIndex - 1 - offset + (page > 1 ? PAGE_SIZE : 0)]
  const nextProductId =
    productIndex !== undefined &&
    allPagesList.length > 0 &&
    allPagesList[productIndex + 1 - offset + (page > 1 ? PAGE_SIZE : 0)]

  const queryProductExists = referenceProducts[queryProductId] !== undefined
  const productExists = referenceProducts[productId] !== undefined
  const prevProductExists = referenceProducts[prevProductId] !== undefined
  const nextProductExists = referenceProducts[nextProductId] !== undefined

  useFeatureRoute({ condition: matchingViewEnabled })

  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (firstLoad && allPagesList.length === 0) {
      triggerListReferenceProducts(page)
      setFirstLoad(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad, allPagesList, listReferenceProducts])

  function loadMatches([id, delay = 0]) {
    const product = referenceProducts[id]

    if (product) {
      const { existingMatches } = product

      const hasDirtyExistingMatches =
        existingMatches && existingMatches.some(({ dirty }) => dirty)
      const dirtySearchNewMatches = searchResultsMatchingStatus[id]
      const hasDirtyMatches = hasDirtyExistingMatches

      const loadingExisting = loadingExistingMatches.includes(id)

      const hasValidExisting = existingMatches

      const nowTimestamp = Date.now()

      if (
        (hasDirtyMatches || dirtySearchNewMatches || !hasValidExisting) &&
        !loadingExisting
      ) {
        if (dirtySearchNewMatches) {
          clearNewSearchMatches(id)
        }

        setTimeout(() => {
          log.debug("fetching existing matches for product:", id)
          listExistingMatches(id, { requestedAt: nowTimestamp })
        }, delay)
      }
    }
  }

  useEffect(() => {
    if (queryProductId && !queryProductExists) {
      log.debug("fetch product from query id:", queryProductId)
      dispatch(refProductActions.filterReferenceProductsById(queryProductId))
    }
  }, [dispatch, queryProductId, queryProductExists])

  useEffect(() => {
    if (productQuery) {
      log.debug("fetch product from query:", productQuery)
      dispatch(refProductActions.filterReferenceProductsByQuery(productQuery))
    }
  }, [dispatch, productQuery])

  useEffect(() => {
    if (productId && productExists) {
      log.debug("fetch product matches:", productId)
      loadMatches([productId, SELECTED_PRODUCT_MATCHES_PREFETCHING_DELAY])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, productExists])

  useEffect(() => {
    if (prevProductId && prevProductExists) {
      log.debug("fetch prev product matches:", prevProductId)
      loadMatches([prevProductId, MATCHES_PREFETCHING_DELAY])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProductId, prevProductExists])

  useEffect(() => {
    if (nextProductId && nextProductExists) {
      log.debug("fetch next product matches:", nextProductId)
      loadMatches([nextProductId, MATCHES_PREFETCHING_DELAY])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextProductId, nextProductExists])

  return <MatchingPanelPresenter matchingViewEnabled={matchingViewEnabled} />
}

const mapStateToProps = ({
  daltixProduct: { searchResultsMatchingStatus },
  refProduct: {
    referenceProducts,
    list,
    prevPageList,
    nextPageList,
    detailedProductIndex,
    offset,
    page,
    loadingExistingMatches,
  },
  user: { user: { customer: { matchingViewEnabled } = {} } = {} },
}) => ({
  allPagesList: [...prevPageList, ...list, ...nextPageList],
  referenceProducts,
  page,
  offset,
  loadingExistingMatches,
  productIndex: detailedProductIndex,
  productId:
    detailedProductIndex !== undefined && list.length > 0
      ? list[detailedProductIndex - offset]
      : undefined,
  searchResultsMatchingStatus,
  matchingViewEnabled,
})

export default connect(mapStateToProps, { ...refProductActions, ...dxProductActions })(
  MatchingPanel,
)
