import { map, mergeMap, catchError, delay, takeUntil, switchMap } from "rxjs/operators"
import { from, of } from "rxjs"
import { ofType } from "redux-observable"

// action types
const DO_NOTHING_ACTION = "@foo/DO_NOTHING"

// internal stuff
const CANCEL_SUFFIX = "_CANCEL"
const SUCCESS_SUFFIX = "_SUCCESS"
const ERROR_SUFFIX = "_ERROR"
const INVALID_SUFFIX = "_INVALID"
const MIN_DELAY = 0

// helper functions
const cancelType = (type) => type + CANCEL_SUFFIX
const successType = (type) => type + SUCCESS_SUFFIX
const errorType = (type) => type + ERROR_SUFFIX
const invalidType = (type) => type + INVALID_SUFFIX

function successAction(actionType, parent) {
  return (payload) => ({
    type: successType(actionType),
    parent,
    payload,
  })
}

function errorAction(actionType, parent) {
  return (error) => ({
    type: errorType(actionType),
    parent,
    error,
  })
}

function invalidAction(actionType, parent) {
  return () => ({
    type: invalidType(actionType),
    parent,
  })
}

function defaultValidation() {
  return true
}

function buildEpic(actionType, fetch, valid = defaultValidation, override = false) {
  return (action$, state$) =>
    action$.pipe(
      ofType(actionType),
      // filter(action => fallbackAction || valid(action, state$.value)),
      (override ? switchMap : mergeMap)((action) => {
        if (valid(action, state$.value)) {
          return from(fetch(action, state$.value)).pipe(
            delay(MIN_DELAY),
            map(successAction(actionType, action)),
            takeUntil(action$.pipe(ofType(cancelType(actionType)))),
            catchError((error) => of(errorAction(actionType, action)(error))),
          )
        }
        return of(invalidAction(actionType, action)())
      }),
    )
}

const doNothing = () => ({
  type: DO_NOTHING_ACTION,
})

const actionCreators = {
  doNothing,
}

// exports

export {
  actionCreators,
  cancelType,
  successType,
  errorType,
  invalidType,
  buildEpic,
  successAction,
  errorAction,
  invalidAction,
}
