import React, { useCallback } from "react"
import { Helmet } from "react-helmet"
import mixpanel from "mixpanel-browser"
import PropTypes from "prop-types"

const appName = "Daltix Navigator"

function HtmlTitle({ children }) {
  const htmlTitle = React.Children.toArray(children).join(" ")

  const handleTitleChange = useCallback(() => {
    mixpanel.track(`Open: ${document.title}`)
  }, [])

  return (
    <Helmet onChangeClientState={handleTitleChange}>
      <title>{`${appName}${htmlTitle ? ` - ${htmlTitle}` : ""}`}</title>
    </Helmet>
  )
}

HtmlTitle.propTypes = {
  children: PropTypes.node,
}

HtmlTitle.defaultProps = {
  children: null,
}

export default HtmlTitle
