import React from "react"
import { Tooltip } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import DialogContent from "@material-ui/core/DialogContent"
import CloseIcon from "@material-ui/icons/CloseRounded"

import { isElement, isReactComponent } from "../../util/react"
import "./style.sass"
import ImageZoom from "../image-zoom"
import { titleCase } from "../../util/strings"

function getRenderableValue(value) {
  if (
    !!value &&
    value.constructor === Object &&
    !isReactComponent(value) &&
    !isElement(value)
  ) {
    return JSON.stringify(value)
  }

  if (Array.isArray(value)) {
    return value.join(", ")
  }

  return value
}

function ComparisonRow({
  attribute,
  items,
  options: { center = true, imagesAttributeName = "images", attributeMap = {} } = {},
}) {
  const centered = center && items.length === 2

  const mappedAttribute = attributeMap[attribute]
  const mappedAttributeLabel = (mappedAttribute && mappedAttribute.label) || attribute
  const mappedAttributeTitle =
    (mappedAttribute && mappedAttribute.title) || titleCase(attribute)
  const mappedAttributeStyle = mappedAttribute && mappedAttribute.style
  const attributeLabel =
    attribute === "title" || attribute === imagesAttributeName
      ? ""
      : mappedAttributeLabel

  const getAttributeValue = (item) => {
    const rawValue = item[attribute]

    return attribute === imagesAttributeName ? (
      <ImageZoom
        src={rawValue}
        imageProps={{
          className: "dx-comparison-table-picture expandable",
        }}
      />
    ) : (
      getRenderableValue(rawValue)
    )
  }

  const attributeNameColumn = (
    <td key="attribute-name-column" className="dx-column-attribute-name">
      <Tooltip classes={{ tooltip: "tooltip" }} title={mappedAttributeTitle}>
        <div>{attributeLabel}</div>
      </Tooltip>
    </td>
  )

  let rowClassName = "dx-comparison-row "
  if (centered) {
    rowClassName += "dx-comparison-row-centered "
  }
  if (attribute === imagesAttributeName) {
    rowClassName += "dx-image-row "
  }
  if (attribute === "title") {
    rowClassName += "dx-compariton-row-title "
  }

  const columns = items.map((item, idx) => (
    <td
      key={`attribute-${item.id || idx}`}
      className={`dx-column-value dx-column-${idx + 1} ${
        attribute === imagesAttributeName ? "dx-image-column" : ""
      }`}
      style={{ width: `${100 / items.length}%`, ...mappedAttributeStyle }}
    >
      {getAttributeValue(item)}
    </td>
  ))

  const attributeNameColumnPosition = centered ? 1 : 0
  columns.splice(attributeNameColumnPosition, 0, attributeNameColumn)

  return <tr className={rowClassName}>{columns}</tr>
}

function ComparisonTable({
  items = [],
  options,
  options: {
    attributes,
    imagesAttributeName = "images",
    titleAttributeName = "title",
    subTitleAttributeName = "subtitle",
  } = {},
}) {
  const existingAttributes = items.reduce(
    (result, item) => [...result, ...Object.keys(item)],
    [],
  )

  const selectedAttributes = attributes
    ? [
        imagesAttributeName,
        titleAttributeName,
        subTitleAttributeName,
        ...attributes
          .map(({ name }) => name)
          .filter((attr) => items.some((item) => item[attr])),
      ]
    : existingAttributes

  const attributeMap =
    attributes &&
    attributes.reduce((acc, { name, ...rest }) => {
      acc[name] = rest

      return acc
    }, {})

  const finalAttributes = [...new Set(selectedAttributes)]

  const imagesAttribute = finalAttributes.includes(imagesAttributeName)
    ? finalAttributes.find((name) => name === imagesAttributeName)
    : undefined
  const titleAttribute = finalAttributes.includes(titleAttributeName)
    ? finalAttributes.find((name) => name === titleAttributeName)
    : undefined
  const subTilteAttribute = finalAttributes.includes(subTitleAttributeName)
    ? finalAttributes.find((name) => name === subTitleAttributeName)
    : undefined
  const otherAttributes = finalAttributes.filter(
    (name) =>
      name !== subTitleAttributeName &&
      name !== titleAttributeName &&
      name !== imagesAttributeName,
  )

  const finalAttributesList = [...otherAttributes]

  let finalItems = items

  if (titleAttribute || subTilteAttribute) {
    finalItems = items.map(
      ({ [titleAttribute]: title, [subTilteAttribute]: subTitle, ...rest }) => ({
        title: (
          <div className="dx-comparison-table-title-container">
            {subTilteAttribute && (
              <div className="dx-comparison-table-subtitle">{subTitle}</div>
            )}

            {titleAttribute && <div className="dx-comparison-table-title">{title}</div>}
          </div>
        ),
        ...rest,
      }),
    )

    finalAttributesList.unshift("title")
  }

  if (imagesAttribute) {
    finalAttributesList.unshift(imagesAttribute)
  }

  return (
    <div className="dx-comparison-table">
      <table>
        <tbody>
          {finalAttributesList.map((attribute) => (
            <ComparisonRow
              key={attribute}
              attribute={attribute}
              items={finalItems}
              options={{ ...options, attributeMap }}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

function ComparisonPopup({ items = [], open = false, onClose, options }) {
  return (
    <Dialog
      className="dialog dx-comparison-popup"
      scroll="paper"
      open={open}
      fullWidth
      maxWidth="xl"
      onClose={onClose}
    >
      <DialogContent className="dx-comparison-popup-content">
        <div className="dx-dialog-header">
          <IconButton className="close-button" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <ComparisonTable items={items} options={options} />
      </DialogContent>
    </Dialog>
  )
}

export default ComparisonPopup
