import PropTypes from "prop-types"
import React from "react"

import * as RUISelect from "@radix-ui/react-select"

import clsx from "clsx"

import styles from "./BaseSelectViewport.module.scss"

function BaseSelectViewport({ children, className, ...props }) {
  return (
    <RUISelect.Viewport {...props} className={clsx(styles.viewport, className)}>
      {children}
    </RUISelect.Viewport>
  )
}

BaseSelectViewport.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

BaseSelectViewport.defaultProps = {
  className: "",
}

export { BaseSelectViewport }
