import { errorType, successType } from "../../../util/redux-observable-helpers"

/* -------------------------------------------------------------------------- */
/*                                    async                                   */
/* -------------------------------------------------------------------------- */
export const ENQUEUE_GET_REF_PRODUCTS_MATCHES_BY_SHOP =
  "@matches-overview-page/ENQUEUE_GET_REF_PRODUCTS_MATCHES_BY_SHOP"

export const GET_REF_PRODUCTS_MATCHES_BY_SHOP =
  "@matches-overview-page/GET_REF_PRODUCTS_MATCHES_BY_SHOP"

export const GET_REF_PRODUCTS_MATCHES_BY_SHOP_SUCCESS = successType(
  GET_REF_PRODUCTS_MATCHES_BY_SHOP,
)

export const GET_REF_PRODUCTS_MATCHES_BY_SHOP_ERROR = errorType(
  GET_REF_PRODUCTS_MATCHES_BY_SHOP,
)

export const GET_REF_PRODUCTS_MATCHES_BY_SHOP_META =
  "@matches-overview-page/GET_REF_PRODUCTS_MATCHES_BY_SHOP_META"

export const GET_REF_PRODUCTS_MATCHES_BY_SHOP_META_SUCCESS = successType(
  GET_REF_PRODUCTS_MATCHES_BY_SHOP_META,
)

export const GET_REF_PRODUCTS_MATCHES_BY_SHOP_META_ERROR = errorType(
  GET_REF_PRODUCTS_MATCHES_BY_SHOP_META,
)

export const TRIGGER_APPLY_MATCHES_OVERVIEW_FILTERS =
  "@matches-overview-page/TRIGGER_APPLY_MATCHES_OVERVIEW_FILTERS"

export const TRIGGER_GET_REF_PRODUCTS_MATCHES_BY_SHOP =
  "@matches-overview-page/TRIGGER_GET_REF_PRODUCTS_MATCHES_BY_SHOP"

/* -------------------------------------------------------------------------- */
/*                                  mutations                                 */
/* -------------------------------------------------------------------------- */

export const CLEAR_MATCHES_OVERVIEW_APPLIED_FILTERS =
  "@matches-overview-page/CLEAR_MATCHES_OVERVIEW_APPLIED_FILTERS"

export const CLEAR_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH =
  "@matches-overview-page/CLEAR_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH"

export const CLEAR_MATCHES_OVERVIEW_UNSTAGED_FILTERS =
  "@matches-overview-page/CLEAR_MATCHES_OVERVIEW_UNSTAGED_FILTERS"

export const MATCHES_OVERVIEW_REHYDRATE_FROM_PREVIOUS_STATE =
  "@matches-overview-page/REHYDRATE_FROM_PREVIOUS_STATE"

export const READ_MATCHES_OVERVIEW_URL_STATE =
  "@matches-overview-page/READ_MATCHES_OVERVIEW_URL_STATE"

export const SET_MATCHES_OVERVIEW_PAGE =
  "@matches-overview-page/SET_MATCHES_OVERVIEW_PAGE"

export const SET_MATCHES_OVERVIEW_PAGE_SIZE =
  "@matches-overview-page/SET_MATCHES_OVERVIEW_PAGE_SIZE"

export const SET_MATCHES_OVERVIEW_MATCH_STATUSES_FILTER =
  "@matches-overview-page/SET_MATCHES_OVERVIEW_MATCH_STATUSES_FILTER"

export const SET_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH =
  "@matches-overview-page/SET_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH"

export const STAGE_FILTER_MATCH_COUNTRY =
  "@matches-overview-page/STAGE_FILTER_MATCH_COUNTRY"

export const STAGE_FILTER_MATCH_SHOP = "@matches-overview-page/STAGE_FILTER_MATCH_SHOP"

export const STAGE_FILTER_REF_PRODUCTS_CATEGORY =
  "@matches-overview-page/STAGE_FILTER_REF_PRODUCTS_CATEGORY"

export const STAGE_FILTER_REF_PRODUCTS_FAMILY =
  "@matches-overview-page/STAGE_FILTER_REF_PRODUCTS_FAMILY"

export const TOGGLE_FILTER_REF_PRODUCTS_WITHOUT_MATCHES =
  "@matches-overview-page/TOGGLE_FILTER_REF_PRODUCTS_WITHOUT_MATCHES"

/** @deprecated */
export const TOGGLE_FILTER_APPROVED_MATCHES =
  "@matches-overview-page/TOGGLE_FILTER_APPROVED_MATCHES"

/** @deprecated */
export const TOGGLE_FILTER_DISCARDED_MATCHES =
  "@matches-overview-page/TOGGLE_FILTER_DISCARDED_MATCHES"

/** @deprecated */
export const TOGGLE_FILTER_IN_REVIEW_MATCHES =
  "@matches-overview-page/TOGGLE_FILTER_IN_REVIEW_MATCHES"
