import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import app from "../ducks/app"
import daltixProduct from "../ducks/daltix-product"
import matches from "../ducks/matches"
import notification from "../ducks/notification"
import refProduct from "../ducks/reference-products"
import user, { actionTypes as userActions } from "../ducks/user"
import AssortmentAnalysisPage from "../ducks/pages/assortment-analysis"
import DaltixProductsRepository from "../ducks/repositories/daltix-products"
import MatchedProductsRepository from "../ducks/repositories/matched-products"
import MatchesOverviewPage from "../ducks/pages/matches-overview"
import ProductComparisonWidget from "../ducks/widgets/product-comparison"
import ProductSearchWidget from "../ducks/widgets/product-search"
import ReferenceProductsRepository from "../ducks/repositories/reference-products"
import SideNavigation from "../ducks/side-navigation"

import report from "../ducks/report"

export default (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    app,
    daltixProduct,
    report,
    matches,
    notification,
    refProduct,
    user,
    pages: combineReducers({
      AssortmentAnalysisPage,
      MatchesOverviewPage,
    }),
    repositories: combineReducers({
      DaltixProductsRepository,
      MatchedProductsRepository,
      ReferenceProductsRepository,
    }),
    widgets: combineReducers({
      ProductComparisonWidget,
      ProductSearchWidget,
    }),
    navigation: SideNavigation,
  })

  const rootReducer = (state, action) => {
    if (action.type === userActions.USER_LOG_OUT_SUCCESS) {
      return appReducer({ router: state.router }, action)
    }

    return appReducer(state, action)
  }

  return rootReducer
}
