import { ofType } from "redux-observable"
import { debounceTime, map, filter } from "rxjs/operators"
import { get } from "lodash"
import * as APIService from "../../../api/service"

import * as Actions from "./action-types"
import * as MatchActions from "../../matches/action-types"

import { withParent } from "../../../util/epics"
import {
  enqueueGetReferenceProductMatches,
  getReferenceProductsMatches,
} from "./action-creators"
import { buildEpic } from "../../../util/redux-observable-helpers"
import {
  PRODUCT_COMPARISON_WIDGET_KEY,
  PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY,
} from "./constants"

const DEBOUNCE_MILLISECONDS = 50

function getReferenceProductIdState(state) {
  return get(state, [
    "widgets",
    PRODUCT_COMPARISON_WIDGET_KEY,
    PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY,
  ])
}

function triggerGetReferenceProductsMatchesFromProductComparisonWidget(action$) {
  return action$.pipe(
    ofType(
      // Explicit triggers
      Actions.PRODUCT_COMPARISON_TRIGGER_GET_REF_PRODUCT_MATCHES,
      // Implicit triggers
      Actions.PRODUCT_COMPARISON_OPEN,
      MatchActions.MATCH_APPROVE_SUCCESS,
      MatchActions.MATCH_DISCARD_SUCCESS,
      MatchActions.MATCH_REMOVE_APPROVAL_SUCCESS,
      MatchActions.MATCH_RESTORE_SUCCESS,
    ),
    map((action) => withParent(action, enqueueGetReferenceProductMatches())),
  )
}

function enqueueGetReferenceProductsMatchesFromProductComparisonWidget(
  action$,
  state$,
) {
  return action$.pipe(
    ofType(Actions.PRODUCT_COMPARISON_ENQUEUE_GET_REF_PRODUCT_MATCHES),
    debounceTime(DEBOUNCE_MILLISECONDS),
    filter(() => !!getReferenceProductIdState(state$.value)),
    map((action) => withParent(action, getReferenceProductsMatches())),
  )
}

const getReferenceProductMatchesFromProductComparisonWidget = buildEpic(
  Actions.PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES,
  (_, state) => {
    const referenceProductId = get(state, [
      "widgets",
      PRODUCT_COMPARISON_WIDGET_KEY,
      PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY,
    ])

    return APIService.listExistingMatches(referenceProductId, { withDiscarded: true })
  },
  undefined,
  true,
)

export default [
  triggerGetReferenceProductsMatchesFromProductComparisonWidget,
  enqueueGetReferenceProductsMatchesFromProductComparisonWidget,
  getReferenceProductMatchesFromProductComparisonWidget,
]
