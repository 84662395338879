import { EUR_SYMBOL } from "./constants"

const currencies = {
  AFN: {
    countries: ["AFGHANISTAN"],
    currency: "Afghani",
    numeric_code: 971,
    minor_unit: "2",
    symbol: "؋",
    withdrawal_date: null,
  },
  EUR: {
    countries: [
      "ÅLAND ISLANDS",
      "ANDORRA",
      "AUSTRIA",
      "BELGIUM",
      "CYPRUS",
      "ESTONIA",
      "EUROPEAN UNION",
      "FINLAND",
      "FRANCE",
      "FRENCH GUIANA",
      "FRENCH SOUTHERN TERRITORIES (THE)",
      "GERMANY",
      "GREECE",
      "GUADELOUPE",
      "HOLY SEE (THE)",
      "IRELAND",
      "ITALY",
      "LATVIA",
      "LITHUANIA",
      "LUXEMBOURG",
      "MALTA",
      "MARTINIQUE",
      "MAYOTTE",
      "MONACO",
      "MONTENEGRO",
      "NETHERLANDS (THE)",
      "PORTUGAL",
      "RÉUNION",
      "SAINT BARTHÉLEMY",
      "SAINT MARTIN (FRENCH PART)",
      "SAINT PIERRE AND MIQUELON",
      "SAN MARINO",
      "SLOVAKIA",
      "SLOVENIA",
      "SPAIN",
      "SERBIA AND MONTENEGRO",
    ],
    currency: "Euro",
    numeric_code: 978,
    minor_unit: "2",
    symbol: EUR_SYMBOL,
    withdrawal_date: null,
  },
  ALL: {
    countries: ["ALBANIA"],
    currency: "Lek",
    numeric_code: 8,
    minor_unit: "2",
    symbol: "L",
    withdrawal_date: null,
  },
  DZD: {
    countries: ["ALGERIA"],
    currency: "Algerian Dinar",
    numeric_code: 12,
    minor_unit: "2",
    symbol: "دج",
    withdrawal_date: null,
  },
  USD: {
    countries: [
      "AMERICAN SAMOA",
      "BONAIRE, SINT EUSTATIUS AND SABA",
      "BRITISH INDIAN OCEAN TERRITORY (THE)",
      "ECUADOR",
      "EL SALVADOR",
      "GUAM",
      "HAITI",
      "MARSHALL ISLANDS (THE)",
      "MICRONESIA (FEDERATED STATES OF)",
      "NORTHERN MARIANA ISLANDS (THE)",
      "PALAU",
      "PANAMA",
      "PUERTO RICO",
      "TIMOR-LESTE",
      "TURKS AND CAICOS ISLANDS (THE)",
      "UNITED STATES MINOR OUTLYING ISLANDS (THE)",
      "UNITED STATES OF AMERICA (THE)",
      "VIRGIN ISLANDS (BRITISH)",
      "VIRGIN ISLANDS (U.S.)",
    ],
    currency: "US Dollar",
    numeric_code: 840,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  AOA: {
    countries: ["ANGOLA"],
    currency: "Kwanza",
    numeric_code: 973,
    minor_unit: "2",
    symbol: "Kz",
    withdrawal_date: null,
  },
  XCD: {
    countries: [
      "ANGUILLA",
      "ANTIGUA AND BARBUDA",
      "DOMINICA",
      "GRENADA",
      "MONTSERRAT",
      "SAINT KITTS AND NEVIS",
      "SAINT LUCIA",
      "SAINT VINCENT AND THE GRENADINES",
    ],
    currency: "East Caribbean Dollar",
    numeric_code: 951,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  "": {
    countries: [
      "ANTARCTICA",
      "PALESTINE, STATE OF",
      "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    ],
    currency: "No universal currency",
    numeric_code: null,
    minor_unit: "",
    symbol: "",
    withdrawal_date: null,
  },
  ARS: {
    countries: ["ARGENTINA"],
    currency: "Argentine Peso",
    numeric_code: 32,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  AMD: {
    countries: ["ARMENIA"],
    currency: "Armenian Dram",
    numeric_code: 51,
    minor_unit: "2",
    symbol: "֏",
    withdrawal_date: null,
  },
  AWG: {
    countries: ["ARUBA"],
    currency: "Aruban Florin",
    numeric_code: 533,
    minor_unit: "2",
    symbol: "ƒ",
    withdrawal_date: null,
  },
  AUD: {
    countries: [
      "AUSTRALIA",
      "CHRISTMAS ISLAND",
      "COCOS (KEELING) ISLANDS (THE)",
      "HEARD ISLAND AND McDONALD ISLANDS",
      "KIRIBATI",
      "NAURU",
      "NORFOLK ISLAND",
      "TUVALU",
    ],
    currency: "Australian Dollar",
    numeric_code: 36,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  AZN: {
    countries: ["AZERBAIJAN"],
    currency: "Azerbaijan Manat",
    numeric_code: 944,
    minor_unit: "2",
    symbol: "₼",
    withdrawal_date: null,
  },
  BSD: {
    countries: ["BAHAMAS (THE)"],
    currency: "Bahamian Dollar",
    numeric_code: 44,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  BHD: {
    countries: ["BAHRAIN"],
    currency: "Bahraini Dinar",
    numeric_code: 48,
    minor_unit: "3",
    symbol: ".د.ب",
    withdrawal_date: null,
  },
  BDT: {
    countries: ["BANGLADESH"],
    currency: "Taka",
    numeric_code: 50,
    minor_unit: "2",
    symbol: "৳",
    withdrawal_date: null,
  },
  BBD: {
    countries: ["BARBADOS"],
    currency: "Barbados Dollar",
    numeric_code: 52,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  BYN: {
    countries: ["BELARUS"],
    currency: "Belarusian Ruble",
    numeric_code: 933,
    minor_unit: "2",
    symbol: "Br",
    withdrawal_date: null,
  },
  BZD: {
    countries: ["BELIZE"],
    currency: "Belize Dollar",
    numeric_code: 84,
    minor_unit: "2",
    symbol: "BZ$",
    withdrawal_date: null,
  },
  XOF: {
    countries: [
      "BENIN",
      "BURKINA FASO",
      "CÔTE D'IVOIRE",
      "GUINEA-BISSAU",
      "MALI",
      "NIGER (THE)",
      "SENEGAL",
      "TOGO",
    ],
    currency: "CFA Franc BCEAO",
    numeric_code: 952,
    minor_unit: "0",
    symbol: "CFA",
    withdrawal_date: null,
  },
  BMD: {
    countries: ["BERMUDA"],
    currency: "Bermudian Dollar",
    numeric_code: 60,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  INR: {
    countries: ["BHUTAN", "INDIA"],
    currency: "Indian Rupee",
    numeric_code: 356,
    minor_unit: "2",
    symbol: "₹",
    withdrawal_date: null,
  },
  BTN: {
    countries: ["BHUTAN"],
    currency: "Ngultrum",
    numeric_code: 64,
    minor_unit: "2",
    symbol: "Nu.",
    withdrawal_date: null,
  },
  BOB: {
    countries: ["BOLIVIA (PLURINATIONAL STATE OF)"],
    currency: "Boliviano",
    numeric_code: 68,
    minor_unit: "2",
    symbol: "$b",
    withdrawal_date: null,
  },
  BOV: {
    countries: ["BOLIVIA (PLURINATIONAL STATE OF)"],
    currency: "Mvdol",
    numeric_code: 984,
    minor_unit: "2",
    symbol: "BOV",
    withdrawal_date: null,
  },
  BAM: {
    countries: ["BOSNIA AND HERZEGOVINA"],
    currency: "Convertible Mark",
    numeric_code: 977,
    minor_unit: "2",
    symbol: "KM",
    withdrawal_date: null,
  },
  BWP: {
    countries: ["BOTSWANA"],
    currency: "Pula",
    numeric_code: 72,
    minor_unit: "2",
    symbol: "P",
    withdrawal_date: null,
  },
  NOK: {
    countries: ["BOUVET ISLAND", "NORWAY", "SVALBARD AND JAN MAYEN"],
    currency: "Norwegian Krone",
    numeric_code: 578,
    minor_unit: "2",
    symbol: "kr",
    withdrawal_date: null,
  },
  BRL: {
    countries: ["BRAZIL"],
    currency: "Brazilian Real",
    numeric_code: 986,
    minor_unit: "2",
    symbol: "R$",
    withdrawal_date: null,
  },
  BND: {
    countries: ["BRUNEI DARUSSALAM"],
    currency: "Brunei Dollar",
    numeric_code: 96,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  BGN: {
    countries: ["BULGARIA"],
    currency: "Bulgarian Lev",
    numeric_code: 975,
    minor_unit: "2",
    symbol: "лв",
    withdrawal_date: null,
  },
  BIF: {
    countries: ["BURUNDI"],
    currency: "Burundi Franc",
    numeric_code: 108,
    minor_unit: "0",
    symbol: "FBu",
    withdrawal_date: null,
  },
  CVE: {
    countries: ["CABO VERDE"],
    currency: "Cabo Verde Escudo",
    numeric_code: 132,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  KHR: {
    countries: ["CAMBODIA"],
    currency: "Riel",
    numeric_code: 116,
    minor_unit: "2",
    symbol: "៛",
    withdrawal_date: null,
  },
  XAF: {
    countries: [
      "CAMEROON",
      "CENTRAL AFRICAN REPUBLIC (THE)",
      "CHAD",
      "CONGO (THE)",
      "EQUATORIAL GUINEA",
      "GABON",
    ],
    currency: "CFA Franc BEAC",
    numeric_code: 950,
    minor_unit: "0",
    symbol: "FCFA",
    withdrawal_date: null,
  },
  CAD: {
    countries: ["CANADA"],
    currency: "Canadian Dollar",
    numeric_code: 124,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  KYD: {
    countries: ["CAYMAN ISLANDS (THE)"],
    currency: "Cayman Islands Dollar",
    numeric_code: 136,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  CLP: {
    countries: ["CHILE"],
    currency: "Chilean Peso",
    numeric_code: 152,
    minor_unit: "0",
    symbol: "$",
    withdrawal_date: null,
  },
  CLF: {
    countries: ["CHILE"],
    currency: "Unidad de Fomento",
    numeric_code: 990,
    minor_unit: "4",
    symbol: "CLF",
    withdrawal_date: null,
  },
  CNY: {
    countries: ["CHINA"],
    currency: "Yuan Renminbi",
    numeric_code: 156,
    minor_unit: "2",
    symbol: "¥",
    withdrawal_date: null,
  },
  COP: {
    countries: ["COLOMBIA"],
    currency: "Colombian Peso",
    numeric_code: 170,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  COU: {
    countries: ["COLOMBIA"],
    currency: "Unidad de Valor Real",
    numeric_code: 970,
    minor_unit: "2",
    symbol: "COU",
    withdrawal_date: null,
  },
  KMF: {
    countries: ["COMOROS (THE)"],
    currency: "Comorian Franc",
    numeric_code: 174,
    minor_unit: "0",
    symbol: "CF",
    withdrawal_date: null,
  },
  CDF: {
    countries: ["CONGO (THE DEMOCRATIC REPUBLIC OF THE)"],
    currency: "Congolese Franc",
    numeric_code: 976,
    minor_unit: "2",
    symbol: "FC",
    withdrawal_date: null,
  },
  NZD: {
    countries: ["COOK ISLANDS (THE)", "NEW ZEALAND", "NIUE", "PITCAIRN", "TOKELAU"],
    currency: "New Zealand Dollar",
    numeric_code: 554,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  CRC: {
    countries: ["COSTA RICA"],
    currency: "Costa Rican Colon",
    numeric_code: 188,
    minor_unit: "2",
    symbol: "₡",
    withdrawal_date: null,
  },
  HRK: {
    countries: ["CROATIA", "CROATIA"],
    currency: "Kuna",
    numeric_code: 191,
    minor_unit: "2",
    symbol: "kn",
    withdrawal_date: null,
  },
  CUP: {
    countries: ["CUBA"],
    currency: "Cuban Peso",
    numeric_code: 192,
    minor_unit: "2",
    symbol: "₱",
    withdrawal_date: null,
  },
  CUC: {
    countries: ["CUBA"],
    currency: "Peso Convertible",
    numeric_code: 931,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  ANG: {
    countries: ["CURAÇAO", "SINT MAARTEN (DUTCH PART)", "NETHERLANDS ANTILLES"],
    currency: "Netherlands Antillean Guilder",
    numeric_code: 532,
    minor_unit: "2",
    symbol: "ƒ",
    withdrawal_date: null,
  },
  CZK: {
    countries: ["CZECHIA"],
    currency: "Czech Koruna",
    numeric_code: 203,
    minor_unit: "2",
    symbol: "Kč",
    withdrawal_date: null,
  },
  DKK: {
    countries: ["DENMARK", "FAROE ISLANDS (THE)", "GREENLAND"],
    currency: "Danish Krone",
    numeric_code: 208,
    minor_unit: "2",
    symbol: "kr",
    withdrawal_date: null,
  },
  DJF: {
    countries: ["DJIBOUTI"],
    currency: "Djibouti Franc",
    numeric_code: 262,
    minor_unit: "0",
    symbol: "Fdj",
    withdrawal_date: null,
  },
  DOP: {
    countries: ["DOMINICAN REPUBLIC (THE)"],
    currency: "Dominican Peso",
    numeric_code: 214,
    minor_unit: "2",
    symbol: "RD$",
    withdrawal_date: null,
  },
  EGP: {
    countries: ["EGYPT"],
    currency: "Egyptian Pound",
    numeric_code: 818,
    minor_unit: "2",
    symbol: "£",
    withdrawal_date: null,
  },
  SVC: {
    countries: ["EL SALVADOR"],
    currency: "El Salvador Colon",
    numeric_code: 222,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  ERN: {
    countries: ["ERITREA"],
    currency: "Nakfa",
    numeric_code: 232,
    minor_unit: "2",
    symbol: "Nfk",
    withdrawal_date: null,
  },
  SZL: {
    countries: ["ESWATINI", "SWAZILAND"],
    currency: "Lilangeni",
    numeric_code: 748,
    minor_unit: "2",
    symbol: "E",
    withdrawal_date: null,
  },
  ETB: {
    countries: ["ETHIOPIA"],
    currency: "Ethiopian Birr",
    numeric_code: 230,
    minor_unit: "2",
    symbol: "Br",
    withdrawal_date: null,
  },
  FKP: {
    countries: ["FALKLAND ISLANDS (THE) [MALVINAS]"],
    currency: "Falkland Islands Pound",
    numeric_code: 238,
    minor_unit: "2",
    symbol: "£",
    withdrawal_date: null,
  },
  FJD: {
    countries: ["FIJI"],
    currency: "Fiji Dollar",
    numeric_code: 242,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  XPF: {
    countries: ["FRENCH POLYNESIA", "NEW CALEDONIA", "WALLIS AND FUTUNA"],
    currency: "CFP Franc",
    numeric_code: 953,
    minor_unit: "0",
    symbol: "₣",
    withdrawal_date: null,
  },
  GMD: {
    countries: ["GAMBIA (THE)"],
    currency: "Dalasi",
    numeric_code: 270,
    minor_unit: "2",
    symbol: "D",
    withdrawal_date: null,
  },
  GEL: {
    countries: ["GEORGIA"],
    currency: "Lari",
    numeric_code: 981,
    minor_unit: "2",
    symbol: "₾",
    withdrawal_date: null,
  },
  GHS: {
    countries: ["GHANA"],
    currency: "Ghana Cedi",
    numeric_code: 936,
    minor_unit: "2",
    symbol: "GH₵",
    withdrawal_date: null,
  },
  GIP: {
    countries: ["GIBRALTAR"],
    currency: "Gibraltar Pound",
    numeric_code: 292,
    minor_unit: "2",
    symbol: "£",
    withdrawal_date: null,
  },
  GTQ: {
    countries: ["GUATEMALA"],
    currency: "Quetzal",
    numeric_code: 320,
    minor_unit: "2",
    symbol: "Q",
    withdrawal_date: null,
  },
  GBP: {
    countries: [
      "GUERNSEY",
      "ISLE OF MAN",
      "JERSEY",
      "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)",
    ],
    currency: "Pound Sterling",
    numeric_code: 826,
    minor_unit: "2",
    symbol: "£",
    withdrawal_date: null,
  },
  GNF: {
    countries: ["GUINEA"],
    currency: "Guinean Franc",
    numeric_code: 324,
    minor_unit: "0",
    symbol: "FG",
    withdrawal_date: null,
  },
  GYD: {
    countries: ["GUYANA"],
    currency: "Guyana Dollar",
    numeric_code: 328,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  HTG: {
    countries: ["HAITI"],
    currency: "Gourde",
    numeric_code: 332,
    minor_unit: "2",
    symbol: "G",
    withdrawal_date: null,
  },
  HNL: {
    countries: ["HONDURAS"],
    currency: "Lempira",
    numeric_code: 340,
    minor_unit: "2",
    symbol: "L",
    withdrawal_date: null,
  },
  HKD: {
    countries: ["HONG KONG"],
    currency: "Hong Kong Dollar",
    numeric_code: 344,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  HUF: {
    countries: ["HUNGARY"],
    currency: "Forint",
    numeric_code: 348,
    minor_unit: "2",
    symbol: "Ft",
    withdrawal_date: null,
  },
  ISK: {
    countries: ["ICELAND"],
    currency: "Iceland Krona",
    numeric_code: 352,
    minor_unit: "0",
    symbol: "kr",
    withdrawal_date: null,
  },
  IDR: {
    countries: ["INDONESIA", "TIMOR-LESTE"],
    currency: "Rupiah",
    numeric_code: 360,
    minor_unit: "2",
    symbol: "Rp",
    withdrawal_date: null,
  },
  XDR: {
    countries: ["INTERNATIONAL MONETARY FUND (IMF)"],
    currency: "SDR (Special Drawing Right)",
    numeric_code: 960,
    minor_unit: "-",
    symbol: "XDR ",
    withdrawal_date: null,
  },
  IRR: {
    countries: ["IRAN (ISLAMIC REPUBLIC OF)"],
    currency: "Iranian Rial",
    numeric_code: 364,
    minor_unit: "2",
    symbol: "﷼",
    withdrawal_date: null,
  },
  IQD: {
    countries: ["IRAQ"],
    currency: "Iraqi Dinar",
    numeric_code: 368,
    minor_unit: "3",
    symbol: "ع.د",
    withdrawal_date: null,
  },
  ILS: {
    countries: ["ISRAEL"],
    currency: "New Israeli Sheqel",
    numeric_code: 376,
    minor_unit: "2",
    symbol: "₪",
    withdrawal_date: null,
  },
  JMD: {
    countries: ["JAMAICA"],
    currency: "Jamaican Dollar",
    numeric_code: 388,
    minor_unit: "2",
    symbol: "J$",
    withdrawal_date: null,
  },
  JPY: {
    countries: ["JAPAN"],
    currency: "Yen",
    numeric_code: 392,
    minor_unit: "0",
    symbol: "¥",
    withdrawal_date: null,
  },
  JOD: {
    countries: ["JORDAN"],
    currency: "Jordanian Dinar",
    numeric_code: 400,
    minor_unit: "3",
    symbol: "JD",
    withdrawal_date: null,
  },
  KZT: {
    countries: ["KAZAKHSTAN"],
    currency: "Tenge",
    numeric_code: 398,
    minor_unit: "2",
    symbol: "₸",
    withdrawal_date: null,
  },
  KES: {
    countries: ["KENYA"],
    currency: "Kenyan Shilling",
    numeric_code: 404,
    minor_unit: "2",
    symbol: "KSh",
    withdrawal_date: null,
  },
  KPW: {
    countries: ["KOREA (THE DEMOCRATIC PEOPLE'S REPUBLIC OF)"],
    currency: "North Korean Won",
    numeric_code: 408,
    minor_unit: "2",
    symbol: "₩",
    withdrawal_date: null,
  },
  KRW: {
    countries: ["KOREA (THE REPUBLIC OF)"],
    currency: "Won",
    numeric_code: 410,
    minor_unit: "0",
    symbol: "₩",
    withdrawal_date: null,
  },
  KWD: {
    countries: ["KUWAIT"],
    currency: "Kuwaiti Dinar",
    numeric_code: 414,
    minor_unit: "3",
    symbol: "KD",
    withdrawal_date: null,
  },
  KGS: {
    countries: ["KYRGYZSTAN"],
    currency: "Som",
    numeric_code: 417,
    minor_unit: "2",
    symbol: "лв",
    withdrawal_date: null,
  },
  LAK: {
    countries: ["LAO PEOPLE'S DEMOCRATIC REPUBLIC (THE)"],
    currency: "Lao Kip",
    numeric_code: 418,
    minor_unit: "2",
    symbol: "₭",
    withdrawal_date: null,
  },
  LBP: {
    countries: ["LEBANON"],
    currency: "Lebanese Pound",
    numeric_code: 422,
    minor_unit: "2",
    symbol: "£",
    withdrawal_date: null,
  },
  LSL: {
    countries: ["LESOTHO"],
    currency: "Loti",
    numeric_code: 426,
    minor_unit: "2",
    symbol: "M",
    withdrawal_date: null,
  },
  ZAR: {
    countries: ["LESOTHO", "NAMIBIA", "SOUTH AFRICA"],
    currency: "Rand",
    numeric_code: 710,
    minor_unit: "2",
    symbol: "R",
    withdrawal_date: null,
  },
  LRD: {
    countries: ["LIBERIA"],
    currency: "Liberian Dollar",
    numeric_code: 430,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  LYD: {
    countries: ["LIBYA"],
    currency: "Libyan Dinar",
    numeric_code: 434,
    minor_unit: "3",
    symbol: "LD",
    withdrawal_date: null,
  },
  CHF: {
    countries: ["LIECHTENSTEIN", "SWITZERLAND"],
    currency: "Swiss Franc",
    numeric_code: 756,
    minor_unit: "2",
    symbol: "CHF",
    withdrawal_date: null,
  },
  MOP: {
    countries: ["MACAO"],
    currency: "Pataca",
    numeric_code: 446,
    minor_unit: "2",
    symbol: "MOP$",
    withdrawal_date: null,
  },
  MKD: {
    countries: ["NORTH MACEDONIA"],
    currency: "Denar",
    numeric_code: 807,
    minor_unit: "2",
    symbol: "ден",
    withdrawal_date: null,
  },
  MGA: {
    countries: ["MADAGASCAR"],
    currency: "Malagasy Ariary",
    numeric_code: 969,
    minor_unit: "2",
    symbol: "Ar",
    withdrawal_date: null,
  },
  MWK: {
    countries: ["MALAWI", "MALAWI"],
    currency: "Malawi Kwacha",
    numeric_code: 454,
    minor_unit: "2",
    symbol: "MK",
    withdrawal_date: null,
  },
  MYR: {
    countries: ["MALAYSIA"],
    currency: "Malaysian Ringgit",
    numeric_code: 458,
    minor_unit: "2",
    symbol: "RM",
    withdrawal_date: null,
  },
  MVR: {
    countries: ["MALDIVES"],
    currency: "Rufiyaa",
    numeric_code: 462,
    minor_unit: "2",
    symbol: "Rf",
    withdrawal_date: null,
  },
  MRU: {
    countries: ["MAURITANIA"],
    currency: "Ouguiya",
    numeric_code: 929,
    minor_unit: "2",
    symbol: "UM",
    withdrawal_date: null,
  },
  MUR: {
    countries: ["MAURITIUS"],
    currency: "Mauritius Rupee",
    numeric_code: 480,
    minor_unit: "2",
    symbol: "₨",
    withdrawal_date: null,
  },
  XUA: {
    countries: ["MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP"],
    currency: "ADB Unit of Account",
    numeric_code: 965,
    minor_unit: "-",
    symbol: "XUA",
    withdrawal_date: null,
  },
  MXN: {
    countries: ["MEXICO"],
    currency: "Mexican Peso",
    numeric_code: 484,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  MXV: {
    countries: ["MEXICO"],
    currency: "Mexican Unidad de Inversion (UDI)",
    numeric_code: 979,
    minor_unit: "2",
    symbol: "MXV",
    withdrawal_date: null,
  },
  MDL: {
    countries: ["MOLDOVA (THE REPUBLIC OF)"],
    currency: "Moldovan Leu",
    numeric_code: 498,
    minor_unit: "2",
    symbol: "lei",
    withdrawal_date: null,
  },
  MNT: {
    countries: ["MONGOLIA"],
    currency: "Tugrik",
    numeric_code: 496,
    minor_unit: "2",
    symbol: "₮",
    withdrawal_date: null,
  },
  MAD: {
    countries: ["MOROCCO", "WESTERN SAHARA"],
    currency: "Moroccan Dirham",
    numeric_code: 504,
    minor_unit: "2",
    symbol: "MAD",
    withdrawal_date: null,
  },
  MZN: {
    countries: ["MOZAMBIQUE"],
    currency: "Mozambique Metical",
    numeric_code: 943,
    minor_unit: "2",
    symbol: "MT",
    withdrawal_date: null,
  },
  MMK: {
    countries: ["MYANMAR"],
    currency: "Kyat",
    numeric_code: 104,
    minor_unit: "2",
    symbol: "K",
    withdrawal_date: null,
  },
  NAD: {
    countries: ["NAMIBIA"],
    currency: "Namibia Dollar",
    numeric_code: 516,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  NPR: {
    countries: ["NEPAL"],
    currency: "Nepalese Rupee",
    numeric_code: 524,
    minor_unit: "2",
    symbol: "₨",
    withdrawal_date: null,
  },
  NIO: {
    countries: ["NICARAGUA"],
    currency: "Cordoba Oro",
    numeric_code: 558,
    minor_unit: "2",
    symbol: "C$",
    withdrawal_date: null,
  },
  NGN: {
    countries: ["NIGERIA"],
    currency: "Naira",
    numeric_code: 566,
    minor_unit: "2",
    symbol: "₦",
    withdrawal_date: null,
  },
  OMR: {
    countries: ["OMAN"],
    currency: "Rial Omani",
    numeric_code: 512,
    minor_unit: "3",
    symbol: "﷼",
    withdrawal_date: null,
  },
  PKR: {
    countries: ["PAKISTAN"],
    currency: "Pakistan Rupee",
    numeric_code: 586,
    minor_unit: "2",
    symbol: "₨",
    withdrawal_date: null,
  },
  PAB: {
    countries: ["PANAMA"],
    currency: "Balboa",
    numeric_code: 590,
    minor_unit: "2",
    symbol: "B/.",
    withdrawal_date: null,
  },
  PGK: {
    countries: ["PAPUA NEW GUINEA"],
    currency: "Kina",
    numeric_code: 598,
    minor_unit: "2",
    symbol: "K",
    withdrawal_date: null,
  },
  PYG: {
    countries: ["PARAGUAY"],
    currency: "Guarani",
    numeric_code: 600,
    minor_unit: "0",
    symbol: "Gs",
    withdrawal_date: null,
  },
  PEN: {
    countries: ["PERU", "PERU"],
    currency: "Sol",
    numeric_code: 604,
    minor_unit: "2",
    symbol: "S/.",
    withdrawal_date: null,
  },
  PHP: {
    countries: ["PHILIPPINES (THE)"],
    currency: "Philippine Peso",
    numeric_code: 608,
    minor_unit: "2",
    symbol: "₱",
    withdrawal_date: null,
  },
  PLN: {
    countries: ["POLAND"],
    currency: "Zloty",
    numeric_code: 985,
    minor_unit: "2",
    symbol: "zł",
    withdrawal_date: null,
  },
  QAR: {
    countries: ["QATAR"],
    currency: "Qatari Rial",
    numeric_code: 634,
    minor_unit: "2",
    symbol: "﷼",
    withdrawal_date: null,
  },
  RON: {
    countries: ["ROMANIA", "ROMANIA"],
    currency: "Romanian Leu",
    numeric_code: 946,
    minor_unit: "2",
    symbol: "lei",
    withdrawal_date: null,
  },
  RUB: {
    countries: ["RUSSIAN FEDERATION (THE)"],
    currency: "Russian Ruble",
    numeric_code: 643,
    minor_unit: "2",
    symbol: "₽",
    withdrawal_date: null,
  },
  RWF: {
    countries: ["RWANDA"],
    currency: "Rwanda Franc",
    numeric_code: 646,
    minor_unit: "0",
    symbol: "R₣",
    withdrawal_date: null,
  },
  SHP: {
    countries: ["SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"],
    currency: "Saint Helena Pound",
    numeric_code: 654,
    minor_unit: "2",
    symbol: "£",
    withdrawal_date: null,
  },
  WST: {
    countries: ["SAMOA"],
    currency: "Tala",
    numeric_code: 882,
    minor_unit: "2",
    symbol: "WS$",
    withdrawal_date: null,
  },
  STN: {
    countries: ["SAO TOME AND PRINCIPE"],
    currency: "Dobra",
    numeric_code: 930,
    minor_unit: "2",
    symbol: "Db",
    withdrawal_date: null,
  },
  SAR: {
    countries: ["SAUDI ARABIA"],
    currency: "Saudi Riyal",
    numeric_code: 682,
    minor_unit: "2",
    symbol: "﷼",
    withdrawal_date: null,
  },
  RSD: {
    countries: ["SERBIA"],
    currency: "Serbian Dinar",
    numeric_code: 941,
    minor_unit: "2",
    symbol: "Дин.",
    withdrawal_date: null,
  },
  SCR: {
    countries: ["SEYCHELLES"],
    currency: "Seychelles Rupee",
    numeric_code: 690,
    minor_unit: "2",
    symbol: "₨",
    withdrawal_date: null,
  },
  SLL: {
    countries: ["SIERRA LEONE"],
    currency: "Leone",
    numeric_code: 694,
    minor_unit: "2",
    symbol: "Le",
    withdrawal_date: null,
  },
  SGD: {
    countries: ["SINGAPORE"],
    currency: "Singapore Dollar",
    numeric_code: 702,
    minor_unit: "2",
    symbol: "S$",
    withdrawal_date: null,
  },
  XSU: {
    countries: ['SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS "SUCRE"'],
    currency: "Sucre",
    numeric_code: 994,
    minor_unit: "-",
    symbol: "Sucre",
    withdrawal_date: null,
  },
  SBD: {
    countries: ["SOLOMON ISLANDS"],
    currency: "Solomon Islands Dollar",
    numeric_code: 90,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  SOS: {
    countries: ["SOMALIA"],
    currency: "Somali Shilling",
    numeric_code: 706,
    minor_unit: "2",
    symbol: "S",
    withdrawal_date: null,
  },
  SSP: {
    countries: ["SOUTH SUDAN"],
    currency: "South Sudanese Pound",
    numeric_code: 728,
    minor_unit: "2",
    symbol: "£",
    withdrawal_date: null,
  },
  LKR: {
    countries: ["SRI LANKA"],
    currency: "Sri Lanka Rupee",
    numeric_code: 144,
    minor_unit: "2",
    symbol: "₨",
    withdrawal_date: null,
  },
  SDG: {
    countries: ["SUDAN (THE)", "SOUTH SUDAN"],
    currency: "Sudanese Pound",
    numeric_code: 938,
    minor_unit: "2",
    symbol: "ج.س.",
    withdrawal_date: null,
  },
  SRD: {
    countries: ["SURINAME"],
    currency: "Surinam Dollar",
    numeric_code: 968,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  SEK: {
    countries: ["SWEDEN"],
    currency: "Swedish Krona",
    numeric_code: 752,
    minor_unit: "2",
    symbol: "kr",
    withdrawal_date: null,
  },
  CHE: {
    countries: ["SWITZERLAND"],
    currency: "WIR Euro",
    numeric_code: 947,
    minor_unit: "2",
    symbol: "CHE",
    withdrawal_date: null,
  },
  CHW: {
    countries: ["SWITZERLAND"],
    currency: "WIR Franc",
    numeric_code: 948,
    minor_unit: "2",
    symbol: "CHW",
    withdrawal_date: null,
  },
  SYP: {
    countries: ["SYRIAN ARAB REPUBLIC"],
    currency: "Syrian Pound",
    numeric_code: 760,
    minor_unit: "2",
    symbol: "£",
    withdrawal_date: null,
  },
  TWD: {
    countries: ["TAIWAN (PROVINCE OF CHINA)"],
    currency: "New Taiwan Dollar",
    numeric_code: 901,
    minor_unit: "2",
    symbol: "NT$",
    withdrawal_date: null,
  },
  TJS: {
    countries: ["TAJIKISTAN"],
    currency: "Somoni",
    numeric_code: 972,
    minor_unit: "2",
    symbol: "SM",
    withdrawal_date: null,
  },
  TZS: {
    countries: ["TANZANIA, UNITED REPUBLIC OF"],
    currency: "Tanzanian Shilling",
    numeric_code: 834,
    minor_unit: "2",
    symbol: "TSh",
    withdrawal_date: null,
  },
  THB: {
    countries: ["THAILAND"],
    currency: "Baht",
    numeric_code: 764,
    minor_unit: "2",
    symbol: "฿",
    withdrawal_date: null,
  },
  TOP: {
    countries: ["TONGA"],
    currency: "Pa'anga",
    numeric_code: 776,
    minor_unit: "2",
    symbol: "T$",
    withdrawal_date: null,
  },
  TTD: {
    countries: ["TRINIDAD AND TOBAGO"],
    currency: "Trinidad and Tobago Dollar",
    numeric_code: 780,
    minor_unit: "2",
    symbol: "TT$",
    withdrawal_date: null,
  },
  TND: {
    countries: ["TUNISIA"],
    currency: "Tunisian Dinar",
    numeric_code: 788,
    minor_unit: "3",
    symbol: "د.ت",
    withdrawal_date: null,
  },
  TRY: {
    countries: ["TURKEY", "TURKEY"],
    currency: "Turkish Lira",
    numeric_code: 949,
    minor_unit: "2",
    symbol: "₺",
    withdrawal_date: null,
  },
  TMT: {
    countries: ["TURKMENISTAN"],
    currency: "Turkmenistan New Manat",
    numeric_code: 934,
    minor_unit: "2",
    symbol: "T",
    withdrawal_date: null,
  },
  UGX: {
    countries: ["UGANDA"],
    currency: "Uganda Shilling",
    numeric_code: 800,
    minor_unit: "0",
    symbol: "USh",
    withdrawal_date: null,
  },
  UAH: {
    countries: ["UKRAINE"],
    currency: "Hryvnia",
    numeric_code: 980,
    minor_unit: "2",
    symbol: "₴",
    withdrawal_date: null,
  },
  AED: {
    countries: ["UNITED ARAB EMIRATES (THE)"],
    currency: "UAE Dirham",
    numeric_code: 784,
    minor_unit: "2",
    symbol: "د.إ",
    withdrawal_date: null,
  },
  USN: {
    countries: ["UNITED STATES OF AMERICA (THE)"],
    currency: "US Dollar (Next day)",
    numeric_code: 997,
    minor_unit: "2",
    symbol: "USN ",
    withdrawal_date: null,
  },
  UYU: {
    countries: ["URUGUAY"],
    currency: "Peso Uruguayo",
    numeric_code: 858,
    minor_unit: "2",
    symbol: "$U",
    withdrawal_date: null,
  },
  UYI: {
    countries: ["URUGUAY"],
    currency: "Uruguay Peso en Unidades Indexadas (UI)",
    numeric_code: 940,
    minor_unit: "0",
    symbol: "UYI",
    withdrawal_date: null,
  },
  UYW: {
    countries: ["URUGUAY"],
    currency: "Unidad Previsional",
    numeric_code: 927,
    minor_unit: "4",
    symbol: "UYW",
    withdrawal_date: null,
  },
  UZS: {
    countries: ["UZBEKISTAN"],
    currency: "Uzbekistan Sum",
    numeric_code: 860,
    minor_unit: "2",
    symbol: "лв",
    withdrawal_date: null,
  },
  VUV: {
    countries: ["VANUATU"],
    currency: "Vatu",
    numeric_code: 548,
    minor_unit: "0",
    symbol: "VT",
    withdrawal_date: null,
  },
  VES: {
    countries: ["VENEZUELA (BOLIVARIAN REPUBLIC OF)"],
    currency: "Bolívar Soberano",
    numeric_code: 928,
    minor_unit: "2",
    symbol: "Bs.S",
    withdrawal_date: null,
  },
  VND: {
    countries: ["VIET NAM"],
    currency: "Dong",
    numeric_code: 704,
    minor_unit: "0",
    symbol: "₫",
    withdrawal_date: null,
  },
  YER: {
    countries: ["YEMEN"],
    currency: "Yemeni Rial",
    numeric_code: 886,
    minor_unit: "2",
    symbol: "﷼",
    withdrawal_date: null,
  },
  ZMW: {
    countries: ["ZAMBIA"],
    currency: "Zambian Kwacha",
    numeric_code: 967,
    minor_unit: "2",
    symbol: "ZK",
    withdrawal_date: null,
  },
  ZWL: {
    countries: ["ZIMBABWE"],
    currency: "Zimbabwe Dollar",
    numeric_code: 932,
    minor_unit: "2",
    symbol: "$",
    withdrawal_date: null,
  },
  XBA: {
    countries: ["ZZ01_Bond Markets Unit European_EURCO"],
    currency: "Bond Markets Unit European Composite Unit (EURCO)",
    numeric_code: 955,
    minor_unit: "-",
    symbol: "XBA ",
    withdrawal_date: null,
  },
  XBB: {
    countries: ["ZZ02_Bond Markets Unit European_EMU-6"],
    currency: "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
    numeric_code: 956,
    minor_unit: "-",
    symbol: "XBB ",
    withdrawal_date: null,
  },
  XBC: {
    countries: ["ZZ03_Bond Markets Unit European_EUA-9"],
    currency: "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
    numeric_code: 957,
    minor_unit: "-",
    symbol: "XBC ",
    withdrawal_date: null,
  },
  XBD: {
    countries: ["ZZ04_Bond Markets Unit European_EUA-17"],
    currency: "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
    numeric_code: 958,
    minor_unit: "-",
    symbol: "XBD ",
    withdrawal_date: null,
  },
  XTS: {
    countries: ["ZZ06_Testing_Code"],
    currency: "Codes specifically reserved for testing purposes",
    numeric_code: 963,
    minor_unit: "-",
    symbol: "XTS ",
    withdrawal_date: null,
  },
  XXX: {
    countries: ["ZZ07_No_Currency"],
    currency: "The codes assigned for transactions where no currency is involved",
    numeric_code: 999,
    minor_unit: "-",
    symbol: "XXX ",
    withdrawal_date: null,
  },
  XAU: {
    countries: ["ZZ08_Gold"],
    currency: "Gold",
    numeric_code: 959,
    minor_unit: "-",
    symbol: "XAU ",
    withdrawal_date: null,
  },
  XPD: {
    countries: ["ZZ09_Palladium"],
    currency: "Palladium",
    numeric_code: 964,
    minor_unit: "-",
    symbol: "XPD ",
    withdrawal_date: null,
  },
  XPT: {
    countries: ["ZZ10_Platinum"],
    currency: "Platinum",
    numeric_code: 962,
    minor_unit: "-",
    symbol: "XPT ",
    withdrawal_date: null,
  },
  XAG: {
    countries: ["ZZ11_Silver"],
    currency: "Silver",
    numeric_code: 961,
    minor_unit: "-",
    symbol: "XAG ",
    withdrawal_date: null,
  },
  AFA: {
    countries: ["AFGHANISTAN"],
    currency: "Afghani",
    numeric_code: 4,
    minor_unit: "",
    symbol: "AFA ",
    withdrawal_date: 1041379200000,
  },
  FIM: {
    countries: ["ÅLAND ISLANDS", "FINLAND"],
    currency: "Markka",
    numeric_code: 246,
    minor_unit: "",
    symbol: "FIM ",
    withdrawal_date: 1014940800000,
  },
  ALK: {
    countries: ["ALBANIA"],
    currency: "Old Lek",
    numeric_code: 8,
    minor_unit: "",
    symbol: "ALK ",
    withdrawal_date: 628473600000,
  },
  ADP: {
    countries: ["ANDORRA"],
    currency: "Andorran Peseta",
    numeric_code: 20,
    minor_unit: "",
    symbol: "ADP ",
    withdrawal_date: 1057014000000,
  },
  ESP: {
    countries: ["ANDORRA", "SPAIN"],
    currency: "Spanish Peseta",
    numeric_code: 724,
    minor_unit: "",
    symbol: "ESP ",
    withdrawal_date: 1014940800000,
  },
  FRF: {
    countries: [
      "ANDORRA",
      "FRANCE",
      "FRENCH GUIANA",
      "FRENCH SOUTHERN TERRITORIES",
      "GUADELOUPE",
      "MARTINIQUE",
      "MAYOTTE",
      "MONACO",
      "RÉUNION",
      "SAINT MARTIN",
      "SAINT PIERRE AND MIQUELON",
      "SAINT-BARTHÉLEMY",
    ],
    currency: "French Franc",
    numeric_code: 250,
    minor_unit: "",
    symbol: "FRF ",
    withdrawal_date: 1014940800000,
  },
  AOK: {
    countries: ["ANGOLA"],
    currency: "Kwanza",
    numeric_code: 24,
    minor_unit: "",
    symbol: "AOK ",
    withdrawal_date: 667785600000,
  },
  AON: {
    countries: ["ANGOLA"],
    currency: "New Kwanza",
    numeric_code: 24,
    minor_unit: "",
    symbol: "AON ",
    withdrawal_date: 949363200000,
  },
  AOR: {
    countries: ["ANGOLA"],
    currency: "Kwanza Reajustado",
    numeric_code: 982,
    minor_unit: "",
    symbol: "AOR ",
    withdrawal_date: 949363200000,
  },
  ARA: {
    countries: ["ARGENTINA"],
    currency: "Austral",
    numeric_code: 32,
    minor_unit: "",
    symbol: "ARA ",
    withdrawal_date: 694224000000,
  },
  ARP: {
    countries: ["ARGENTINA"],
    currency: "Peso Argentino",
    numeric_code: 32,
    minor_unit: "",
    symbol: "ARP ",
    withdrawal_date: 489020400000,
  },
  ARY: {
    countries: ["ARGENTINA"],
    currency: "Peso",
    numeric_code: 32,
    minor_unit: "",
    symbol: "ARY ",
    withdrawal_date: null,
  },
  RUR: {
    countries: [
      "ARMENIA",
      "AZERBAIJAN",
      "BELARUS",
      "GEORGIA",
      "KAZAKHSTAN",
      "KYRGYZSTAN",
      "MOLDOVA, REPUBLIC OF",
      "RUSSIAN FEDERATION",
      "TAJIKISTAN",
      "TURKMENISTAN",
      "UZBEKISTAN",
    ],
    currency: "Russian Ruble",
    numeric_code: 810,
    minor_unit: "",
    symbol: "RUR ",
    withdrawal_date: 775692000000,
  },
  ATS: {
    countries: ["AUSTRIA"],
    currency: "Schilling",
    numeric_code: 40,
    minor_unit: "",
    symbol: "ATS ",
    withdrawal_date: 1014940800000,
  },
  AYM: {
    countries: ["AZERBAIJAN"],
    currency: "Azerbaijan Manat",
    numeric_code: 945,
    minor_unit: "",
    symbol: "AYM ",
    withdrawal_date: 1128121200000,
  },
  AZM: {
    countries: ["AZERBAIJAN"],
    currency: "Azerbaijanian Manat",
    numeric_code: 31,
    minor_unit: "",
    symbol: "AZM ",
    withdrawal_date: 1133395200000,
  },
  BYB: {
    countries: ["BELARUS"],
    currency: "Belarusian Ruble",
    numeric_code: 112,
    minor_unit: "",
    symbol: "BYB ",
    withdrawal_date: 978307200000,
  },
  BYR: {
    countries: ["BELARUS"],
    currency: "Belarusian Ruble",
    numeric_code: 974,
    minor_unit: "",
    symbol: "Br",
    withdrawal_date: 1483228800000,
  },
  BEC: {
    countries: ["BELGIUM"],
    currency: "Convertible Franc",
    numeric_code: 993,
    minor_unit: "",
    symbol: "BEC ",
    withdrawal_date: 636249600000,
  },
  BEF: {
    countries: ["BELGIUM"],
    currency: "Belgian Franc",
    numeric_code: 56,
    minor_unit: "",
    symbol: "BEF ",
    withdrawal_date: 1014940800000,
  },
  BEL: {
    countries: ["BELGIUM"],
    currency: "Financial Franc",
    numeric_code: 992,
    minor_unit: "",
    symbol: "BEL ",
    withdrawal_date: 636249600000,
  },
  BOP: {
    countries: ["BOLIVIA"],
    currency: "Peso boliviano",
    numeric_code: 68,
    minor_unit: "",
    symbol: "BOP ",
    withdrawal_date: 539136000000,
  },
  BAD: {
    countries: ["BOSNIA AND HERZEGOVINA"],
    currency: "Dinar",
    numeric_code: 70,
    minor_unit: "",
    symbol: "BAD ",
    withdrawal_date: 899247600000,
  },
  BRB: {
    countries: ["BRAZIL"],
    currency: "Cruzeiro",
    numeric_code: 76,
    minor_unit: "",
    symbol: "BRB ",
    withdrawal_date: 510019200000,
  },
  BRC: {
    countries: ["BRAZIL"],
    currency: "Cruzado",
    numeric_code: 76,
    minor_unit: "",
    symbol: "BRC ",
    withdrawal_date: 602294400000,
  },
  BRE: {
    countries: ["BRAZIL"],
    currency: "Cruzeiro",
    numeric_code: 76,
    minor_unit: "",
    symbol: "BRE ",
    withdrawal_date: 730940400000,
  },
  BRN: {
    countries: ["BRAZIL"],
    currency: "New Cruzado",
    numeric_code: 76,
    minor_unit: "",
    symbol: "BRN ",
    withdrawal_date: 636249600000,
  },
  BRR: {
    countries: ["BRAZIL"],
    currency: "Cruzeiro Real",
    numeric_code: 987,
    minor_unit: "",
    symbol: "BRR ",
    withdrawal_date: 773013600000,
  },
  BGJ: {
    countries: ["BULGARIA"],
    currency: "Lev A/52",
    numeric_code: 100,
    minor_unit: "",
    symbol: "BGJ ",
    withdrawal_date: null,
  },
  BGK: {
    countries: ["BULGARIA"],
    currency: "Lev A/62",
    numeric_code: 100,
    minor_unit: "",
    symbol: "BGK ",
    withdrawal_date: null,
  },
  BGL: {
    countries: ["BULGARIA"],
    currency: "Lev",
    numeric_code: 100,
    minor_unit: "",
    symbol: "BGL ",
    withdrawal_date: 1067644800000,
  },
  BUK: {
    countries: ["BURMA"],
    currency: "Kyat",
    numeric_code: 104,
    minor_unit: "",
    symbol: "BUK ",
    withdrawal_date: 633830400000,
  },
  HRD: {
    countries: ["CROATIA"],
    currency: "Croatian Dinar",
    numeric_code: 191,
    minor_unit: "",
    symbol: "HRD ",
    withdrawal_date: 788914800000,
  },
  CYP: {
    countries: ["CYPRUS"],
    currency: "Cyprus Pound",
    numeric_code: 196,
    minor_unit: "",
    symbol: "CYP ",
    withdrawal_date: 1199145600000,
  },
  CSJ: {
    countries: ["CZECHOSLOVAKIA"],
    currency: "Krona A/53",
    numeric_code: 203,
    minor_unit: "",
    symbol: "CSJ ",
    withdrawal_date: null,
  },
  CSK: {
    countries: ["CZECHOSLOVAKIA"],
    currency: "Koruna",
    numeric_code: 200,
    minor_unit: "",
    symbol: "CSK ",
    withdrawal_date: 730940400000,
  },
  ECS: {
    countries: ["ECUADOR"],
    currency: "Sucre",
    numeric_code: 218,
    minor_unit: "",
    symbol: "ECS ",
    withdrawal_date: 967762800000,
  },
  ECV: {
    countries: ["ECUADOR"],
    currency: "Unidad de Valor Constante (UVC)",
    numeric_code: 983,
    minor_unit: "",
    symbol: "ECV ",
    withdrawal_date: 967762800000,
  },
  GQE: {
    countries: ["EQUATORIAL GUINEA"],
    currency: "Ekwele",
    numeric_code: 226,
    minor_unit: "",
    symbol: "GQE ",
    withdrawal_date: 517964400000,
  },
  EEK: {
    countries: ["ESTONIA"],
    currency: "Kroon",
    numeric_code: 233,
    minor_unit: "",
    symbol: "kr",
    withdrawal_date: 1293840000000,
  },
  XEU: {
    countries: ["EUROPEAN MONETARY CO-OPERATION FUND (EMCF)"],
    currency: "European Currency Unit (E.C.U)",
    numeric_code: 954,
    minor_unit: "",
    symbol: "XEU ",
    withdrawal_date: 915148800000,
  },
  GEK: {
    countries: ["GEORGIA"],
    currency: "Georgian Coupon",
    numeric_code: 268,
    minor_unit: "",
    symbol: "GEK ",
    withdrawal_date: 812502000000,
  },
  DDM: {
    countries: ["GERMAN DEMOCRATIC REPUBLIC"],
    currency: "Mark der DDR",
    numeric_code: 278,
    minor_unit: "",
    symbol: "DDM ",
    withdrawal_date: null,
  },
  DEM: {
    countries: ["GERMANY"],
    currency: "Deutsche Mark",
    numeric_code: 276,
    minor_unit: "",
    symbol: "DEM ",
    withdrawal_date: 1014940800000,
  },
  GHC: {
    countries: ["GHANA"],
    currency: "Cedi",
    numeric_code: 288,
    minor_unit: "",
    symbol: "₵",
    withdrawal_date: 1199145600000,
  },
  GHP: {
    countries: ["GHANA"],
    currency: "Ghana Cedi",
    numeric_code: 939,
    minor_unit: "",
    symbol: "GHP ",
    withdrawal_date: 1180652400000,
  },
  GRD: {
    countries: ["GREECE"],
    currency: "Drachma",
    numeric_code: 300,
    minor_unit: "",
    symbol: "GRD ",
    withdrawal_date: 1014940800000,
  },
  GNE: {
    countries: ["GUINEA"],
    currency: "Syli",
    numeric_code: 324,
    minor_unit: "",
    symbol: "GNE ",
    withdrawal_date: 628473600000,
  },
  GNS: {
    countries: ["GUINEA"],
    currency: "Syli",
    numeric_code: 324,
    minor_unit: "",
    symbol: "GNS ",
    withdrawal_date: 507600000000,
  },
  GWE: {
    countries: ["GUINEA-BISSAU"],
    currency: "Guinea Escudo",
    numeric_code: 624,
    minor_unit: "",
    symbol: "GWE ",
    withdrawal_date: null,
  },
  GWP: {
    countries: ["GUINEA-BISSAU"],
    currency: "Guinea-Bissau Peso",
    numeric_code: 624,
    minor_unit: "",
    symbol: "GWP ",
    withdrawal_date: 862441200000,
  },
  ITL: {
    countries: ["HOLY SEE (VATICAN CITY STATE)", "ITALY", "SAN MARINO"],
    currency: "Italian Lira",
    numeric_code: 380,
    minor_unit: "",
    symbol: "ITL ",
    withdrawal_date: 1014940800000,
  },
  ISJ: {
    countries: ["ICELAND"],
    currency: "Old Krona",
    numeric_code: 352,
    minor_unit: "",
    symbol: "ISJ ",
    withdrawal_date: null,
  },
  IEP: {
    countries: ["IRELAND"],
    currency: "Irish Pound",
    numeric_code: 372,
    minor_unit: "",
    symbol: "IEP ",
    withdrawal_date: 1014940800000,
  },
  ILP: {
    countries: ["ISRAEL"],
    currency: "Pound",
    numeric_code: 376,
    minor_unit: "",
    symbol: "ILP ",
    withdrawal_date: null,
  },
  ILR: {
    countries: ["ISRAEL"],
    currency: "Old Shekel",
    numeric_code: 376,
    minor_unit: "",
    symbol: "ILR ",
    withdrawal_date: null,
  },
  LAJ: {
    countries: ["LAO"],
    currency: "Pathet Lao Kip",
    numeric_code: 418,
    minor_unit: "",
    symbol: "LAJ ",
    withdrawal_date: 312854400000,
  },
  LVL: {
    countries: ["LATVIA"],
    currency: "Latvian Lats",
    numeric_code: 428,
    minor_unit: "",
    symbol: "Ls",
    withdrawal_date: 1388534400000,
  },
  LVR: {
    countries: ["LATVIA"],
    currency: "Latvian Ruble",
    numeric_code: 428,
    minor_unit: "",
    symbol: "LVR ",
    withdrawal_date: 786236400000,
  },
  LSM: {
    countries: ["LESOTHO"],
    currency: "Loti",
    numeric_code: 426,
    minor_unit: "",
    symbol: "LSM ",
    withdrawal_date: 483750000000,
  },
  ZAL: {
    countries: ["LESOTHO", "SOUTH AFRICA"],
    currency: "Financial Rand",
    numeric_code: 991,
    minor_unit: "",
    symbol: "ZAL ",
    withdrawal_date: 794012400000,
  },
  LTL: {
    countries: ["LITHUANIA"],
    currency: "Lithuanian Litas",
    numeric_code: 440,
    minor_unit: "",
    symbol: "Lt",
    withdrawal_date: 1417392000000,
  },
  LTT: {
    countries: ["LITHUANIA"],
    currency: "Talonas",
    numeric_code: 440,
    minor_unit: "",
    symbol: "LTT ",
    withdrawal_date: 741477600000,
  },
  LUC: {
    countries: ["LUXEMBOURG"],
    currency: "Luxembourg Convertible Franc",
    numeric_code: 989,
    minor_unit: "",
    symbol: "LUC ",
    withdrawal_date: 636249600000,
  },
  LUF: {
    countries: ["LUXEMBOURG"],
    currency: "Luxembourg Franc",
    numeric_code: 442,
    minor_unit: "",
    symbol: "LUF ",
    withdrawal_date: 1014940800000,
  },
  LUL: {
    countries: ["LUXEMBOURG"],
    currency: "Luxembourg Financial Franc",
    numeric_code: 988,
    minor_unit: "",
    symbol: "LUL ",
    withdrawal_date: 636249600000,
  },
  MGF: {
    countries: ["MADAGASCAR"],
    currency: "Malagasy Franc",
    numeric_code: 450,
    minor_unit: "",
    symbol: "MGF ",
    withdrawal_date: 1101859200000,
  },
  MVQ: {
    countries: ["MALDIVES"],
    currency: "Maldive Rupee",
    numeric_code: 462,
    minor_unit: "",
    symbol: "MVQ ",
    withdrawal_date: 628473600000,
  },
  MLF: {
    countries: ["MALI"],
    currency: "Mali Franc",
    numeric_code: 466,
    minor_unit: "",
    symbol: "MLF ",
    withdrawal_date: 468115200000,
  },
  MTL: {
    countries: ["MALTA"],
    currency: "Maltese Lira",
    numeric_code: 470,
    minor_unit: "",
    symbol: "MTL ",
    withdrawal_date: 1199145600000,
  },
  MTP: {
    countries: ["MALTA"],
    currency: "Maltese Pound",
    numeric_code: 470,
    minor_unit: "",
    symbol: "MTP ",
    withdrawal_date: 423270000000,
  },
  MRO: {
    countries: ["MAURITANIA"],
    currency: "Ouguiya",
    numeric_code: 478,
    minor_unit: "",
    symbol: "UM",
    withdrawal_date: 1512086400000,
  },
  MXP: {
    countries: ["MEXICO"],
    currency: "Mexican Peso",
    numeric_code: 484,
    minor_unit: "",
    symbol: "MXP ",
    withdrawal_date: 725842800000,
  },
  MZE: {
    countries: ["MOZAMBIQUE"],
    currency: "Mozambique Escudo",
    numeric_code: 508,
    minor_unit: "",
    symbol: "MZE ",
    withdrawal_date: null,
  },
  MZM: {
    countries: ["MOZAMBIQUE"],
    currency: "Mozambique Metical",
    numeric_code: 508,
    minor_unit: "",
    symbol: "MZM ",
    withdrawal_date: 1149116400000,
  },
  NLG: {
    countries: ["NETHERLANDS"],
    currency: "Netherlands Guilder",
    numeric_code: 528,
    minor_unit: "",
    symbol: "NLG ",
    withdrawal_date: 1014940800000,
  },
  NIC: {
    countries: ["NICARAGUA"],
    currency: "Cordoba",
    numeric_code: 558,
    minor_unit: "",
    symbol: "NIC ",
    withdrawal_date: 654739200000,
  },
  PEH: {
    countries: ["PERU"],
    currency: "Sol",
    numeric_code: 604,
    minor_unit: "",
    symbol: "PEH ",
    withdrawal_date: null,
  },
  PEI: {
    countries: ["PERU"],
    currency: "Inti",
    numeric_code: 604,
    minor_unit: "",
    symbol: "PEI ",
    withdrawal_date: 678322800000,
  },
  PES: {
    countries: ["PERU"],
    currency: "Sol",
    numeric_code: 604,
    minor_unit: "",
    symbol: "PES ",
    withdrawal_date: 507600000000,
  },
  PLZ: {
    countries: ["POLAND"],
    currency: "Zloty",
    numeric_code: 616,
    minor_unit: "",
    symbol: "PLZ ",
    withdrawal_date: 852076800000,
  },
  PTE: {
    countries: ["PORTUGAL"],
    currency: "Portuguese Escudo",
    numeric_code: 620,
    minor_unit: "",
    symbol: "PTE ",
    withdrawal_date: 1014940800000,
  },
  ROK: {
    countries: ["ROMANIA"],
    currency: "Leu A/52",
    numeric_code: 642,
    minor_unit: "",
    symbol: "ROK ",
    withdrawal_date: null,
  },
  ROL: {
    countries: ["ROMANIA"],
    currency: "Old Leu",
    numeric_code: 642,
    minor_unit: "",
    symbol: "ROL ",
    withdrawal_date: 1117580400000,
  },
  STD: {
    countries: ["SAO TOME AND PRINCIPE"],
    currency: "Dobra",
    numeric_code: 678,
    minor_unit: "",
    symbol: "Db",
    withdrawal_date: 1512086400000,
  },
  CSD: {
    countries: ["SERBIA AND MONTENEGRO"],
    currency: "Serbian Dinar",
    numeric_code: 891,
    minor_unit: "",
    symbol: "CSD ",
    withdrawal_date: 1159657200000,
  },
  SKK: {
    countries: ["SLOVAKIA"],
    currency: "Slovak Koruna",
    numeric_code: 703,
    minor_unit: "",
    symbol: "SKK ",
    withdrawal_date: 1230768000000,
  },
  SIT: {
    countries: ["SLOVENIA"],
    currency: "Tolar",
    numeric_code: 705,
    minor_unit: "",
    symbol: "SIT ",
    withdrawal_date: 1167609600000,
  },
  RHD: {
    countries: ["SOUTHERN RHODESIA"],
    currency: "Rhodesian Dollar",
    numeric_code: 716,
    minor_unit: "",
    symbol: "RHD ",
    withdrawal_date: null,
  },
  ESA: {
    countries: ["SPAIN"],
    currency: "Spanish Peseta",
    numeric_code: 996,
    minor_unit: "",
    symbol: "ESA ",
    withdrawal_date: null,
  },
  ESB: {
    countries: ["SPAIN"],
    currency: '"A" Account (convertible Peseta Account)',
    numeric_code: 995,
    minor_unit: "",
    symbol: "ESB ",
    withdrawal_date: 786236400000,
  },
  SDD: {
    countries: ["SUDAN"],
    currency: "Sudanese Dinar",
    numeric_code: 736,
    minor_unit: "",
    symbol: "SDD ",
    withdrawal_date: 1183244400000,
  },
  SDP: {
    countries: ["SUDAN"],
    currency: "Sudanese Pound",
    numeric_code: 736,
    minor_unit: "",
    symbol: "SDP ",
    withdrawal_date: 896655600000,
  },
  SRG: {
    countries: ["SURINAME"],
    currency: "Surinam Guilder",
    numeric_code: 740,
    minor_unit: "",
    symbol: "SRG ",
    withdrawal_date: 1070236800000,
  },
  CHC: {
    countries: ["SWITZERLAND"],
    currency: "WIR Franc (for electronic)",
    numeric_code: 948,
    minor_unit: "",
    symbol: "CHC ",
    withdrawal_date: 1099267200000,
  },
  TJR: {
    countries: ["TAJIKISTAN"],
    currency: "Tajik Ruble",
    numeric_code: 762,
    minor_unit: "",
    symbol: "TJR ",
    withdrawal_date: 986079600000,
  },
  TPE: {
    countries: ["TIMOR-LESTE"],
    currency: "Timor Escudo",
    numeric_code: 626,
    minor_unit: "",
    symbol: "TPE ",
    withdrawal_date: 1036108800000,
  },
  TRL: {
    countries: ["TURKEY"],
    currency: "Old Turkish Lira",
    numeric_code: 792,
    minor_unit: "",
    symbol: "₤",
    withdrawal_date: 1133395200000,
  },
  TMM: {
    countries: ["TURKMENISTAN"],
    currency: "Turkmenistan Manat",
    numeric_code: 795,
    minor_unit: "",
    symbol: "TMM ",
    withdrawal_date: 1230768000000,
  },
  UGS: {
    countries: ["UGANDA"],
    currency: "Uganda Shilling",
    numeric_code: 800,
    minor_unit: "",
    symbol: "UGS ",
    withdrawal_date: 546822000000,
  },
  UGW: {
    countries: ["UGANDA"],
    currency: "Old Shilling",
    numeric_code: 800,
    minor_unit: "",
    symbol: "UGW ",
    withdrawal_date: null,
  },
  UAK: {
    countries: ["UKRAINE"],
    currency: "Karbovanet",
    numeric_code: 804,
    minor_unit: "",
    symbol: "UAK ",
    withdrawal_date: 841532400000,
  },
  SUR: {
    countries: ["UNION OF SOVIET SOCIALIST REPUBLICS"],
    currency: "Rouble",
    numeric_code: 810,
    minor_unit: "",
    symbol: "SUR ",
    withdrawal_date: 660009600000,
  },
  USS: {
    countries: ["UNITED STATES"],
    currency: "US Dollar (Same day)",
    numeric_code: 998,
    minor_unit: "",
    symbol: "USS ",
    withdrawal_date: 1393632000000,
  },
  UYN: {
    countries: ["URUGUAY"],
    currency: "Old Uruguay Peso",
    numeric_code: 858,
    minor_unit: "",
    symbol: "UYN ",
    withdrawal_date: 628473600000,
  },
  UYP: {
    countries: ["URUGUAY"],
    currency: "Uruguayan Peso",
    numeric_code: 858,
    minor_unit: "",
    symbol: "UYP ",
    withdrawal_date: 730940400000,
  },
  VEB: {
    countries: ["VENEZUELA"],
    currency: "Bolivar",
    numeric_code: 862,
    minor_unit: "",
    symbol: "VEB ",
    withdrawal_date: 1199145600000,
  },
  VEF: {
    countries: [
      "VENEZUELA",
      "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
      "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    ],
    currency: "Bolivar Fuerte",
    numeric_code: 937,
    minor_unit: "",
    symbol: "Bs",
    withdrawal_date: 1322697600000,
  },
  VNC: {
    countries: ["VIETNAM"],
    currency: "Old Dong",
    numeric_code: 704,
    minor_unit: "",
    symbol: "VNC ",
    withdrawal_date: null,
  },
  YDD: {
    countries: ["YEMEN, DEMOCRATIC"],
    currency: "Yemeni Dinar",
    numeric_code: 720,
    minor_unit: "",
    symbol: "YDD ",
    withdrawal_date: 683679600000,
  },
  YUD: {
    countries: ["YUGOSLAVIA"],
    currency: "New Yugoslavian Dinar",
    numeric_code: 890,
    minor_unit: "",
    symbol: "YUD ",
    withdrawal_date: 631152000000,
  },
  YUM: {
    countries: ["YUGOSLAVIA"],
    currency: "New Dinar",
    numeric_code: 891,
    minor_unit: "",
    symbol: "YUM ",
    withdrawal_date: 1057014000000,
  },
  YUN: {
    countries: ["YUGOSLAVIA"],
    currency: "Yugoslavian Dinar",
    numeric_code: 890,
    minor_unit: "",
    symbol: "YUN ",
    withdrawal_date: 815180400000,
  },
  ZRN: {
    countries: ["ZAIRE"],
    currency: "New Zaire",
    numeric_code: 180,
    minor_unit: "",
    symbol: "ZRN ",
    withdrawal_date: 928191600000,
  },
  ZRZ: {
    countries: ["ZAIRE"],
    currency: "Zaire",
    numeric_code: 180,
    minor_unit: "",
    symbol: "ZRZ ",
    withdrawal_date: 760057200000,
  },
  ZMK: {
    countries: ["ZAMBIA"],
    currency: "Zambian Kwacha",
    numeric_code: 894,
    minor_unit: "",
    symbol: "ZMK ",
    withdrawal_date: 1354320000000,
  },
  ZWC: {
    countries: ["ZIMBABWE"],
    currency: "Rhodesian Dollar",
    numeric_code: 716,
    minor_unit: "",
    symbol: "ZWC ",
    withdrawal_date: 628473600000,
  },
  ZWD: {
    countries: ["ZIMBABWE", "ZIMBABWE"],
    currency: "Zimbabwe Dollar (old)",
    numeric_code: 716,
    minor_unit: "",
    symbol: "Z$",
    withdrawal_date: 1154386800000,
  },
  ZWN: {
    countries: ["ZIMBABWE"],
    currency: "Zimbabwe Dollar (new)",
    numeric_code: 942,
    minor_unit: "",
    symbol: "ZWN ",
    withdrawal_date: 1157065200000,
  },
  ZWR: {
    countries: ["ZIMBABWE"],
    currency: "Zimbabwe Dollar",
    numeric_code: 935,
    minor_unit: "",
    symbol: "ZWR ",
    withdrawal_date: 1243810800000,
  },
  XFO: {
    countries: ["ZZ01_Gold-Franc"],
    currency: "Gold-Franc",
    numeric_code: null,
    minor_unit: "",
    symbol: "XFO ",
    withdrawal_date: 1159657200000,
  },
  XRE: {
    countries: ["ZZ02_RINET Funds Code"],
    currency: "RINET Funds Code",
    numeric_code: null,
    minor_unit: "",
    symbol: "XRE ",
    withdrawal_date: 941414400000,
  },
  XFU: {
    countries: ["ZZ05_UIC-Franc"],
    currency: "UIC-Franc",
    numeric_code: null,
    minor_unit: "",
    symbol: "XFU ",
    withdrawal_date: 1383264000000,
  },
}

export const currencyToSymbol = (code = "") => {
  const curr = code.toUpperCase()

  if (Object.keys(currencies).includes(curr)) {
    return currencies[curr].symbol || EUR_SYMBOL
  }

  return curr || EUR_SYMBOL
}
