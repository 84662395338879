import React from "react"
import PropTypes from "prop-types"

import clsx from "clsx"

import styles from "./TableElement.module.scss"

function TableElement({ children, className, ...props }) {
  return (
    <table {...props} className={clsx(styles.table, className)}>
      {children}
    </table>
  )
}

TableElement.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

TableElement.defaultProps = {
  className: "",
}

export default TableElement
