import { get } from "lodash"
import { useSelector } from "react-redux"
import { MATCHES_OVERVIEW_PAGE_FILTERS_KEY } from "../../../ducks/pages/matches-overview/constants"

export default function useMatchesOverviewFilters(path) {
  return useSelector((state) =>
    get(state, [
      "pages",
      "MatchesOverviewPage",
      MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
      ...path,
    ]),
  )
}
