import React from "react"
import _PropTypes from "prop-types"
import ReactPlaceholder from "react-placeholder"
import { sentenceCase } from "text-case"
import { useDimensionFilter, useDimensionOutline } from "./hooks"
import { Select } from "../ui"
import { buildAnalyticsEventName } from "../../util/user-monitoring"

const analyticsEventName = buildAnalyticsEventName({
  feature: "Assortment Analysis",
  target: "Dimension",
  userAction: "Selected",
})

const selectorPlaceholder = (
  <ReactPlaceholder
    className="rounded"
    showLoadingAnimation
    style={{ width: 256, height: 32, margin: "0" }}
    type="rect"
  />
)

function AssortmentAnalysisDimensionSelector() {
  const {
    data: dimensions,
    isError,
    isIdle,
    isFetching,
    isSuccess,
  } = useDimensionOutline()
  const { dimension, setDimension } = useDimensionFilter()

  if (isIdle || isFetching) {
    return selectorPlaceholder
  }

  if (isError) {
    return null
  }

  if (isSuccess && dimensions) {
    return (
      <Select
        analyticsEventName={analyticsEventName}
        defaultValue={dimension}
        onValueChange={(value) => setDimension({ dimension: value })}
      >
        {dimensions.map((opt) => (
          <Select.Item key={opt} value={opt}>
            {sentenceCase(opt)}
          </Select.Item>
        ))}
      </Select>
    )
  }

  return null
}

AssortmentAnalysisDimensionSelector.propTypes = {}
AssortmentAnalysisDimensionSelector.defaultProps = {}

export { AssortmentAnalysisDimensionSelector }
