import React, { Component } from "react"
import ErrorIcon from "@material-ui/icons/ErrorOutlineRounded"
import FactorInputContainer from "./FactorInputContainer"

class FactorInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <span className="factor-input-error" title="Something went wrong here :(">
          <ErrorIcon color="secondary" />
        </span>
      )
    }

    return <FactorInputContainer {...this.props} />
  }
}

export default FactorInput
