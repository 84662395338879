import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"

import clsx from "clsx"

import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { scroll } from "framer-motion"

import { Table } from "../../ui"
import styles from "./ProductComparisonTable.module.scss"
import { isDevelopment } from "../../../util/env"
import { ProductComparisonTableHeaderTitle } from "./ProductComparisonTableHeaderTitle"
import { ProductComparisonTableHeaderImage } from "./ProductComparisonTableHeaderImage"
import { ProductComparisonTableHeaderControls } from "./ProductComparisonTableHeaderControls"
import { ProductComparisonTableHeaderFooter } from "./ProductComparisonTableHeaderFooter"
import { ProductComparisonTableBodyCell } from "./ProductComparisonTableBodyCell"
import { ProductComparisonTableHeaderImageAndTitle } from "./ProductComparisonTableHeaderImageAndTitle"
import { ProductComparisonTableHeaderFooterAndControls } from "./ProductComparisonTableHeaderFooterAndControls"

import useReferenceProduct from "../../../hooks/use-reference-product"
import { ProductComparisonTableNavigation } from "./ProductComparisonTableNavigation"
import { ComparisonData } from "./ComparisonDataPropType"

const debugTable = isDevelopment
const SCROLLABLE_TABLE = "scrollable-table"

function headerFactory({
  productId = null,
  daltixId = null,
  matchId = null,
  matchData = {},
  outdated = false,
}) {
  return {
    daltixId,
    isMatch:
      (daltixId !== null && matchId !== null) ||
      (daltixId !== null && matchId === null),
    matchId,
    productId,
    matchData,
    outdated,
    Title: ProductComparisonTableHeaderTitle,
    Image: ProductComparisonTableHeaderImage,
    Controls: ProductComparisonTableHeaderControls,
    Footer: ProductComparisonTableHeaderFooter,
    ImageAndTitle: ProductComparisonTableHeaderImageAndTitle,
    FooterAndControls: ProductComparisonTableHeaderFooterAndControls,
  }
}

function RenderHeader({ column, element, getContext, isPlaceholder }) {
  if (isPlaceholder) {
    return null
  }

  const module = column.columnDef.header

  const renderedComponent = flexRender(module[element], {
    ...getContext(),
    daltixId: module.daltixId,
    matchId: module.matchId,
    productId: module.productId,
    isMatch: module.isMatch,
    matchData: module.matchData,
    outdated: module.outdated,
  })

  return renderedComponent
}

RenderHeader.propTypes = {
  column: PropTypes.shape({
    columnDef: PropTypes.shape({
      header: PropTypes.shape({
        daltixId: PropTypes.string,
        matchId: PropTypes.string,
        productId: PropTypes.string.isRequired,
        isMatch: PropTypes.bool.isRequired,
        matchData: ComparisonData,
        outdated: PropTypes.bool,
        Title: PropTypes.func.isRequired,
        Image: PropTypes.func.isRequired,
        Controls: PropTypes.func.isRequired,
        Footer: PropTypes.func.isRequired,
        ImageAndTitle: PropTypes.func.isRequired,
        FooterAndControls: PropTypes.func.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  element: PropTypes.oneOf([
    "Title",
    "Image",
    "Controls",
    "Footer",
    "ImageAndTitle",
    "FooterAndControls",
  ]).isRequired,
  getContext: PropTypes.func.isRequired,
  isPlaceholder: PropTypes.bool,
}

RenderHeader.defaultProps = {
  isPlaceholder: false,
}

function RenderAdjustableHeader(headerGroup) {
  const [showCompact, enableCompact] = useState(false)

  const table = document.getElementById(SCROLLABLE_TABLE)
  if (table) {
    scroll(
      (progress) => {
        enableCompact(progress > 0)
      },
      { source: table },
    )
  }

  return (
    <React.Fragment key={headerGroup?.id}>
      <tr
        id="mini-display"
        className={clsx(styles.row, (!showCompact && styles.hide) || styles.show)}
      >
        {headerGroup?.headers.map((header) => (
          <RenderHeader
            key={`${header.id}-adjusted`}
            {...header}
            element="ImageAndTitle"
          />
        ))}
      </tr>
      <tr
        id="mini-display"
        className={clsx(styles.row, (!showCompact && styles.hide) || styles.show)}
      >
        {headerGroup?.headers.map((header) => (
          <RenderHeader
            key={`${header.id}-adjusted`}
            {...header}
            element="FooterAndControls"
          />
        ))}
      </tr>
      <tr
        id="full-display"
        className={clsx(styles.row, (showCompact && styles.hide) || styles.show)}
      >
        {headerGroup?.headers.map((header) => (
          <RenderHeader key={header.id} {...header} element="Title" />
        ))}
      </tr>
      <tr
        id="full-display"
        className={clsx(styles.row, (showCompact && styles.hide) || styles.show)}
      >
        {headerGroup?.headers.map((header) => (
          <RenderHeader key={header.id} {...header} element="Image" />
        ))}
      </tr>
      <tr
        id="full-display"
        className={clsx(styles.row, (showCompact && styles.hide) || styles.show)}
      >
        {headerGroup?.headers.map((header) => (
          <RenderHeader key={header.id} {...header} element="Controls" />
        ))}
      </tr>
      <tr
        id="full-display"
        className={clsx(styles.row, (showCompact && styles.hide) || styles.show)}
      >
        {headerGroup?.headers.map((header) => (
          <RenderHeader key={header.id} {...header} element="Footer" />
        ))}
      </tr>
    </React.Fragment>
  )
}

function ProductComparisonTable({ productId, daltixProducts }) {
  const refProd = useReferenceProduct(productId)

  // sort the maches to avoid flickering
  const sortedProducts = Object.keys(daltixProducts).sort()

  const matchesData = useMemo(() => {
    const data = {}
    sortedProducts.forEach((id) => {
      data[id] = {
        data: {
          match: daltixProducts[id].data,
          matched: {
            ...daltixProducts[id].data?.matched,
            "ref_product_id": productId,
          },
        },
        isReady: true,
      }
    })
    return data
  }, [daltixProducts, productId, sortedProducts])

  const columns = useMemo(
    () => [
      {
        id: productId,
        header: headerFactory({
          productId,
          matchData: { ref: refProd, match: {} },
        }),
        cell: (c) => {
          const ref = refProd
          const data = { ref, match: {} }
          return (
            <ProductComparisonTableBodyCell data={data} type={c.row.original.type} />
          )
        },
        footer: null,
      },
      ...sortedProducts.map((id) => {
        const ref = refProd
        const match = matchesData[id]
        const data = { ref, match }

        return {
          id,
          header: headerFactory({
            productId,
            daltixId: match?.data?.match?.id,
            matchId: match?.data?.matched?.id,
            matchData: data,
            outdated: match?.data?.match?.outdated,
          }),
          cell: (c) => (
            <ProductComparisonTableBodyCell
              data={data}
              isMatch
              type={c.row.original.type}
            />
          ),
          footer: null,
        }
      }),
    ],
    [matchesData, productId, refProd, sortedProducts],
  )

  const data = [
    { type: "source" },
    { type: "matchType" },
    { type: "retailer" },
    { type: "brand" },
    { type: "ean" },
    { type: "content" },
    { type: "price" },
    { type: "xFactor" },
    { type: "adjusted" },
    { type: "matchLabel" },
    { type: "description" },
    { type: "ingredients" },
    { type: "nutrients" },
    { type: "nutriScore" },
    { type: "country" },
    { type: "comment" },
  ]

  const table = useReactTable({
    data,
    columns,
    debugTable,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Table.Wrapper justifyContent="start" data-testid="product-comparison-widget">
      <Table.Container
        id={SCROLLABLE_TABLE}
        className={clsx(styles.container, "fade-in")}
      >
        <ProductComparisonTableNavigation parentID={SCROLLABLE_TABLE} size={354.36} />
        <Table.Element className={styles.table}>
          <thead className={styles.head}>
            {table
              .getHeaderGroups()
              .map((headerGroup) => RenderAdjustableHeader(headerGroup))}
          </thead>
          <tbody className={styles.body}>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={clsx(styles.row, styles.shadowsV, styles.shadowsH)}
              >
                {row.getVisibleCells().map((cell) => (
                  <React.Fragment key={cell.id}>
                    <td className={styles.data}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </Table.Element>
      </Table.Container>
    </Table.Wrapper>
  )
}

ProductComparisonTable.propTypes = {
  productId: PropTypes.string.isRequired,
  daltixProducts: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      data: PropTypes.object,
      isReady: PropTypes.bool,
    }),
  }).isRequired,
}

export { ProductComparisonTable }
