import { useSelector, shallowEqual } from "react-redux"

const defaultOptions = {
  defaultValue: {},
}

function useCustomer({ defaultValue } = defaultOptions) {
  return useSelector(
    ({ user: { user: { customer = defaultValue } = {} } = {} } = {}) => customer,
    shallowEqual,
  )
}

export default useCustomer
