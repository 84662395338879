import React from "react"
import IdenticalIcon from "@mui/icons-material/DragHandleOutlined"
import MultipackIcon from "@mui/icons-material/ViewModuleOutlined"
import VolumeVariantIcon from "@mui/icons-material/ScaleOutlined"
import NotDefinedIcon from "@mui/icons-material/PriorityHighOutlined"
import UnknownXFactorIcon from "@mui/icons-material/HelpCenterOutlined"

import {
  MATCH_TYPE_FRESH,
  MATCH_TYPE_IDENTICAL,
  MATCH_TYPE_MULTIPACK,
  MATCH_TYPE_MULTIPACK_VOLUME_VARIANT,
  MATCH_TYPE_PRIVATE_LABEL,
  MATCH_TYPE_UNKNOWN_XFACTOR,
  MATCH_TYPE_VOLUME_VARIANT,
} from "../../util/match-type"
import { DXIcon } from "../dx-icon/DXIcon"

function MatchTypeIcon({ type, className = "" }) {
  let icon

  switch (type) {
    case MATCH_TYPE_IDENTICAL:
      icon = <IdenticalIcon className={className} />
      break

    case MATCH_TYPE_MULTIPACK:
      icon = <MultipackIcon className={className} />
      break

    case MATCH_TYPE_VOLUME_VARIANT:
    case MATCH_TYPE_MULTIPACK_VOLUME_VARIANT:
      icon = <VolumeVariantIcon className={className} />
      break

    case MATCH_TYPE_UNKNOWN_XFACTOR:
      icon = <UnknownXFactorIcon className={className} />
      break

    case MATCH_TYPE_PRIVATE_LABEL:
    case MATCH_TYPE_FRESH:
      icon = <DXIcon className={className} type={type} />
      break

    default:
      icon = <NotDefinedIcon className={className} />
      break
  }

  return icon
}

export function useMatchTypeTooltip(type) {
  let tooltip

  switch (type) {
    case MATCH_TYPE_IDENTICAL:
      tooltip = "Identical"
      break

    case MATCH_TYPE_MULTIPACK:
      tooltip = "Multipack"
      break

    case MATCH_TYPE_VOLUME_VARIANT:
    case MATCH_TYPE_MULTIPACK_VOLUME_VARIANT:
      tooltip = "Volume Variant"
      break

    case MATCH_TYPE_PRIVATE_LABEL:
      tooltip = "Private Label"
      break

    case MATCH_TYPE_FRESH:
      tooltip = "Fresh"
      break

    case MATCH_TYPE_UNKNOWN_XFACTOR:
      tooltip = "Unknown xFactor"
      break

    default:
      tooltip = "Not Defined"
      break
  }

  return tooltip
}

export default MatchTypeIcon
