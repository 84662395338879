import React from "react"
import PropTypes from "prop-types"
import Image from "../../img/Image"

import styles from "./NoResultsDisplay.module.scss"

function NoResultsDisplay({ children, alt, src }) {
  return (
    <div role="alert" className={styles.container}>
      <Image alt={alt} src={src} className={styles.image} />
      <h3 className={styles.message}>{children}</h3>
    </div>
  )
}

NoResultsDisplay.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

NoResultsDisplay.defaultProps = {
  alt: "",
}

export { NoResultsDisplay }
