import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import ReactPlaceholder from "react-placeholder"
import { ProductComparisonTableHeaderContainer } from "./ProductComparisonTableHeaderContainer"

import styles from "./ProductComparisonTableHeaderTitle.module.scss"
// import useDaltixProduct from "../../../hooks/use-daltix-product"
import useReferenceProduct from "../../../hooks/use-reference-product"
import { ComparisonData } from "./ComparisonDataPropType"

function Placeholder(props) {
  return (
    <ReactPlaceholder
      className="rounded"
      color="#f5f5f5"
      showLoadingAnimation
      style={{ width: "6rem", height: "1rem" }}
      type="rect"
      {...props}
    />
  )
}

function MatchTitle({ daltixId: _daltixId, matchData, matchId: _ }) {
  // const { data, isReady } = useDaltixProduct(daltixId)
  const isReady = matchData?.isReady
  const data = matchData?.data?.match

  if (!isReady) {
    return <Placeholder />
  }

  return <p>{data?.name}</p>
}

MatchTitle.propTypes = {
  daltixId: PropTypes.string.isRequired,
  matchData: ComparisonData.isRequired,
  matchId: PropTypes.string.isRequired,
}

function ReferenceProductTitle({ productId }) {
  const { data, isReady } = useReferenceProduct(productId)

  return (
    <Placeholder ready={isReady}>
      <p>{data?.name}</p>
    </Placeholder>
  )
}

ReferenceProductTitle.propTypes = {
  productId: PropTypes.string.isRequired,
}

function ProductComparisonTableHeaderTitle({
  daltixId,
  isMatch,
  matchData,
  matchId,
  productId,
  outdated,
}) {
  return (
    <ProductComparisonTableHeaderContainer>
      <h1 className={clsx(styles.title, outdated && styles.outdated)}>
        {isMatch ? (
          <MatchTitle
            daltixId={daltixId}
            matchData={matchData?.match}
            matchId={matchId}
            outdated={outdated}
          />
        ) : (
          <ReferenceProductTitle productId={productId} />
        )}
      </h1>
    </ProductComparisonTableHeaderContainer>
  )
}

ProductComparisonTableHeaderTitle.propTypes = {
  daltixId: PropTypes.string,
  isMatch: PropTypes.bool.isRequired,
  matchData: ComparisonData.isRequired,
  matchId: PropTypes.string,
  productId: PropTypes.string.isRequired,
  outdated: PropTypes.bool,
}

ProductComparisonTableHeaderTitle.defaultProps = {
  daltixId: "",
  matchId: "",
  outdated: false,
}

export { ProductComparisonTableHeaderTitle }
