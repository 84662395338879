import { get } from "lodash"
import useAsyncSlice from "../../../hooks/use-async-slice"
import { triggerGetCustomerAssortment } from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

const action = triggerGetCustomerAssortment()

function selector(state) {
  return get(state, [
    "pages",
    ASSORTMENT_ANALYSIS_PAGE_KEY,
    ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY,
  ])
}

export function useAssortmentAnalysisTable() {
  return useAsyncSlice({
    action,
    selector,
    immediate: false,
  })
}
