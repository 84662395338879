import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import {
  Box,
  ClickAwayListener,
  inputBaseClasses,
  outlinedInputClasses,
  selectClasses,
} from "@mui/material"
import { bool, node } from "prop-types"
import React, { useState } from "react"

function EditableCell({ children, isDisabled }) {
  const [isEditing, setEditing] = useState(false)

  const onClick = () => {
    if (isDisabled) {
      return
    }

    setEditing(true)
  }

  return (
    <ClickAwayListener onClickAway={() => setEditing(false)}>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        width="100%"
        sx={{
          cursor: isDisabled ? undefined : "pointer",
          "&:hover svg": {
            visibility: isEditing ? "" : "visible",
          },
          [`& .${selectClasses.icon}:not(.${selectClasses.iconOpen})`]: {
            display: isEditing ? undefined : "none",
          },
          [`.${inputBaseClasses.root}`]: {
            pointerEvents: isEditing ? undefined : "none",
            backgroundColor: isEditing ? undefined : "transparent",
          },
          [`& .${inputBaseClasses.input}`]: {
            transition: "padding 0.3s",
            padding: isEditing ? undefined : 0,
          },
          [`.${outlinedInputClasses.notchedOutline}`]: {
            border: isEditing ? undefined : "none",
          },
        }}
        onClick={onClick}
      >
        {!isDisabled && <EditOutlinedIcon className="editable-cell-icon" />}
        {children}
      </Box>
    </ClickAwayListener>
  )
}

EditableCell.propTypes = {
  children: node.isRequired,
  isDisabled: bool,
}

EditableCell.defaultProps = {
  isDisabled: false,
}

export { EditableCell }
