import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import React, { useEffect, useState } from "react"
import { useProductSearch } from "../../ducks/widgets/product-search/hooks"
import { ProductSearchFilter } from "./partials/Filter/Filter"
import { ProductSearchTable } from "./partials/Table/Table"
import { ProductSearchToolbar } from "./partials/Toolbar/Toolbar"

const INITIAL_SIZE = "small"

export function ProductSearchDialog() {
  const [size, setSize] = useState(INITIAL_SIZE)
  const { isOpen, close, state, referenceProduct, getReferenceProductById, setQuery } =
    useProductSearch()
  const { referenceProductId } = state
  const referenceProductName = referenceProduct?.data?.name

  useEffect(() => {
    if (!referenceProductId) {
      return
    }

    getReferenceProductById(referenceProductId)
  }, [getReferenceProductById, referenceProductId])

  useEffect(() => {
    if (!referenceProductName) {
      return
    }

    setQuery(referenceProductName)
  }, [referenceProductName, setQuery])

  useEffect(() => {
    if (!isOpen) {
      setSize(INITIAL_SIZE)
    }
  }, [isOpen])

  return (
    <Dialog
      data-testid="product-search"
      open={isOpen}
      onClose={close}
      maxWidth={false}
      className="dx-full-dialog"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Product search
          <IconButton onClick={close} aria-label="close" size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box display="flex" flexDirection="column" px={2}>
        <ProductSearchToolbar selectedRefProduct={referenceProduct.data} size={size} />

        <ProductSearchFilter
          selectedRefProduct={referenceProduct.data}
          size={size}
          setSize={setSize}
        />
      </Box>

      <Box display="flex" flexGrow={1} px={2} overflow="hidden">
        <ProductSearchTable size={size} />
      </Box>
    </Dialog>
  )
}
