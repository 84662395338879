import PropTypes from "prop-types"

export const ArticlePropType = PropTypes.shape({
  articleIds: PropTypes.arrayOf(PropTypes.string),
  brand: PropTypes.string,
  brandLayer: PropTypes.string,
  calories: PropTypes.string,
  categoryId: PropTypes.number.isRequired,
  detailedFlavour: PropTypes.string,
  flavour: PropTypes.string,
  format: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  multipack: PropTypes.arrayOf(PropTypes.string),
  packaging: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pricePerLitre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rbpId: PropTypes.string,
  regularZero: PropTypes.string,
  shop: PropTypes.string.isRequired,
  sparkling: PropTypes.string,
  type: PropTypes.string,
})
