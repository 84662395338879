import PropTypes from "prop-types"
import {
  DIMENSION_BRAND_TYPE,
  DIMENSION_PRICE,
} from "../../../ducks/pages/assortment-analysis/constants"

export const DimensionPricePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  start: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  end: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.oneOf([DIMENSION_PRICE]),
})

export const DimensionBrandPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([DIMENSION_BRAND_TYPE]),
})
