import { get } from "lodash"
import { sentenceCase } from "text-case"
import {
  toAsyncErrorSlice,
  toAsyncLoadingSlice,
  toAsyncSuccessSlice,
} from "../../../../util/redux-helpers"
import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
} from "../constants"

function getFiltersOutline(state) {
  return get(state, [ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY])
}

function getDimensionsOutline(state) {
  return get(state, [
    ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY,
    ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
  ])
}

function getProductGroupsOutline(state) {
  return get(state, [
    ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY,
    ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
  ])
}

export function handleGetCustomerAssortmentOutline(state) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY]: {
      ...getFiltersOutline(state),
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: {
        ...toAsyncSuccessSlice(getDimensionsOutline(state).data),
      },
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]: {
        ...toAsyncLoadingSlice(getProductGroupsOutline(state).data),
      },
    },
  }
}

export function handleGetCustomerAssortmentOutlineSuccess(state, { payload }) {
  const { dimensions, productGroups } = payload

  const outline = productGroups.map((pg) => ({
    id: pg.groupId,
    categoryId: pg.id,
    categoryName: pg.categoryName,
    groupName: pg.groupName,
    title: pg.constituents.map((x) => sentenceCase(x || "")).join(" > "),
  }))

  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY]: {
      ...getFiltersOutline(state),
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: {
        ...toAsyncSuccessSlice(dimensions),
      },
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]: {
        ...toAsyncSuccessSlice(outline),
      },
    },
  }
}

export function handleGetCustomerAssortmentOutlineError(state, { error }) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY]: {
      ...getFiltersOutline(state),
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: {
        ...toAsyncErrorSlice(getDimensionsOutline(state).data, error),
      },
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]: {
        ...toAsyncErrorSlice(getProductGroupsOutline(state).data, error),
      },
    },
  }
}
