import React from "react"
import PropTypes from "prop-types"
import MultiSelect from "../multi-select/MultiSelect"
import { CATEGORY_SEPARATOR } from "../../ducks/category"

function CategorySelect({
  className,
  disabled,
  index,
  label,
  onChange,
  options,
  selected,
  itemRenderer,
  analyticsEventName,
}) {
  const ItemRendererComp = itemRenderer

  return (
    <MultiSelect
      label={label}
      className={className}
      disabled={disabled}
      options={options}
      value={selected}
      onChange={(e) => onChange(index, e)}
      optionKey={({ name }) => name}
      optionLabelRenderer={(category) => <ItemRendererComp {...category} />}
      groupLabelRenderer={({ group }) => group.split(CATEGORY_SEPARATOR).join(" > ")}
      analyticsEventName={analyticsEventName}
    />
  )
}

CategorySelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      }).isRequired,
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
          }),
        ).isRequired,
      }),
    ),
  ]).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemRenderer: PropTypes.elementType.isRequired,
  analyticsEventName: PropTypes.string,
}

CategorySelect.defaultProps = {
  className: "",
  disabled: false,
  analyticsEventName: "",
}

export default CategorySelect
