import { Autocomplete, CircularProgress, TextField, useTheme } from "@mui/material"
import { debounce } from "@mui/material/utils"
import { bool } from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import mixpanel from "mixpanel-browser"
import { getRefProdAutoCompleteSuggestions } from "../../../../api/service"
import { useProductSearch } from "../../../../ducks/widgets/product-search/hooks"

function RefProductAutocomplete({ isLoading }) {
  const theme = useTheme()
  const [input, setInput] = useState("")
  const [selected, setSelected] = useState(null)
  const [suggestions, setSuggestions] = useState([])
  const { referenceProduct, listReferenceProduct } = useProductSearch()
  const referenceProductName = referenceProduct?.data?.name

  const getSuggestions = useMemo(() => {
    async function fetch(query) {
      const {
        products: { articleNrs, brands, eans, names },
      } = await getRefProdAutoCompleteSuggestions({ limit: 10, query })

      const newSuggestions = [
        ...articleNrs.map((v) => ({ type: "ref", title: v, id: `ref_${v}` })),
        ...eans.map((v) => ({ type: "ean", title: v, id: `ean_${v}` })),
        ...names.map((v) => ({ type: "name", title: v, id: `name_${v}` })),
        ...brands.map((v) => ({ type: "brand", title: v, id: `brand_${v}` })),
      ]

      newSuggestions.sort((a, b) => a.title.localeCompare(b.title))
      setSuggestions(newSuggestions)
    }

    return debounce(fetch, 400)
  }, [])

  useEffect(() => {
    if (!input) {
      return undefined
    }

    return getSuggestions(input)
  }, [getSuggestions, input])

  useEffect(() => {
    if (!referenceProductName) {
      return
    }

    const defaultValue = { type: "name", title: referenceProductName }
    setSuggestions([defaultValue])
    setSelected(defaultValue)
    setInput(referenceProductName)

    mixpanel.track("Product Search - search reference product", {
      name: referenceProductName,
    })
  }, [referenceProductName])

  const onSelectOption = (_, newValue) => {
    setSelected(newValue)
    listReferenceProduct(newValue.title)
  }

  const onInputChange = (_, newValue, reason) => {
    if (reason !== "input") {
      return
    }
    setInput(newValue)
  }

  return (
    <Autocomplete
      data-testid="reference-product-autocomplete"
      autoComplete
      disableClearable
      size="small"
      noOptionsText={input ? "No reference products" : "Type to search"}
      filterSelectedOptions
      inputValue={input}
      value={selected}
      options={suggestions}
      onChange={onSelectOption}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, optSelected) => option.id === optSelected.id}
      getOptionDisabled={(option) => option.title === selected.title}
      filterOptions={(x) => x}
      sx={{ width: 240, fontWeight: 700 }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          InputProps={{
            ...params.InputProps,
            sx: { fontWeight: 700, fontSize: theme.typography.body2.fontSize },
            endAdornment: (
              <>
                {isLoading && (
                  <CircularProgress
                    sx={{ position: "absolute", right: "1rem" }}
                    size={15}
                  />
                )}

                {!isLoading && params.InputProps?.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

RefProductAutocomplete.propTypes = {
  isLoading: bool,
}

RefProductAutocomplete.defaultProps = {
  isLoading: false,
}

export { RefProductAutocomplete }
