import React from "react"
import PropTypes from "prop-types"

import { Dialog } from "../ui/Dialog"

function MatchesOverviewFiltersDialog({
  children,
  defaultOpen,
  onApply,
  onClear,
  onClose,
  title,
  trigger,
}) {
  const isControlledSearch = typeof onApply === "function"

  return (
    <Dialog.Root
      defaultOpen={defaultOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose()
        }
      }}
    >
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Content width="thin" height="short">
        <Dialog.Header>
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.CloseX title={title} />
        </Dialog.Header>
        <Dialog.Body>
          {isControlledSearch && (
            <Dialog.Description>
              {`When you are done choosing your filters, click "show results" to apply
                them.`}
            </Dialog.Description>
          )}
          {children}
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Close>
            <Dialog.Button
              border="outline"
              color="danger"
              height="short"
              type="button"
              onClick={onClear}
            >
              <span>Clear all filters</span>
            </Dialog.Button>
          </Dialog.Close>
          {isControlledSearch && (
            <Dialog.Close>
              <Dialog.Button
                border="outline"
                height="short"
                type="button"
                onClick={onApply}
              >
                <span>Show results</span>
              </Dialog.Button>
            </Dialog.Close>
          )}
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}

MatchesOverviewFiltersDialog.propTypes = {
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  trigger: PropTypes.node.isRequired,
}

MatchesOverviewFiltersDialog.defaultProps = {
  defaultOpen: false,
}

export default MatchesOverviewFiltersDialog
