/* eslint-disable import/prefer-default-export */

export const handleClearReferenceProductsList = (state, action) => {
  const {
    parent: { options: { fromUrl } = {} },
  } = action

  if (fromUrl) return state

  return {
    ...state,
    detailedProductIndex: undefined,
    list: [],
    prevPageList: [],
    nextPageList: [],
    page: undefined,
    offset: undefined,
    productCount: undefined,
  }
}
