import React from "react"
import PropTypes from "prop-types"
import ListItem from "@material-ui/core/ListItem"

import styles from "./BaseSlideoverFilterItem.module.scss"

function BaseSlideoverItem({ children, ...props }) {
  return (
    <ListItem dense {...props} className={styles["no-gutters"]}>
      {children}
    </ListItem>
  )
}

BaseSlideoverItem.propTypes = {
  children: PropTypes.node.isRequired,
}

BaseSlideoverItem.defaultProps = {}

export { BaseSlideoverItem }
