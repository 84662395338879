import { AddIcon } from "./AddIcon"
import { CheckIcon } from "./CheckIcon"
import { CompareIcon } from "./CompareIcon"
import { CrossIcon } from "./CrossIcon"
import { EllipsisIcon } from "./EllipsisIcon"
import { EmptyIcon } from "./EmptyIcon"
import { AccessibleIcon } from "./AccessibleIcon"
import { IconSortASC } from "./IconSortASC"
import { IconSortDESC } from "./IconSortDESC"
/**
 * Usage:
 *
 * Wrapping a regular SVG icon and make it accessible with WAI-ARIA attributes
 *
 * ```js
 * <Icon label="foo">
 *   <SomeSVGICon size={24} />
 * </Icon>
 * ```
 *
 * Using Daltix Navigator Icons. The example below renders an <AddIcon /> component
 * which already implements <AccessibleIcon />
 *
 * ```js
 * <Icon.Add {...props} />
 * ```
 */
const Icon = Object.assign(AccessibleIcon, {
  Add: AddIcon,
  Check: CheckIcon,
  Compare: CompareIcon,
  Cross: CrossIcon,
  Ellipis: EllipsisIcon,
  Empty: EmptyIcon,
  SortASC: IconSortASC,
  SortDESC: IconSortDESC,
})

export { Icon }
