import * as Action from "./action-types"
import * as Handler from "./handlers"

import { ReducerFactory, toAsyncInitialSlice } from "../../../util/redux-helpers"

import {
  PRODUCT_COMPARISON_WIDGET_MATCHES_KEY,
  PRODUCT_COMPARISON_WIDGET_FILTERS_KEY,
  PRODUCT_COMPARISON_WIDGET_FILTERS_STATUS_KEY,
  PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY,
  PRODUCT_COMPARISON_WIDGET_COMPARABLE_KEY,
} from "./constants"

const initialState = {
  [PRODUCT_COMPARISON_WIDGET_MATCHES_KEY]: toAsyncInitialSlice(),
  [PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY]: "",
  [PRODUCT_COMPARISON_WIDGET_FILTERS_KEY]: {
    [PRODUCT_COMPARISON_WIDGET_FILTERS_STATUS_KEY]: [],
  },
  [PRODUCT_COMPARISON_WIDGET_COMPARABLE_KEY]: [],
}

const reducer = ReducerFactory.create(initialState, {
  /* -------------------------------------------------------------------------- */
  /*                      comparison overlay event handlers                     */
  /* -------------------------------------------------------------------------- */
  [Action.PRODUCT_COMPARISON_CLOSE]: Handler.handleProductComparisonWidgetClosed,
  [Action.PRODUCT_COMPARISON_OPEN]: Handler.handleProductComparisonWidgetOpened,
  [Action.PRODUCT_COMPARISON_SET_DALTIX_PRODUCT_STATUS_FILTERS]:
    Handler.handleProductComparisonDaltixProductStatusFilterChange,
  [Action.PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES]:
    Handler.handleProductComparisonWidgetGetRefProductMatches,
  [Action.PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES_ERROR]:
    Handler.handleProductComparisonWidgetGetRefProductMatchesError,
  [Action.PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES_SUCCESS]:
    Handler.handleProductComparisonWidgetGetRefProductMatchesSuccess,
  [Action.PRODUCT_COMPARISON_SET_COMPARABLE_PRODUCTS]:
    Handler.handleProductComparisonWidgetCompare,
})

export default reducer
