import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  accordionSummaryClasses,
} from "@mui/material"
import { arrayOf, string } from "prop-types"
import React from "react"
import { DXIcon } from "../../dx-icon/DXIcon"

function EANS({ eans }) {
  if (eans.length === 0) {
    return null
  }

  if (eans.length === 1) {
    return <Typography variant="body2">{eans[0]}</Typography>
  }

  return (
    <Accordion sx={{ boxShadow: 0, backgroundColor: "transparent" }}>
      <AccordionSummary
        sx={{
          p: 0,
          [`.${accordionSummaryClasses.content}`]: {
            display: "flex",
            alignItems: "center",
            gap: 1,
          },
          [`&.${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]: {
            minHeight: 0,
          },
          [`.${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]: {
            m: 0,
          },
        }}
        expandIcon={<DXIcon type="caret" size="0.458rem" color="#0000008f" />}
      >
        <Typography variant="body2">{eans[0]}</Typography>
        <Typography mr={0.5} fontSize="0.625rem" color="#0000008f">
          +{eans.length - 1}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {eans.map((ean, i) => {
          if (i === 0) {
            return null
          }

          return (
            <Typography key={ean} display="block" variant="body2">
              {ean}
            </Typography>
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}

EANS.propTypes = {
  eans: arrayOf(string),
}

EANS.defaultProps = {
  eans: [],
}

export { EANS }
