/* eslint-disable import/prefer-default-export */

export const handleListCountriesWithMatches = (state) => ({
  ...state,
  loadingCountriesList: true,
})

export const handleListCountriesWithMatchesSuccess = (
  state,
  { payload: { countries } },
) => ({
  ...state,
  availableMatchCountries: countries,
  loadingCountriesList: false,
  countriesListError: undefined,
})

export const handleListCountriesWithMatchesError = (state, action) => {
  const { error } = action

  return {
    ...state,
    availableMatchCountries: [],
    loadingCountriesList: false,
    countriesListError: error,
  }
}
