import { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import ReactCountryFlag from "react-country-flag"
import * as React from "react"
import useMatchesOverviewFilters from "./use-matches-overview-filters"
import { stageFilterMatchCountry } from "../../../ducks/pages/matches-overview/action-creators"
import {
  filterSearchParamsApplied,
  getActiveSearchParams,
} from "../../../ducks/pages/matches-overview/helpers"
import { getCountryName } from "../../../util/intl"
import {
  MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_OUTLINE_KEY,
} from "../../../ducks/pages/matches-overview/constants"

const displayStyles = { display: "flex", alignItems: "center", justifyContent: "start" }
const countryFlagStyles = { marginInline: "0.2rem", fontSize: "1rem" }

export default function useMatchesOverviewMatchCountries() {
  const dispatch = useDispatch()

  const { data = [] } = useMatchesOverviewFilters([
    MATCHES_OVERVIEW_PAGE_FILTERS_OUTLINE_KEY,
    "countries",
  ])

  const filters = useMatchesOverviewFilters([
    MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
    "countries",
  ])
  const active = useMemo(() => getActiveSearchParams(filters), [filters])
  const applied = useMemo(() => filterSearchParamsApplied(filters), [filters])

  const countries = useMemo(
    () =>
      data.map((countryCode) => ({
        display: (
          <span style={displayStyles}>
            <span>{getCountryName(countryCode)}</span>
            <ReactCountryFlag countryCode={countryCode} style={countryFlagStyles} />
          </span>
        ),
        ticked: active.includes(countryCode),
        value: countryCode,
      })),
    [active, data],
  )

  const setCountries = useCallback(
    (...countriesArgs) => {
      countriesArgs.forEach(({ value, state }) => {
        dispatch(stageFilterMatchCountry({ value, state }))
      })
    },
    [dispatch],
  )

  return {
    appliedCountries: applied,
    countries,
    setCountries,
  }
}
