import { get } from "lodash"
import { useDispatch } from "react-redux"
import useAsyncSlice from "../../../hooks/use-async-slice"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {{ filterOptions: Object | undefined, setFilterOptions: ({ selectedFilters }) => void}}
 *  the currently selected filters and a setter function
 */
export function useFilterOptions() {
  const dispatch = useDispatch()

  function selector(state) {
    return get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY,
    ])
  }

  function setFilterOptions(newOptions) {
    dispatch(
      Actions.setAssortmentAnalysisFilterOptions({ selectedFilters: newOptions }),
    )
  }
  const filterOptions = useAsyncSlice({
    action: Actions.triggerGetCustomerAssortmentFilterOptions(),
    selector,
  })

  return { filterOptions, setFilterOptions }
}
