import React from "react"
import PropTypes from "prop-types"
import mixpanel from "mixpanel-browser"

import * as RUISelect from "@radix-ui/react-select"
import { isValidAnalyticsEventName } from "../../../../util/user-monitoring"

function BaseSelectRoot({ analyticsEventName, children, onValueChange, ...props }) {
  function handleChange(value) {
    if (isValidAnalyticsEventName(analyticsEventName)) {
      mixpanel.track(analyticsEventName, { value })
    }

    onValueChange(value)
  }

  return (
    <RUISelect.Root {...props} onValueChange={handleChange}>
      {children}
    </RUISelect.Root>
  )
}

BaseSelectRoot.propTypes = {
  children: PropTypes.node.isRequired,
  analyticsEventName: PropTypes.string,
  onValueChange: PropTypes.func,
}

BaseSelectRoot.defaultProps = {
  analyticsEventName: "",
  onValueChange: () => undefined,
}

export { BaseSelectRoot }
