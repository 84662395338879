/* eslint-disable no-console */

const TRACE = "trace"
const DEBUG = "debug"
const INFO = "info"
const WARN = "warn"
const ERROR = "error"
const FATAL = "fatal"

const levelMap = {
  [TRACE]: 1,
  [DEBUG]: 2,
  [INFO]: 3,
  [WARN]: 4,
  [ERROR]: 5,
  [FATAL]: 6,
}

const levelName =
  process.env.REACT_APP_LOG_LEVEL ||
  (process.env.NODE_ENV !== "production" ? TRACE : WARN)
const level = levelMap[levelName.toLowerCase()] || levelMap[ERROR]

const categoriesList = process.env.REACT_APP_LOG_CATEGORIES || ""
const categories = categoriesList
  .split(",")
  .map((category) => category.trim())
  .filter((category) => category)

console.info("LOGGING LEVEL:", levelName, level, levelMap[levelName.toLowerCase()])
console.info("LOGGING CATEGORIES:", categories)

export class Logger {
  constructor(category = "") {
    this.category = category
    this.active = categories.length === 0 || categories.includes(this.category)
  }

  trace = (...args) => {
    if (this.active && levelMap[TRACE] >= level) {
      console.info(
        ...[`[${TRACE}]`, new Date().toISOString(), `(${this.category})`, ...args],
      )
    }
  }

  debug = (...args) => {
    if (this.active && levelMap[DEBUG] >= level) {
      console.info(
        ...[`[${DEBUG}] ${new Date().toISOString()}`, `(${this.category})`, ...args],
      )
    }
  }

  info = (...args) => {
    if (this.active && levelMap[INFO] >= level) {
      console.info(
        ...[`[${INFO}] ${new Date().toISOString()}`, `(${this.category})`, ...args],
      )
    }
  }

  warn = (...args) => {
    if (this.active && levelMap[WARN] >= level) {
      console.warn(
        ...[`[${WARN}] ${new Date().toISOString()}`, `(${this.category})`, ...args],
      )
    }
  }

  error = (...args) => {
    if (this.active && levelMap[ERROR] >= level) {
      console.error(
        ...[`[${ERROR}] ${new Date().toISOString()}`, `(${this.category})`, ...args],
      )
    }
  }

  fatal = (...args) => {
    if (this.active && levelMap[FATAL] >= level) {
      console.error(
        `[${FATAL}] ${[new Date().toISOString(), `(${this.category})`, ...args]}`,
      )
    }
  }
}

export const trace = (...args) => {
  if (levelMap[TRACE] >= level) {
    console.info(...[`[${TRACE}] ${new Date().toISOString()}`, ...args])
  }
}

export const debug = (...args) => {
  if (levelMap[DEBUG] >= level) {
    console.info(...[`[${DEBUG}] ${new Date().toISOString()}`, ...args])
  }
}

export const info = (...args) => {
  if (levelMap[INFO] >= level) {
    console.info(...[`[${INFO}] ${new Date().toISOString()}`, ...args])
  }
}

export const warn = (...args) => {
  if (levelMap[WARN] >= level) {
    console.warn(...[`[${WARN}] ${new Date().toISOString()}`, ...args])
  }
}

export const error = (...args) => {
  if (levelMap[ERROR] >= level) {
    console.error(...[`[${ERROR}] ${new Date().toISOString()}`, ...args])
  }
}

export const fatal = (...args) => {
  if (levelMap[FATAL] >= level) {
    console.error(`[${FATAL}] ${[new Date().toISOString(), ...args]}`)
  }
}
