import { successType, errorType, buildEpic } from "../util/redux-observable-helpers"
import * as api from "../api/service"
import { integerToRoman } from "../util/number"

export const CATEGORY_SEPARATOR = ":"

// Action Types

const CATEGORY_LIST = "@categories/LIST"
const CATEGORY_LIST_SUCCESS = successType(CATEGORY_LIST)
const CATEGORY_LIST_ERROR = errorType(CATEGORY_LIST)

const actionTypes = {
  CATEGORY_LIST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_ERROR,
}

// Action Creators

const listCategories = () => ({
  type: CATEGORY_LIST,
})

const actionCreators = {
  listCategories,
}

// Epics

const listCategoriesEpic = buildEpic(CATEGORY_LIST, () =>
  api.listReferenceProductCategories(),
)

const epics = [listCategoriesEpic]

// helpers

const CATEGORY_LEVEL_LABELS = ["Segment", "Sub-Segment", "Category", "Sub-Category"]

export function getCategoryLevelLabel(level) {
  return CATEGORY_LEVEL_LABELS[level - 1] || `Category ${integerToRoman(level)}`
}

export function getCategorySubOptions(tree, selectedParentCategories) {
  const options = []

  selectedParentCategories.forEach((path) => {
    const keys = path.split(CATEGORY_SEPARATOR)
    let children = tree
    keys.forEach((key) => {
      children = children[key].children
    })

    const childrenKeys = Object.keys(children)

    if (childrenKeys.length >= 1) {
      options.push({
        group: path,
        children: childrenKeys.map((key) => ({
          name: key,
          count: children[key].count,
        })),
      })
    }
  })

  options.sort()

  return options
}

function isValidSubCategory(selectedParentCategories) {
  return (path) => {
    const parentPath = path
      .split(CATEGORY_SEPARATOR)
      .slice(0, -1)
      .join(CATEGORY_SEPARATOR)
    return selectedParentCategories.includes(parentPath)
  }
}

export function handleListCategories(state) {
  return {
    ...state,
    loadingCategoriesTree: true,
  }
}

export const handleListCategoriesSuccess = (state, action) => {
  const {
    payload: { tree: categoriesTree, maxDepth },
  } = action

  return {
    ...state,
    loadingCategoriesTree: false,
    categoriesTree,
    categoryLevels: maxDepth,
    categoriesTreeError: undefined,
  }
}

export function handleListCategoriesError(state, action) {
  return {
    ...state,
    loadingCategoriesTree: false,
    categoriesTree: {},
    categoryLevels: 0,
    categoriesTreeError: action.error,
  }
}

// handler helpers

export function handleUpdateSubCategories(level, state, action) {
  const selectedParentCategoriesKey = `selectedL${level - 1}Categories`
  const selectedCategoriesKey = `selectedL${level}Categories`
  const categoriesOptionsKey = `l${level}CategoriesOptions`

  const {
    [selectedParentCategoriesKey]: selectedParentCategories,
    [selectedCategoriesKey]: selectedCategories,
  } = state

  const { tree } = action

  const categoriesOptions = getCategorySubOptions(tree, selectedParentCategories)
  const validCategories = selectedCategories.filter(
    isValidSubCategory(selectedParentCategories),
  )

  return {
    ...state,
    [categoriesOptionsKey]: categoriesOptions,
    [selectedCategoriesKey]: validCategories,
  }
}

export function handleSelectCategories(level, state, action) {
  const selectedCategoriesKey = `selectedL${level}Categories`

  let filter

  if (level === 1) {
    const { tree } = action
    filter = (key) => key in tree
  } else {
    const selectedParentCategoriesKey = `selectedL${level - 1}Categories`
    const { [selectedParentCategoriesKey]: selectedParentCategories } = state
    filter = isValidSubCategory(selectedParentCategories)
  }

  const { categories } = action
  const validCategories = categories.filter(filter)

  return {
    ...state,
    [selectedCategoriesKey]: validCategories,
  }
}

// reducer

export const initalState = {
  categoriesTree: {},
  loadingCategoriesTree: true,
  categoryLevels: 0,
  categoriesTreeError: undefined,
  options: [],
  selected: [],
}

function reducer(state = initalState, { type, ...action }) {
  switch (type) {
    case CATEGORY_LIST:
      return handleListCategories(state, action)
    case CATEGORY_LIST_SUCCESS:
      return handleListCategoriesSuccess(state, action)
    case CATEGORY_LIST_ERROR:
      return handleListCategoriesError(state, action)
    default:
  }

  return state
}

// exports

export { actionTypes, actionCreators, epics }

export default reducer
