/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from "react"
import { Tooltip } from "@material-ui/core"
import Image from "../img/Image"

import "./style.sass"

function ImageZoom({ src, imageProps = {}, ...props }) {
  const [zoomSrc, setZoomSrc] = useState()
  const [enabled, setEnabled] = useState(false)
  const [backgroundPosition, setBackgroundPosition] = useState("0% 0%")

  const handleMouseMove = useCallback(
    (e) => {
      if (!enabled) return

      const { left, top, width, height } = e.target.getBoundingClientRect()
      const x = ((e.pageX - left) / width) * 100
      const y = ((e.pageY - top) / height) * 100
      setBackgroundPosition(`${x}% ${y}%`)
    },
    [enabled],
  )

  const handleClick = useCallback(() => {
    setEnabled(!enabled)
  }, [enabled])

  const hanleSrcSelected = (newSrc) => {
    setZoomSrc(newSrc)
  }

  const figureStyle = {
    backgroundPosition,
    backgroundImage: `url(${zoomSrc})`,
  }

  return (
    <div className={`dx-image-zoom ${enabled ? "enabled" : ""}`}>
      <Tooltip
        classes={{ tooltip: "tooltip" }}
        title={`Click ${enabled ? "again" : ""} to ${enabled ? "stop" : ""} super zoom`}
      >
        <figure
          style={figureStyle}
          onMouseMove={handleMouseMove}
          onClick={handleClick}
          {...props}
        >
          <Image src={src} onSrcSelected={hanleSrcSelected} {...imageProps} />
        </figure>
      </Tooltip>
    </div>
  )
}

export default ImageZoom
