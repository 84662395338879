import React from "react"
import PropTypes from "prop-types"
import "./AreaBar.scss"
import { Box, Typography } from "@mui/material"

function AreaBar({ title, children, className = "", ...props }) {
  return (
    <Box {...props} className={`area-bar ${className}`}>
      <Typography variant="h1">{title}</Typography>
      {children}
    </Box>
  )
}

AreaBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
}

AreaBar.defaultProps = {
  children: null,
  className: "",
  title: "",
}

export { AreaBar }
