export const env = process.env.NODE_ENV || "development"

/**
 * Identifies application build environment
 *
 * WARN: navigator-dev.daltix.com is `development` runtime but is a `production` build
 * consequently `isProduction` will yield `true`, and `isDevelopment` will yield false.
 */
export const isDevelopment = process.env.NODE_ENV === "development"
export const isTest = process.env.NODE_ENV === "test"
export const isProduction = process.env.NODE_ENV === "production"

/**
 * Identifies if the application is running on a local machine or a remote machine
 */
export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
)

export const isDebug = () => !window.location.hostname.startsWith("navigator.")

export function envLabel() {
  let label

  if (window.location.hostname.startsWith("localhost")) {
    label = "localhost"
  } else if (!window.location.hostname.startsWith("navigator.daltix")) {
    label = "dev"
  }

  return label
}

export const getEnv = () => envLabel() || "prod"

export const auth0 = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || "https://prod-navigator.api",
  clientId: process.env.REACT_APP_AUTH0_CLIEND_ID || "bNTVVi2de9mxqCdXNsU4bT1aB3Dwpau5",
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "daltix-prod.eu.auth0.com",
  scope: process.env.REACT_APP_AUTH0_SCOPE || "email openid",
}

export const userflow = {
  token: process.env.REACT_APP_USERFLOW_TOKEN || "ct_3gigapcjbvdwzmapgzyrxh356a",
}

export const split = {
  authorizationKey:
    process.env.REACT_APP_SPLIT_IO_AUTHORIZATION_KEY ??
    "f4odcglnivjdq3hfmeanbiju1dtblp216d9s",
}

export const apiURL = process.env.REACT_APP_API_URL
export const imagesBaseURL = process.env.REACT_APP_IMAGES_BASE_URL
export const proxyURL = `${apiURL}/html2canvas-proxy`
