import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"
import ReactPlaceholder from "react-placeholder"

import styles from "./ProductComparisonTableHeaderFooter.module.scss"

import { ProductComparisonTableHeaderContainer } from "./ProductComparisonTableHeaderContainer"

import { BadgeCount } from "../../ui"

import useMatchedProduct from "../../../hooks/use-matched-product"
import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
  STATUS_UNMATCHED,
} from "../../../util/match-status"
import { DXIcon } from "../../dx-icon/DXIcon"
import useProductComparisonMatches from "../hooks/use-product-comparison-matches"

const StatusIconLookup = {
  [STATUS_APPROVED]: (
    <DXIcon type={STATUS_APPROVED} tooltip="Approved" testID="approved-icon" />
  ),
  [STATUS_DISCARDED]: (
    <DXIcon type={STATUS_DISCARDED} tooltip="Discarded" testID="discarded-icon" />
  ),
  [STATUS_REVIEW]: (
    <DXIcon type={STATUS_REVIEW} tooltip="In Review" testID="in-review-icon" />
  ),
  [STATUS_UNMATCHED]: (
    <DXIcon type={STATUS_UNMATCHED} tooltip="Unmatched" testID="unmatched-icon" />
  ),
}

function Placeholder() {
  return (
    <ReactPlaceholder
      className="rounded"
      color="#f5f5f5"
      showLoadingAnimation
      style={{ width: "6rem", height: "4rem" }}
      type="rect"
    />
  )
}

function MatchFooter({ daltixId: _, matchId, outdated }) {
  const { data, isFetching, isReady, run } = useMatchedProduct(matchId || "")

  if (!isFetching && !isReady) {
    run()
  }

  if (!isReady) {
    return <Placeholder />
  }
  return (
    <div className={clsx(styles.footer, outdated && styles.outdated)}>
      <div className={styles.heading}>
        <h2> </h2>
      </div>
      <div className={styles.info}>
        {StatusIconLookup[data?.status || STATUS_UNMATCHED]}
        {outdated && <DXIcon type="inactive" />}
      </div>
    </div>
  )
}

MatchFooter.propTypes = {
  daltixId: PropTypes.string.isRequired,
  matchId: PropTypes.string.isRequired,
  outdated: PropTypes.bool.isRequired,
}

function ReferenceProductFooter({ productId: _ }) {
  const { data, isReady } = useProductComparisonMatches()

  if (!isReady) {
    return <Placeholder />
  }

  const approvedMatches = data.filter((m) => m.status === STATUS_APPROVED)

  return (
    <footer className={styles.footer}>
      <div className={styles.heading}>
        <h2>Match Status</h2>
      </div>
      <div className={styles.info}>
        <BadgeCount absolute={false} color="primary" count={approvedMatches.length} />{" "}
        <span>Approved (active)</span>
      </div>
    </footer>
  )
}

ReferenceProductFooter.propTypes = {
  productId: PropTypes.string.isRequired,
}

function ProductComparisonTableHeaderFooter({
  daltixId,
  isMatch,
  matchId,
  productId,
  outdated,
}) {
  return (
    <ProductComparisonTableHeaderContainer testID="product-comparison-footer">
      {isMatch ? (
        <MatchFooter daltixId={daltixId} matchId={matchId} outdated={outdated} />
      ) : (
        <ReferenceProductFooter productId={productId} />
      )}
    </ProductComparisonTableHeaderContainer>
  )
}

ProductComparisonTableHeaderFooter.propTypes = {
  daltixId: PropTypes.string,
  isMatch: PropTypes.bool.isRequired,
  matchId: PropTypes.string,
  productId: PropTypes.string.isRequired,
  outdated: PropTypes.bool,
}

ProductComparisonTableHeaderFooter.defaultProps = {
  daltixId: null,
  matchId: null,
  outdated: false,
}

export { ProductComparisonTableHeaderFooter }
