import { Chip, Tooltip } from "@mui/material"
import { bool, func, number, string } from "prop-types"
import React from "react"

/**
 * CounterChip behaves similarly to badges, but without relying on absolute positioning.
 * Instead of customizing the CSS of the Badge component to disable "position: absolute",
 * we've created CounterChip to provide similar functionality while minimizing layout risks
 * as new versions of dependencies are introduced.
 *
 * @param {import("@mui/material").ChipProps & { tooltip: string }} props
 */
function CounterChip(props) {
  const { label, tooltip, isLoading } = props

  if (isLoading) {
    return <Chip color="success" size="small" {...props} label="..." />
  }

  const moreThan99 = label > 99
  const effectiveTooltip = tooltip || (moreThan99 ? label : "")

  const count = moreThan99 ? "99+" : label
  return (
    <Tooltip title={effectiveTooltip} placement="bottom" arrow>
      <Chip
        color="success"
        size="small"
        {...props}
        sx={{ display: label === 0 ? "none" : undefined }}
        label={count}
      />
    </Tooltip>
  )
}

CounterChip.propTypes = {
  label: number,
  tooltip: string,
  isLoading: bool,
  render: func,
}

CounterChip.defaultProps = {
  isLoading: false,
  tooltip: "",
  label: 0,
  render: undefined,
}

export { CounterChip }
