import React from "react"
import PropTypes from "prop-types"
import { NULL } from "../../util/constants"

function CategoryItem({ name }) {
  const text = name === NULL ? "Unknown" : name

  return (
    <span className="category-item">
      <span className="name">{text}</span>
    </span>
  )
}

CategoryItem.propTypes = {
  name: PropTypes.string.isRequired,
}

export default CategoryItem
