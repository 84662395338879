import { ORIGIN_EXISTING, ORIGIN_SUGGESTION } from "../../../util/match-origin"
import { SOURCE_MANUAL } from "../../../util/match-source"

/* eslint-disable import/prefer-default-export, camelcase */

export const handleMatch = (
  state,
  { source, referenceProductId, daltixProductId, status, factor, labels, comment },
) => {
  if (source === ORIGIN_EXISTING || source === ORIGIN_SUGGESTION) {
    const { referenceProducts, pendingMatches } = state
    const matches = referenceProducts[referenceProductId][source] || []
    const previousStatus = (
      matches.find((match) => {
        const { daltix_id: daltixId } = match
        return daltixId === daltixProductId
      }) || {}
    ).status

    return {
      ...state,
      referenceProducts: {
        ...referenceProducts,
        [referenceProductId]: {
          ...referenceProducts[referenceProductId],
          [source]: referenceProducts[referenceProductId][source].map(
            ({
              daltix_id: daltixId,
              status: currentStatus,
              mult_factor: currentFactor,
              labels: currentLabels,
              comment: currentComment,
              match_source: matchSource = SOURCE_MANUAL,
              ...match
            }) => ({
              ...match,
              match_source: matchSource,
              daltix_id: daltixId,
              dirty: daltixId === daltixProductId,
              status:
                daltixId === daltixProductId ? status || currentStatus : currentStatus,
              mult_factor:
                daltixId === daltixProductId ? factor || currentFactor : currentFactor,
              labels: daltixId === daltixProductId ? labels : currentLabels,
              comment: daltixId === daltixProductId ? comment : currentComment,
            }),
          ),
        },
      },
      pendingMatches: {
        ...pendingMatches,
        [`${referenceProductId}:${daltixProductId}`]: previousStatus,
      },
      loading: daltixProductId,
    }
  }

  return state
}

export const handleMatchSuccess = (
  state,
  { parent: { source, referenceProductId, daltixProductId } },
) => {
  if (source === ORIGIN_EXISTING || source === ORIGIN_SUGGESTION) {
    const matchId = `${referenceProductId}:${daltixProductId}`

    const {
      pendingMatches: { [matchId]: match, ...pendingMatches },
    } = state

    return {
      ...state,
      pendingMatches,
      matchedSuccess: { [referenceProductId]: daltixProductId },
      loading: undefined,
    }
  }

  return {
    ...state,
    matchedSuccess: { [referenceProductId]: daltixProductId, loading: undefined },
  }
}

export const handleMatchError = (
  state,
  { parent: { source, referenceProductId, daltixProductId } },
) => {
  if (source === ORIGIN_EXISTING || source === ORIGIN_SUGGESTION) {
    const matchId = `${referenceProductId}:${daltixProductId}`

    const {
      referenceProducts,
      pendingMatches: { [matchId]: previousStatus, ...pendingMatches },
    } = state

    return {
      ...state,
      referenceProducts: {
        ...referenceProducts,
        [referenceProductId]: {
          ...referenceProducts[referenceProductId],
          [source]: referenceProducts[referenceProductId][source].map(
            ({ daltix_id: daltixId, status, ...match }) => ({
              ...match,
              daltix_id: daltixId,
              dirty: daltixId === daltixProductId,
              status: previousStatus,
            }),
          ),
        },
      },
      pendingMatches,
      loading: undefined,
    }
  }

  return state
}
