import PropTypes from "prop-types"
import React from "react"

import mixpanel from "mixpanel-browser"

import * as RUIToggleGroup from "@radix-ui/react-toggle-group"

import { Tooltip } from "@material-ui/core"

import styles from "./ToggleGroup.module.scss"

import { Icon } from "../Icon"
import { isValidAnalyticsEventName } from "../../../util/user-monitoring"

function ToggleGroupRoot(props) {
  return (
    <RUIToggleGroup.Root
      orientation="horizontal"
      type="multiple"
      {...props}
      className={styles.group}
    />
  )
}

function ToggleGroupItem({ analyticsEventName, tooltip, onClick, ...props }) {
  function handleClick(...args) {
    if (isValidAnalyticsEventName(analyticsEventName)) {
      mixpanel.track(analyticsEventName)
    }

    onClick(...args)
  }

  const Item = (
    <RUIToggleGroup.Item {...props} onClick={handleClick} className={styles.item} />
  )

  if (tooltip) {
    return (
      <Tooltip classes={{ tooltip: "tooltip" }} title={tooltip}>
        {Item}
      </Tooltip>
    )
  }

  return Item
}

ToggleGroupItem.propTypes = {
  analyticsEventName: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
}

ToggleGroupItem.defaultProps = {
  analyticsEventName: "",
  tooltip: "",
  onClick: () => undefined,
}

export const ToggleGroup = {
  Root: ToggleGroupRoot,
  Item: ToggleGroupItem,
  Icon,
}
