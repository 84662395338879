import { Tooltip, Typography } from "@mui/material"
import { instanceOf } from "prop-types"
import React, { memo } from "react"
import { contentString } from "../../../../util/strings"
import { getContentsV1AsArray } from "../../../../util/volumes"

const Content = memo(({ content }) => {
  if (!content) {
    return null
  }
  const textContent = getContentsV1AsArray(content).map(contentString).join(" / ")
  return (
    <Tooltip title={textContent.length > 9 ? textContent : ""} arrow placement="bottom">
      <Typography display="block" variant="body2" noWrap textOverflow="ellipsis">
        {textContent}
      </Typography>
    </Tooltip>
  )
})

Content.propTypes = {
  content: instanceOf(Object).isRequired,
}

export { Content }
