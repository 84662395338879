import React, { useEffect, useState } from "react"
import { Logger } from "../../util/log"
import { normalizeUnitValue } from "../../util/volumes"
import "./FactorInput.sass"
import FactorInputPresenter from "./FactorInputPresenter"

const log = new Logger("ui:FactorInput")

function FactorInputContainer({
  productUnit,
  productNormalizedUnit,
  matchUnit,
  matchPrice,
  defaultProductMultiplier,
  defaultProductContent,
  defaultMatchMultiplier,
  defaultMatchValue,
  currentFactor = 1,
  isNullFactor = false,
  open: openProp,
  // events
  onChange,
  onClose = () => {},
  // rest
  ...props
}) {
  const unit = productNormalizedUnit || matchUnit
  const calcUnit = productUnit || matchUnit
  let normalizedMatchPrice

  const normalized = normalizeUnitValue(matchUnit, defaultMatchValue || 1, unit)
  if (normalized) {
    const normalizedMatchContentValue = normalized

    if (matchPrice) {
      normalizedMatchPrice = matchPrice / normalizedMatchContentValue
    }
  }
  let normalizedMatchContentValueCalc
  const normalizedForCalc = normalizeUnitValue(
    matchUnit,
    defaultMatchValue || 1,
    calcUnit,
  )
  if (normalizedForCalc) {
    ;[normalizedMatchContentValueCalc] = normalizedForCalc
  }

  const [open, setOpen] = useState(false)
  const [productMultiplier, setProductMultiplier] = useState(
    defaultProductMultiplier || 1,
  )
  const [productContent, setProductContent] = useState(defaultProductContent)
  const [matchMultiplier, setMatchMultiplier] = useState(defaultMatchMultiplier || 1)
  const [matchContentValue, setMatchContentValue] = useState(
    normalizedMatchContentValueCalc,
  )
  const [factor, setFactor] = useState(currentFactor)

  useEffect(() => {
    setFactor(currentFactor)
  }, [currentFactor])

  const handleClose = (event) => {
    setProductMultiplier(defaultProductMultiplier || 1)
    setProductContent(defaultProductContent)
    setMatchMultiplier(defaultMatchMultiplier || 1)
    setMatchContentValue(normalizedMatchContentValueCalc)
    setOpen(false)
    onClose(event)
    setFactor(currentFactor)
  }

  const handleFactorInputChange = (value) => {
    const parsed = parseFloat(value)
    setFactor(!Number.isNaN(parsed) ? parsed : 1)
  }

  const handleOpen = () => setOpen(true)

  function handleApply(event) {
    if (onChange) {
      onChange(factor)
    }
    onClose(event)
    setOpen(false)
  }

  function handleFactorInputKeyPress(event) {
    if (event.key === "Enter") {
      handleApply(event)
    }
  }

  let calculatedFactor

  if (productContent && productMultiplier && matchContentValue && matchMultiplier) {
    calculatedFactor =
      (productContent * productMultiplier) / (matchContentValue * matchMultiplier)
  }

  const [calcExpanded, setCalcExpanded] = useState(
    calculatedFactor !== undefined && calculatedFactor !== currentFactor,
  )

  let adjustedMatchPrice

  if (matchPrice) {
    adjustedMatchPrice = matchPrice * factor
  }

  log.debug(
    props.matchName,
    "normalizedMatchPrice",
    normalizedMatchPrice,
    "defaultProductContent",
    defaultProductContent,
    "productContent",
    productContent,
    "productMultiplier",
    productMultiplier,
    "productUnit",
    productUnit,
    "productNormalizedUnit",
    productNormalizedUnit,
    "currentFactor",
    currentFactor,
    "isNullFactor",
    isNullFactor,
    "normalizedMatchContentValueCalc",
    normalizedMatchContentValueCalc,
    "matchContentValue",
    matchContentValue,
    "matchMultiplier",
    matchMultiplier,
    "calculatedFactor",
    calculatedFactor,
    "matchUnit",
    matchUnit,
  )

  return (
    <FactorInputPresenter
      {...props}
      adjustedMatchPrice={adjustedMatchPrice}
      calcExpanded={calcExpanded}
      calcUnit={calcUnit}
      calculatedFactor={calculatedFactor}
      isNullFactor={isNullFactor}
      factor={factor}
      matchContentValue={matchContentValue}
      matchMultiplier={matchMultiplier}
      matchPrice={matchPrice}
      open={openProp === undefined ? open : openProp}
      productContent={productContent}
      productMultiplier={productMultiplier}
      // events
      onCalcHeaderClick={() => setCalcExpanded(!calcExpanded)}
      onClose={handleClose}
      onFactorInputChange={handleFactorInputChange}
      onFactorInputKeyPress={handleFactorInputKeyPress}
      onMatchContentValueChange={setMatchContentValue}
      onMatchMultiplierChange={setMatchMultiplier}
      onProductContentChange={setProductContent}
      onProductMultiplierChange={setProductMultiplier}
      onOpen={handleOpen}
      onUseCalculatedFactor={() => setFactor(calculatedFactor)}
      onApply={handleApply}
    />
  )
}

export default FactorInputContainer
