import { errorType, successType } from "../../../util/redux-observable-helpers"

export const MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID =
  "@matched-products-repository/GET_ONE_BY_ID"

export const MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_SUCCESS = successType(
  MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
)
export const MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_ERROR = errorType(
  MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
)

export const MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS =
  "@matched-products-repository/GET_PRODUCTS_BY_IDS"

export const MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS_SUCCESS = successType(
  MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS,
)
export const MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS_ERROR = errorType(
  MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS,
)
