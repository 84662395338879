export const handleGetShopsFromMatchesSuccess = (state, action) => {
  const {
    payload: { shops },
  } = action

  return {
    ...state,
    availableMatchShops: shops,
    availableMatchShopsError: undefined,
  }
}

export const handleGetShopsFromMatchesError = (state, action) => {
  const { error } = action

  return {
    ...state,
    availableMatchShops: [],
    availableMatchShopsError: error,
  }
}
