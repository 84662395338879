import PropTypes from "prop-types"

const ComparisonData = PropTypes.shape({
  ref: {
    data: PropTypes.object,
    isReady: PropTypes.bool,
  },
  match: {
    data: {
      match: PropTypes.object,
      matched: PropTypes.object,
    },
    isReady: PropTypes.bool,
  },
})

export { ComparisonData }
