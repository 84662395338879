import PropTypes from "prop-types"
import React from "react"

import * as RUISelect from "@radix-ui/react-select"

import clsx from "clsx"

import styles from "./BaseSelectLabel.module.scss"

function BaseSelectLabel({ children, className, ...props }) {
  return (
    <RUISelect.Label {...props} className={clsx(styles.label, className)}>
      {children}
    </RUISelect.Label>
  )
}

BaseSelectLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

BaseSelectLabel.defaultProps = {
  className: "",
}

export { BaseSelectLabel }
