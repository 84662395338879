import { buildLexicographicallySortedMatchId } from "../../../util/match"
import { STATUS_APPROVED, STATUS_REVIEW } from "../../../util/match-status"

export const handleUpdateBasicMatchInfo = (state, action) => {
  const { matches, pendingChanges } = state
  const { referenceProductId, daltixProductId, factor, labels, comment } = action

  const key = buildLexicographicallySortedMatchId(referenceProductId, daltixProductId)
  const current = matches[key]

  return {
    ...state,
    matches: {
      ...matches,
      [key]: {
        ...current,
        daltix_id: daltixProductId,
        ref_product_id: referenceProductId,
        mult_factor: factor,
        labels,
        comment,
        pending: true,
      },
    },
    pendingChanges: {
      ...pendingChanges,
      [key]: current,
    },
  }
}

export const handleUpdateBasicMatchInfoSuccess = (state, action) => {
  const {
    parent: { referenceProductId, daltixProductId },
    payload: {
      result: {
        matchIds: [matchId],
      },
    },
  } = action

  const key = buildLexicographicallySortedMatchId(referenceProductId, daltixProductId)

  const {
    matches,
    pendingChanges: { [key]: _ = {}, ...pendingChanges },
  } = state

  const { pending, ...current } = matches[key]

  return {
    ...state,
    matches: {
      ...matches,
      [key]: {
        ...current,
        id: matchId,
      },
    },
    pendingChanges,
  }
}

export const handleUpdateMatchStatus = (state, action) => {
  const { matches, pendingChanges } = state
  const { referenceProductId, daltixProductId, status } = action

  const key = buildLexicographicallySortedMatchId(referenceProductId, daltixProductId)
  const current = matches[key]

  return {
    ...state,
    matches: {
      ...matches,
      [key]: {
        ...current,
        daltix_id: daltixProductId,
        ref_product_id: referenceProductId,
        status,
        pending: true,
      },
    },
    pendingChanges: {
      ...pendingChanges,
      [key]: current,
    },
  }
}

export const handleUpdateMatchStatusSuccess = (state, action) => {
  const {
    parent: { referenceProductId, daltixProductId, status },
    payload: {
      result: {
        matchIds: [matchId],
      },
    },
  } = action

  const key = buildLexicographicallySortedMatchId(referenceProductId, daltixProductId)

  const {
    matches,
    pendingChanges: { [key]: match = {}, ...pendingChanges },
    statusSummary,
  } = state

  let approvedDiff = 0
  let inReviewDiff = 0
  let newStatusSummary = statusSummary

  if (status && status !== match.status && newStatusSummary) {
    switch (status) {
      case STATUS_APPROVED:
        approvedDiff = 1
        break
      case STATUS_REVIEW:
        inReviewDiff = 1
        break
      default:
        break
    }

    switch (match.status) {
      case STATUS_APPROVED:
        approvedDiff = -1
        break
      case STATUS_REVIEW:
        inReviewDiff = -1
        break
      default:
        break
    }

    newStatusSummary = {
      ...newStatusSummary,
      [STATUS_APPROVED]: newStatusSummary.approved + approvedDiff,
      [STATUS_REVIEW]: newStatusSummary.in_review + inReviewDiff,
    }
  }

  const { pending, ...current } = matches[key]

  return {
    ...state,
    matches: {
      ...matches,
      [key]: {
        ...current,
        id: matchId,
      },
    },
    statusSummary: newStatusSummary,
    pendingChanges,
  }
}

export const handleUpdateMatchError = (state, action) => {
  const {
    parent: { referenceProductId, daltixProductId },
  } = action

  const key = buildLexicographicallySortedMatchId(referenceProductId, daltixProductId)

  const {
    matches,
    pendingChanges: { [key]: previousState, ...pendingChanges },
  } = state

  return {
    ...state,
    matches: {
      ...matches,
      [key]: previousState,
    },
    pendingChanges,
  }
}
