import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { IconAdjustmentsHorizontal } from "@tabler/icons-react"
import { BadgeCount, Button } from "../ui"

import styles from "./MatchesOverviewControlFiltersButton.module.scss"

const MatchesOverviewFiltersButton = forwardRef(
  ({ activeFilterCount, children, ...buttonProps }, forwardedRef) => (
    <div className={styles["button-container"]}>
      <Button {...buttonProps} ref={forwardedRef}>
        <IconAdjustmentsHorizontal size={20} />
        <span>{children}</span>
      </Button>
      {activeFilterCount > 0 ? <BadgeCount count={activeFilterCount} /> : null}
    </div>
  ),
)

MatchesOverviewFiltersButton.propTypes = {
  activeFilterCount: PropTypes.number,
  children: PropTypes.node.isRequired,
}

MatchesOverviewFiltersButton.defaultProps = {
  activeFilterCount: 0,
}

export default MatchesOverviewFiltersButton
