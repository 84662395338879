import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"

import * as RUIDialog from "@radix-ui/react-dialog"

import styles from "./BaseDialogTitle.module.scss"

function BaseDialogTitle({ children, srOnly, ...props }) {
  return (
    <RUIDialog.Title {...props} className={clsx(styles.title, { "sr-only": srOnly })}>
      <span>{children}</span>
    </RUIDialog.Title>
  )
}

BaseDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  srOnly: PropTypes.bool,
}

BaseDialogTitle.defaultProps = {
  srOnly: false,
}

export { BaseDialogTitle }
