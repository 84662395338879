import Loupe from "@material-ui/icons/Loupe"
import React from "react"

import { AccessibleIcon } from "./AccessibleIcon"

function CompareIcon(props) {
  return (
    <AccessibleIcon border="solid" color="success" shape="circle" size="lg" {...props}>
      <Loupe fontSize="inherit" />
    </AccessibleIcon>
  )
}

export { CompareIcon }
