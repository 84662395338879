import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material"
import { func, oneOf, string } from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import mixpanel from "mixpanel-browser"
import { useMatchActions } from "../../../../hooks/use-match-actions"
import { ORIGIN_SEARCH } from "../../../../util/match-origin"
import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
} from "../../../../util/match-status"
import { DXIcon } from "../../../dx-icon/DXIcon"

function MatchActions({
  matchStatus,
  referenceProductId,
  daltixProductId,
  shop,
  country,
  matchId,
  onCompare,
}) {
  const { approve, discard, restore, unapprove, match } = useMatchActions()
  const updatedStatus = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.status,
  )
  const updateProduct = useSelector(({ refProduct }) => refProduct.loading)

  const isLoading = updateProduct === daltixProductId

  const status = updatedStatus || matchStatus

  let Buttons

  switch (status) {
    case STATUS_APPROVED:
      Buttons = (
        <>
          <div />
          <Tooltip title="Disapprove match" arrow placement="bottom">
            <IconButton
              size="small"
              color="primary"
              data-testid="disapprove-match"
              onClick={() => {
                unapprove({
                  source: ORIGIN_SEARCH,
                  referenceProductId,
                  daltixProductId,
                })
                mixpanel.track("Product Search - disapprove match", {
                  status: STATUS_APPROVED,
                  referenceProductId,
                  daltixProductId,
                })
              }}
            >
              <DXIcon outlined type={STATUS_DISCARDED} />
            </IconButton>
          </Tooltip>
        </>
      )
      break
    case STATUS_DISCARDED:
      Buttons = (
        <>
          <Tooltip title="Restore match" arrow placement="bottom">
            <IconButton
              size="small"
              color="primary"
              data-testid="restore-match"
              onClick={() => {
                restore({ source: ORIGIN_SEARCH, referenceProductId, daltixProductId })
                mixpanel.track("Product Search - restore match", {
                  status: STATUS_DISCARDED,
                  referenceProductId,
                  daltixProductId,
                })
              }}
            >
              <DXIcon outlined type={STATUS_APPROVED} />
            </IconButton>
          </Tooltip>
          <div />
        </>
      )
      break
    case STATUS_REVIEW:
      Buttons = (
        <>
          <Tooltip title="Approve match" arrow placement="bottom">
            <IconButton
              size="small"
              color="primary"
              data-testid="approve-match"
              onClick={() => {
                approve({ source: ORIGIN_SEARCH, referenceProductId, daltixProductId })
                mixpanel.track("Product Search - approve match", {
                  status: STATUS_REVIEW,
                  referenceProductId,
                  daltixProductId,
                })
              }}
            >
              <DXIcon outlined type={STATUS_APPROVED} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Discard match" arrow placement="bottom">
            <IconButton
              size="small"
              color="primary"
              data-testid="discard-match"
              onClick={() => {
                discard({ source: ORIGIN_SEARCH, referenceProductId, daltixProductId })
                mixpanel.track("Product Search - discard match", {
                  status: STATUS_REVIEW,
                  referenceProductId,
                  daltixProductId,
                })
              }}
            >
              <DXIcon outlined type={STATUS_DISCARDED} />
            </IconButton>
          </Tooltip>
        </>
      )
      break
    case undefined:
      Buttons = (
        <>
          <Tooltip title="Match" arrow placement="bottom">
            <IconButton
              color="primary"
              size="small"
              data-testid="match"
              onClick={() => {
                match({
                  source: ORIGIN_SEARCH,
                  referenceProductId,
                  daltixProductId,
                  shop,
                  country,
                })
                mixpanel.track("Product Search - create match", {
                  status: "undefined",
                  referenceProductId,
                  daltixProductId,
                })
              }}
            >
              <DXIcon outlined type={STATUS_APPROVED} />
            </IconButton>
          </Tooltip>
          <div />
        </>
      )
      break
    default:
      Buttons = null
  }

  return (
    <Box
      data-testid="match-actions"
      data-status={status}
      display="grid"
      gridTemplateColumns="repeat(3, 2.125rem)"
      gap={1}
    >
      {isLoading && (
        <Box display="flex" alignItems="center">
          <CircularProgress size={24} />
        </Box>
      )}
      {!isLoading && Buttons}
      <Tooltip title="Compare" arrow placement="bottom">
        <div>
          <IconButton onClick={onCompare} size="small" color="primary">
            <DXIcon type="compare" outlined />
          </IconButton>
        </div>
      </Tooltip>
    </Box>
  )
}

MatchActions.propTypes = {
  matchStatus: oneOf([STATUS_APPROVED, STATUS_DISCARDED, STATUS_REVIEW, undefined]),
  referenceProductId: string.isRequired,
  daltixProductId: string.isRequired,
  shop: string.isRequired,
  country: string.isRequired,
  matchId: string,
  onCompare: func.isRequired,
}

MatchActions.defaultProps = {
  matchStatus: undefined,
  matchId: "",
}

export { MatchActions }
