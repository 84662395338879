/* eslint-disable import/prefer-default-export */
export const handleUseFallbackImage = (state, { productId }) => {
  const { referenceProducts } = state

  return {
    ...state,
    referenceProducts: {
      ...referenceProducts,
      [productId]: {
        ...referenceProducts[productId],
        picture: undefined,
      },
    },
  }
}
