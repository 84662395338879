import { get } from "lodash"
import { useMemo } from "react"
import { useSelector } from "react-redux"

export default function useCustomerMatchLabels() {
  const options = useSelector((state) =>
    get(state, ["user", "user", "customer", "preferences", "matchLabels"], []).filter(
      (label) => label,
    ),
  )

  const formatted = useMemo(
    () =>
      options.map(({ alias, slug }) => ({
        aliasTitleCase: alias.charAt(0).toUpperCase() + alias.slice(1),
        alias,
        slug,
      })),
    [options],
  )

  return formatted
}
