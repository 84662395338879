import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { SplitFactoryProvider } from "@splitsoftware/splitio-react"
import { split } from "../../util/env"
import { useAuth0 } from "../../hooks/use-authentication"

import { LoaderSpinner } from "../../components/ui/Loader/LoaderSpinner"
import { ANONYMOUS_ID, TRAFFIC_TYPE } from "./constants"
import { useUserTreatments } from "./useUserTreatments"
import { useAccountTreatments } from "./useAccountTreatments"

/**
 * SplitIO configuration and documentation is not very straight forward. Below are
 * some hyperlinks we used to understand how to configure Split.
 *
 * @see https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#configuration *
 * @see https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#instantiate-multiple-sdk-clients
 * @see https://github.com/splitio/react-client/issues/10
 * @see https://github.com/splitio/react-client/issues/89
 */

function AccountSplitClient({ children }) {
  const { isReady } = useAccountTreatments()

  return isReady ? children : <LoaderSpinner />
}

AccountSplitClient.propTypes = {
  children: PropTypes.node.isRequired,
}

function UserSplitClient({ children }) {
  const { isReady } = useUserTreatments()

  return isReady ? children : <LoaderSpinner />
}

UserSplitClient.propTypes = {
  children: PropTypes.node.isRequired,
}

function FeatureFlagProvider({ children }) {
  const auth0 = useAuth0()

  const userId = auth0.user?.sub || ANONYMOUS_ID

  const sdkConfig = useMemo(
    () => ({
      core: {
        authorizationKey: split.authorizationKey,
        key: userId,
        trafficType: TRAFFIC_TYPE.user,
      },
    }),
    [userId],
  )

  if (!userId) {
    return <LoaderSpinner />
  }

  console.info("Initializing Split")

  return (
    <SplitFactoryProvider config={sdkConfig}>
      <UserSplitClient>
        <AccountSplitClient>{children}</AccountSplitClient>
      </UserSplitClient>
    </SplitFactoryProvider>
  )
}

FeatureFlagProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { FeatureFlagProvider }
