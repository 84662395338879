import React from "react"
import { useDispatch } from "react-redux"
import { Box, useTheme } from "@mui/material"
import * as Action from "../../ducks/pages/matches-overview/action-creators"
import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_FLAGGED,
  STATUS_REVIEW,
} from "../../util/match-status"
import { buildAnalyticsEventName } from "../../util/user-monitoring"
import { CheckIcon, CrossIcon, EllipsisIcon, ToggleGroup } from "../ui"
import useMatchesOverviewMatchStatusFilter from "./hooks/use-matches-overview-match-status-filter"
import { DXIcon } from "../dx-icon/DXIcon"
import { useHasMatchOverviewFlags } from "../../integrations/split"

const feature = "Matches Overview"
const userAction = "Clicked"

const approvedEventName = buildAnalyticsEventName({
  feature,
  target: "Filter Approved Matches",
  userAction,
})

const inReviewEventName = buildAnalyticsEventName({
  feature,
  target: "Filter In Review Matches",
  userAction,
})

const discardedEventName = buildAnalyticsEventName({
  feature,
  target: "Filter Discarded Matches",
  userAction,
})

const flaggedEventName = buildAnalyticsEventName({
  feature,
  target: "Filter Flagged",
  userAction,
})

export default function MatchesOverviewMatchStatusToggleGroup() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const matchStatuses = useMatchesOverviewMatchStatusFilter()
  const hasMatchOverviewFlags = useHasMatchOverviewFlags()

  function handleChange(value) {
    dispatch(Action.setFilterMatchStatuses({ matchStatuses: value }))
  }

  return (
    <ToggleGroup.Root
      aria-label="Match status filters"
      value={matchStatuses}
      onValueChange={handleChange}
    >
      <ToggleGroup.Item
        analyticsEventName={approvedEventName}
        aria-label="Show/hide 'Approved' matches"
        tooltip="Show/hide 'Approved' matches"
        value={STATUS_APPROVED}
      >
        <CheckIcon label="approved" />
      </ToggleGroup.Item>
      <ToggleGroup.Item
        analyticsEventName={inReviewEventName}
        aria-label="Show/hide 'In Review' matches"
        tooltip="Show/hide 'In Review' matches"
        value={STATUS_REVIEW}
      >
        <EllipsisIcon label="in review" />
      </ToggleGroup.Item>
      <ToggleGroup.Item
        analyticsEventName={discardedEventName}
        aria-label="Show/hide 'Discarded' matches"
        tooltip="Show/hide 'Discarded' matches"
        value={STATUS_DISCARDED}
      >
        <CrossIcon label="discarded" />
      </ToggleGroup.Item>
      {hasMatchOverviewFlags && (
        <ToggleGroup.Item
          analyticsEventName={flaggedEventName}
          aria-label="Show/hide 'Flagged' matches"
          tooltip="Show/hide 'Flagged' matches"
          value={STATUS_FLAGGED}
        >
          <Box color={theme.palette.error.main}>
            <DXIcon type="flag" filled size="23px" />
          </Box>
        </ToggleGroup.Item>
      )}
    </ToggleGroup.Root>
  )
}
