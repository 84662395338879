import { normalize } from "normalizr"
import { productListSchema } from "../../../schema/product"
import { Logger } from "../../../util/log"

const log = new Logger("ducks:ref-product:list-reference-products")

export const handleListReferenceProducts = (state, { page, listName }) => {
  const { loadingProductPages } = state

  return {
    ...state,
    [listName]: [],
    loadingProductPages: [...new Set([...loadingProductPages, page])],
  }
}

export const handleListReferenceProductsSuccess = (
  state,
  {
    parent: { page: requestedPage, listName },
    payload: {
      products,
      meta: { total },
    },
  },
) => {
  const { loadingProductPages, productCount } = state

  const normalized = normalize(products, productListSchema)
  const list = normalized.result || []

  return {
    ...state,
    loadingProductPages: loadingProductPages.filter((page) => page !== requestedPage),
    [listName]: list,
    productCount: listName === "list" ? total : productCount,
    listError: undefined,
  }
}

export const handleListReferenceProductsError = (
  state,
  { parent: { page: requestedPage, listName }, error },
) => {
  const { loadingProductPages } = state

  if (listName === "list") {
    return {
      ...state,
      loadingProductPages: loadingProductPages.filter((page) => page !== requestedPage),
      list: [],
      productCount: undefined,
      listError: error,
    }
  }

  return {
    ...state,
    loadingProductPages: loadingProductPages.filter((page) => page !== requestedPage),
    [listName]: [],
  }
}

export const handleGetByIdsSuccess = (state, { payload }) => {
  const { referenceProducts } = state

  const mergedReferenceproducts = payload.reduce(
    (prods, product) => ({
      ...prods,
      [product.id]: {
        ...referenceProducts[product.id],
        ...product,
      },
    }),
    referenceProducts,
  )

  return {
    ...state,
    referenceProducts: mergedReferenceproducts,
  }
}

export const handleGetByIdsError = (state, { error }) => {
  log.error("Error loading products:", error.message)

  return state
}
