import { get } from "lodash"
import useAsyncSlice from "./use-async-slice"
import { getOneMatchedProductById } from "../ducks/repositories/matched-products/action-creators"

export default function useMatchedProduct(id, invalidateCache = false) {
  const action = getOneMatchedProductById({ id, invalidateCache })

  function selector(state) {
    return get(state, ["repositories", "MatchedProductsRepository", "documents", id])
  }

  return useAsyncSlice({
    action,
    selector,
  })
}
