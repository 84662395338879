import React from "react"
import PropTypes from "prop-types"
import Checkbox from "@material-ui/core/Checkbox"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import styles from "./BaseSlideoverFilterItem.module.scss"

function BaseSlideoverFilterItem({ text, checked, onChange, ...props }) {
  const [isChecked, setIsChecked] = React.useState(checked)
  return (
    <ListItem
      {...props}
      button
      className={styles["no-gutters"]}
      dense
      onClick={() => {
        setIsChecked(!isChecked)
        onChange(!isChecked)
      }}
    >
      <Checkbox
        className={styles["checkbox-gutter"]}
        classes={{
          checked: styles.checked,
        }}
        disableRipple
        checked={isChecked}
      />
      <ListItemText className={styles["text-gutter"]} primary={text} />
    </ListItem>
  )
}

BaseSlideoverFilterItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string,
}

BaseSlideoverFilterItem.defaultProps = {
  checked: false,
  onChange: (_checked) => undefined,
  text: "",
}

export { BaseSlideoverFilterItem }
