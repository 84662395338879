/* eslint-disable import/prefer-default-export */

export const handleSortBy = (state, action) => {
  const { field = "name" } = action

  return {
    ...state,
    sortBy: field,
  }
}
