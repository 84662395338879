/**
 * Builds an analytics event name based on the provided parameters.
 *
 * @param {Object} options - The options object containing details for the analytics event.
 * @param {string} options.feature - The feature related to the analytics event (e.g., "Matches Overview").
 * @param {string} options.target - The name of the HTML element on the page that was interacted with
 *  (e.g., "Tab", "Dropdown", "Filter").
 * @param {string} options.userAction - The user action that triggered the analytics event
 *   (e.g., "Clicked", "Hover").
 * @returns {string} - The formatted analytics event name.
 * @throws {TypeError} - Throws an error if any of the parameters is an empty string.
 *
 * @example
 * const eventName = buildAnalyticsEventName({
 *   feature: "Matches Overview",
 *   target: "Tab",
 *   userAction: "Clicked"
 * });
 *
 * >>> Result: "MT - Matches Overview Tab Clicked"
 */
export function buildAnalyticsEventName({ feature, target, userAction }) {
  const args = [feature, target, userAction]

  if (args.some((arg) => !arg.trim())) {
    throw new Error("Parameters cannot be empty strings")
  }

  return `MT - ${feature} ${target} ${userAction}`
}
