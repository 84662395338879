import { titleCase } from "./strings"

export const SOURCE_DALTIX = "daltix"
export const SOURCE_MANUAL = "manual"

const sourcesMap = {
  [SOURCE_MANUAL]: {
    code: SOURCE_MANUAL,
    label: "Manual",
    badgeLabel: "Manual",
    badgeCode: "MAN",
    title: "This product was matched manually",
  },
  [SOURCE_DALTIX]: {
    code: SOURCE_DALTIX,
    label: "Daltix",
    badgeLabel: "Daltix",
    badgeCode: "DX",
    title: "This product was matched by Daltix",
  },
}

export const sourceCode2data = (source) =>
  sourcesMap[source] || {
    code: source,
    label: titleCase(source),
    badgeLabel: titleCase(source),
    badgeCode: titleCase(source).toLocaleUpperCase().slice(0, 3),
    title: titleCase(source),
  }

export const source2label = (source) => sourceCode2data(source).label

export const getMatchSourceFilterOptions = (sources) =>
  sources.map((source) => ({
    value: source,
    label: source2label(source),
  }))
