import { useDispatch } from "react-redux"
import { STATUS_DISCARDED, STATUS_REVIEW } from "../util/match-status"
import * as MatchActions from "../ducks/matches/action-creators"

/**
 * @typedef ActionParam
 * @property {string} source - The source of the match
 * @property {string} referenceProductId - The reference product id
 * @property {string} daltixProductId - The daltix product id
 */
export function useMatchActions() {
  const dispatch = useDispatch()

  /** @param {ActionParam} */
  function approve({ source, referenceProductId, daltixProductId }) {
    dispatch(
      MatchActions.approveMatch({
        source,
        referenceProductId,
        daltixProductId,
      }),
    )
  }

  /** @param {ActionParam} */
  function discard({ source, referenceProductId, daltixProductId }) {
    dispatch(
      MatchActions.discardMatch({
        source,
        referenceProductId,
        daltixProductId,
      }),
    )
  }

  /** @param {ActionParam} */
  function restore({ source, referenceProductId, daltixProductId }) {
    dispatch(
      MatchActions.restoreMatch({
        source,
        referenceProductId,
        daltixProductId,
        status: STATUS_DISCARDED,
      }),
    )
  }

  /** @param {ActionParam} */
  function unapprove({ source, referenceProductId, daltixProductId }) {
    dispatch(
      MatchActions.removeMatchApproval({
        source,
        referenceProductId,
        daltixProductId,
      }),
    )
  }

  /** @param {ActionParam} */
  function match({ source, referenceProductId, daltixProductId, shop, country }) {
    dispatch(
      MatchActions.createManualMatch({
        source,
        referenceProductId,
        daltixProductId,
        shop,
        country,
        status: STATUS_REVIEW,
        factor: 1,
      }),
    )
  }

  return { approve, discard, restore, unapprove, match }
}
