import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import { connect } from "react-redux"

import mixpanel from "mixpanel-browser"
import { actionCreators as matchActions } from "../../ducks/matches"
import { ProductComparisonDialog } from "../product-comparison/ProductComparisonDialog"
import { ProductSearchDialog } from "../product-search/ProductSearch"
import MatchesReviewPanelPresenter from "./MatchesReviewPanelPresenter"

function MatchesReviewPanel({
  page = 1,
  total,
  loadingCountriesList,
  availableMatchCountries,
  loadingMatchSources,
  validSources,
  loadingCategories,
  loadingRefProductSummary,
  // actions
  getMatchStatusSummary,
  listMatchSources,
  listCountriesWithMatches,
  setMatchesListPage,
}) {
  const loading = useMemo(
    () =>
      loadingCategories ||
      loadingCountriesList ||
      loadingMatchSources ||
      loadingRefProductSummary,
    [
      loadingCategories,
      loadingCountriesList,
      loadingMatchSources,
      loadingRefProductSummary,
    ],
  )

  useEffect(() => {
    if (availableMatchCountries.length === 0) {
      listCountriesWithMatches()
    }

    getMatchStatusSummary()

    if (validSources.length === 0) {
      listMatchSources()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handlePageChange(_, offset, newPage) {
    mixpanel.track("MT -  Review Matches Page Changed", {
      offset,
      newPage,
    })

    setMatchesListPage(newPage)
  }
  return (
    <>
      <ProductComparisonDialog />
      <ProductSearchDialog />
      <MatchesReviewPanelPresenter
        page={page}
        total={total}
        loading={loading}
        onPageSelected={handlePageChange}
      />
    </>
  )
}

MatchesReviewPanel.propTypes = {
  page: PropTypes.number,
}

MatchesReviewPanel.defaultProps = {
  page: undefined,
}

const mapStateToProps = ({
  router: { location },
  matches: {
    total,
    page,
    loadingCountriesList,
    availableMatchCountries,
    loadingMatchSources,
    validSources,
    loadingStatusSummary,
    referenceProductCategories: {
      categoriesTree,
      loadingCategoriesTree: loadingCategories,
    },
  },
  refProduct: { summary },
}) => ({
  page,
  total,
  location,
  loadingCountriesList,
  availableMatchCountries,
  loadingMatchSources,
  validSources,
  loadingStatusSummary,
  loadingCategories,
  categoriesTree,
  loadingRefProductSummary: summary === undefined,
})

export default connect(mapStateToProps, matchActions)(MatchesReviewPanel)
