import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"
import { Tooltip } from "@material-ui/core"
import styles from "./AccessibleIcon.module.scss"

export function AccessibleIcon({
  border,
  className,
  color,
  shape,
  size,
  children,
  label,
  tooltip,
  testID,
}) {
  const FriendlyIcon = (
    <span
      data-testid={testID}
      className={clsx(
        styles.root,
        styles[border],
        styles[color],
        styles[shape],
        styles[size],
        className,
      )}
    >
      {children}
      <span className="sr-only">{label}</span>
    </span>
  )

  if (typeof tooltip === "string" && tooltip.trim() !== "") {
    return (
      <Tooltip classes={{ tooltip: "tooltip" }} title={tooltip}>
        {FriendlyIcon}
      </Tooltip>
    )
  }

  return FriendlyIcon
}
AccessibleIcon.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  border: PropTypes.oneOf(["none", "solid", "dashed"]),
  color: PropTypes.oneOf(["danger", "info", "warn", "success", "neutral", "secondary"]),
  className: PropTypes.string,
  shape: PropTypes.oneOf(["round", "circle"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "base", "lg", "xl", "xxl"]),
  tooltip: PropTypes.string,
  testID: PropTypes.string,
}
AccessibleIcon.defaultProps = {
  border: "dashed",
  className: "",
  color: "neutral",
  shape: "circle",
  size: "md",
  tooltip: "",
  testID: "",
}
