import React from "react"
import PropTypes from "prop-types"
import { kebabCase } from "lodash"

import styles from "./MatchesOverviewFiltersCheckboxList.module.scss"
import { Checkbox } from "../ui"

function MatchesOverviewFiltersCheckboxList({
  analyticsEventName,
  groupBy,
  items,
  onToggle,
}) {
  const groupedItems = groupBy(items)

  return (
    <>
      {Object.keys(groupedItems).map((groupKey) => {
        const id = `${groupKey || "root"}-list-container`

        return (
          <div key={id} data-testid={id}>
            {groupKey !== "" ? <p>{groupKey}</p> : null}
            <ul className={styles.list} aria-label={groupBy}>
              {groupedItems[groupKey].map(({ display, ticked, value }) => {
                const elementId = kebabCase(value)

                return (
                  <li key={elementId} className={styles.item}>
                    <Checkbox
                      analyticsEventName={analyticsEventName}
                      id={elementId}
                      display={display}
                      ticked={ticked}
                      value={value}
                      onToggle={onToggle}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </>
  )
}

MatchesOverviewFiltersCheckboxList.propTypes = {
  analyticsEventName: PropTypes.string.isRequired,
  groupBy: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      ticked: PropTypes.oneOf([false, true, "indeterminate"]).isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onToggle: PropTypes.func.isRequired,
}

MatchesOverviewFiltersCheckboxList.defaultProps = {
  groupBy: (items) => ({
    "": items,
  }),
}

export default MatchesOverviewFiltersCheckboxList
