import { createSvgIcon } from "@mui/material"
import React from "react"

export const AvailabilityActive = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00141 16.0001C6.90357 16.0001 5.8692 15.7901 4.89831 15.3702C3.92741 14.9503 3.07837 14.3769 2.35116 13.65C1.62395 12.9231 1.05028 12.0744 0.63017 11.104C0.210057 10.1335 0 9.09933 0 8.0015C0 6.89503 0.209966 5.855 0.629897 4.88141C1.04983 3.90781 1.62323 3.06091 2.35011 2.34073C3.077 1.62053 3.92568 1.05038 4.89614 0.630263C5.86661 0.210149 6.90076 9.15527e-05 7.99859 9.15527e-05C9.10506 9.15527e-05 10.1451 0.210058 11.1187 0.629989C12.0923 1.04992 12.9392 1.61982 13.6594 2.33968C14.3796 3.05955 14.9497 3.90607 15.3698 4.87924C15.7899 5.8524 16 6.89221 16 7.99868C16 9.09652 15.79 10.1309 15.3701 11.1018C14.9502 12.0727 14.3803 12.9217 13.6604 13.6489C12.9405 14.3761 12.094 14.9498 11.1209 15.3699C10.1477 15.79 9.10788 16.0001 8.00141 16.0001ZM8 15.0446C9.96059 15.0446 11.6248 14.3585 12.9927 12.9863C14.3606 11.6141 15.0445 9.95205 15.0445 8.00009C15.0445 6.0395 14.3606 4.37525 12.9927 3.00736C11.6248 1.63949 9.96059 0.955547 8 0.955547C6.04804 0.955547 4.38596 1.63949 3.01376 3.00736C1.64156 4.37525 0.955455 6.0395 0.955455 8.00009C0.955455 9.95205 1.64156 11.6141 3.01376 12.9863C4.38596 14.3585 6.04804 15.0446 8 15.0446Z"
      fill="#0FB1A2"
    />
  </svg>,
  "AvailabilityActive",
)

export const AvailabilityInactive = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00141 16.0001C6.89494 16.0001 5.85491 15.7901 4.88132 15.3702C3.90772 14.9503 3.06082 14.3804 2.34064 13.6605C1.62044 12.9406 1.05028 12.0941 0.63017 11.1209C0.210057 10.1478 0 9.10798 0 8.0015C0 6.89503 0.209966 5.855 0.629897 4.88141C1.04983 3.90781 1.61972 3.06091 2.33959 2.34073C3.05946 1.62053 3.90598 1.05038 4.87915 0.630263C5.8523 0.210149 6.89212 9.15527e-05 7.99859 9.15527e-05C9.10506 9.15527e-05 10.1451 0.210058 11.1187 0.629989C12.0923 1.04992 12.9392 1.61982 13.6594 2.33968C14.3796 3.05955 14.9497 3.90607 15.3698 4.87924C15.7899 5.8524 16 6.89221 16 7.99868C16 9.10515 15.79 10.1452 15.3701 11.1188C14.9502 12.0924 14.3803 12.9393 13.6604 13.6595C12.9405 14.3797 12.094 14.9498 11.1209 15.3699C10.1477 15.79 9.10788 16.0001 8.00141 16.0001ZM8 15.0446C8.86691 15.0446 9.69821 14.8954 10.4939 14.5969C11.2896 14.2983 12.006 13.8646 12.6429 13.2956L2.70445 3.35715C2.14953 4.00817 1.7193 4.72926 1.41375 5.5204C1.10822 6.31153 0.955455 7.1381 0.955455 8.00009C0.955455 9.96595 1.63808 11.6315 3.00333 12.9968C4.36859 14.362 6.03414 15.0446 8 15.0446ZM13.2826 12.643C13.8289 12.006 14.2591 11.2897 14.5733 10.494C14.8875 9.69831 15.0445 8.867 15.0445 8.00009C15.0445 6.03423 14.3619 4.36868 12.9967 3.00343C11.6314 1.63817 9.96586 0.955547 8 0.955547C7.13611 0.955547 6.30637 1.10696 5.51079 1.4098C4.7152 1.71264 3.99729 2.14854 3.35706 2.71751L13.2826 12.643Z"
      fill="#D12D1A"
    />
  </svg>,
  "AvailabilityInactive",
)

export const Caret = createSvgIcon(
  <svg viewBox="0 0 8 4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.00053 4.00024L0.333862 0.504404L0.862656 0.000244141L4.00053 3.00146L7.1384 0.00975061L7.6672 0.513911L4.00053 4.00024Z"
      fill="currentColor"
      fillOpacity="0.56"
    />
  </svg>,
  "Caret",
)

export const CompareOutlined = createSvgIcon(
  <svg viewBox="0 0 32 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.1692 25.2469L7.82187 23.8996L13.1596 18.5781H0V16.6672H13.1596L7.82187 11.3457L9.1692 9.99838L16.7935 17.6227L9.1692 25.2469ZM22.8308 15.2486L15.2065 7.62431L22.8308 0L24.1781 1.33116L18.8404 6.66886H32V8.57973H18.8404L24.1781 13.9012L22.8308 15.2486Z"
      fill="currentColor"
    />
  </svg>,
  "CompareOutlined",
)

export const OpenInNew = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5453 39C10.8533 39 10.256 38.7488 9.75362 38.2464C9.25121 37.744 9 37.1467 9 36.4547V11.5453C9 10.8533 9.25121 10.256 9.75362 9.75362C10.256 9.25121 10.8533 9 11.5453 9H22.6493V11.0022H11.5453C11.4095 11.0022 11.2851 11.0588 11.1719 11.1719C11.0588 11.2851 11.0022 11.4095 11.0022 11.5453V36.4547C11.0022 36.5905 11.0588 36.7149 11.1719 36.8281C11.2851 36.9412 11.4095 36.9978 11.5453 36.9978H36.4547C36.5905 36.9978 36.7149 36.9412 36.8281 36.8281C36.9412 36.7149 36.9978 36.5905 36.9978 36.4547V25.3507H39V36.4547C39 37.1467 38.7488 37.744 38.2464 38.2464C37.744 38.7488 37.1467 39 36.4547 39H11.5453ZM19.7783 29.6606L18.3666 28.2217L35.586 11.0022H26.2127V9H39V21.7873H36.9978V12.4411L19.7783 29.6606Z"
      fill="currentColor"
      fillOpacity="0.56"
    />
  </svg>,
  "OpenInNew",
)

export const StatusApproved = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75789 11.5482L12.4324 5.87377L11.6988 5.15314L6.75789 10.1021L4.26397 7.6082L3.55142 8.32883L6.75789 11.5482ZM8.00141 16.0001C6.90357 16.0001 5.8692 15.7901 4.89831 15.3702C3.92741 14.9503 3.07837 14.3769 2.35116 13.65C1.62395 12.9231 1.05028 12.0744 0.63017 11.104C0.210057 10.1335 0 9.09933 0 8.0015C0 6.89503 0.209966 5.855 0.629897 4.88141C1.04983 3.90781 1.62323 3.06091 2.35011 2.34073C3.077 1.62053 3.92568 1.05038 4.89614 0.630263C5.86661 0.210149 6.90076 9.15527e-05 7.99859 9.15527e-05C9.10506 9.15527e-05 10.1451 0.210058 11.1187 0.629989C12.0923 1.04992 12.9392 1.61982 13.6594 2.33968C14.3796 3.05955 14.9497 3.90607 15.3698 4.87924C15.7899 5.8524 16 6.89221 16 7.99868C16 9.09652 15.79 10.1309 15.3701 11.1018C14.9502 12.0727 14.3803 12.9217 13.6604 13.6489C12.9405 14.3761 12.094 14.9498 11.1209 15.3699C10.1477 15.79 9.10788 16.0001 8.00141 16.0001Z"
      fill="#0FB1A2"
    />
  </svg>,
  "StatusApproved",
)

export const StatusApprovedOutlined = createSvgIcon(
  <svg viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.671 21.6528L23.3107 11.0131L21.9352 9.66196L12.671 18.9413L7.99494 14.2652L6.65891 15.6164L12.671 21.6528ZM15.0026 30C12.9442 30 11.0048 29.6063 9.18433 28.8189C7.3639 28.0316 5.77194 26.9564 4.40843 25.5935C3.0449 24.2306 1.96928 22.6393 1.18157 20.8197C0.393857 19.0001 0 17.0611 0 15.0026C0 12.928 0.393685 10.978 1.18106 9.15247C1.96843 7.32697 3.04356 5.73904 4.40646 4.3887C5.76938 3.03833 7.36065 1.96928 9.18026 1.18157C10.9999 0.393858 12.9389 0 14.9974 0C17.072 0 19.0221 0.393687 20.8475 1.18106C22.673 1.96843 24.261 3.03698 25.6113 4.38672C26.9617 5.73649 28.0307 7.32372 28.8184 9.14841C29.6061 10.9731 30 12.9227 30 14.9974C30 17.0558 29.6063 18.9952 28.8189 20.8157C28.0316 22.6361 26.963 24.2281 25.6133 25.5916C24.2635 26.9551 22.6763 28.0307 20.8516 28.8184C19.0269 29.6061 17.0773 30 15.0026 30ZM15 28.2085C18.6761 28.2085 21.7966 26.9221 24.3614 24.3492C26.9261 21.7763 28.2085 18.6599 28.2085 15C28.2085 11.3239 26.9261 8.20343 24.3614 5.63863C21.7966 3.07386 18.6761 1.79148 15 1.79148C11.3401 1.79148 8.22367 3.07386 5.65079 5.63863C3.07792 8.20343 1.79148 11.3239 1.79148 15C1.79148 18.6599 3.07792 21.7763 5.65079 24.3492C8.22367 26.9221 11.3401 28.2085 15 28.2085Z"
      fill="currentColor"
    />
  </svg>,
  "StatusApprovedOutlined",
)

export const StatusDiscarded = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.8745 11.7993L8 8.67376L11.1255 11.7993L11.7992 11.1256L8.67366 8.00009L11.7992 4.87459L11.1255 4.2009L8 7.32643L4.8745 4.2009L4.20081 4.87459L7.32633 8.00009L4.20081 11.1256L4.8745 11.7993ZM8.00141 16.0001C6.90357 16.0001 5.8692 15.7901 4.89831 15.3702C3.92741 14.9503 3.07837 14.3769 2.35116 13.65C1.62395 12.9231 1.05028 12.0744 0.63017 11.104C0.210057 10.1335 0 9.09933 0 8.0015C0 6.89503 0.209966 5.855 0.629897 4.88141C1.04983 3.90781 1.62323 3.06091 2.35011 2.34073C3.077 1.62053 3.92568 1.05038 4.89614 0.630263C5.86661 0.210149 6.90076 9.15527e-05 7.99859 9.15527e-05C9.10506 9.15527e-05 10.1451 0.210058 11.1187 0.629989C12.0923 1.04992 12.9392 1.61982 13.6594 2.33968C14.3796 3.05955 14.9497 3.90607 15.3698 4.87924C15.7899 5.8524 16 6.89221 16 7.99868C16 9.09652 15.79 10.1309 15.3701 11.1018C14.9502 12.0727 14.3803 12.9217 13.6604 13.6489C12.9405 14.3761 12.094 14.9498 11.1209 15.3699C10.1477 15.79 9.10788 16.0001 8.00141 16.0001Z"
      fill="#D12D1A"
    />
  </svg>,
  "StatusDiscarded",
)

export const StatusDiscardedOutlined = createSvgIcon(
  <svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.74899 23.5984L16 17.3473L22.251 23.5984L23.5984 22.251L17.3473 16L23.5984 9.74899L22.251 8.40162L16 14.6527L9.74899 8.40162L8.40162 9.74899L14.6527 16L8.40162 22.251L9.74899 23.5984ZM16.0028 32C13.8071 32 11.7384 31.5801 9.79662 30.7402C7.85483 29.9003 6.15673 28.7535 4.70233 27.2998C3.2479 25.846 2.10057 24.1486 1.26034 22.2077C0.420114 20.2668 0 18.1985 0 16.0028C0 13.7899 0.419931 11.7098 1.25979 9.76264C2.09965 7.81543 3.24646 6.12165 4.70022 4.68128C6.15401 3.24088 7.85136 2.10057 9.79228 1.26034C11.7332 0.420115 13.8015 0 15.9972 0C18.2101 0 20.2902 0.419933 22.2374 1.25979C24.1846 2.09966 25.8784 3.23945 27.3187 4.67917C28.7591 6.11892 29.8994 7.81197 30.7397 9.7583C31.5799 11.7046 32 13.7842 32 15.9972C32 18.1929 31.5801 20.2616 30.7402 22.2034C29.9003 24.1452 28.7606 25.8433 27.3208 27.2977C25.8811 28.7521 24.188 29.8994 22.2417 30.7397C20.2954 31.5799 18.2158 32 16.0028 32ZM16 30.0891C19.9212 30.0891 23.2497 28.7169 25.9855 25.9725C28.7212 23.2281 30.0891 19.9039 30.0891 16C30.0891 12.0788 28.7212 8.75033 25.9855 6.01454C23.2497 3.27879 19.9212 1.91091 16 1.91091C12.0961 1.91091 8.77191 3.27879 6.02751 6.01454C3.28311 8.75033 1.91091 12.0788 1.91091 16C1.91091 19.9039 3.28311 23.2281 6.02751 25.9725C8.77191 28.7169 12.0961 30.0891 16 30.0891Z"
      fill="currentColor"
    />
  </svg>,
  "StatusApprovedOutlined",
)

export const FlagFilled = createSvgIcon(
  <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
    <rect width="60%" height="60%" x="25%" y="19%" fill="white" />
    <path
      d="M4.76113 12.9232H5.69229V8.71264H8.08097L8.92308 10.3969H12.9231V5.60333H10.4454L9.60325 3.91911H4.76113V12.9232ZM8.00141 16.0001C6.90357 16.0001 5.8692 15.7901 4.89831 15.3702C3.92741 14.9503 3.07837 14.3769 2.35116 13.65C1.62395 12.9231 1.05028 12.0744 0.63017 11.104C0.210057 10.1335 0 9.09933 0 8.0015C0 6.89503 0.209966 5.855 0.629897 4.88141C1.04983 3.90781 1.62323 3.06091 2.35011 2.34073C3.077 1.62053 3.92568 1.05038 4.89614 0.630263C5.86661 0.210149 6.90076 9.15527e-05 7.99859 9.15527e-05C9.10506 9.15527e-05 10.1451 0.210058 11.1187 0.629989C12.0923 1.04992 12.9392 1.61982 13.6594 2.33968C14.3796 3.05955 14.9497 3.90607 15.3698 4.87924C15.7899 5.8524 16 6.89221 16 7.99868C16 9.09652 15.79 10.1309 15.3701 11.1018C14.9502 12.0727 14.3803 12.9217 13.6604 13.6489C12.9405 14.3761 12.094 14.9498 11.1209 15.3699C10.1477 15.79 9.10788 16.0001 8.00141 16.0001Z"
      fill="currentColor"
    />
  </svg>,
  "FlagFilled",
)

export const StatusInReview = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.57769 8.89887C3.82791 8.89887 4.03993 8.81129 4.21376 8.63614C4.38757 8.46099 4.47447 8.24831 4.47447 7.99809C4.47447 7.74787 4.3869 7.53586 4.21176 7.36205C4.0366 7.18822 3.82391 7.10131 3.57369 7.10131C3.32349 7.10131 3.11147 7.18889 2.93765 7.36405C2.76384 7.53919 2.67693 7.75187 2.67693 8.00209C2.67693 8.25231 2.76451 8.46432 2.93965 8.63814C3.11481 8.81196 3.32749 8.89887 3.57769 8.89887ZM8.002 8.89887C8.25222 8.89887 8.46423 8.81129 8.63804 8.63614C8.81187 8.46099 8.89878 8.24831 8.89878 7.99809C8.89878 7.74787 8.8112 7.53586 8.63604 7.36205C8.4609 7.18822 8.24822 7.10131 7.998 7.10131C7.74778 7.10131 7.53577 7.18889 7.36196 7.36405C7.18813 7.53919 7.10122 7.75187 7.10122 8.00209C7.10122 8.25231 7.1888 8.46432 7.36396 8.63814C7.5391 8.81196 7.75178 8.89887 8.002 8.89887ZM12.4133 8.89887C12.6636 8.89887 12.8756 8.81129 13.0494 8.63614C13.2232 8.46099 13.3101 8.24831 13.3101 7.99809C13.3101 7.74787 13.2225 7.53586 13.0474 7.36205C12.8722 7.18822 12.6596 7.10131 12.4093 7.10131C12.1591 7.10131 11.9471 7.18889 11.7733 7.36405C11.5995 7.53919 11.5126 7.75187 11.5126 8.00209C11.5126 8.25231 11.6001 8.46432 11.7753 8.63814C11.9504 8.81196 12.1631 8.89887 12.4133 8.89887ZM8.00701 16.0001C6.89887 16.0001 5.86042 15.7901 4.89168 15.3702C3.92292 14.9503 3.0755 14.3769 2.34944 13.65C1.62337 12.9231 1.05028 12.0755 0.63017 11.1073C0.210057 10.1391 0 9.10165 0 7.99502C0 6.88839 0.209966 5.8494 0.629897 4.87804C1.04983 3.90668 1.62323 3.06091 2.35011 2.34073C3.077 1.62053 3.92456 1.05038 4.89279 0.630263C5.86102 0.210149 6.89845 9.15527e-05 8.00507 9.15527e-05C9.1117 9.15527e-05 10.1507 0.210058 11.1221 0.629989C12.0934 1.04992 12.9392 1.61982 13.6594 2.33968C14.3796 3.05955 14.9497 3.90607 15.3698 4.87924C15.7899 5.8524 16 6.89034 16 7.99308C16 9.10122 15.79 10.1397 15.3701 11.1084C14.9502 12.0772 14.3803 12.9233 13.6604 13.6468C12.9405 14.3702 12.094 14.9433 11.1209 15.366C10.1477 15.7887 9.10975 16.0001 8.00701 16.0001Z"
      fill="#F59C00"
    />
  </svg>,
  "StatusInReview",
)

export const StatusUnmatched = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7293 10.0714L14.6988 9.79597C14.8516 9.22437 14.9333 8.62261 14.9333 8.00012C14.9333 7.37763 14.8516 6.77587 14.6988 6.20427L15.7293 5.92882C15.9059 6.58946 16 7.2838 16 8.00012C16 8.71645 15.9059 9.41078 15.7293 10.0714ZM14.9294 3.99948L14.0061 4.53368C13.3971 3.48114 12.519 2.603 11.4664 1.99401L12.0006 1.07075C13.2147 1.77317 14.227 2.78547 14.9294 3.99948ZM10.0713 0.270863L9.79585 1.30135C9.22425 1.14856 8.62249 1.06679 8 1.06679C7.37751 1.06679 6.77575 1.14856 6.20415 1.30135L5.92869 0.270863C6.58934 0.0942687 7.28368 0.00012207 8 0.00012207C8.71632 0.00012207 9.41066 0.0942688 10.0713 0.270863ZM3.99936 1.07075L4.53355 1.99401C3.48102 2.603 2.60288 3.48114 1.99389 4.53368L1.07063 3.99948C1.77305 2.78547 2.78535 1.77317 3.99936 1.07075ZM0.270741 5.92882C0.0941467 6.58946 0 7.2838 0 8.00012C0 8.71644 0.0941466 9.41078 0.270741 10.0714L1.30123 9.79597C1.14844 9.22437 1.06667 8.62261 1.06667 8.00012C1.06667 7.37763 1.14844 6.77587 1.30123 6.20427L0.270741 5.92882ZM1.07063 12.0008L1.99389 11.4666C2.60288 12.5191 3.48102 13.3972 4.53355 14.0062L3.99936 14.9295C2.78535 14.2271 1.77305 13.2148 1.07063 12.0008ZM5.92869 15.7294L6.20415 14.6989C6.77575 14.8517 7.37751 14.9335 8 14.9335C8.62249 14.9335 9.22425 14.8517 9.79585 14.6989L10.0713 15.7294C9.41066 15.906 8.71632 16.0001 8 16.0001C7.28368 16.0001 6.58934 15.906 5.92869 15.7294ZM12.0006 14.9295L11.4664 14.0062C12.519 13.3972 13.3971 12.5191 14.0061 11.4666L14.9294 12.0008C14.227 13.2148 13.2147 14.2271 12.0006 14.9295Z"
      fill="black"
      fillOpacity="0.56"
    />
  </svg>,
  "StatusUnmatched",
)

export const MatchTypePrivateLabel = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6.94586 11.1403C6.81695 11.269 6.66314 11.3333 6.48442 11.3333C6.3057 11.3333 6.15204 11.269 6.02345 11.1403L0.856353 5.9703C0.784515 5.89842 0.734943 5.8229 0.707635 5.74375C0.680336 5.66458 0.666687 5.58125 0.666687 5.49375V1.30276C0.666687 1.12091 0.727215 0.969424 0.84827 0.848311C0.969315 0.727188 1.12071 0.666626 1.30246 0.666626H5.49111C5.57443 0.666626 5.65864 0.679685 5.74373 0.705803C5.82881 0.731921 5.90732 0.778522 5.97925 0.845606L11.1237 5.99296C11.2616 6.13097 11.3315 6.29126 11.3333 6.47384C11.3351 6.65641 11.2688 6.8149 11.1345 6.94931L6.94586 11.1403ZM6.50919 10.7058L10.7002 6.50941L5.49352 1.30276H1.30246V5.49375L6.50919 10.7058ZM2.7566 3.36536C2.9244 3.36536 3.06901 3.30393 3.19042 3.18108C3.31183 3.05823 3.37254 2.91285 3.37254 2.74495C3.37254 2.57705 3.31204 2.43236 3.19105 2.31088C3.07006 2.18941 2.92566 2.12868 2.75786 2.12868C2.58646 2.12868 2.44006 2.1892 2.31865 2.31025C2.19724 2.43131 2.13654 2.57579 2.13654 2.74369C2.13654 2.91159 2.19703 3.05718 2.31802 3.18045C2.439 3.30372 2.58519 3.36536 2.7566 3.36536Z"
      fill="black"
      fillOpacity="0.56"
    />
    <path
      d="M6.40941 8.09136L4.0307 5.71265L4.96918 4.77417C5.14959 4.59375 5.33775 4.47451 5.53365 4.41644C5.72878 4.35759 5.92043 4.35565 6.10859 4.41063C6.29675 4.46406 6.46981 4.56975 6.62777 4.72771C6.78573 4.88567 6.89104 5.05912 6.94369 5.24805C6.99634 5.43699 6.9917 5.62979 6.92975 5.82647C6.86858 6.02237 6.74663 6.21169 6.56389 6.39443L5.96572 6.9926L5.56269 6.58956L6.07955 6.0727C6.17634 5.97591 6.23945 5.87951 6.26887 5.7835C6.2983 5.68593 6.29868 5.59185 6.27003 5.50126C6.24138 5.40911 6.18795 5.32394 6.10975 5.24573C6.03077 5.16675 5.94598 5.11371 5.85538 5.08661C5.76479 5.05796 5.67148 5.05912 5.57547 5.09009C5.47868 5.12029 5.3815 5.18417 5.28394 5.28174L4.94478 5.62089L6.91233 7.58844L6.40941 8.09136Z"
      fill="black"
      fillOpacity="0.56"
    />
  </svg>,
  "MatchTypePrivateLabel",
)

export const MatchTypeFresh = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
  >
    <path
      d="M11.9702 32C8.63883 32 5.81097 30.8378 3.4866 28.5134C1.1622 26.189 0 23.3612 0 20.0298C0 17.1848 0.872981 14.6739 2.61895 12.4971C4.36488 10.3203 6.6261 8.92734 9.40261 8.31821C8.65183 8.18835 7.92584 7.98353 7.22463 7.70377C6.52342 7.42397 5.90483 7.0102 5.36887 6.46246C4.55671 5.6432 4.01664 4.68878 3.74866 3.59918C3.48068 2.50961 3.3408 1.38638 3.32901 0.229469C3.55331 0.135058 3.77467 0.0677805 3.99308 0.0276342C4.21149 -0.0124814 4.40923 -0.00892127 4.5863 0.0383151C6.70644 0.153997 8.49958 0.982687 9.96571 2.52439C11.4319 4.06612 12.1614 5.91712 12.1543 8.07738C12.5604 6.87566 13.1193 5.7542 13.8312 4.71301C14.543 3.67181 15.3487 2.70144 16.2482 1.8019C16.4536 1.5965 16.6992 1.4938 16.9849 1.4938C17.2705 1.4938 17.5161 1.5965 17.7215 1.8019C17.9269 2.00729 18.0296 2.24989 18.0296 2.52969C18.0296 2.80945 17.9269 3.05203 17.7215 3.25743C16.9895 4.00115 16.3344 4.79149 15.7559 5.62845C15.1775 6.46542 14.6852 7.34547 14.2791 8.26862C17.1052 8.81634 19.422 10.1898 21.2293 12.3891C23.0367 14.5884 23.9404 17.1353 23.9404 20.0298C23.9404 23.3612 22.7781 26.189 20.4537 28.5134C18.1294 30.8378 15.3015 32 11.9702 32ZM11.9702 29.9106C14.7231 29.9106 17.0581 28.952 18.9752 27.0349C20.8923 25.1178 21.8509 22.7827 21.8509 20.0298C21.8509 17.2769 20.8923 14.9419 18.9752 13.0248C17.0581 11.1077 14.7231 10.1491 11.9702 10.1491C9.21727 10.1491 6.88225 11.1077 4.96511 13.0248C3.048 14.9419 2.08945 17.2769 2.08945 20.0298C2.08945 22.7827 3.048 25.1178 4.96511 27.0349C6.88225 28.952 9.21727 29.9106 11.9702 29.9106Z"
      fill="black"
      fillOpacity="0.56"
    />
  </svg>,
  "MatchTypeFresh",
)

export const FlagOutlined = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" fill="currentColor">
    <path
      d="M9.52226 25.8462H11.3846V17.4251H16.1619L17.8462 20.7935H25.8462V11.2065H20.8907L19.2065 7.83804H9.52226V25.8462ZM19.0802 18.9312L17.396 15.5628H11.3846V9.68419H17.9725L19.6567 13.0526H24V18.9312H19.0802ZM16.0028 32C13.8071 32 11.7384 31.5801 9.79662 30.7402C7.85483 29.9003 6.15673 28.7535 4.70233 27.2998C3.2479 25.846 2.10057 24.1486 1.26034 22.2077C0.420114 20.2668 0 18.1985 0 16.0028C0 13.7899 0.419931 11.7098 1.25979 9.76264C2.09965 7.81543 3.24646 6.12165 4.70022 4.68128C6.15401 3.24088 7.85136 2.10057 9.79228 1.26034C11.7332 0.420115 13.8015 0 15.9972 0C18.2101 0 20.2902 0.419933 22.2374 1.25979C24.1846 2.09966 25.8784 3.23945 27.3187 4.67917C28.7591 6.11892 29.8994 7.81197 30.7397 9.7583C31.5799 11.7046 32 13.7842 32 15.9972C32 18.1929 31.5801 20.2616 30.7402 22.2034C29.9003 24.1452 28.7606 25.8433 27.3208 27.2977C25.8811 28.7521 24.188 29.8994 22.2417 30.7397C20.2954 31.5799 18.2158 32 16.0028 32ZM16 30.0891C19.9212 30.0891 23.2497 28.7169 25.9855 25.9725C28.7212 23.2281 30.0891 19.9039 30.0891 16C30.0891 12.0788 28.7212 8.75033 25.9855 6.01454C23.2497 3.27879 19.9212 1.91091 16 1.91091C12.0961 1.91091 8.77191 3.27879 6.02751 6.01454C3.28311 8.75033 1.91091 12.0788 1.91091 16C1.91091 19.9039 3.28311 23.2281 6.02751 25.9725C8.77191 28.7169 12.0961 30.0891 16 30.0891Z"
      fill="currentColor"
    />
  </svg>,
  "FlagOutlined",
)
