import { string } from "prop-types"
import useShops from "../../../../hooks/use-shops"

function Retailer({ shop }) {
  const { getByCode } = useShops()
  return getByCode(shop).name
}

Retailer.propTypes = {
  shop: string.isRequired,
}

export { Retailer }
