import PropTypes from "prop-types"
import React, { forwardRef } from "react"

import { Button } from "./Button"

import { AddIcon } from "../Icon"

const ButtonAdd = forwardRef(({ title, ...props }, forwardedRef) => (
  <Button
    aria-label={title}
    {...props}
    border="none"
    color="secondary"
    height="regular"
    squared
    title={title}
    variant="solid"
    ref={forwardedRef}
  >
    <AddIcon label={title} size="xs" />
  </Button>
))

ButtonAdd.displayName = "ButtonAdd"

ButtonAdd.propTypes = {
  title: PropTypes.string.isRequired,
}

export { ButtonAdd }
