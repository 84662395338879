import { Logger } from "../../../util/log"

const log = new Logger("ducks:matches:handler:list-matches")

export const handleListMatches = (state, action) => {
  const { requestId, reload, clearTotal } = action

  const { total } = state

  if (reload) {
    return {
      ...state,
      lastRequestId: requestId,
    }
  }

  return {
    ...state,
    total: clearTotal ? undefined : total,
    lastRequestId: requestId,
    loadingMatchesList: true,
    matchListError: undefined,
  }
}

export const handleListMatchesSuccess = (state, action) => {
  const { matches: currentMatches, lastRequestId } = state

  const {
    parent: { requestId },
    payload: {
      meta: { total, page },
      matches,
    },
  } = action

  if (requestId !== lastRequestId) {
    log.debug(
      `[handleListMatchesSuccess] Late response will be ignored. Last request ID: ${lastRequestId}. Response request ID: ${requestId}`,
    )
    return state
  }

  const newMatches = {}

  matches.forEach(({ id, ref_product_id: refProductId, daltix_id: daltixId }) => {
    if (!currentMatches[id]) {
      newMatches[id] = {
        id,
        ref_product_id: refProductId,
        daltix_id: daltixId,
      }
    }
  })

  const matchIdIternalNewEntries = {}
  matches.forEach(({ id, ref_product_id: refProductId, daltix_id: daltixId }) => {
    matchIdIternalNewEntries[id] = {
      ref_product_id: refProductId,
      daltix_id: daltixId,
    }
  })

  const { matchIdInternalMap } = state

  return {
    ...state,
    list: matches.map(({ id, ref_product_id: refProductId, daltix_id: daltixId }) => ({
      id,
      ref_product_id: refProductId,
      daltix_id: daltixId,
    })),
    loadingMatchesList: false,
    matchIdInternalMap: {
      ...matchIdInternalMap,
      ...matchIdIternalNewEntries,
    },
    matches: {
      ...currentMatches,
      ...newMatches,
    },
    page,
    total,
    matchListError: undefined,
  }
}

export const handleListMatchesError = (state, action) => {
  const { error } = action

  return {
    ...state,
    loadingMatchesList: false,
    matchListError: error,
  }
}
