import React, { Children } from "react"
import PropTypes from "prop-types"
import AppPropTypes from "../../../prop-shapes"

import styles from "./TableCellLabel.module.scss"

const colorVariants = Object.freeze({
  green: "#2bdb25",
  grey: "#999999",
  lilac: "#8684f5",
  yellow: "#dbba25",
})

function TableCellLabel({ children, color }) {
  const colorVariant = colorVariants[color]

  return (
    <div className={styles.container}>
      {Children.map(children, (child, index) => (
        <div
          style={{
            backgroundColor: index === 0 ? colorVariant : `${colorVariant}66`,
          }}
        >
          {child}
        </div>
      ))}
    </div>
  )
}

TableCellLabel.propTypes = {
  children: AppPropTypes.childrenElements.isRequired,
  color: PropTypes.oneOf(["green", "grey", "lilac", "yellow"]),
}

TableCellLabel.defaultProps = {
  color: "grey",
}

export default TableCellLabel
