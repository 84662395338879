import mixpanel from "mixpanel-browser"
import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple"
import styles from "./Button.module.scss"
import { isValidAnalyticsEventName } from "../../../util/user-monitoring"

const Button = forwardRef(
  (
    {
      analyticsEventName,
      border,
      children,
      className,
      color,
      height,
      loading,
      onClick,
      shape,
      squared,
      variant,
      ...props
    },
    forwardedRef,
  ) => {
    function handleClick(...args) {
      if (isValidAnalyticsEventName(analyticsEventName)) {
        mixpanel.track(analyticsEventName)
      }

      onClick(...args)
    }

    return (
      <button
        type="button"
        aria-busy={loading ? "true" : undefined}
        aria-disabled={props.disabled ? "true" : undefined}
        {...props}
        ref={forwardedRef}
        className={clsx(
          styles.button,
          styles[border],
          styles[color],
          styles[height],
          styles[shape],
          styles[variant],
          {
            [styles.squared]: squared,
          },
          className,
        )}
        onClick={handleClick}
      >
        {children}
        <TouchRipple center />
      </button>
    )
  },
)

Button.propTypes = {
  analyticsEventName: PropTypes.string,
  border: PropTypes.oneOf(["none", "outline"]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(["danger", "info", "warn", "success", "neutral", "secondary"]),
  disabled: PropTypes.bool,
  height: PropTypes.oneOf(["short", "regular"]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  shape: PropTypes.oneOf(["round", "circle"]),
  squared: PropTypes.bool,
  variant: PropTypes.oneOf(["ghost", "solid"]),
}

Button.defaultProps = {
  analyticsEventName: undefined,
  border: "outline",
  className: "",
  color: "neutral",
  disabled: false,
  height: "short",
  loading: false,
  onClick: () => undefined,
  shape: "round",
  squared: false,
  variant: "ghost",
}

export { Button }
