import React, { useMemo } from "react"
import PropTypes from "prop-types"
import "./style.sass"
import CategoryItem from "./CategoryItem"
import CategoryItemWithCount from "./CategoryItemWithCount"
import MultiCategorySelectFallbackPresenter from "./MultiCategorySelectFallbackPresenter"
import CategorySelect from "./CategorySelect"
import { getCategoryLevelLabel } from "../../ducks/category"

function MultiCategorySelectPresenter({
  categoryLevels,
  className,
  options,
  selected,
  itemRenderer,
  // event handlers
  onChange,
  analyticsEventName,
}) {
  const levels = useMemo(
    () => new Array(Math.min(categoryLevels, 4)).fill(),
    [categoryLevels],
  )

  return (
    <>
      {levels.map((_, i) => {
        const levelOptions = options[i] || []
        const levelSelectedOptions = selected[i] || []
        const categoryLabel = getCategoryLevelLabel(i + 1)
        const currClassName = `category-select ${
          i >= categoryLevels || levelOptions.length === 0 ? "disabled" : ""
        } ${className}`
        const disabled = i >= categoryLevels || levelOptions.length === 0

        return (
          <CategorySelect
            // eslint-disable-next-line react/no-array-index-key
            key={`category-${i}`}
            className={currClassName}
            disabled={disabled}
            index={i + 1}
            itemRenderer={itemRenderer}
            label={categoryLabel}
            onChange={onChange}
            options={levelOptions}
            selected={levelSelectedOptions}
            analyticsEventName={analyticsEventName}
          />
        )
      })}
    </>
  )
}

MultiCategorySelectPresenter.propTypes = {
  className: PropTypes.string,
  categoryLevels: PropTypes.number.isRequired,
  itemRenderer: PropTypes.elementType,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired,
        }).isRequired,
      ),
      PropTypes.arrayOf(
        PropTypes.shape({
          group: PropTypes.string.isRequired,
          children: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              count: PropTypes.number.isRequired,
            }),
          ).isRequired,
        }),
      ),
    ]),
  ),
  selected: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  analyticsEventName: PropTypes.string,
}

MultiCategorySelectPresenter.defaultProps = {
  className: "",
  itemRenderer: CategoryItem,
  options: [],
  selected: [],
  analyticsEventName: "",
}

export default MultiCategorySelectPresenter
export { CategoryItem, CategoryItemWithCount, MultiCategorySelectFallbackPresenter }
