import { get } from "lodash"
import {
  PRODUCT_COMPARISON_WIDGET_KEY,
  PRODUCT_COMPARISON_WIDGET_MATCHES_KEY,
} from "../../../ducks/widgets/product-comparison/constants"
import useAsyncSlice from "../../../hooks/use-async-slice"
import { triggerGetReferenceProductMatches } from "../../../ducks/widgets/product-comparison/action-creators"

const action = triggerGetReferenceProductMatches()

function selector(state) {
  return get(state, [
    "widgets",
    PRODUCT_COMPARISON_WIDGET_KEY,
    PRODUCT_COMPARISON_WIDGET_MATCHES_KEY,
  ])
}

/**
 * Gets the product comparison widget reference product existing matches
 *
 * @returns {{
 *  data: Array<{ matchId: string, daltixId: string, status: string }> | undefined;
 *  dispatch: (action: { [key: string]: any }) => void;
 *  error: Error | null;
 *  isError: boolean;
 *  isIdle: boolean;
 *  isFetching: boolean;
 *  isRefetching: boolean;
 *  isSuccess: boolean;
 *  status: string;
 *  run: () => void;
 * }} - An async slice of array of matches
 */
export default function useProductComparisonMatches() {
  return useAsyncSlice({
    action,
    immediate: false,
    selector,
  })
}
