import React, { useEffect, useState } from "react"
import Autosuggest from "react-autosuggest"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import { withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import Image from "../img/Image"
import { titleCase } from "../../util/strings"
import "./ProductAutocomplete.legacy.scss"
import useShops from "../../hooks/use-shops"

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps

  return (
    <TextField
      fullWidth
      color="primary"
      InputProps={{
        inputRef: (node) => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  )
}

const renderSuggestion =
  ({ shopsLookup }) =>
  (
    { id, ean = "", brand = "", title = "", picture, shop },
    { query, isHighlighted },
  ) => {
    const friendlyShopName = shopsLookup[shop]?.name || titleCase(shop)

    const titleMatches = match(title, query)
    const titleParts = parse(titleCase(title), titleMatches)

    const brandMatches = match(brand, query)
    const brandParts = parse(titleCase(brand), brandMatches)

    const eanMatches = match(ean, query)
    const eanParts = parse(ean, eanMatches)

    return (
      <MenuItem selected={isHighlighted} component="div" className="item">
        <div className="card">
          <Image key={id} alt="Daltix" src={picture} className="image" />
          <div className="text">
            <Typography className="title">
              {titleParts.map((part, index) =>
                part.highlight ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={String(index)} style={{ fontWeight: 500 }}>
                    {part.text}
                  </span>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <strong key={String(index)} style={{ fontWeight: 300 }}>
                    {part.text}
                  </strong>
                ),
              )}
            </Typography>

            <span>
              {brand && (
                <Typography className="brand">
                  {brandParts.map((part, index) =>
                    part.highlight ? (
                      // eslint-disable-next-line react/no-array-index-key
                      <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                      </span>
                    ) : (
                      // eslint-disable-next-line react/no-array-index-key
                      <strong key={String(index)} style={{ fontWeight: 300 }}>
                        {part.text}
                      </strong>
                    ),
                  )}
                </Typography>
              )}
              {shop && <Typography className="shop">{friendlyShopName}</Typography>}
            </span>

            <Typography className="ean">
              {eanParts.map((part, index) =>
                part.highlight ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={String(index)} style={{ fontWeight: 500 }}>
                    {part.text}
                  </span>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <strong key={String(index)} style={{ fontWeight: 300 }}>
                    {part.text}
                  </strong>
                ),
              )}
            </Typography>
          </div>
        </div>
      </MenuItem>
    )
  }

function getSuggestionValue(suggestion) {
  return titleCase(suggestion.title)
}

const styles = (theme) => ({
  container: {
    position: "relative",
    flexGrow: 1,
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: 0,
    left: 0,
    right: 0,
    minWidth: "60em",
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  input: {
    color: theme.palette.primary.main,
  },
})

function ProductAutocompleteV1({
  className,
  value = "",
  suggestions,
  suggestionsTotal,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  placeholder,
  classes,
  disabled = false,
  onValueChange,
  onSelected,
}) {
  const { items: shopsLookup } = useShops()

  const [internalValue, setInternalValue] = useState(value)

  function handleChangeInternalValue(_, props) {
    const { newValue, method } = props

    if (method !== "enter") {
      setInternalValue(newValue)
      onValueChange(_, props)
    }
  }

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  function handleKeyPress(event) {
    if (event.key === "Enter" && onSelected) {
      onSelected(event, { suggestionValue: internalValue })
    }
  }

  function handleOnSelected(event, { suggestionValue, method }) {
    if (method !== "enter") {
      onSelected(event, { suggestionValue })
    }
  }

  const autosuggestProps = {
    renderInputComponent,
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested:
      !internalValue || internalValue === "" ? onSuggestionsClearRequested : () => null,
    getSuggestionValue,
    renderSuggestion: renderSuggestion({ shopsLookup }),
  }

  const hiddenCount = suggestionsTotal - suggestions.length

  return (
    <Autosuggest
      {...autosuggestProps}
      inputProps={{
        classes,
        className,
        placeholder,
        value: internalValue,
        disabled,
        onChange: handleChangeInternalValue,
        onKeyPress: handleKeyPress,
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={(options) => (
        <Paper
          {...options.containerProps}
          square
          className={`${options.containerProps.className} auto-complete-container`}
        >
          {options.children}
          {options.children !== null && hiddenCount > 0 && (
            <div className="more-products-message">
              <strong>{hiddenCount}</strong> more product(s)
            </div>
          )}
        </Paper>
      )}
      onSuggestionSelected={handleOnSelected}
    />
  )
}

export default withStyles(styles)(ProductAutocompleteV1)
