import * as Actions from "./action-types"

/* -------------------------------------------------------------------------- */
/*                                    async                                   */
/* -------------------------------------------------------------------------- */

export function enqueueGetReferenceProductMatches() {
  return {
    type: Actions.PRODUCT_COMPARISON_ENQUEUE_GET_REF_PRODUCT_MATCHES,
  }
}

/* -------------------------------------------------------------------------- */
/*                                  fetchers                                  */
/* -------------------------------------------------------------------------- */

export function getReferenceProductsMatches() {
  return {
    type: Actions.PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES,
  }
}

/* -------------------------------------------------------------------------- */
/*                                  mutations                                 */
/* -------------------------------------------------------------------------- */

export function setProductComparisonDaltixProductFilters({ statuses }) {
  return {
    type: Actions.PRODUCT_COMPARISON_SET_DALTIX_PRODUCT_STATUS_FILTERS,
    statuses,
  }
}

export function setProductComparisonComparables(ids) {
  return {
    type: Actions.PRODUCT_COMPARISON_SET_COMPARABLE_PRODUCTS,
    ids,
  }
}

/* -------------------------------------------------------------------------- */
/*                                    state                                   */
/* -------------------------------------------------------------------------- */

export function closeProductComparisonWidget() {
  return {
    type: Actions.PRODUCT_COMPARISON_CLOSE,
  }
}

export function openProductComparisonWidget({ referenceProductId }) {
  return {
    type: Actions.PRODUCT_COMPARISON_OPEN,
    referenceProductId,
  }
}

/* -------------------------------------------------------------------------- */
/*                                  triggers                                  */
/* -------------------------------------------------------------------------- */

export function triggerGetReferenceProductMatches() {
  return {
    type: Actions.PRODUCT_COMPARISON_TRIGGER_GET_REF_PRODUCT_MATCHES,
  }
}
