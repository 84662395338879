import React, { Suspense, memo } from "react"
import PropTypes from "prop-types"
import { Link, Route, Switch } from "react-router-dom"
import { Box, useTheme } from "@mui/material"
import { TopMenu } from "@daltix/ui-react"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"

import { ContentContainer, SideNavigation } from "../../components/side-navigation"
import MatchingPanel from "../../components/matching-panel/MatchingPanel"
import MatchesReviewPanel from "../../components/matches-review-panel/MatchesReviewPanel"
import { isDebug } from "../../util/env"
import HtmlTitle from "../../components/html-title/HtmlTitle"
import { PortalFallback } from "../portal/Portal"
import MatchesOverviewPanel from "../../components/matches-overview/MatchesOverviewPanel"
import RestrictedRoute from "../../hocs/restricted-route"

function MatchingEnginePresenter({ customerDbName, loading, pathname, routes }) {
  const theme = useTheme()
  const debugProps = {}
  const { t } = useTranslation()

  if (isDebug()) {
    debugProps["data-db-name"] = customerDbName
  }

  if (loading) {
    return <PortalFallback />
  }

  return (
    <Box sx={{ display: "flex" }}>
      <SideNavigation />
      <ContentContainer>
        <HtmlTitle>Matches</HtmlTitle>
        <TopMenu {...debugProps} style={{ background: theme.palette.grey[400] }}>
          <TopMenu.Item
            active={pathname === routes.review}
            component={Link}
            to={routes.review}
            onClick={() => mixpanel.track("MT - Review Matches Tab Clicked")}
          >
            {t("Review Matches")}
          </TopMenu.Item>
          <TopMenu.Item
            active={pathname === routes.match}
            component={Link}
            to={routes.match}
            onClick={() => mixpanel.track("MT - Make Matches Tab Clicked")}
          >
            {t("Make Matches")}
          </TopMenu.Item>
          <TopMenu.Item
            active={pathname === routes.overview}
            component={Link}
            to={routes.overview}
            onClick={() => mixpanel.track("MT - Matches Overview Tab Clicked")}
          >
            {t("Matches Overview")}
          </TopMenu.Item>
        </TopMenu>
        <Suspense>
          <Switch>
            <Route path={routes.review} component={MatchesReviewPanel} />
            <Route path={routes.match} component={MatchingPanel} />
            <RestrictedRoute path={routes.overview} component={MatchesOverviewPanel} />
          </Switch>
        </Suspense>
      </ContentContainer>
    </Box>
  )
}

MatchingEnginePresenter.propTypes = {
  routes: PropTypes.shape({
    match: PropTypes.string.isRequired,
    overview: PropTypes.string.isRequired,
    review: PropTypes.string.isRequired,
  }).isRequired,
  customerDbName: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default memo(MatchingEnginePresenter)
