// matching tool
export const PERM_READ_MATCHES = "read:matches"
export const PERM_CREATE_MATCHES = "create:matches"
export const PERM_APPROVE_MATCHES = "approve:matches"
export const PERM_UPDATE_MATCHES = "update:matches"
export const PERM_DISCARD_MATCHES = "discard:matches"

// apps
export const PERM_READ_APPS = "read:apps"

// reports
export const PERM_READ_REPORTS = "read:reports"
