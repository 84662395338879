import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import AppPropTypes from "../../../prop-shapes"

import styles from "./TableCellItem.module.scss"

function TableCellItem({ availability, children }) {
  return <div className={clsx(styles.item, availability)}>{children}</div>
}

TableCellItem.propTypes = {
  availability: PropTypes.oneOf(["available", "unavailable"]),
  children: AppPropTypes.childrenNodes.isRequired,
}

TableCellItem.defaultProps = {
  availability: "available",
}

export default TableCellItem
