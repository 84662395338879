import React, { useCallback } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import MultiCategorySelector, {
  CategoryItemWithCount,
} from "../multi-category-selector"
import * as matchActions from "../../ducks/reference-products/action-creators"

function MatchesReviewRefProdCategoriesSelector() {
  const dispatch = useDispatch()

  const {
    refProdCategoryLevels,
    l1CategoriesOptions,
    l2CategoriesOptions,
    l3CategoriesOptions,
    l4CategoriesOptions,
    selectedL1Categories,
    selectedL2Categories,
    selectedL3Categories,
    selectedL4Categories,
  } = useSelector(({ refProduct }) => {
    const { categoriesTree, categoryLevels } = refProduct.categories

    return {
      selectedL1Categories: refProduct.selectedL1Categories,
      selectedL2Categories: refProduct.selectedL2Categories,
      selectedL3Categories: refProduct.selectedL3Categories,
      selectedL4Categories: refProduct.selectedL4Categories,
      l1CategoriesOptions: Object.keys(categoriesTree).map((key) => ({
        name: key,
        count: categoriesTree[key].count,
      })),
      l2CategoriesOptions: refProduct.l2CategoriesOptions,
      l3CategoriesOptions: refProduct.l3CategoriesOptions,
      l4CategoriesOptions: refProduct.l4CategoriesOptions,
      refProdCategoryLevels: categoryLevels,
    }
  }, shallowEqual)

  const handleCategoriesChange = useCallback(
    (level, { target: { value } }) => {
      const actionCreator =
        matchActions[`startFilterReferenceProductsByL${level}Categories`]
      dispatch(actionCreator(value))
    },
    [dispatch],
  )

  return (
    <MultiCategorySelector
      categoryLevels={refProdCategoryLevels}
      options={[
        l1CategoriesOptions,
        l2CategoriesOptions,
        l3CategoriesOptions,
        l4CategoriesOptions,
      ]}
      selected={[
        selectedL1Categories,
        selectedL2Categories,
        selectedL3Categories,
        selectedL4Categories,
      ]}
      itemRenderer={CategoryItemWithCount}
      onChange={handleCategoriesChange}
      analyticsEventName="MT - Make Matches Ref. Filter Change"
    />
  )
}

export default MatchesReviewRefProdCategoriesSelector
