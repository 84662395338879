import { useSplitTreatments } from "@splitsoftware/splitio-react"
import { featureNamesMap } from "./featureNamesMap"
import { ANONYMOUS_ID, ON, TRAFFIC_TYPE } from "./constants"
import { useAuth0 } from "../../hooks/use-authentication"

const names = [featureNamesMap.MATCH_OVERVIEW_COMPARISON_WIDGET]

export function useUserTreatments() {
  const auth0 = useAuth0()
  const splitKey = auth0.user?.sub || ANONYMOUS_ID

  const proxy = useSplitTreatments({
    names,
    splitKey,
    trafficType: TRAFFIC_TYPE.user, // by user id
  })

  function has(featureName) {
    return proxy.treatments[featureName]?.treatment === ON
  }

  return { ...proxy, has }
}
