import Tooltip from "@mui/material/Tooltip"
import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"
import ReactPlaceholder from "react-placeholder"

import { Box } from "@mui/material"
import { useDispatch } from "react-redux"

import styles from "./ProductComparisonTableHeaderFooterAndControls.module.scss"

import { ComparisonData } from "./ComparisonDataPropType"
import { ProductComparisonTableHeaderContainer } from "./ProductComparisonTableHeaderContainer"

import { BadgeCount, ButtonAdd, ButtonCheck, ButtonCross } from "../../ui"

import * as MatchActions from "../../../ducks/matches/action-creators"
import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
  STATUS_UNMATCHED,
} from "../../../util/match-status"
import { DXIcon } from "../../dx-icon/DXIcon"
import { MatchStatusIcon } from "../../match-status-icon/MatchStatusIcon"
import useProductComparisonMatches from "../hooks/use-product-comparison-matches"
import useMatchedProduct from "../../../hooks/use-matched-product"

function Placeholder() {
  return (
    <ReactPlaceholder
      className="rounded"
      color="#f5f5f5"
      showLoadingAnimation
      style={{ width: "6rem", height: "4rem" }}
      type="rect"
    />
  )
}

function useMatchActions() {
  const dispatch = useDispatch()

  function approve({ match, daltixId }) {
    dispatch(
      MatchActions.approveMatch({
        source: match.source,
        referenceProductId: match.ref_product_id,
        daltixProductId: daltixId,
      }),
    )
  }

  function discard({ match, daltixId }) {
    dispatch(
      MatchActions.discardMatch({
        source: match.source,
        referenceProductId: match.ref_product_id,
        daltixProductId: daltixId,
      }),
    )
  }

  function restore({ match, daltixId }) {
    dispatch(
      MatchActions.restoreMatch({
        source: match.source,
        referenceProductId: match.ref_product_id,
        daltixProductId: daltixId,
        status: STATUS_DISCARDED,
      }),
    )
  }

  function unapprove({ match, daltixId }) {
    dispatch(
      MatchActions.removeMatchApproval({
        source: match.source,
        referenceProductId: match.ref_product_id,
        daltixProductId: daltixId,
      }),
    )
  }

  return { approve, discard, restore, unapprove }
}

function MatchControls({ matchData }) {
  const matchId = matchData?.data?.matched?.id
  const daltixId = matchData?.data?.match?.id
  const { data: match, isFetching, isReady, run } = useMatchedProduct(matchId || "")
  const { approve, discard, restore, unapprove } = useMatchActions()

  if (!isFetching && !isReady) {
    run()
  }

  if (!isReady) {
    return <Placeholder />
  }

  let Controls

  switch (match?.status) {
    case STATUS_APPROVED:
      Controls = (
        <Tooltip title="disapprove match">
          <ButtonCross onClick={() => unapprove({ match, daltixId })} />
        </Tooltip>
      )
      break
    case STATUS_DISCARDED:
      Controls = (
        <Tooltip title="restore match">
          <ButtonAdd onClick={() => restore({ match, daltixId })} />
        </Tooltip>
      )
      break
    case STATUS_REVIEW:
      Controls = (
        <>
          <Tooltip title="approve match">
            <ButtonCheck onClick={() => approve({ match, daltixId })} />
          </Tooltip>
          <Tooltip title="discard match">
            <ButtonCross onClick={() => discard({ match, daltixId })} />
          </Tooltip>
        </>
      )
      break
    default:
      Controls = null
  }

  return <div className={styles.controls}>{Controls}</div>
}

MatchControls.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matchData: PropTypes.object.isRequired,
}

function ReferenceProductControls({ productId: _ }) {
  return <div className={styles.controls} />
}

ReferenceProductControls.propTypes = {
  productId: PropTypes.string.isRequired,
}

function MatchFooter({ matchData }) {
  const matchId = matchData?.data?.matched?.id
  const { data, isFetching, isReady, run } = useMatchedProduct(matchId || "")

  if (!isFetching && !isReady) {
    run()
  }

  if (!isReady) {
    return <Placeholder />
  }

  const outdated = matchData?.data?.match?.outdated

  return (
    <div className={clsx(styles.footer, outdated && styles.outdated)}>
      <div className={styles.heading}>
        <h2> </h2>
      </div>
      <div className={styles.info}>
        <div className={styles.container}>
          <span style={{ display: "flex" }}>
            <MatchStatusIcon status={data?.status || STATUS_UNMATCHED} />
            {outdated && (
              <Box marginLeft={1} display="flex" alignItems="center">
                <DXIcon type="inactive" />
              </Box>
            )}
          </span>
          <MatchControls matchData={matchData} />
        </div>
      </div>
    </div>
  )
}

MatchFooter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matchData: PropTypes.object.isRequired,
}

function ReferenceProductFooter({ productId }) {
  const { data, isReady } = useProductComparisonMatches()

  if (!isReady) {
    return <Placeholder />
  }

  const approvedMatches = data.filter((m) => m.status === STATUS_APPROVED)

  return (
    <footer className={styles.footer}>
      <div className={styles.heading}>
        <h2>Match Status</h2>
      </div>
      <div className={styles.info}>
        <div className={styles.container}>
          <span className={styles.badge}>
            <BadgeCount
              absolute={false}
              color="primary"
              count={approvedMatches.length}
            />
            &nbsp;&nbsp;
            <span>Approved (active)</span>
          </span>
          <ReferenceProductControls productId={productId} />
        </div>
      </div>
    </footer>
  )
}

ReferenceProductFooter.propTypes = {
  productId: PropTypes.string.isRequired,
}

function ProductComparisonTableHeaderFooterAndControls({ matchData: data, isMatch }) {
  const productData = isMatch ? data?.match : data?.ref
  return (
    <ProductComparisonTableHeaderContainer>
      {isMatch ? (
        <MatchFooter matchData={productData} />
      ) : (
        <ReferenceProductFooter productId={productData?.id} />
      )}
    </ProductComparisonTableHeaderContainer>
  )
}

ProductComparisonTableHeaderFooterAndControls.propTypes = {
  matchData: ComparisonData.isRequired,
  isMatch: PropTypes.bool.isRequired,
}

export { ProductComparisonTableHeaderFooterAndControls }
