import React from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import SearchIcon from "@material-ui/icons/Search"
import AddIcon from "@material-ui/icons/Add"

import SearchPanel from "../search-panel/SearchPanel"

import "./AddMatchesPanel.sass"

function AddMatchesPanelPresenter({
  expanded,
  onChange,
  className = "",
  productId,
  noFilterTotalSearchResults,
  lastSearchTerm,
}) {
  let searchPreviewTitle = null

  if (!expanded && noFilterTotalSearchResults !== undefined) {
    searchPreviewTitle = (
      <span
        className="fade-in"
        title={`${noFilterTotalSearchResults} search result${
          noFilterTotalSearchResults > 1 ? "s" : ""
        } found for '${lastSearchTerm}'`}
      >
        {noFilterTotalSearchResults}
        <SearchIcon fontSize="inherit" className="expansion-panel-icon" />
      </span>
    )
  }

  const title = (
    <Typography component="span" className="list-title flex">
      <AddIcon className="expansion-panel-icon" />
      Add Matches
      <span className="grow" />
      <span className="expansion-panel-subtitle aux-text">{searchPreviewTitle}</span>
    </Typography>
  )

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={onChange}
      className={`${className} add-matches-panel dx-panel`}
      classes={{
        expanded: `grow expanded ${className}`,
      }}
      data-testid="add-matches-panel"
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className="list-title grow">{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="flex v add-matches-content">
        <SearchPanel productId={productId} expanded={expanded} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default AddMatchesPanelPresenter
