import PropTypes from "prop-types"
import React, { memo, useEffect, useState } from "react"
import noPicture from "../../no-image.png"
import { forceArray } from "../../util/array"

function Image({ src, alt, onError, onSrcSelected, ...rest }) {
  const sources = [...forceArray(src), noPicture]

  const [index, setIndex] = useState(0)

  useEffect(() => {
    onSrcSelected(sources[index])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  function handleError(e) {
    setIndex(index + 1)
    if (onError) {
      onError(e)
    }
  }

  return (
    <img
      {...rest}
      alt={alt}
      data-sources={`[${sources.join("; ")}]`}
      src={sources[index]}
      onError={handleError}
    />
  )
}

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  alt: PropTypes.string,
  onError: PropTypes.func,
  onSrcSelected: PropTypes.func,
}

Image.defaultProps = {
  alt: "",
  src: [],
  onError: () => undefined,
  onSrcSelected: () => undefined,
}

export default memo(Image)
