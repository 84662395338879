import React from "react"
import HtmlTitle from "../html-title/HtmlTitle"

import _styles from "./AssortmentAnalysisPanel.module.scss"
import AssortmentAnalysisToolbar from "./AssortmentAnalysisToolbar"
import { AssortmentAnalysisTable } from "./table"

function AssortmentAnalysisPanel() {
  return (
    <>
      <HtmlTitle>Assortment Analysis</HtmlTitle>
      <AssortmentAnalysisToolbar />
      <AssortmentAnalysisTable />
    </>
  )
}

AssortmentAnalysisPanel.propTypes = {}

export default AssortmentAnalysisPanel
