import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { isDebug } from "./env"

export function getMessage(error) {
  const { response: { data: { message: apiErrorMessage } = {} } = {} } = error

  if (apiErrorMessage) {
    console.info("API ERROR:", apiErrorMessage)

    return apiErrorMessage
  }

  return error.message
}

const isLocal = process.env.NODE_ENV === "development"

export function initSentry() {
  Sentry.init({
    beforeSend: (event) => {
      if (isLocal || isDebug()) {
        return null
      }

      return event
    },
    dsn: "https://04e9e2f82a174b5780178f814ec0622f@o107635.ingest.sentry.io/5676084",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: isLocal ? 0.0 : 0.2,
  })
}

export function logError(error, errorInfo = null) {
  if (isLocal) {
    console.error(error, errorInfo)

    return
  }

  Sentry.withScope((scope) => {
    if (errorInfo) {
      scope.setExtras(errorInfo)
    }

    Sentry.captureException(error)
  })
}
