import React from "react"
import { Box } from "@mui/material"
import useCustomer from "../../hooks/use-customer"
import AppCardGroup from "../app-card-group"

import "./style.sass"

function AppsContainer() {
  const customer = useCustomer()
  const { apps } = customer

  return (
    <Box className="apps-container">
      {apps?.map((group, i) => (
        <AppCardGroup key={group.id} last={i === apps.length - 1} {...group} />
      ))}
    </Box>
  )
}

export default AppsContainer
