import { ofType } from "redux-observable"
import { map } from "rxjs/operators"
import { Logger } from "../../../util/log"
import { buildEpic } from "../../../util/redux-observable-helpers"
import * as api from "../../../api/service"
import {
  MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
  MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS,
} from "./action-types"
import { withParent } from "../../../util/epics"

import * as MatchActions from "../../matches/action-types"
import { getOneMatchedProductById } from "./action-creators"

const _log = new Logger("ducks:repositories:matched-products:epics")

function triggerMatchProductByIdRefresh(action$) {
  return action$.pipe(
    ofType(
      MatchActions.MATCH_APPROVE_SUCCESS,
      MatchActions.MATCH_DISCARD_SUCCESS,
      MatchActions.MATCH_REMOVE_APPROVAL_SUCCESS,
      MatchActions.MATCH_RESTORE_SUCCESS,
    ),
    map((action) =>
      withParent(
        action,
        getOneMatchedProductById({
          id: action.payload.result.matchIds.at(0),
          invalidateCache: true,
        }),
      ),
    ),
  )
}

// epics
const getOneMatchedProductByIdEpic = buildEpic(
  MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
  ({ id, invalidateCache }) =>
    api.getMatches([
      {
        id,
        invalidateCache,
      },
    ]),
)

const getMatchedProductByIdsEpic = buildEpic(
  MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS,
  ({ ids, invalidateCache }) =>
    api.getMatches(
      ids.map((id) => ({
        id,
        invalidateCache,
      })),
    ),
)

export default [
  triggerMatchProductByIdRefresh,
  getOneMatchedProductByIdEpic,
  getMatchedProductByIdsEpic,
]
