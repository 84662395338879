import PropTypes from "prop-types"
import React from "react"
import * as RUIDialog from "@radix-ui/react-dialog"
import clsx from "clsx"
import styles from "./BaseDialogDescription.module.scss"

function BaseDialogDescription({ children, className, ...props }) {
  return (
    <RUIDialog.Description className={clsx(styles.description, className)} {...props}>
      {children}
    </RUIDialog.Description>
  )
}

BaseDialogDescription.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

BaseDialogDescription.defaultProps = {
  className: "",
}

export { BaseDialogDescription }
