import React from "react"
import PropTypes from "prop-types"
import { Paper, Typography, Chip, Box } from "@mui/material"
import ArrowForward from "@mui/icons-material/ArrowForward"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import "./style.sass"
import { imagesBaseURL } from "../../util/env"
import { STATUS_REVIEW } from "../../util/match-status"

import { AVAILABILITY_ACTIVE } from "../../util/product-availability"
import { useMatchSummary } from "../../hooks/use-match-summary"

function ReviewMatchesWidget(path) {
  const { t } = useTranslation()
  const { summary, getSummary } = useMatchSummary()

  let extraInfo = <></>

  if (!summary && path === "/matches/review") {
    getSummary()
  }

  if (summary && path === "/matches/review") {
    const review = summary.in_review
    extraInfo = (
      <Chip
        color="primary"
        variant="outlined"
        onClick={() => {
          // change location directly to trigger the route change with the applied filters
          window.location = `${path}?a=${AVAILABILITY_ACTIVE}&filters=true&p=1&st=${STATUS_REVIEW}`
        }}
        label={
          <>
            <strong>{review}</strong>{" "}
            {t(`Match${review === 1 ? "" : "es"} need to be approved`)}
          </>
        }
      />
    )
  }

  return extraInfo
}

function AppCard({ title, description, icon, path, labels }) {
  const { t } = useTranslation()

  return (
    <Link to={path} className="card-link">
      <Paper className="app-card">
        <img
          className="app-card-icon"
          src={`${imagesBaseURL}/navigator/icons/cards/${icon}`}
          alt={t(title)}
        />
        <Typography component="h5" className="app-card-title">
          {t(title)}
        </Typography>
        {labels && (
          <div className="app-card-labels">
            {labels?.map((label) => (
              <Chip
                key={label}
                className="app-card-label"
                label={t(label)}
                size="small"
              />
            ))}
          </div>
        )}
        <Typography className="app-card-description">{t(description)}</Typography>
        <div className="app-card-spacer" />
        {ReviewMatchesWidget(path)}
        <Box className="app-card-link">
          <ArrowForward /> {t("View")}
        </Box>
      </Paper>
    </Link>
  )
}

AppCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
}

AppCard.defaultProps = {
  labels: null,
}

export default AppCard
