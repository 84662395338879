import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"

import * as RUISelect from "@radix-ui/react-select"

import styles from "./BaseSelectIcon.module.scss"

function BaseSelectIcon({ className, ...props }) {
  return <RUISelect.Icon {...props} className={clsx(styles.icon, className)} />
}

BaseSelectIcon.propTypes = {
  className: PropTypes.string,
}

BaseSelectIcon.defaultProps = {
  className: "",
}

export { BaseSelectIcon }
