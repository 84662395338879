import {
  handleListCategories,
  handleListCategoriesError,
  handleListCategoriesSuccess,
} from "../../category"

export function handleGetReferenceProductsCategories(state, action) {
  const { referenceProductCategories } = state

  return {
    ...state,
    referenceProductCategories: handleListCategories(
      referenceProductCategories,
      action,
    ),
  }
}

export function handleGetReferenceProductsCategoriesSuccess(state, action) {
  const { referenceProductCategories } = state

  return {
    ...state,
    referenceProductCategories: handleListCategoriesSuccess(
      referenceProductCategories,
      action,
    ),
  }
}

export function handleGetReferenceProductsCategoriesError(state, action) {
  const { referenceProductCategories } = state

  return {
    ...state,
    referenceProductCategories: handleListCategoriesError(
      referenceProductCategories,
      action,
    ),
  }
}
