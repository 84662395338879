export const getPersistableReviewMatchesFields = ({
  selectedMatchSources,
  selectedMatchStatuses,
  availableMatchShops,
  selectedMatchShops,
  selectedMatchCountries,
  l2CategoriesOptions,
  l3CategoriesOptions,
  l4CategoriesOptions,
  selectedL1Categories,
  selectedL2Categories,
  selectedL3Categories,
  selectedL4Categories,
  selectedRefProdCountries,
  reviewPageSettings,
}) => ({
  selectedMatchSources,
  selectedMatchStatuses,
  availableMatchShops,
  selectedMatchShops,
  selectedMatchCountries,
  l2CategoriesOptions,
  l3CategoriesOptions,
  l4CategoriesOptions,
  selectedL1Categories,
  selectedL2Categories,
  selectedL3Categories,
  selectedL4Categories,
  selectedRefProdCountries,
  reviewPageSettings,
})

export const getPersistableReferenceProductsFields = ({
  list,
  prevPageList,
  nextPageList,
  existingMatches,
  suggestedMatches,
  detailedProductIndex,
  productCount,
  page,
  offset,
  selectedCountries,
  selectedFamilies,
  l2CategoriesOptions,
  l3CategoriesOptions,
  l4CategoriesOptions,
  selectedL1Categories,
  selectedL2Categories,
  selectedL3Categories,
  selectedL4Categories,
  sortBy,
}) => ({
  list,
  prevPageList,
  nextPageList,
  existingMatches,
  suggestedMatches,
  detailedProductIndex,
  productCount,
  page,
  offset,
  selectedCountries,
  selectedFamilies,
  l2CategoriesOptions,
  l3CategoriesOptions,
  l4CategoriesOptions,
  selectedL1Categories,
  selectedL2Categories,
  selectedL3Categories,
  selectedL4Categories,
  sortBy,
})
