import PropTypes from "prop-types"
import React from "react"
import ReactPlaceholder from "react-placeholder"
import * as Sentry from "@sentry/react"

import { useDispatch } from "react-redux"

import * as ProductComparisonActions from "../../ducks/widgets/product-comparison/action-creators"

import { Button, Table } from "../ui"
import useReferenceProduct from "../../hooks/use-reference-product"
import { contentString } from "../../util/strings"
import { getContentsV1AsArray } from "../../util/volumes"
import { CompareIcon } from "../ui/Icon/CompareIcon"

import styles from "./MatchesOverviewReferenceProduct.module.scss"
import { useHasMatchesOverviewComparisonWidget } from "../../integrations/split"

function ReferenceProductRowHeader({
  brand,
  contents_v1: contentsV1,
  images,
  name,
  productId,
}) {
  const dispatch = useDispatch()
  const userHasWidget = useHasMatchesOverviewComparisonWidget()

  const hasSources = Array.isArray(images) && !!images[0]
  const sources = hasSources ? images : null
  const sourceAlt = `${name} image`
  const contentsArray = contentsV1 ? getContentsV1AsArray(contentsV1) : undefined

  function handleOpenProductComparisonWidget() {
    dispatch(
      ProductComparisonActions.openProductComparisonWidget({
        referenceProductId: productId,
      }),
    )
  }

  return (
    <Table.RowHeader data-testid={productId}>
      <Table.CellImage alt={sourceAlt} sources={sources} />
      <div className={styles.container}>
        <p className={styles.brand}>{brand}</p>
        <p className={styles["product-name"]}>{name}</p>
        {contentsArray && (
          <small className={styles["product-pricing"]}>
            {contentsArray.map(contentString).join(" / ")}
          </small>
        )}
      </div>
      <div>
        {userHasWidget && (
          <div className={styles["controls-container"]}>
            <Button
              data-testid="open-product-comparison"
              border="none"
              shape="circle"
              height="short"
              onClick={handleOpenProductComparisonWidget}
            >
              <CompareIcon
                border="none"
                color="neutral"
                shape="circle"
                label={`Open comparison widget for product ${name} `}
              />
            </Button>
          </div>
        )}
      </div>
    </Table.RowHeader>
  )
}

ReferenceProductRowHeader.propTypes = {
  brand: PropTypes.string,
  contents_v1: PropTypes.objectOf(
    PropTypes.shape({
      approximate: PropTypes.bool,
      multiplier: PropTypes.number,
      parsed: PropTypes.bool,
      string: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  images: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
}

ReferenceProductRowHeader.defaultProps = {
  brand: "",
  contents_v1: undefined,
  images: [],
}

function ReferenceProductRowHeaderPlaceholder() {
  return (
    <Table.RowHeader>
      <ReactPlaceholder
        className="rounded"
        showLoadingAnimation
        style={{ width: "80%", height: "80%", margin: "0.8rem auto" }}
        type="rect"
      />
      <ReactPlaceholder
        className="rounded"
        rows={3}
        showLoadingAnimation
        style={{ width: "80%" }}
        type="text"
      />
    </Table.RowHeader>
  )
}

function MatchesOverviewReferenceProduct({ productId }) {
  const { data, error, isIdle, isFetching, isError, isRefetching, isSuccess } =
    useReferenceProduct(productId)

  if (isIdle || isFetching) {
    return <ReferenceProductRowHeaderPlaceholder />
  }

  if (isError) {
    Sentry.captureException(error)

    return (
      <div>
        <p className="text-bold">Oops. Something went wrong.</p>
        <p>Rest assured. We will investigate.</p>
      </div>
    )
  }

  if (isSuccess || isRefetching) {
    return <ReferenceProductRowHeader {...data} productId={productId} />
  }

  return null
}

MatchesOverviewReferenceProduct.propTypes = {
  productId: PropTypes.string.isRequired,
}

export default MatchesOverviewReferenceProduct
