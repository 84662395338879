export function isBackOrForwardUserEvent(locationChangeEvent) {
  return ["PUSH", "POP"].includes(locationChangeEvent.payload?.action)
}

export function isInPage(pagePath, state) {
  const {
    router: {
      location: { pathname = "" },
    },
  } = state

  return pathname.startsWith(pagePath)
}

export function isUserAuthenticated(state) {
  const {
    user: { user },
  } = state

  return user !== undefined
}

export function withParent(parentAction, action) {
  return {
    ...action,
    parent: parentAction,
  }
}
