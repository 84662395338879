import { defaultTo } from "lodash"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// // or:
// {Object} myObj
// {number} myObj.a
// {string} myObj.b
// {*} myObj.c

/**
 * Custom React hook for managing asynchronous slices of Redux state.
 *
 * @param {object} config - The hook configuration object
 * @param {Record<string, unknown>} config.action - A Redux action
 * @param {boolean} config.immediate - When true (default) the action is dispatched immediatly when hook is mounted
 * @param {(s: DefaultRootState) => { data: unknown; error: Error | null; status: string; }} config.selector A function that retrieves a state slice from the current Redux state
 * @returns {{
 *   data: unknown;
 *   dispatch: (action: { [key: string]: any }) => void;
 *   error: Error | null;
 *   isError: boolean;
 *   isFetching: boolean;
 *   isIdle: boolean;
 *   isPending: boolean;
 *   isReady: boolean;
 *   isRefetching: boolean;
 *   isSuccess: boolean;
 *   status: string;
 *   run: () => void;
 * }}
 */
export default function useAsyncSlice({ action, immediate = true, selector }) {
  const dispatch = useDispatch()

  const { data, error, status } = useSelector((state) =>
    defaultTo(selector(state), {
      data: undefined,
      error: undefined,
      status: "idle",
    }),
  )

  const run = useCallback(() => {
    dispatch(action)
  }, [action, dispatch])

  useEffect(() => {
    if (immediate) {
      run()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasData = data !== undefined

  const isError = status === "rejected"
  const isIdle = status === "idle"
  const isPending = status === "pending"
  const isSuccess = status === "resolved"

  const isFetching = isPending && !hasData
  const isReady = isSuccess || hasData
  const isRefetching = isPending && hasData

  return {
    data,
    dispatch,
    error,
    isError,
    isFetching,
    isIdle,
    isPending,
    isReady,
    isRefetching,
    isSuccess,
    status,
    run,
  }
}
