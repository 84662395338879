import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"
import mixpanel from "mixpanel-browser"
import { generateId, slugify } from "../../util/strings"
import { KEY_DEMO_USER, getSetting, mergeSettings } from "../../util/local-storage"
import { buildDemoUserEmail } from "../../util/user"

import "./style.sass"

const KEY_DEMO_CUSTOMER = "demo-customer"

function DemoUserTracker({ match: { params: { customer } = {} } = {} }) {
  const id = getSetting(KEY_DEMO_USER) || `user-${generateId(16)}--${slugify(customer)}`
  const email = buildDemoUserEmail(id)

  useEffect(() => {
    mergeSettings({
      [KEY_DEMO_USER]: id,
    })

    mixpanel.register({
      [KEY_DEMO_USER]: email,
      [KEY_DEMO_CUSTOMER]: customer,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Redirect to="/" />
}

export default DemoUserTracker
