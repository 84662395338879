import React, { useEffect, useRef, useState, useCallback } from "react"
import PropTypes from "prop-types"
import useRestricted from "../../hooks/use-restricted"
import { PERM_UPDATE_MATCHES } from "../../util/permissions"
import MultiSelect from "../multi-select/MultiSelect"
import "./style.sass"
import useCustomerMatchLabels from "../../hooks/use-customer-match-labels"
import { stringToColor } from "../../util/color"

function multiSelectRenderer(label, selected, renderOptions) {
  if (selected.length === 0) {
    return <div className="labels-label">{label}</div>
  }

  return (
    <div className="labels-root">
      {selected
        .slice()
        .sort()
        .map((value) => (
          <span
            key={value}
            className="dx-label"
            style={{
              backgroundColor:
                value === "do-not-align" ? "#999999" : stringToColor(value),
            }}
          >
            {/**
             * In case a customer requests the deletion of a label.
             * Some some products might still have that label.
             * We need to handle these cases by returning a null render
             */}
            {renderOptions.find(
              ({ label: optLabel, value: optValue }) =>
                optValue === value || optLabel === value,
            )?.label ?? null}
          </span>
        ))}
    </div>
  )
}

function QualityIndicator({ comment, labels, onCommentChange, onLabelsChange }) {
  const commentTextAreaRef = useRef(null)

  const customerQualityLabels = useCustomerMatchLabels()

  const options = customerQualityLabels.map(({ alias, slug }) => ({
    label: alias,
    value: slug,
  }))

  const optionSlugs = customerQualityLabels.map((x) => x.slug)

  const contemporaryLabels = labels.filter((label) => optionSlugs.includes(label))

  const { hasAccess: isUpdater } = useRestricted({
    requiredPermissions: PERM_UPDATE_MATCHES,
  })

  const [commentText, setCommentText] = useState(comment)
  const [editingComment, setEditingComment] = useState(false)

  const handleCommentChange = useCallback(
    ({ target: { value } }) => setCommentText(value),
    [],
  )

  const handleBlur = useCallback(() => {
    if (commentText !== comment) {
      const finalComment = commentText.trim() === "" ? "" : commentText
      onCommentChange(finalComment)
      setCommentText(finalComment)
    }
    setEditingComment(false)
  }, [commentText, comment, onCommentChange])

  const handlerCommentClick = useCallback(() => {
    if (isUpdater) {
      setEditingComment(true)
    }
  }, [isUpdater])

  useEffect(() => {
    if (commentTextAreaRef.current && editingComment) {
      commentTextAreaRef.current.focus()
    }
  }, [editingComment])

  const showCommentInput = editingComment || !comment

  const showQualityIndicator = isUpdater || comment || contemporaryLabels.length > 0

  if (!showQualityIndicator) {
    return null
  }

  return (
    <div
      className={`quality-indicator-root ${isUpdater ? "editable" : ""}`}
      data-testid="match-quality-labels-root"
    >
      <MultiSelect
        className={`labels ${contemporaryLabels.length === 0 ? "not-set" : ""}`}
        label="select labels"
        value={contemporaryLabels}
        optionKey={({ value }) => value}
        optionLabelRenderer={({ label }) => label}
        options={options}
        displaySelectAllOption={false}
        onChange={onLabelsChange}
        renderValue={multiSelectRenderer}
        selectProps={{
          readOnly: !isUpdater,
        }}
      />
      <div className="comment-container">
        {showCommentInput && (
          <textarea
            ref={commentTextAreaRef}
            className={`comment ${!commentText ? "not-set" : ""}`}
            placeholder="Add a comment"
            value={commentText}
            rows={2}
            onChange={handleCommentChange}
            onPaste={handleCommentChange}
            onBlur={handleBlur}
          />
        )}

        {!showCommentInput && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className="comment"
            onClick={handlerCommentClick}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handlerCommentClick(event)
              }
            }}
            title={isUpdater ? "Click to edit" : ""}
          >
            <div className="label">Comment:</div>
            <div className="text">{comment}</div>
          </div>
        )}
      </div>
    </div>
  )
}

QualityIndicator.propTypes = {
  comment: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  onCommentChange: PropTypes.func,
  onLabelsChange: PropTypes.func,
}

QualityIndicator.defaultProps = {
  comment: "",
  labels: [],
  onCommentChange: () => undefined,
  onLabelsChange: () => undefined,
}

export default QualityIndicator
