import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import "./AppArea.scss"

function AppArea({ children, className, ...props }) {
  return (
    <Box {...props} className={`app-area ${className}`}>
      {children}
    </Box>
  )
}

AppArea.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

AppArea.defaultProps = {
  className: "",
}

export { AppArea }
