import React from "react"

import styles from "./LoaderSpinner.module.scss"

export function LoaderSpinner() {
  return (
    <div className={styles.canvas}>
      <div className={styles.spinner} />
    </div>
  )
}
