import PropTypes from "prop-types"
import React from "react"

import * as RUISelect from "@radix-ui/react-select"

import styles from "./BaseSelectTrigger.module.scss"

const BaseSelectTrigger = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <RUISelect.Trigger {...props} className={styles.trigger} ref={forwardedRef}>
    {children}
  </RUISelect.Trigger>
))

BaseSelectTrigger.displayName = "BaseSelectTrigger"

BaseSelectTrigger.propTypes = {
  children: PropTypes.node.isRequired,
}

export { BaseSelectTrigger }
