/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { IconPackageOff } from "@tabler/icons-react"
import clsx from "clsx"
import Image from "../../img/Image"

import styles from "./TableCellImage.module.scss"

function TableCellImage({ alt, className, sources, wrapper: Wrapper, wrapperProps }) {
  return (
    <div className={clsx(styles.container, className)}>
      <Wrapper {...wrapperProps}>
        {sources === null ? (
          <IconPackageOff size={64} color="#aaa" />
        ) : (
          <Image className={styles.image} alt={alt} src={sources} />
        )}
      </Wrapper>
    </div>
  )
}

TableCellImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  wrapper: PropTypes.symbol,
  wrapperProps: PropTypes.object,
  sources: PropTypes.arrayOf(PropTypes.string),
}

TableCellImage.defaultProps = {
  alt: undefined,
  className: "",
  sources: null,
  wrapper: Fragment,
  wrapperProps: {},
}

export default TableCellImage
