function getHeightVirtualRow(row) {
  return `${row.size}px`
}

function getTransformVirtualRowY(row, rowIndex) {
  return `translateY(${row.start - row * rowIndex.size}px)`
}

/**
 * When virtualising rows you might need to shift the virtualised rows positions, for
 * various reasons. Use this approach when you prefer to shift every element using
 * a transform operation in the Y axis. Otherwise use `computeVirtualRowFillerPaddings`.
 *
 * ```js
 * return (
 *   <>
 *    {virtualRows.map((virtualRow, virtualRowIndex) => {
 *      const row = rows[virtualRow.index]
 *
 *       return (
 *        <tr
 *          key={row.id}
 *          className={styles.row}
 *          style={getVirtualRowStyles((virtualRow, virtualRowIndex))}
 *        >
 *          {row.getVisibleCells().map((cell) => (
 *            <td key={cell.id} className={styles.cell}>
 *              {flexRender(cell.column.columnDef.cell, cell.getContext())}
 *            </td>
 *          ))}
 *        </tr>
 *      )
 *    })}
 *  />
 * )
 *```
 */
export function getVirtualRowStyles(row, rowIndex) {
  return {
    height: getHeightVirtualRow(row),
    transform: getTransformVirtualRowY(row, rowIndex),
  }
}

/**
 * When virtualising rows you might need to shift the virtualised rows positions, for
 * various reasons. Use this approach when you prefer to add up to two filler rows that
 * guarantee the proper top and bottom shifts of the actual virtual items. Otherwise
 * use `getVirtualRowStyles`.
 *
 * ```js
 * const [paddingTop, paddingBottom] = computeVirtualRowFillerPaddings(totalSize, virtualRows)
 *
 * return (
 *   <>
 *     {paddingTop > 0 && (
 *       <tr data-testid="tr-virtual-padding-top">
 *         <td style={{ height: `${paddingTop}px` }} />
 *       </tr>
 *     )}
 *     {virtualRows.map((virtualRow) => (
 *       <tr
 *         key={row.id}
 *         className={styles.row}
 *       >
 *         {row.getVisibleCells().map((cell) => (
 *           <td key={cell.id} className={styles.cell}>
 *             {flexRender(cell.column.columnDef.cell, cell.getContext())}
 *           </td>
 *         ))}
 *       </tr>
 *     ))
 *     {paddingBottom > 0 && (
 *       <tr data-testid="tr-virtual-padding-bottom">
 *         <td style={{ height: `${paddingBottom}px` }} />
 *       </tr>
 *     )}
 *   </>
 * )
 *```
 */
export function computeVirtualRowFillerPaddings(totalSize, virtualRows) {
  let top = 0
  let bottom = 0

  /** @see https://tanstack.com/virtual/v3/docs/api/virtual-item */
  if (virtualRows.length > 0) {
    const firstRow = virtualRows[0]
    const lastRow = virtualRows[virtualRows.length - 1]

    top = firstRow.start
    bottom = totalSize - lastRow.end
  }

  return [top, bottom]
}
