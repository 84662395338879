import * as MatchedProductsRepositoryActions from "./action-types"

export function getOneMatchedProductById({ id, invalidateCache = false }) {
  return {
    type: MatchedProductsRepositoryActions.MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
    id,
    invalidateCache,
  }
}

export function getMatchedProductByIds({ ids, invalidateCache = false }) {
  return {
    type: MatchedProductsRepositoryActions.MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS,
    ids,
    invalidateCache,
  }
}
