import { isString } from "./strings"

// TODO @pcmnac this code returns 0 and "" if value is 0 or ""
export function forceArray(value) {
  return !value || Array.isArray(value) ? value : [value]
}

export function isArray(value) {
  return value instanceof Array
}

export function toBidimensionalStringArray(input) {
  let result = input

  if (isString(result)) {
    result = [[result]]
  } else if (isArray(result) && result.every(isString)) {
    result = [result]
  }

  return result
}

/**
 * Finds all elements that were added and all elements that were removed.
 *
 * @param {Array<string>} beforeArray
 * @param {Array<string>} afterArray
 *
 * @returns {{ added: string[], removed: string[]}}
 *  The values that were added and removed
 */
export function diffChanges(beforeArray = [], afterArray = []) {
  return {
    added: afterArray.filter((x) => !beforeArray.includes(x)),
    removed: beforeArray.filter((x) => !afterArray.includes(x)),
  }
}

/**
 * Finds the element that was changed between the provided arrays.
 *
 * It assumes the provided arrays differ by exactly one element.
 *
 * Raises Error when there is no diff or there are multiple diffs
 *
 * @param {Array<string>} beforeArray
 * @param {Array<string>} afterArray
 *
 * @returns {["added" | "removed", string]} The value that changed with respect
 *  to the previous tick and whether it was 'added' or 'removed' from the reference list
 */
export function diffChange(beforeArray, afterArray) {
  const { added, removed } = diffChanges(beforeArray, afterArray)

  if (added.length === 1 && removed.length === 0) {
    return ["added", added[0]]
  }

  if (added.length === 0 && removed.length === 1) {
    return ["removed", removed[0]]
  }

  if (added.length > 0 && removed.length > 0) {
    return ["added-and-removed", ["added", ...added, "removed", ...removed].toString()]
  }

  throw Error(
    `diffChange: expected single diff in either added or removed, but got ([added], [removed]): ([${added}], [${removed}])`,
  )
}

/**
 * @returns {Array<unknown> | undefined} the input array with at least one element, or undefined
 */
export function arrayWithElementsOrUndefined(array) {
  if (Array.isArray(array)) {
    return array.length > 0 ? array : undefined
  }

  return undefined
}
