import { useState, useCallback } from "react"
import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import {
  PRODUCT_COMPARISON_WIDGET_COMPARABLE_KEY,
  PRODUCT_COMPARISON_WIDGET_KEY,
  PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY,
} from "../../../ducks/widgets/product-comparison/constants"
import { setProductComparisonComparables } from "../../../ducks/widgets/product-comparison/action-creators"
import * as ProductComparisonActions from "../../../ducks/widgets/product-comparison/action-creators"
import { listProductsWithMatchesByIds, listExistingMatches } from "../../../api/service"

async function listProducts({ refProductId, productsToCompare, filters }) {
  if (productsToCompare.length > 0) {
    return listProductsWithMatchesByIds({
      referenceProductId: refProductId,
      ids: productsToCompare,
      ...filters,
    })
  }

  const { matches } = await listExistingMatches(refProductId, {
    withDiscarded: true,
    ...filters,
  })

  if (matches.length === 0) {
    return {}
  }

  const allMatchesIds = matches.map((m) => m.daltix_id)

  return listProductsWithMatchesByIds({
    referenceProductId: refProductId,
    ids: allMatchesIds,
    ...filters,
  })
}

export default function useProductComparisonCompare() {
  const dispatch = useDispatch()
  const [products, setProducts] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const comparableProducts = useSelector(
    ({ widgets }) =>
      widgets[PRODUCT_COMPARISON_WIDGET_KEY][PRODUCT_COMPARISON_WIDGET_COMPARABLE_KEY],
  )

  const setComparableProducts = (ids = []) => {
    dispatch(setProductComparisonComparables(ids))
  }

  const close = () => {
    setProducts({})
    setComparableProducts([])
  }

  const open = (referenceProductId, ids) => {
    setComparableProducts(ids)
    dispatch(
      ProductComparisonActions.openProductComparisonWidget({
        referenceProductId,
      }),
    )
  }

  const refProductId = useSelector((state) =>
    get(state, [
      "widgets",
      PRODUCT_COMPARISON_WIDGET_KEY,
      PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY,
    ]),
  )

  const getProducts = useCallback(
    async (filters = {}) => {
      setLoading(true)
      setError(undefined)
      try {
        const newProducts = await listProducts({
          refProductId,
          productsToCompare: comparableProducts.map((p) => p.daltixId),
          filters,
        })
        setProducts(newProducts || {})
      } catch (e) {
        setError(e)
      }
      setLoading(false)
    },
    [comparableProducts, refProductId],
  )

  return {
    setComparableProducts,
    refProductId,
    getProducts,
    products,
    loading,
    error,
    open,
    onlyMatches: comparableProducts.length === 0,
    close,
    isOpen: !!refProductId,
  }
}
