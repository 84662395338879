export function getQueryParam(param) {
  const cleanParam = param.replace(/[[]/, "\\[").replace(/[\]]/, "\\]")

  const regexS = `[\\?&]${cleanParam}=([^&#]*)`
  const regex = new RegExp(regexS)
  const results = regex.exec(document.URL)

  if (
    results === null ||
    (results && typeof results[1] !== "string" && results[1].length)
  ) {
    return ""
  }

  return decodeURIComponent(results[1]).replace(/\+/g, " ")
}
