function hashCode(str) {
  let hash = 0

  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  return hash
}

export function stringToColor(str) {
  return `hsl(${hashCode(str) % 360}, 100%, 30%)`
}
