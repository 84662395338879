/* eslint-disable react/prop-types */
import React from "react"
import { connect } from "react-redux"

import AddMatchesPanelPresenter from "./AddMatchesPanelPresenter"

function AddMatchesPanel(props) {
  const { noFilterTotalSearchResults, lastSearchTerm } = props

  return (
    <AddMatchesPanelPresenter
      {...props}
      lastSearchTerm={lastSearchTerm}
      noFilterTotalSearchResults={noFilterTotalSearchResults}
    />
  )
}

const mapStateToProps = ({
  daltixProduct: { noFilterTotalSearchResults, lastSearchTerm },
}) => ({
  noFilterTotalSearchResults,
  lastSearchTerm,
})

export default connect(mapStateToProps)(AddMatchesPanel)
