import React from "react"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import ListSubheader from "@mui/material/ListSubheader"
import Typography from "@mui/material/Typography"
import Close from "@mui/icons-material/Close"

import styles from "./BaseSlideoverRoot.module.scss"

function BaseSlideoverRoot({ children, actions, open, title, onClose, ...props }) {
  const handleUserKeyPress = React.useCallback(
    (event) => {
      if (event.key === "Escape" && open) {
        onClose()
      }
    },
    [onClose, open],
  )

  React.useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  return (
    <Drawer
      {...props}
      open={open}
      anchor="right"
      onClose={onClose}
      classes={{
        paper: styles.paper,
      }}
    >
      <ListSubheader sx={{ paddingRight: 1 }}>
        <div className={styles.header}>
          <Typography align="center" variant="h6" className={styles.title}>
            {title}
          </Typography>
          <Box>
            {actions}
            <IconButton aria-label="Close" onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
        </div>
        <Divider />
      </ListSubheader>
      {children}
    </Drawer>
  )
}

BaseSlideoverRoot.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
}

BaseSlideoverRoot.defaultProps = {
  children: null,
  actions: null,
  title: "",
}

export { BaseSlideoverRoot }
