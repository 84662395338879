export function integerToRoman(num) {
  if (typeof num !== "number") return false

  const digits = String(+num).split("")

  const key = [
    "",
    "C",
    "CC",
    "CCC",
    "CD",
    "D",
    "DC",
    "DCC",
    "DCCC",
    "CM",
    "",
    "X",
    "XX",
    "XXX",
    "XL",
    "L",
    "LX",
    "LXX",
    "LXXX",
    "XC",
    "",
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
  ]
  let romanNumber = ""
  let i = 3

  // eslint-disable-next-line no-plusplus
  while (i--) {
    const digit = +digits.pop() + i * 10

    romanNumber = (key[digit] || "") + romanNumber
  }

  return Array(+digits.join("") + 1).join("M") + romanNumber
}
