import React, { useCallback, useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import mixpanel from "mixpanel-browser"

import { actionCreators as refProductActions } from "../../ducks/reference-products"
import { actionCreators as daltixProductActions } from "../../ducks/daltix-product"
import { actionCreators as matchProductActions } from "../../ducks/matches"
import MatchesReviewItemPresenter, {
  MatchesReviewItemFallbackPresenter,
} from "./MatchesReviewItemPresenter"
import { ORIGIN_REVIEW } from "../../util/match-origin"
import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
} from "../../util/match-status"
import { isValidRefProduct, isValidDxProduct } from "../../util/product"
import { withErrorBoundary } from "../../hocs/error-boundary"
import { NULL } from "../../util/constants"

function MatchesReviewItem({
  id,
  referenceProductGroupId,
  daltixProductId,
  // rest
  ...props
}) {
  const { mult_factor: multFactor } = props
  const dispatch = useDispatch()

  const { referenceProducts, daltixProducts, matches, selectedMatchStatuses } =
    useSelector(
      ({
        refProduct: refProductStore,
        matches: matchesStore,
        daltixProduct: daltixProductStore,
      }) => ({
        daltixProducts: daltixProductStore.daltixProducts,
        matches: matchesStore.matches,
        referenceProducts: refProductStore.referenceProducts,
        selectedMatchStatuses: matchesStore.selectedMatchStatuses.filter(
          (status) => status !== NULL,
        ),
      }),
      shallowEqual,
    )

  const match = matches[id] || {}

  const referenceProduct = referenceProducts[referenceProductGroupId]
  const daltixProduct = daltixProducts[daltixProductId]

  useEffect(() => {
    if (id && (!match.ready || match.error)) {
      dispatch(matchProductActions.getMatchesByIds({ ids: [id] }))
    }
  }, [dispatch, id, match.error, match.ready])

  useEffect(() => {
    if (
      referenceProductGroupId &&
      (!referenceProduct || !isValidRefProduct(referenceProduct))
    ) {
      dispatch(refProductActions.getProductsByIds({ ids: [referenceProductGroupId] }))
    }
  }, [dispatch, referenceProduct, referenceProductGroupId])

  useEffect(() => {
    if (
      daltixProductId &&
      (!daltixProduct || (!isValidDxProduct(daltixProduct) && !daltixProduct.error))
    ) {
      dispatch(daltixProductActions.getDxProductsByIds([daltixProductId]))
    }
  }, [dispatch, daltixProduct, daltixProductId])

  const loadingRefProd = !referenceProduct
  const loadingDxProd = !daltixProduct
  const refProdError = referenceProduct && referenceProduct.error
  const dxProdError = daltixProduct && daltixProduct.error

  const { mult_factor: factor, status: currentStatus, labels, comment } = match

  const handleFactorChange = useCallback(
    (newFactor) => {
      dispatch(
        matchProductActions.updateMatchBasicInfo({
          source: ORIGIN_REVIEW,
          referenceProductId: referenceProductGroupId,
          daltixProductId,
          factor: newFactor,
          labels,
          comment,
        }),
      )
    },
    [dispatch, referenceProductGroupId, daltixProductId, labels, comment],
  )

  const handleLabelsChanged = useCallback(
    ({ target: { value } }) => {
      dispatch(
        matchProductActions.updateMatchBasicInfo({
          source: ORIGIN_REVIEW,
          referenceProductId: referenceProductGroupId,
          daltixProductId,
          factor,
          labels: value,
          comment,
        }),
      )
    },
    [dispatch, referenceProductGroupId, daltixProductId, factor, comment],
  )

  const handleCommentChanged = useCallback(
    (newComment) => {
      dispatch(
        matchProductActions.updateMatchBasicInfo({
          source: ORIGIN_REVIEW,
          referenceProductId: referenceProductGroupId,
          daltixProductId,
          factor,
          labels,
          comment: newComment,
        }),
      )
    },
    [dispatch, referenceProductGroupId, daltixProductId, factor, labels],
  )

  const handleApprove = useCallback(() => {
    if (multFactor === undefined || multFactor === null) {
      // if the factor is not set, set it to 1 when it's approved
      handleFactorChange(1)
    }
    dispatch(
      matchProductActions.approveMatch({
        source: ORIGIN_REVIEW,
        referenceProductId: referenceProductGroupId,
        daltixProductId,
        reloadList:
          selectedMatchStatuses.length > 0 &&
          !selectedMatchStatuses.includes(STATUS_APPROVED),
      }),
    )
  }, [
    multFactor,
    dispatch,
    referenceProductGroupId,
    daltixProductId,
    selectedMatchStatuses,
    handleFactorChange,
  ])

  const handleUndoApproval = useCallback(() => {
    dispatch(
      matchProductActions.removeMatchApproval({
        source: ORIGIN_REVIEW,
        referenceProductId: referenceProductGroupId,
        daltixProductId,
        reloadList:
          selectedMatchStatuses.length > 0 &&
          !selectedMatchStatuses.includes(STATUS_REVIEW),
      }),
    )
  }, [dispatch, referenceProductGroupId, daltixProductId, selectedMatchStatuses])

  const handleUnmatch = useCallback(() => {
    dispatch(
      matchProductActions.discardMatch({
        source: ORIGIN_REVIEW,
        referenceProductId: referenceProductGroupId,
        daltixProductId,
        reloadList:
          selectedMatchStatuses.length > 0 &&
          !selectedMatchStatuses.includes(STATUS_DISCARDED),
      }),
    )
  }, [dispatch, referenceProductGroupId, daltixProductId, selectedMatchStatuses])

  const handleRestore = useCallback(() => {
    dispatch(
      matchProductActions.restoreMatch({
        source: ORIGIN_REVIEW,
        referenceProductId: referenceProductGroupId,
        daltixProductId,
        reloadList:
          selectedMatchStatuses.length > 0 &&
          !selectedMatchStatuses.includes(STATUS_APPROVED),
      }),
    )
  }, [dispatch, referenceProductGroupId, daltixProductId, selectedMatchStatuses])

  const handleNavigateToReferenceProduct = useCallback(
    ({ refProdId, refProdName, refProdBrand }) => {
      mixpanel.track("MT - Inspect Match", {
        id: refProdId,
        name: refProdName,
        brand: refProdBrand,
      })

      dispatch(refProductActions.filterReferenceProductsById(refProdId))
    },
    [dispatch],
  )

  const handleNavigateToStore = useCallback(({ type, url, name }) => {
    mixpanel.track("See Product in Store", {
      origin: "review matches",
      type,
      url,
      name,
    })
  }, [])

  return (
    <MatchesReviewItemPresenter
      loadingRefProd={loadingRefProd}
      loadingDxProd={loadingDxProd}
      refProdError={refProdError}
      dxProdError={dxProdError}
      referenceProduct={referenceProduct}
      daltixProduct={daltixProduct}
      onApprove={handleApprove}
      onUndoApproval={handleUndoApproval}
      onUnmatch={handleUnmatch}
      onRestore={handleRestore}
      onFactorChange={handleFactorChange}
      onReferenceProductClick={handleNavigateToReferenceProduct}
      onNavigateToStore={handleNavigateToStore}
      onLabelsChange={handleLabelsChanged}
      onCommentChange={handleCommentChanged}
      mult_factor={factor}
      status={currentStatus}
      labels={labels}
      comment={comment}
      {...match}
      {...props}
    />
  )
}

const ErrorBoundaryComponent = withErrorBoundary(
  MatchesReviewItem,
  MatchesReviewItemFallbackPresenter,
)

export default ErrorBoundaryComponent
