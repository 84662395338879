import PropTypes from "prop-types"
import React from "react"

import * as RUISelect from "@radix-ui/react-select"

import styles from "./BaseSelectItem.module.scss"
import { CheckIcon } from "../../Icon/CheckIcon"

const BaseSelectItem = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <RUISelect.Item {...props} className={styles.item} ref={forwardedRef}>
    <RUISelect.ItemIndicator className={styles.indicator}>
      <CheckIcon border="none" size="sm" color="neutral" label="" />
    </RUISelect.ItemIndicator>
    <RUISelect.ItemText>{children}</RUISelect.ItemText>
  </RUISelect.Item>
))

BaseSelectItem.displayName = "BaseSelectItem"

BaseSelectItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export { BaseSelectItem }
