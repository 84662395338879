import PropTypes from "prop-types"
import React from "react"

import * as RUISelect from "@radix-ui/react-select"

import clsx from "clsx"

import styles from "./BaseSelectContent.module.scss"

function BaseSelectContent({ children, className, ...props }) {
  return (
    <RUISelect.Content {...props} className={clsx(styles.content, className)}>
      {children}
    </RUISelect.Content>
  )
}

BaseSelectContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

BaseSelectContent.defaultProps = {
  className: "",
}

export { BaseSelectContent }
