import { toAsyncSuccessSlice } from "../../../../util/redux-helpers"
import {
  MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_OUTLINE_KEY,
} from "../constants"

function getUniqFromShopKey(allowedShops, shopKey) {
  const uniqueCountries = new Set()

  Object.values(allowedShops).forEach((shop) => {
    uniqueCountries.add(shop[shopKey])
  })

  return Array.from(uniqueCountries)
}

export function handleUserProfileRetrieved(state, { parent: _, payload }) {
  const {
    user: { customer },
  } = payload

  /** customer is only available after selection in /select-account */
  const allowedShops = customer?.allowedShops ?? {}

  const countries = getUniqFromShopKey(allowedShops, "country")
  const shops = getUniqFromShopKey(allowedShops, "shop")

  return {
    ...state,
    [MATCHES_OVERVIEW_PAGE_FILTERS_KEY]: {
      ...state.filters,
      [MATCHES_OVERVIEW_PAGE_FILTERS_OUTLINE_KEY]: {
        ...state.filters.outline,
        countries: toAsyncSuccessSlice(countries),
        shops: toAsyncSuccessSlice(shops),
      },
    },
  }
}
