import PropTypes from "prop-types"
import React from "react"
import ReactPlaceholder from "react-placeholder"
import { Table } from "../ui"
import useMatchedProduct from "../../hooks/use-matched-product"
import useDaltixProduct from "../../hooks/use-daltix-product"

function MatchCell({
  isProductAvailable,
  matchPriceFactor,
  productImages,
  productName,
  productSellingPrice,
}) {
  const hasSources = Array.isArray(productImages) && !!productImages[0]
  const sources = hasSources ? productImages : null
  const sourceAlt = `${productName ?? "unknown product"} image`

  return (
    <Table.CellItem availability={isProductAvailable ? "available" : "unavailable"}>
      <Table.CellImage alt={sourceAlt} sources={sources} />
      {Number.isFinite(productSellingPrice) ? (
        <Table.CellItemPrice factor={matchPriceFactor} price={productSellingPrice} />
      ) : null}
    </Table.CellItem>
  )
}

MatchCell.propTypes = {
  isProductAvailable: PropTypes.bool.isRequired,
  matchPriceFactor: PropTypes.number,
  productImages: PropTypes.arrayOf(PropTypes.string),
  productName: PropTypes.string,
  productSellingPrice: PropTypes.number,
}

MatchCell.defaultProps = {
  matchPriceFactor: null,
  productImages: null,
  productName: "",
  productSellingPrice: null,
}

function MatchCellPlaceholder() {
  return (
    <Table.CellItem>
      <ReactPlaceholder
        className="rounded"
        showLoadingAnimation
        style={{
          width: "80%",
          height: "80%",
          margin: "0.8rem auto",
        }}
        type="rect"
      />
    </Table.CellItem>
  )
}

function MatchCellError({ error }) {
  return (
    <div>
      <p>
        <strong>{error.message}</strong>
      </p>
    </div>
  )
}

MatchCellError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
}

MatchCellError.defaultProps = {
  error: new Error(
    "Unknown error occurrend when trying to render: `MatchesOverviewShopMatchesListItem`",
  ),
}

function MatchesOverviewShopMatchesListItem({ daltixId, matchId }) {
  const matchProduct = useMatchedProduct(matchId || "")
  const daltixProduct = useDaltixProduct(daltixId)

  const isError = matchProduct.isError || daltixProduct.isError
  const isSuccess = matchProduct.isSuccess && daltixProduct.isSuccess

  if (isError) {
    return <MatchCellError error={matchProduct.error ?? daltixProduct.error} />
  }

  if (isSuccess) {
    const { mult_factor: factor } = matchProduct.data

    const { name, images, price, outdated } = daltixProduct.data

    return (
      <MatchCell
        isProductAvailable={!outdated}
        matchPriceFactor={factor}
        productName={name}
        productImages={images}
        productSellingPrice={price}
      />
    )
  }

  return <MatchCellPlaceholder />
}

MatchesOverviewShopMatchesListItem.propTypes = {
  daltixId: PropTypes.string.isRequired,
  matchId: PropTypes.string.isRequired,
}

export default MatchesOverviewShopMatchesListItem
