import React from "react"
import PropTypes from "prop-types"

import styles from "./ProductComparisonTableHeaderContainer.module.scss"

function ProductComparisonTableHeaderContainer({ children, testID }) {
  return (
    <th className={styles.header} data-testid={testID}>
      <div className={styles.container}>{children}</div>
    </th>
  )
}

ProductComparisonTableHeaderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  testID: PropTypes.string,
}

ProductComparisonTableHeaderContainer.defaultProps = {
  testID: "",
}

export { ProductComparisonTableHeaderContainer }
