import { createSearchParams } from "./createSearchParams"

export function numberQuery(key, fallback, isFloat = false) {
  const maybeNumber = createSearchParams().get(key)

  const parsedNumber = isFloat ? parseFloat(maybeNumber) : parseInt(maybeNumber, 10)

  if (Number.isNaN(parsedNumber)) {
    return fallback
  }

  return parsedNumber
}
