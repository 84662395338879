import React from "react"
import PropTypes from "prop-types"

function TablePad({ height, ...props }) {
  return (
    <tr {...props}>
      <td style={{ height: `${height}px` }} aria-hidden="true" />
    </tr>
  )
}

TablePad.propTypes = {
  height: PropTypes.number.isRequired,
}

export default TablePad
