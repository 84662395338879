import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"

import styles from "./BaseDialogHeader.module.scss"

function BaseDialogHeader({ className, children }) {
  return <header className={clsx(styles.header, className)}>{children}</header>
}

BaseDialogHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

BaseDialogHeader.defaultProps = {
  className: "",
}

export { BaseDialogHeader }
