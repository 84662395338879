import PropTypes from "prop-types"
import React from "react"

import { lowerCase, sentenceCase } from "text-case"

import styles from "./AssortmentAnalysisColumnHeaderDimension.module.scss"

import {
  DIMENSION_BRAND_TYPE,
  DIMENSION_PRICE,
} from "../../../../ducks/pages/assortment-analysis/constants"
import { Icon } from "../../../ui/Icon/Icon"
import { SORT_ASC, SORT_DESC } from "../../../../util/sorting"
import { useDimensionSort } from "../../hooks"

import { buildAnalyticsEventName } from "../../../../util/user-monitoring"
import { Button } from "../../../ui"

const iconContraryLookup = {
  [SORT_ASC]: Icon.SortDESC,
  [SORT_DESC]: Icon.SortASC,
}

const analyticsEventName = buildAnalyticsEventName({
  feature: "Assortment analysis",
  target: "Dimension Sort Direction Switch",
  userAction: "Clicked",
})

function AssortmentAnalysisColumnHeaderDimension({ dimensionType }) {
  const { dimensionDirection, setDimensionDirection } = useDimensionSort()

  const Sort = iconContraryLookup[dimensionDirection]

  return (
    <>
      <span className={styles.dimension}>{sentenceCase(dimensionType)}</span>
      <Button
        analyticsEventName={analyticsEventName}
        border="none"
        color="neutral"
        height="short"
        shape="circle"
        onClick={setDimensionDirection}
      >
        <Sort
          border="none"
          color="neutral"
          label={`Change ${lowerCase(dimensionType)} sort direction`}
        />
      </Button>
    </>
  )
}

AssortmentAnalysisColumnHeaderDimension.propTypes = {
  dimensionType: PropTypes.oneOf([DIMENSION_BRAND_TYPE, DIMENSION_PRICE]).isRequired,
}

export { AssortmentAnalysisColumnHeaderDimension }
