import { get } from "lodash"
import useAsyncSlice from "./use-async-slice"
import { getOneReferenceProductById } from "../ducks/repositories/reference-products/action-creators"

export default function useReferenceProduct(id) {
  const action = getOneReferenceProductById({ id, invalidateCache: false })

  function selector(state) {
    return get(state, ["repositories", "ReferenceProductsRepository", "documents", id])
  }

  return useAsyncSlice({
    action,
    selector,
  })
}
