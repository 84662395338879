import React, { useMemo } from "react"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import BrandIcon from "@material-ui/icons/LocalOfferRounded"

import { titleCase, contentString } from "../../util/strings"
import { currencyToSymbol } from "../../util/currencies"
import { getContentsV1AsArray } from "../../util/volumes"
import useShop from "../../hooks/use-shop"
import EANList from "../ean-list/EANList"

import "./ProductCard.sass"

function Listing({
  name,
  shop,
  country,
  price,
  normalizedPrice,
  normalizedUnit,
  currency = "eur",
  unit_price_currency = "eur",
  url,
  onNavigateToStore,
}) {
  const { name: friendlyShopName } = useShop(shop)

  const currency_symbol = currencyToSymbol(currency)
  const unit_currency_symbol = currencyToSymbol(unit_price_currency)
  const mainPrice = price || normalizedPrice
  const showPrice = price || (normalizedPrice && normalizedUnit)

  return (
    <div className="listing">
      <span className="shop">
        {shop && friendlyShopName}{" "}
        <span className="country">{country && `(${country})`}</span>
        {url && (
          <>
            {" "}
            <a
              href={url}
              onClick={() => onNavigateToStore({ url, name })}
              target="product_source"
            >
              view
              <i className="dx-icon-external-link" />
            </a>{" "}
          </>
        )}
      </span>
      <Typography align="center" variant="h5" className="price">
        {showPrice && (
          <span className="main-price">
            {currency_symbol} {mainPrice.toFixed(2)}
            {price ? "" : `/${normalizedUnit}`}
          </span>
        )}
        {normalizedPrice && normalizedUnit && normalizedPrice !== mainPrice && (
          <>
            <span className="normalized-price">
              {`${unit_currency_symbol} `}
              {normalizedPrice.toFixed(2)}/
              {normalizedUnit.toLowerCase() === "su" ? "unit" : normalizedUnit}
            </span>
          </>
        )}
      </Typography>
    </div>
  )
}

function ProductCardPresenter({
  id,
  name,
  brand,
  contents_v1: contentsV1,
  article_nr,
  listings = [],
  eans = [],
  description,
  ingredients,
  images,
  slideDirection,
  onNavigateToStore,
}) {
  const brandText = brand ? titleCase(brand) : "Unknown"

  const contentsToDisplay = useMemo(
    () => (contentsV1 ? getContentsV1AsArray(contentsV1) : []),
    [contentsV1],
  )

  const contentsText = useMemo(
    () => contentsToDisplay.map(contentString).join(" / "),
    [contentsToDisplay],
  )

  return (
    <Card className="product-card fill-height fill-height-container">
      <CardHeader
        key={id}
        className="header fade-in"
        classes={{
          title: "title",
          subheader: "subheader",
        }}
        title={
          <span>
            <span className="brand" title={`Brand: ${brandText}`}>
              <BrandIcon fontSize="inherit" className="brand-icon" />
              {brandText}
              {process.env.NODE_ENV !== "production" && (
                <>
                  <br />
                  {id}
                </>
              )}
              <br />
            </span>
            {titleCase(name)}
          </span>
        }
        subheader={<div>{contentsText}</div>}
      />
      <CardContent className="fill-height fill-height-container">
        <CardMedia
          key={`${id}_media`}
          className={`picture fill-height slide-${slideDirection}`}
          image={images}
          title={`${titleCase(name)}`}
        />
        <div key={`${id}_text`} className="fade-in">
          {listings.length > 0 && (
            <>
              <div className="listings">
                {listings.map((listing) => (
                  <Listing
                    key={listing.id}
                    name={name}
                    onNavigateToStore={onNavigateToStore}
                    {...listing}
                  />
                ))}
              </div>
            </>
          )}

          {(eans.length > 0 || article_nr) && (
            <div className="ean-container">
              {eans.length > 0 && <EANList eans={eans} />}

              {article_nr && (
                <span className="article-nr" title="Article Number">
                  <span className="label">REF:</span>
                  <span className="value">{article_nr}</span>
                </span>
              )}
            </div>
          )}

          {description && (
            <Typography align="center" variant="h5" className="description">
              {description}
            </Typography>
          )}

          {ingredients && (
            <Typography align="center" className="ingredients">
              <span className="label">Ingredients: </span>
              {ingredients}
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default ProductCardPresenter
