import { Logger } from "../../../util/log"
import { toAsyncErrorSlice, toAsyncInitialSlice } from "../../../util/redux-helpers"
import {
  EMPTY_FILTERS,
  PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY,
  PRODUCT_SEARCH_WIDGET_MATCHES_KEY,
  PRODUCT_SEARCH_WIDGET_MATCHES_TOTAL_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_FILTERS_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_LIST_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_NO_FILTERS_TOTAL_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_PAGE_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_QUERY_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_TOTAL_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_SUMMARY_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_QUERY_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_TOTAL_KEY,
  PRODUCT_SEARCH_WIDGET_SEARCHING_KEY,
  PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS,
} from "./constants"

const _log = new Logger("ducks:widgets:product-search:handlers")

export function openProductSearchWidget(state, payload) {
  const { referenceProductId } = payload

  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY]: referenceProductId,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY]: [],
    [PRODUCT_SEARCH_WIDGET_REF_PROD_TOTAL_KEY]: 1,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY]: 0,
  }
}

export function closeProductSearchWidget(state, _payload) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY]: "",
    [PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY]: [],
    [PRODUCT_SEARCH_WIDGET_REF_PROD_TOTAL_KEY]: 1,
  }
}

export function listReferenceProductByQuery(state, { query = "" }) {
  const { [PRODUCT_SEARCH_WIDGET_REF_PROD_QUERY_KEY]: prevQuery = "" } = state

  if (prevQuery === query) {
    return state
  }

  return {
    ...state,
    isLoading: true,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_QUERY_KEY]: query,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY]: [],
    [PRODUCT_SEARCH_WIDGET_REF_PROD_TOTAL_KEY]: 1,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY]: 0,
  }
}

export function listReferenceProductByQuerySuccess(state, { payload }) {
  const { meta, products } = payload
  const firstReferenceProductId =
    products?.[0]?.id ?? state[PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY]

  return {
    ...state,
    isLoading: false,
    [PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY]: firstReferenceProductId,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY]: products,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_TOTAL_KEY]: meta.total,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY]: 0,
  }
}

export function listReferenceProductByQueryError(state, { error }) {
  const products =
    state[PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY] || toAsyncInitialSlice()

  return {
    ...state,
    isLoading: false,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY]: {
      ...state[PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY],
      ...toAsyncErrorSlice(products, error),
    },
  }
}

export function searchProducts(state, { options: { page = 1 } }) {
  const { searchResults } = state

  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_SEARCHING_KEY]: true,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_LIST_KEY]: page === 1 ? [] : searchResults,
  }
}

export function searchProductsSuccess(
  state,
  {
    parent: {
      query,
      options: { page = 1 },
    },
    payload: {
      meta: { total, filters },
      products,
    },
  },
) {
  const searchResults = state[PRODUCT_SEARCH_WIDGET_PRODUCTS_LIST_KEY]
  const uniqueSearchResult = {}
  searchResults.forEach((id) => {
    uniqueSearchResult[id] = true
  })
  let productIds = products.map(({ id }) => id)

  if (page !== 1) {
    productIds = productIds.filter((id) => !uniqueSearchResult[id])
  }

  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_SEARCHING_KEY]: false,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_TOTAL_KEY]: total,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_LIST_KEY]:
      page === 1 ? productIds : [...searchResults, ...productIds],
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_FILTERS_KEY]: filters,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_PAGE_KEY]: page,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_QUERY_KEY]: query,
  }
}

export function searchProductsError(state) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_SEARCHING_KEY]: false,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_TOTAL_KEY]: undefined,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_LIST_KEY]: [],
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_PAGE_KEY]: undefined,
  }
}

export function getSearchFilters(state) {
  return state
}

export function getSearchFiltersSuccess(
  state,
  {
    payload: {
      meta: { total },
      options,
    },
  },
) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY]: options,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_NO_FILTERS_TOTAL_KEY]: total,
  }
}

export function getSearchFiltersError(state) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY]: {},
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_NO_FILTERS_TOTAL_KEY]: undefined,
  }
}

export function getRefProductSummary(state) {
  return state
}

export function getRefProductSummarySuccess(state, { payload }) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_SUMMARY_KEY]: payload,
  }
}

export function getRefProductSummaryError(state) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_SUMMARY_KEY]: undefined,
  }
}

export function listMatches(state) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_SEARCHING_KEY]: true,
  }
}

export function listMatchesSuccess(
  state,
  {
    payload: {
      meta: { total },
      matches,
    },
  },
) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_SEARCHING_KEY]: false,
    [PRODUCT_SEARCH_WIDGET_MATCHES_KEY]: matches,
    [PRODUCT_SEARCH_WIDGET_MATCHES_TOTAL_KEY]: total,
  }
}

export function listMatchesError(state) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_SEARCHING_KEY]: false,
    [PRODUCT_SEARCH_WIDGET_MATCHES_KEY]: [],
    [PRODUCT_SEARCH_WIDGET_MATCHES_TOTAL_KEY]: 0,
  }
}

export function setSelectedFilters(state, payload) {
  const { filters } = payload

  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS]: filters,
  }
}

export function clearSelectedFilters(state) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS]: EMPTY_FILTERS,
  }
}

export function setQuery(state, { query }) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_QUERY_KEY]: query,
  }
}

export function setTotal(state, { total }) {
  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_PRODUCTS_TOTAL_KEY]: total,
  }
}

export function setRefProductPage(state, { page }) {
  const selectedRefProduct = state[PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY][page]
  if (!selectedRefProduct) {
    return state
  }

  return {
    ...state,
    [PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY]: page,
    [PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY]: selectedRefProduct.id,
  }
}
