import { Logger } from "../../../util/log"
import { buildEpic } from "../../../util/redux-observable-helpers"
import * as api from "../../../api/service"
import {
  DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
  DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS,
} from "./action-types"

const _log = new Logger("ducks:repositories:daltix-products:epics")

// epics
const getOneDaltixProductByIdEpic = buildEpic(
  DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
  ({ id, invalidateCache }) =>
    api.getDaltixProducts([
      {
        id,
        invalidateCache,
      },
    ]),
)

const getDaltixProductsByIdsEpic = buildEpic(
  DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS,
  ({ ids, invalidateCache }) =>
    api.getDaltixProducts(
      ids.map((id) => ({
        id,
        invalidateCache,
      })),
    ),
)

export default [getOneDaltixProductByIdEpic, getDaltixProductsByIdsEpic]
