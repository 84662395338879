import { get } from "lodash"
import { toAsyncErrorSlice, toAsyncSuccessSlice } from "../../../../util/redux-helpers"
import { ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY } from "../constants"

function getFilterOptions(state) {
  return get(state, [ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY])
}

export function handleGetCustomerAssortmentFilterOptions(state) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY]: {
      ...toAsyncSuccessSlice(getFilterOptions(state).data),
    },
  }
}

export function handleGetCustomerAssortmentFilterOptionsSuccess(state, { payload }) {
  const { filterOptions } = payload

  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY]: {
      ...toAsyncSuccessSlice(filterOptions),
    },
  }
}

export function handleGetCustomerAssortmentFilterOptionsError(state, { error }) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY]: {
      ...toAsyncErrorSlice(getFilterOptions(state).data, error),
    },
  }
}
