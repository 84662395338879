import React, { useEffect } from "react"
import PropTypes from "prop-types"
import useCustomer from "../../hooks/use-customer"
import { useAuth0 } from "../../hooks/use-authentication"
import { isProduction } from "../../util/env"

const customersRejectYandex = ["cocabe"]
const customerAcceptsYandex = (code) => !customersRejectYandex.includes(code)

function ym(...args) {
  if (typeof window.ym === "function") {
    window.ym(92700304, ...args)
  }
}

function initYandex() {
  /* eslint-disable */
  function metrika(m, e, t, r, i, k, a) {
    if (typeof window.ym !== "undefined") {
      return
    }

    m[i] =
      m[i] ||
      function () {
        ;(m[i].a = m[i].a || []).push(arguments)
      }

    m[i].l = 1 * new Date()
    ;(k = e.createElement(t)),
      (a = e.getElementsByTagName(t)[0]),
      (k.async = 1),
      (k.src = r),
      a.parentNode.insertBefore(k, a)
  }
  /* eslint-enable */
  try {
    metrika(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym")

    window.ym(92700304, "init", {
      id: 92700304,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      ut: "noindex",
    })
  } catch (error) {
    console.error("Could not insert Yandex Metrika script in browser window.")
  }
}

function YandexMetrika({ children }) {
  const { isAuthenticated } = useAuth0()
  const { code } = useCustomer()

  useEffect(() => {
    if (isProduction && isAuthenticated && code && customerAcceptsYandex(code)) {
      console.info("Customer authenticated and customer selected. Initializing Yandex.")

      initYandex()
    }
  }, [code, isAuthenticated])

  return <>{children}</>
}

YandexMetrika.propTypes = {
  children: PropTypes.node.isRequired,
}

export { YandexMetrika, ym }
