import React, { memo } from "react"
import PropTypes from "prop-types"
import { Icon, Card } from "@daltix/ui-react"
import { Box } from "@mui/material"

import HtmlTitle from "../../components/html-title/HtmlTitle"
import "./Portal.sass"
import { ContentContainer, SideNavigation } from "../../components/side-navigation"
import AppsContainer from "../../components/apps-container"

function PortalPresenter() {
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNavigation />
      <ContentContainer>
        <HtmlTitle>Home</HtmlTitle>
        <AppsContainer />
      </ContentContainer>
    </Box>
  )
}

export function PortalFallback({ title }) {
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNavigation />
      <HtmlTitle>{title}</HtmlTitle>
      <ContentContainer>
        <Card
          full
          className="loader"
          waiting
          style={{
            height: "100%",
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none",
          }}
        />
      </ContentContainer>
    </Box>
  )
}

export function PortalError({ message, title }) {
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNavigation />
      <HtmlTitle>{title}</HtmlTitle>
      <ContentContainer>
        <Card
          className="error-message-card"
          full
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="dx-error-message">
            <Icon type="warning" className="dx-color-red" />
            <div>
              <h2>Oops! Something went wrong :(</h2>
              <br />
              {message}
            </div>
          </div>
        </Card>
      </ContentContainer>
    </Box>
  )
}

PortalError.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
}

PortalError.defaultProps = {
  title: "Home",
}

PortalFallback.propTypes = {
  title: PropTypes.string,
}

PortalFallback.defaultProps = {
  title: "Home",
}

export default memo(PortalPresenter)
