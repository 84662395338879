import React, { useMemo, useState } from "react"
import { Button, Icon } from "@daltix/ui-react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import { sanitizeEan } from "../../util/strings"
import "./EANList.sass"

function EANList({ eans }) {
  const [eanExpanded, setEanExpanded] = useState(false)
  const [firstEan, ...otherEans] = useMemo(
    () => eans.filter((ean) => ean).map(sanitizeEan),
    [eans],
  )

  return (
    <>
      {firstEan && (
        <span className="ean-list-root">
          EAN:
          {` ${firstEan} `}
          {otherEans.length > 0 && (
            <>
              <Button
                size="xs"
                iconRight={eanExpanded ? <Icon type="up" /> : <Icon type="down" />}
                onClick={() => setEanExpanded(!eanExpanded)}
              >
                <span>+{otherEans.length} EAN</span>
              </Button>
              <ExpansionPanel
                classes={{
                  root: "dx-clear-expansion-panel",
                  expanded: "dx-expanded",
                }}
                expanded={eanExpanded}
              >
                <ExpansionPanelSummary />
                <ExpansionPanelDetails className="ean-list-items">
                  {otherEans.map((ean) => (
                    <span key={ean} className="dx-additional-ean">
                      {ean}
                    </span>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </>
          )}
        </span>
      )}
    </>
  )
}

export default EANList
