import { Typography } from "@mui/material"
import { number, string } from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

function Price({ price, matchId, multFactor }) {
  const updatedMultFactor = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.mult_factor,
  )

  if (price === null || price === undefined) {
    return null
  }

  const effectiveMultFactor = updatedMultFactor || multFactor
  const strike =
    effectiveMultFactor !== 1 &&
    effectiveMultFactor !== undefined &&
    effectiveMultFactor !== null

  return (
    <Typography
      data-testid={strike ? "price-strike" : "price"}
      sx={{ textDecoration: strike ? "line-through" : undefined }}
      variant="body2"
    >
      €{Number(price).toFixed(2)}
    </Typography>
  )
}

Price.propTypes = {
  price: number,
  matchId: string,
  multFactor: number,
}

Price.defaultProps = {
  matchId: "",
  multFactor: undefined,
  price: undefined,
}

export { Price }
