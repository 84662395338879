import React, { useRef, useEffect } from "react"
import Pagination from "material-ui-flat-pagination"
import { AppArea, View, Card } from "@daltix/ui-react"
import PropTypes from "prop-types"
import { PAGE_SIZE } from "../../ducks/matches/common"
import MatchesReviewFilterBar from "../matches-review-filter-bar/MatchesReviewFilterBar"
import MatchesReviewList from "../matches-review-list/MatchesReviewList"
import "./MatchesReviewPanel.scss"
import HtmlTitle from "../html-title/HtmlTitle"

function MatchesReviewPanelPresenter({ loading, page, total, onPageSelected }) {
  const areaEl = useRef(null)

  useEffect(() => {
    if (!loading && areaEl.current) {
      areaEl.current.scrollTo(0, 0)
    }
  }, [loading, page])

  if (loading) {
    return (
      <View>
        <AppArea className="matches-review-panel">
          <div className="loading-area">
            <Card
              waiting
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                height: "4rem",
                minHeight: "4rem",
                overflow: "visible",
              }}
            />
            Initializing...
          </div>
        </AppArea>
      </View>
    )
  }

  return (
    <View>
      <HtmlTitle>Review Matches</HtmlTitle>
      <MatchesReviewFilterBar />
      <div className="dx-app-area matches-review-panel" ref={areaEl}>
        <MatchesReviewList page={page} />
        <div className="pagination">
          {total > 0 && (
            <Pagination
              classes={{
                root: "dx-pagination",
                text: "dx-page-item",
                rootCurrent: "dx-active-page",
                disabled: "dx-inactive-page",
              }}
              page={page}
              limit={PAGE_SIZE}
              offset={(page - 1) * PAGE_SIZE}
              total={total}
              onClick={onPageSelected}
              size="large"
              innerButtonCount={3}
              outerButtonCount={3}
            />
          )}
        </div>
      </div>
    </View>
  )
}

MatchesReviewPanelPresenter.propTypes = {
  loading: PropTypes.bool.isRequired,
  onPageSelected: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number,
}

MatchesReviewPanelPresenter.defaultProps = {
  total: undefined,
}

export default MatchesReviewPanelPresenter
