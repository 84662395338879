import { NULL } from "./constants"

/* eslint-disable import/prefer-default-export, no-restricted-syntax */

export const combineFilters =
  (...filters) =>
  (...args) => {
    let ok = true

    for (const filter of filters) {
      ok = ok && filter(...args)
      if (!ok) break
    }

    return ok
  }

export const normalizeBrand = (brand) => {
  if (brand) {
    return brand.toLowerCase()
  }
  return NULL
}

export const countFilterOptions = (productList = []) => {
  const counters = {
    brands: {},
    shops: {},
    countries: {},
  }

  productList.forEach(({ brand, shop, country }) => {
    const normalizedBrand = normalizeBrand(brand)

    const {
      brands: { [normalizedBrand]: brandCount = 0 },
      shops: { [shop]: shopCount = 0 },
      countries: { [country]: countryCount = 0 },
    } = counters

    counters.brands[normalizedBrand] = brandCount + 1
    counters.shops[shop] = shopCount + 1
    counters.countries[country] = countryCount + 1
  })

  return counters
}

export const isEmpty = (value) => value === "" || value === null || value === undefined
