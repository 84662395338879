import React from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"
import Icon from "@material-ui/core/Icon"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied"
import WarningIcon from "@material-ui/icons/WarningRounded"
import LinearProgress from "@material-ui/core/LinearProgress"
import Matches from "../matches/Matches"
import { titleCase } from "../../util/strings"

import "./MatchesBox.sass"

function MatchesBox({
  productId,
  product,
  matchesLabel,
  source,
  loading,
  className,
  matchesList,
  noFilterTotalMatches,
  error,
  expanded,
  onChange,
}) {
  let title = (
    <Typography className="list-title flex grow center">
      <Icon className="fa fa-sm fa-puzzle-piece title-icon expansion-panel-icon" />{" "}
      Matches
      <span className="grow" />
    </Typography>
  )
  let content = <div />

  if (loading) {
    title = (
      <div className="loading-container">
        <Typography component="span" style={{ marginRight: 20 }}>
          Loading Matches
        </Typography>
        <LinearProgress variant="query" style={{ width: "calc(100% - 1em)" }} />
      </div>
    )
  } else if (error) {
    title = (
      <Typography className="error" color="error">
        Error
      </Typography>
    )

    content = (
      <div className="matches-box-container">
        <SentimentVeryDissatisfiedIcon color="error" />
        <Typography color="error">
          Sorry! Error loading matches: {error.message}
        </Typography>
      </div>
    )
  } else if (!product) {
    content = (
      <div className="matches-box-container aux-text no-product-selected">
        <Typography className="aux-text">No selected product!</Typography>
      </div>
    )
  } else if (matchesList) {
    let matchesPreviewTitle = null

    if (!expanded && noFilterTotalMatches) {
      matchesPreviewTitle = (
        <span className="expansion-panel-subtitle">
          <span
            className="fade-in"
            title={`${noFilterTotalMatches} existing match${
              noFilterTotalMatches > 1 ? "es" : ""
            } for '${titleCase(product.name)}'`}
          >
            {noFilterTotalMatches}
            <Icon className="fa fa-sm fa-puzzle-piece expansion-panel-icon" />
          </span>
        </span>
      )
    }

    title = (
      <Typography className="list-title flex grow center">
        <Icon className="fa fa-sm fa-puzzle-piece title-icon expansion-panel-icon" />{" "}
        Matches
        <span className="grow" />
        {matchesPreviewTitle}
      </Typography>
    )
    if (matchesList.length > 0) {
      let resultsText = null
      if (matchesList.length < noFilterTotalMatches) {
        resultsText = (
          <span>
            showing <strong>{matchesList.length}</strong> filtered match
            {matchesList.length > 1 ? "es" : ""}
            &nbsp; (<strong>{noFilterTotalMatches}</strong> found)
          </span>
        )
      } else {
        resultsText = (
          <span>
            <strong>{noFilterTotalMatches}</strong> match
            {noFilterTotalMatches > 1 ? "es" : ""}
          </span>
        )
      }

      content = (
        <div className="matches-list grow">
          <Matches
            referenceProductId={productId}
            source={source}
            matches={matchesList}
            scrollContainerClass={`${matchesLabel.toLowerCase()}-scroll-container-${productId}`}
            expanded={expanded}
          />
          <span className="result-count ">{resultsText}</span>
        </div>
      )
    } else {
      let resultsText = null
      let icon = null
      if (noFilterTotalMatches > 0) {
        resultsText = (
          <span>
            <strong>{noFilterTotalMatches}</strong> match
            {noFilterTotalMatches > 1 ? "es" : ""} hidden by selected filters
          </span>
        )
        icon = <WarningIcon />
      } else {
        resultsText = "No matches for this product"
        icon = <SentimentVeryDissatisfiedIcon />
      }

      content = (
        <div className="matches-box-container aux-text">
          {icon}
          <Typography className="aux-text">{resultsText}</Typography>
        </div>
      )
    }
  }

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={onChange}
      className={className}
      classes={{
        expanded: `grow expanded ${className}`,
      }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        {title}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        className="existing-matches-content"
        classes={{
          root: "root.class",
        }}
      >
        {content}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default MatchesBox
