import React from "react"
import PropTypes from "prop-types"

import styles from "./MatchesOverviewFieldset.module.scss"

function MatchesOverviewFieldset({ children, title }) {
  return (
    <div className={styles.root}>
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>{title}</legend>
        {children}
      </fieldset>
    </div>
  )
}

MatchesOverviewFieldset.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default MatchesOverviewFieldset
