export const DEFAULT_PATH_DELIMITER = ":"

/**
 * Given an array of paths (e.g.: ['foo', 'foo/bar']) it hydrates the array into a
 * nested object.
 *
 * Example output:
 * ```javascript
 * {
 *   name: 'foo'
 *   children: [
 *     {
 *        name: 'bar'
 *        children: []
 *     }
 *   ]
 * }
 * ```
 *
 * @param {string[]} paths an array of paths to be hydrated into a tree
 * @param {string} separator a character determining how path segments are seperated
 * @param {string} childrenPropName defines the name of the key which holds the children of a node
 *
 * @returns an object representing the paths
 */
export function pathsToTree(
  paths = [],
  separator = DEFAULT_PATH_DELIMITER,
  childrenPropName = "children",
) {
  /* Recursively transform a path into an object like structure */
  function insert(children = [], [head, ...tail]) {
    let child = children.find((c) => c.name === head)

    if (!child) {
      children.push((child = { name: head }))
      child[childrenPropName] = []
    }

    if (tail.length > 0) {
      insert(child[childrenPropName], tail, childrenPropName)
    }

    return children
  }

  const tree = paths
    .map((path) => path.split(separator).filter(Boolean))
    .reduce((children, path) => insert(children, path, childrenPropName), [])

  return tree
}
