import { Skeleton, Typography } from "@mui/material"
import { bool, number } from "prop-types"
import React from "react"

/**
 * @param {import('@mui/material').InputProps & { price: number }}
 */
function ReferencePrice({ price, isLoading, ...props }) {
  if (isLoading) {
    return (
      <Typography data-testid="reference-price-loading" {...props}>
        <Skeleton />
      </Typography>
    )
  }

  if (price === undefined || price === null) {
    return null
  }

  return (
    <Typography
      data-testid="reference-price"
      variant="body2"
      fontWeight={600}
      {...props}
    >
      €{Number(price).toFixed(2)}
    </Typography>
  )
}

ReferencePrice.propTypes = {
  price: number,
  isLoading: bool,
}

ReferencePrice.defaultProps = {
  price: undefined,
  isLoading: false,
}

export { ReferencePrice }
