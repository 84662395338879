import React, { lazy } from "react"
import { redirectionRules } from "../../util/redirection"
import NewAddressInfo from "../new-address-info"

const Main = lazy(() => import("../main/Main"))

const infoPaths = ["/", "/portal"]

function NewAddressRedirector(props) {
  const {
    location: { hostname, pathname, search },
  } = window

  const destinationDomain = redirectionRules[hostname]

  if (destinationDomain) {
    let destinationUrl = destinationDomain

    if (infoPaths.includes(pathname)) {
      return <NewAddressInfo destination={destinationUrl} />
    }

    destinationUrl += `${pathname}${search}`

    window.location = destinationUrl

    return null
  }

  return <Main {...props} />
}

export default NewAddressRedirector
