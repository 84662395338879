import PropTypes from "prop-types"
import React from "react"
import { CheckIcon, CrossIcon, EllipsisIcon, EmptyIcon, ToggleGroup } from "../../ui"
import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
  STATUS_UNMATCHED,
} from "../../../util/match-status"
import { buildAnalyticsEventName } from "../../../util/user-monitoring"

const feature = "Product Comparison Overlay"
const userAction = "Clicked"

const approvedEventName = buildAnalyticsEventName({
  feature,
  target: "Filter Approved Matches",
  userAction,
})

const inReviewEventName = buildAnalyticsEventName({
  feature,
  target: "Filter In Review Matches",
  userAction,
})

const discardedEventName = buildAnalyticsEventName({
  feature,
  target: "Filter Discarded Matches",
  userAction,
})

const unmatchedEventName = buildAnalyticsEventName({
  feature,
  target: "Filter Unmatched Products",
  userAction,
})

function MatchStatusToggleGroup({ selected, onChange, enableUnmatched = false }) {
  return (
    <ToggleGroup.Root
      aria-label="Comparison Match Filters"
      value={selected}
      onValueChange={onChange}
    >
      <ToggleGroup.Item
        analyticsEventName={approvedEventName}
        aria-label="Show/hide 'Approved' matches"
        tooltip="Show/hide 'Approved' matches"
        value={STATUS_APPROVED}
      >
        <CheckIcon label="approved" />
      </ToggleGroup.Item>
      <ToggleGroup.Item
        analyticsEventName={inReviewEventName}
        aria-label="Show/hide 'In Review' matches"
        tooltip="Show/hide 'In Review' matches"
        value={STATUS_REVIEW}
      >
        <EllipsisIcon label="in review" />
      </ToggleGroup.Item>
      <ToggleGroup.Item
        analyticsEventName={discardedEventName}
        aria-label="Show/hide 'Discarded' matches"
        tooltip="Show/hide 'Discarded' matches"
        value={STATUS_DISCARDED}
      >
        <CrossIcon label="discarded" />
      </ToggleGroup.Item>
      {enableUnmatched && (
        <ToggleGroup.Item
          analyticsEventName={unmatchedEventName}
          aria-label="Show/hide 'Unmatched' products"
          tooltip="Show/hide 'Unmatched' products"
          value={STATUS_UNMATCHED}
        >
          <EmptyIcon label="unmatched" />
        </ToggleGroup.Item>
      )}
    </ToggleGroup.Root>
  )
}

MatchStatusToggleGroup.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  enableUnmatched: PropTypes.bool,
}

MatchStatusToggleGroup.defaultProps = {
  enableUnmatched: false,
}

export { MatchStatusToggleGroup }
