import PropTypes from "prop-types"
import React, { forwardRef } from "react"

import { Button } from "./Button"

import { CrossIcon } from "../Icon"

const ButtonCross = forwardRef(({ title, ...props }, forwardedRef) => (
  <Button
    aria-label={title}
    {...props}
    border="none"
    color="danger"
    height="regular"
    squared
    title={title}
    variant="solid"
    ref={forwardedRef}
  >
    <CrossIcon label={title} size="xs" />
  </Button>
))

ButtonCross.displayName = "ButtonCross"

ButtonCross.propTypes = {
  title: PropTypes.string.isRequired,
}

export { ButtonCross }
