import PropTypes from "prop-types"
import React, { forwardRef } from "react"
import { IconX } from "@tabler/icons-react"

import styles from "./ButtonX.module.scss"

const ButtonX = forwardRef(({ title, ...props }, forwardedRef) => (
  <button
    aria-disabled={props.disabled ? "true" : undefined}
    aria-label={`close ${title}`}
    {...props}
    ref={forwardedRef}
    className={styles.closer}
    type="button"
  >
    <IconX aria-hidden="true" />
  </button>
))

ButtonX.displayName = "ButtonX"

ButtonX.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

ButtonX.defaultProps = {
  disabled: false,
}

export { ButtonX }
