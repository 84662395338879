import * as MatchOverviewActions from "./action-types"

/* -------------------------------------------------------------------------- */
/*                                    async                                   */
/* -------------------------------------------------------------------------- */

export function enqueueGetReferenceProductsMatchesByShop() {
  return {
    type: MatchOverviewActions.ENQUEUE_GET_REF_PRODUCTS_MATCHES_BY_SHOP,
  }
}

/* -------------------------------------------------------------------------- */
/*                                 resetters                                  */
/* -------------------------------------------------------------------------- */

export function clearReferenceProductsMatchesByShopFilters(filterGroupKey) {
  return {
    type: MatchOverviewActions.CLEAR_MATCHES_OVERVIEW_APPLIED_FILTERS,
    filterGroupKey,
  }
}

export function clearUnstagedMatchesOverviewFilters() {
  return {
    type: MatchOverviewActions.CLEAR_MATCHES_OVERVIEW_UNSTAGED_FILTERS,
  }
}

export function readMatchesOverviewPageStateFromPreviousState() {
  return {
    type: MatchOverviewActions.MATCHES_OVERVIEW_REHYDRATE_FROM_PREVIOUS_STATE,
  }
}

export function readMatchesOverviewPageStateFromURL({ defaultValues }) {
  return {
    type: MatchOverviewActions.READ_MATCHES_OVERVIEW_URL_STATE,
    defaultValues,
  }
}

/* -------------------------------------------------------------------------- */
/*                                  fetchers                                  */
/* -------------------------------------------------------------------------- */

export function getReferenceProductsMatchesByShop() {
  return {
    type: MatchOverviewActions.GET_REF_PRODUCTS_MATCHES_BY_SHOP,
  }
}

export function getReferenceProductsMatchesByShopMeta() {
  return {
    type: MatchOverviewActions.GET_REF_PRODUCTS_MATCHES_BY_SHOP_META,
  }
}

export function triggerApplyMatchesOverviewFilters() {
  return {
    type: MatchOverviewActions.TRIGGER_APPLY_MATCHES_OVERVIEW_FILTERS,
  }
}

/* -------------------------------------------------------------------------- */
/*                                  mutations                                 */
/* -------------------------------------------------------------------------- */
export function clearFilterReferenceProductSearch() {
  return {
    type: MatchOverviewActions.CLEAR_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH,
  }
}

export function setFilterMatchStatuses({ matchStatuses }) {
  return {
    type: MatchOverviewActions.SET_MATCHES_OVERVIEW_MATCH_STATUSES_FILTER,
    matchStatuses,
  }
}

export function setFilterReferenceProductSearch({ searchFacet, searchTerm }) {
  return {
    type: MatchOverviewActions.SET_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH,
    searchFacet,
    searchTerm,
  }
}

export function setMatchesOverviewPage(page) {
  return {
    type: MatchOverviewActions.SET_MATCHES_OVERVIEW_PAGE,
    page,
  }
}

export function setMatchesOverviewPageSize(pageSize) {
  return {
    type: MatchOverviewActions.SET_MATCHES_OVERVIEW_PAGE_SIZE,
    pageSize,
  }
}

export function stageFilterMatchCountry({ value, state }) {
  return { type: MatchOverviewActions.STAGE_FILTER_MATCH_COUNTRY, value, state }
}

export function stageFilterMatchShop({ value, state }) {
  return { type: MatchOverviewActions.STAGE_FILTER_MATCH_SHOP, value, state }
}

export function stageFilterReferenceProductsCategory({ value, state }) {
  return {
    type: MatchOverviewActions.STAGE_FILTER_REF_PRODUCTS_CATEGORY,
    value,
    state,
  }
}

export function stageFilterReferenceProductsFamily({ value, state }) {
  return { type: MatchOverviewActions.STAGE_FILTER_REF_PRODUCTS_FAMILY, value, state }
}

export function toggleFilterApprovedMatches() {
  return { type: MatchOverviewActions.TOGGLE_FILTER_APPROVED_MATCHES }
}

export function toggleFilterDiscardedMatches() {
  return { type: MatchOverviewActions.TOGGLE_FILTER_DISCARDED_MATCHES }
}

export function toggleFilterInReviewMatches() {
  return { type: MatchOverviewActions.TOGGLE_FILTER_IN_REVIEW_MATCHES }
}

export function toggleFilterReferenceProductsWithoutMatches() {
  return { type: MatchOverviewActions.TOGGLE_FILTER_REF_PRODUCTS_WITHOUT_MATCHES }
}
/* -------------------------------------------------------------------------- */
/*                                  triggers                                  */
/* -------------------------------------------------------------------------- */

export function triggerGetReferenceProductsMatchesByShop() {
  return {
    type: MatchOverviewActions.TRIGGER_GET_REF_PRODUCTS_MATCHES_BY_SHOP,
  }
}
