import React from "react"

import ArrowDownward from "@material-ui/icons/ArrowDownward"

import { AccessibleIcon } from "./AccessibleIcon"

export function IconSortDESC(props) {
  return (
    <AccessibleIcon
      label="sort descending"
      border="solid"
      color="secondary"
      shape="circle"
      size="sm"
      {...props}
    >
      <ArrowDownward fontSize="inherit" />
    </AccessibleIcon>
  )
}
