import PropTypes from "prop-types"
import React from "react"

import * as RUISelect from "@radix-ui/react-select"

import clsx from "clsx"

import styles from "./BaseSelectSeparator.module.scss"

function BaseSelectSeparator({ className }) {
  return <RUISelect.Separator className={clsx(styles.separator, className)} />
}

BaseSelectSeparator.propTypes = {
  className: PropTypes.string,
}

BaseSelectSeparator.defaultProps = {
  className: "",
}

export { BaseSelectSeparator }
