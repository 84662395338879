import React from "react"
import PropTypes from "prop-types"
import ListSubheader from "@material-ui/core/ListSubheader"
import Typography from "@material-ui/core/Typography"

import styles from "./BaseSlideoverFilterHeader.module.scss"

function BaseSlideoverFilterHeader({ text, ...props }) {
  return (
    <ListSubheader {...props} disableSticky>
      <Typography className={styles.text} variant="h6">
        {text}
      </Typography>
    </ListSubheader>
  )
}

BaseSlideoverFilterHeader.propTypes = {
  text: PropTypes.string,
}

BaseSlideoverFilterHeader.defaultProps = {
  text: "",
}

export { BaseSlideoverFilterHeader }
