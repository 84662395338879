import PropTypes from "prop-types"

const dashboardShape = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  options: PropTypes.shape({
    models: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
  }),
})

const childrenElements = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.element),
  PropTypes.element,
])

const childrenNodes = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
])

const AppPropTypes = {
  childrenElements,
  childrenNodes,
  dashboardShape,
}

export default AppPropTypes
