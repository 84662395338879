import { matchesOverviewURLState } from "../helpers"

export function handleReadMatchesOverviewFromPreviousState(state) {
  return { ...state }
}

export function handleReadMatchesOverviewURLState(state, payload) {
  const { defaultValues = {} } = payload

  const {
    categories,
    countries,
    families,
    matchStatuses,
    page,
    pageSize,
    referenceProductMatchesCount,
    searchFacet,
    searchTerm,
    shops,
  } = matchesOverviewURLState({
    ...defaultValues,
  })

  return {
    ...state,
    filters: {
      ...state.filters,
      matches: {
        ...state.filters.matches,
        count: referenceProductMatchesCount,
        countries,
        shops,
        status: matchStatuses,
      },
      referenceProducts: {
        ...state.filters.referenceProducts,
        families,
        categories,
        searchFacet,
        searchTerm,
      },
    },
    pagination: {
      ...state.pagination,
      page,
      pageSize,
    },
  }
}
