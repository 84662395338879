import * as api from "../../../api/service"
import { Logger } from "../../../util/log"
import { buildEpic } from "../../../util/redux-observable-helpers"
import {
  PRODUCT_SEARCH_GET_SEARCH_FILTERS,
  PRODUCT_SEARCH_LIST_BY_QUERY,
  PRODUCT_SEARCH_LIST_MATCHES,
  PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY,
  PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY,
} from "./action-types"

const _log = new Logger("ducks:product-search-widget:epics")

// epics
const listReferenceProductByQueryEpic = buildEpic(
  PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY,
  ({ query }) => api.listReferenceProducts({ query }),
)

const listProductsByQueryEpic = buildEpic(
  PRODUCT_SEARCH_LIST_BY_QUERY,
  ({ query, options }) => api.searchDaltixProducts(query, options),
)

const getProductsSearchFiltersEpic = buildEpic(
  PRODUCT_SEARCH_GET_SEARCH_FILTERS,
  ({ query }) => api.getSearchFilters(query),
)

const listExistingMatchesByIdEpic = buildEpic(
  PRODUCT_SEARCH_LIST_MATCHES,
  ({ referenceProductId, options }) =>
    api.listExistingMatches(referenceProductId, { withDiscarded: true, ...options }),
)

const getReferenceProductSummaryEpic = buildEpic(
  PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY,
  (referenceProductId) => api.getReferenceProductsSummary(referenceProductId),
)

export default [
  listReferenceProductByQueryEpic,
  listProductsByQueryEpic,
  getProductsSearchFiltersEpic,
  listExistingMatchesByIdEpic,
  getReferenceProductSummaryEpic,
]
