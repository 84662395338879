/* eslint-disable import/prefer-default-export */

export const handleClearMatchesList = (state, action) => {
  const {
    parent: { options: { fromUrl } = {} },
  } = action

  if (fromUrl) return state

  return {
    ...state,
    list: [],
    page: 1,
    total: undefined,
  }
}
