import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"
import AppPropTypes from "../../prop-shapes"

import styles from "./AssortmentAnalysisControl.module.scss"

function AssortmentAnalysisControl({ children, grows, icon, iconSlot, justify }) {
  return (
    <li className={clsx(styles.control, { [styles.grow]: grows }, styles[justify])}>
      <span className={styles["slot-start"]}>{iconSlot === "start" && icon}</span>
      {children}
      <span className={styles["slot-end"]}>{iconSlot === "end" && icon}</span>
    </li>
  )
}

AssortmentAnalysisControl.propTypes = {
  children: AppPropTypes.childrenNodes.isRequired,
  grows: PropTypes.bool,
  icon: AppPropTypes.childrenElements,
  iconSlot: PropTypes.oneOf(["start", "end"]),
  justify: PropTypes.oneOf(["start", "center", "end"]),
}

AssortmentAnalysisControl.defaultProps = {
  grows: false,
  icon: null,
  iconSlot: "end",
  justify: "center",
}

export { AssortmentAnalysisControl }
