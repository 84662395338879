import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { actionCreators } from "../ducks/side-navigation"

export default function useSideNav() {
  const dispatch = useDispatch()
  const selector = useSelector((state) =>
    get(state, ["navigation", "isSideNavOpen"], false),
  )

  const toggleNav = (open) => {
    dispatch(actionCreators.toggleSideNavigation(open))
  }

  return {
    isOpen: selector,
    toggleNav,
  }
}
