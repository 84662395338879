import { countries } from "countries-list"

export function getCountryName(countryCode) {
  const upperCasedCountryCode = countryCode.toUpperCase()

  if (upperCasedCountryCode in countries) {
    return countries[upperCasedCountryCode].name
  }

  console.error("getCountryName: unsupported country code", `'${countryCode}'`)

  return upperCasedCountryCode
}
