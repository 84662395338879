import React from "react"
import PropTypes from "prop-types"
import { Box, useTheme } from "@mui/material"
import { drawerWidth } from "./side-navigation"
import useSideNav from "../../hooks/use-side-navigation"

function ContentContainer({ children, ...props }) {
  const theme = useTheme()
  const { isOpen } = useSideNav()
  const { sx, ...rest } = props

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: `calc(100% - ${isOpen ? `${drawerWidth}px` : theme.spacing(7)} - 1px)`,
        [theme.breakpoints.up("sm")]: {
          width: `calc(100% - ${isOpen ? `${drawerWidth}px` : theme.spacing(8)} - 1px)`,
        },
        minHeight: "100vh",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.node,
}

ContentContainer.defaultProps = {
  children: null,
}

export { ContentContainer }
