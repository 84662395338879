import { IconEye, IconX } from "@tabler/icons-react"
import _Proptypes from "prop-types"
import React, { useRef, useState } from "react"
import { useOnClickOutside } from "usehooks-ts"
import { get } from "lodash"
import ReactPlaceholder from "react-placeholder"
import useAsyncSlice from "../../hooks/use-async-slice"
import { getReferenceProductsMatchesByShopMeta } from "../../ducks/pages/matches-overview/action-creators"

const action = getReferenceProductsMatchesByShopMeta()

function selector(state) {
  return get(state, ["pages", "MatchesOverviewPage", "headingCounts"])
}

function useCountsSummary() {
  return useAsyncSlice({ action, selector })
}

function MatchesOverviewInfobar() {
  const { data, isError, isIdle, isFetching } = useCountsSummary()

  const [settingsOpen, setSettingsOpen] = useState(false)

  const clickOutsideRef = useRef()

  useOnClickOutside(clickOutsideRef, closeSettingsPanel)

  function closeSettingsPanel() {
    setSettingsOpen((current) => {
      if (current === true) {
        setSettingsOpen(false)
      }
    })
  }

  function openSettingsPanel() {
    setSettingsOpen((current) => {
      if (current === false) {
        setSettingsOpen(true)
      }
    })
  }

  if (isError) {
    return null
  }

  if (isIdle || isFetching) {
    return (
      // TODO @xico increase length to 3 when View Settings is visible
      <div className="matches-overview-info-bar-container">
        {Array.from({ length: 2 }).map((_, idx) => (
          <ReactPlaceholder
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            as="li"
            className="rounded"
            showLoadingAnimation
            style={{ width: "128px", height: "32px", margin: "0.8rem" }}
            type="rect"
          />
        ))}
      </div>
    )
  }

  return (
    <div className="matches-overview-info-bar-container">
      <div className="mx-4">
        <span>{`${data.total} reference products`}</span>
      </div>
      <div className="matches-overview-info-bar-settings-container">
        {/* TODO remove short circuit and fragment when View Settings is implemented */}
        {false && (
          <>
            <button onClick={openSettingsPanel} type="button">
              <IconEye />
              <span>View Settings</span>
            </button>
            {settingsOpen && (
              <div
                ref={clickOutsideRef}
                className="matches-overview-info-bar-settings-panel divide-y"
              >
                <button
                  onClick={closeSettingsPanel}
                  style={{
                    rounded: "9999px",
                  }}
                  type="button"
                >
                  <IconX size={24} />
                </button>
                <div className="matches-overview-info-bar-settings-panel-row">
                  Grid Settings Panel
                </div>
                <div className="matches-overview-info-bar-settings-panel-row">
                  Sort reference products by: Name
                </div>
                <div className="matches-overview-info-bar-settings-panel-row">
                  Sort matches products by: Shop Name
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="mx-4">
        <span>{`${data.matched} matched (${data.unmatched} unmatched)`}</span>
      </div>
    </div>
  )
}

MatchesOverviewInfobar.propTypes = {}
MatchesOverviewInfobar.defaultProps = {}

export default MatchesOverviewInfobar
