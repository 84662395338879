import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { push } from "connected-react-router"
import {
  Drawer as MuiDrawer,
  useTheme,
  styled,
  List,
  IconButton,
  Divider,
  Icon,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material"
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material"
import mixpanel from "mixpanel-browser"
import { get } from "lodash"
import { useTranslation } from "react-i18next"
import { AccountMenu } from "./account-menu"
import { NavLogo } from "./nav-logo"
import { NavIcon } from "./nav-icon"
import { NavItem } from "./nav-item"
import { IllowBlocker } from "./illow-blocker"
import { actionCreators as userActions } from "../../ducks/user"
import { useAuth0 } from "../../hooks/use-authentication"
import useCustomer from "../../hooks/use-customer"
import { HELP_CENTER_URL } from "../../util/constants"
import { imagesBaseURL } from "../../util/env"
import { mergeSettings, KEY_CUSTOMER } from "../../util/local-storage"
import { ym } from "../../integrations/yandex-metrika"
import "./nav-icon.scss"
import useSideNav from "../../hooks/use-side-navigation"

const bottomItemsCount = 2 // Help, Account
const dividerHeight = 16
export const drawerWidth = 280
const itemsBufferHeight = 72
const logoHeight = 78
const navItemHeight = 64
const minClientHeight = 960
const minApps = 16 // Number of items that fit in the minClientHeight

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    boxSizing: "border-box",
    flexShrink: 0,
    width: drawerWidth,
    whiteSpace: "nowrap",
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": closedMixin(theme),
        },
      },
    ],
  }),
)

/*
  To user wrap the content in a element with flexGrow: 1 and the SideNavigation component on a element with display: flex
    
  ````
    <Box sx={{ display: "flex" }}>
      <SideNavigation />
      <ContentContainer>
        {Content}
      </ContentContainer>
    </Box>
  ```
 */
function SideNavigation() {
  const theme = useTheme()
  const auth0 = useAuth0()
  const { state = {}, pathname: selected } = useLocation()
  const [addMore, setAddMore] = useState(0)
  const [moreAnchor, setMoreAnchorEl] = useState(null)
  const moreOpen = Boolean(moreAnchor)
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    name: userName,
    customer: { code } = {},
    customers = [],
  } = useSelector((stt) => get(stt, ["user", "user"])) || {}
  const { apps = [] } = useCustomer()
  const { isOpen, toggleNav } = useSideNav()

  const customer = useMemo(
    () => customers.find(({ code: customerCode }) => code === customerCode) || {},
    [customers, code],
  )

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { target } = entry
        const dividers = apps?.length - 1 || 0
        const items = apps?.reduce((acc, group) => acc + group.apps.length, 0) || 0
        const totalHeight =
          (items + bottomItemsCount) * navItemHeight +
          dividers * dividerHeight +
          logoHeight +
          itemsBufferHeight

        // remove 1 to avoid the scrollbar on smaller screens and set a minimum of 1 (the more option)
        const itemsToShow =
          Math.floor((target.clientHeight * minApps) / minClientHeight) - 1 || 1

        if (totalHeight > minClientHeight || totalHeight > target.clientHeight) {
          setAddMore(itemsToShow)
        } else {
          setAddMore(0)
        }
      })
    })
    resizeObserver.observe(document.body)

    return () => resizeObserver.disconnect()
  }, [apps])

  const { country, name: account } = customer

  const handleAccountClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleAccountClose = () => {
    setAnchorEl(null)
  }

  const handleClickHome = useCallback(() => {
    dispatch(push("/portal"))
  }, [dispatch])

  const handleClickHelp = useCallback(() => {
    mixpanel.track("Open: Help Center")
    window.open(HELP_CENTER_URL, "help_center")
  }, [])

  const handleLogout = (e) => {
    e.preventDefault()

    mixpanel.track("Log Out")

    dispatch(userActions.logOut())

    auth0.logout({ returnTo: `${window.location.protocol}//${window.location.host}` })
  }

  const handleDrawerOpen = () => {
    toggleNav(true)
  }

  const handleDrawerClose = () => {
    toggleNav(false)
  }

  const handleSelectAccount = useCallback(
    (customerCode) => {
      mergeSettings({
        [KEY_CUSTOMER]: customerCode,
      })

      mixpanel.register({
        customerCode,
      })

      ym("userParams", {
        customerCode,
      })

      dispatch(userActions.logOut())

      let { from } = state

      if (!from || from.pathname === "/select-account") {
        from = "/"
      }

      push(from)
    },
    [dispatch, state],
  )

  const toggleDrawerButton = () => (
    <IconButton
      data-testid="toggle-side-nav"
      onClick={isOpen ? handleDrawerClose : handleDrawerOpen}
      size="small"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
        left: isOpen ? `${drawerWidth - 15}px` : `calc(${theme.spacing(7)} - 15px)`,
        [theme.breakpoints.up("sm")]: {
          left: isOpen ? `${drawerWidth - 15}px` : `calc(${theme.spacing(8)} - 15px)`,
        },
        padding: "2px",
        position: "absolute",
        top: "40px",
        transition: "all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
        "&.MuiIconButton-root:hover": {
          backgroundColor: theme.palette.grey[200],
        },
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  )

  const moreOptionsMenu = (items = []) => (
    <Menu
      anchorEl={moreAnchor}
      id="more-menu"
      open={moreOpen}
      onClose={() => setMoreAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            backgroundColor: theme.palette.grey[100],
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
          },
        },
      }}
    >
      {items}
    </Menu>
  )

  const addMoreOptionsMenu = () => {
    let appCount = 1
    const appsToShow = []
    const moreApps = []
    const totalToShow = addMore === 1 ? 0 : addMore - bottomItemsCount - 1

    apps?.forEach((group, i) => {
      const appGroup = []
      group.apps.forEach((app) => {
        const { id, title, menuIcon, path, labels } = app
        const key = `app-${id}`
        if (appCount <= totalToShow) {
          appGroup.push(
            <NavItem
              key={key}
              icon={
                <NavIcon url={`${imagesBaseURL}/navigator/icons/cards/${menuIcon}`} />
              }
              mini={!isOpen}
              onClick={() => dispatch(push(path))}
              selected={path.includes(selected)}
              title={t(title)}
              labels={labels?.map((label) => t(label))}
            />,
          )
          appCount += 1
        } else {
          moreApps.push(
            <MenuItem
              key={key}
              onClick={() => dispatch(push(path))}
              selected={path.includes(selected)}
            >
              <ListItemIcon>
                <NavIcon
                  className="menu-icon"
                  url={`${imagesBaseURL}/navigator/icons/cards/${menuIcon}`}
                />
              </ListItemIcon>
              {t(title)}
            </MenuItem>,
          )
        }
      })
      const dividerKey = `divider-${i}`
      if (i !== apps?.length - 1 && appCount <= totalToShow) {
        appGroup.push(
          <Divider
            key={dividerKey}
            sx={{ borderColor: "rgba(255, 255, 255, 0.12)", margin: 1 }}
          />,
        )
      }
      if (i !== apps?.length - 1 && moreApps.length > 0) {
        moreApps.push(
          <Divider
            key={dividerKey}
            sx={{ borderColor: "rgba(0, 0, 0, 0.12)", margin: 1 }}
          />,
        )
      }
      appsToShow.push(...appGroup)
    })

    if (moreApps.length > 0) {
      if (appsToShow.length >= 1) {
        appsToShow.push(
          <Divider
            key="divider-more"
            sx={{ borderColor: "rgba(255, 255, 255, 0.12)", margin: 1 }}
          />,
        )
      }
      appsToShow.push(
        <NavItem
          key="more"
          title={t("More")}
          mini={!isOpen}
          icon={<Icon className="material-symbols-outline">more_horiz</Icon>}
          onClick={(ev) => setMoreAnchorEl(ev.currentTarget)}
        />,
      )
      appsToShow.push(moreOptionsMenu(moreApps))
    }

    // eslint-disable-next-line no-console
    console.log("totalToShow", totalToShow, appsToShow, moreApps)
    return appsToShow
  }

  return (
    <>
      <IllowBlocker active />
      {toggleDrawerButton()}
      <Drawer
        variant="permanent"
        open={isOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        }}
      >
        <NavLogo mini={!isOpen} onClick={handleClickHome} />
        <List sx={{ flexGrow: 1 }}>
          {addMore > 0 && addMoreOptionsMenu()}
          {addMore === 0 &&
            apps?.map((group, i) => (
              <>
                {group.apps.map((app) => {
                  const { id, title, menuIcon, path, labels } = app
                  const key = `app-${i}-${id}`
                  return (
                    <NavItem
                      key={key}
                      icon={
                        <NavIcon
                          url={`${imagesBaseURL}/navigator/icons/cards/${menuIcon}`}
                        />
                      }
                      mini={!isOpen}
                      onClick={() => dispatch(push(path))}
                      selected={path.includes(selected)}
                      title={t(title)}
                      labels={labels?.map((label) => t(label))}
                    />
                  )
                })}
                {i !== apps?.length - 1 && (
                  <Divider
                    // eslint-disable-next-line react/no-array-index-key
                    key={`divider-${i}`}
                    sx={{ borderColor: "rgba(255, 255, 255, 0.12)", margin: 1 }}
                  />
                )}
              </>
            ))}
        </List>

        <List>
          <NavItem
            title={t("Help")}
            icon={<Icon className="material-symbols-outlined">help</Icon>}
            mini={!isOpen}
            onClick={handleClickHelp}
          />
          <NavItem
            title={t("Account")}
            icon={<Icon className="material-symbols-outlined">person</Icon>}
            onClick={handleAccountClick}
            mini={!isOpen}
          />
          <AccountMenu
            user={userName}
            selectedAccount={account || code?.toUpperCase()}
            selectedCountry={country}
            customers={customers}
            onLogout={handleLogout}
            open={openMenu}
            anchorEl={anchorEl}
            onClose={handleAccountClose}
            onSelectCustomer={handleSelectAccount}
          />
        </List>
      </Drawer>
    </>
  )
}

export { SideNavigation }
