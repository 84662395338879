import { useDispatch, useSelector } from "react-redux"
import * as MatchActions from "../ducks/matches/action-creators"

export function useMatchSummary() {
  const dispatch = useDispatch()

  const summary = useSelector(({ matches }) => matches.statusSummary)

  function getSummary() {
    dispatch(MatchActions.getMatchStatusSummary())
  }

  return { summary, getSummary }
}
