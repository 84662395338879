import { KEY_INTERNAL_USER, getValue } from "./local-storage"

const INTERNAL_USER = getValue(KEY_INTERNAL_USER)

export const STATUS_REVIEW = "in_review"
export const STATUS_APPROVED = "approved"
export const STATUS_DISCARDED = "discarded"
export const STATUS_DISCARDED_BY_MOPS = "discarded_by_mops"
export const STATUS_UNMATCHED = "unmatched"

/**
 * It's not a valid status for matches in the backend,
 * and it's only used in the toggle group button
 */
export const STATUS_FLAGGED = "flagged"

const internalUserStatuses = [STATUS_DISCARDED_BY_MOPS]

export const matchStatusOptionsList = [
  {
    label: "Approved",
    value: STATUS_APPROVED,
  },
  {
    label: "In Review",
    value: STATUS_REVIEW,
  },
  {
    label: "Discarded",
    value: STATUS_DISCARDED,
  },
  {
    label: "Discarded by MOPs",
    value: STATUS_DISCARDED_BY_MOPS,
  },
].filter(({ value }) => !internalUserStatuses.includes(value) || INTERNAL_USER)

export const matchStatusWithUnmatched = matchStatusOptionsList.concat({
  label: "Unmatched",
  value: STATUS_UNMATCHED,
})

export const defaultMatchStatusesForReview = [STATUS_APPROVED, STATUS_REVIEW]
