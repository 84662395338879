import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import { IconButton } from "@mui/material"
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material"
import styles from "./ProductComparisonTableNavigation.module.scss"

const RIGHT_BUFFER = 10
const isScrollable = (element) => element?.scrollWidth > element?.clientWidth

function ProductComparisonTableNavigation({ parentID, size = 200 }) {
  const parent = document.getElementById(parentID)
  const [scrollLeft, setScrollLeft] = useState(parent?.scrollLeft || 0)

  if (!isScrollable(parent)) {
    return null
  }

  const moveLeft = () => {
    parent.scrollLeft -= size
    setScrollLeft(parent.scrollLeft)
  }

  const moveRight = () => {
    parent.scrollLeft += size
    setScrollLeft(parent.scrollLeft)
  }

  parent.onscroll = () => {
    if (parent.scrollLeft !== scrollLeft) {
      setScrollLeft(parent.scrollLeft)
    }
  }

  const empty = <span />

  return (
    <div className={styles.container}>
      {scrollLeft > 0 ? (
        <IconButton
          className={clsx(styles.button, styles.left)}
          aria-label="scroll left"
          onClick={moveLeft}
          size="large"
        >
          <ChevronLeftRounded fontSize="inherit" />
        </IconButton>
      ) : (
        empty
      )}
      {scrollLeft < parent.scrollWidth - parent.clientWidth - RIGHT_BUFFER ? (
        <IconButton
          className={clsx(styles.button, styles.right)}
          aria-label="scroll right"
          onClick={moveRight}
          size="large"
        >
          <ChevronRightRounded fontSize="inherit" />
        </IconButton>
      ) : (
        empty
      )}
    </div>
  )
}

ProductComparisonTableNavigation.propTypes = {
  parentID: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
}

ProductComparisonTableNavigation.defaultProps = {}

export { ProductComparisonTableNavigation }
