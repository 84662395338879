import * as Action from "./action-types"
import * as Handler from "./handlers"

import { ReducerFactory } from "../../../util/redux-helpers"

import {
  INITIAL_FILTERS,
  PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY,
  PRODUCT_SEARCH_WIDGET_MATCHES_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_LIST_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_NO_FILTERS_TOTAL_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_PAGE_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_QUERY_KEY,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_TOTAL_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_SUMMARY_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_QUERY_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_TOTAL_KEY,
  PRODUCT_SEARCH_WIDGET_SEARCHING_KEY,
  PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS,
} from "./constants"

const initialState = {
  [PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY]: "",
  [PRODUCT_SEARCH_WIDGET_REF_PROD_QUERY_KEY]: "",
  [PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY]: [],
  [PRODUCT_SEARCH_WIDGET_REF_PROD_TOTAL_KEY]: 0,
  [PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY]: 0,
  [PRODUCT_SEARCH_WIDGET_SEARCHING_KEY]: false,
  [PRODUCT_SEARCH_WIDGET_PRODUCTS_LIST_KEY]: [],
  [PRODUCT_SEARCH_WIDGET_PRODUCTS_TOTAL_KEY]: 0,
  [PRODUCT_SEARCH_WIDGET_PRODUCTS_PAGE_KEY]: 1,
  [PRODUCT_SEARCH_WIDGET_PRODUCTS_QUERY_KEY]: "",
  [PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY]: {},
  [PRODUCT_SEARCH_WIDGET_PRODUCTS_NO_FILTERS_TOTAL_KEY]: 0,
  [PRODUCT_SEARCH_WIDGET_MATCHES_KEY]: [],
  [PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS]: INITIAL_FILTERS,
  [PRODUCT_SEARCH_WIDGET_REF_PROD_SUMMARY_KEY]: {},
}

const reducer = ReducerFactory.create(initialState, {
  /* -------------------------------------------------------------------------- */
  /*                        search overlay event handlers                       */
  /* -------------------------------------------------------------------------- */
  [Action.PRODUCT_SEARCH_CLOSE]: Handler.closeProductSearchWidget,
  [Action.PRODUCT_SEARCH_SET]: Handler.openProductSearchWidget,
  [Action.PRODUCT_SEARCH_SET_QUERY]: Handler.setQuery,
  [Action.PRODUCT_SEARCH_SET_TOTAL]: Handler.setTotal,
  [Action.PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY]:
    Handler.listReferenceProductByQuery,
  [Action.PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY_ERROR]:
    Handler.listReferenceProductByQueryError,
  [Action.PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY_SUCCESS]:
    Handler.listReferenceProductByQuerySuccess,
  [Action.PRODUCT_SEARCH_LIST_BY_QUERY]: Handler.searchProducts,
  [Action.PRODUCT_SEARCH_LIST_BY_QUERY_ERROR]: Handler.searchProductsError,
  [Action.PRODUCT_SEARCH_LIST_BY_QUERY_SUCCESS]: Handler.searchProductsSuccess,
  [Action.PRODUCT_SEARCH_GET_SEARCH_FILTERS]: Handler.getSearchFilters,
  [Action.PRODUCT_SEARCH_GET_SEARCH_FILTERS_ERROR]: Handler.getSearchFiltersError,
  [Action.PRODUCT_SEARCH_GET_SEARCH_FILTERS_SUCCESS]: Handler.getSearchFiltersSuccess,
  [Action.PRODUCT_SEARCH_LIST_MATCHES]: Handler.listMatches,
  [Action.PRODUCT_SEARCH_LIST_MATCHES_ERROR]: Handler.listMatchesError,
  [Action.PRODUCT_SEARCH_LIST_MATCHES_SUCCESS]: Handler.listMatchesSuccess,
  [Action.PRODUCT_SEARCH_SET_SELECTED_FILTERS]: Handler.setSelectedFilters,
  [Action.PRODUCT_SEARCH_CLEAR_SELECTED_FILTERS]: Handler.clearSelectedFilters,
  [Action.PRODUCT_SEARCH_SET_REF_PRODUCT_PAGE]: Handler.setRefProductPage,
  [Action.PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY]: Handler.getRefProductSummary,
  [Action.PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY_ERROR]:
    Handler.getRefProductSummaryError,
  [Action.PRODUCT_SEARCH_GET_REF_PRODUCT_SUMMARY_SUCCESS]:
    Handler.getRefProductSummarySuccess,
})

export default reducer
