import { BaseSlideover } from "./partials/BaseSlideover"
import { BaseSlideoverFilter } from "./partials/BaseSlideoverFilter"
import { BaseSlideoverFilterHeader } from "./partials/BaseSlideoverFilterHeader"
import { BaseSlideoverFilterItem } from "./partials/BaseSlideoverFilterItem"
import { BaseSlideoverItem } from "./partials/BaseSlideoverItem"
import { BaseSlideoverRoot } from "./partials/BaseSlideoverRoot"

/**
 * Slideover usage:
 *
 * ```jsx
 * <Slideover title={title} open={open} onClose={onClose}>
 *   <Slideover.Filter>
 *     <Slideover.FilterHeader text={listHeader} />
 *     <Slideover.FilterItem checked={checked} text={itemText} onChange={onChange}>
 *   </Slideover.Filter>
 *   <Divider />
 *   <Slideover.Filter>
 *     <Slideover.FilterHeader text={listHeader} />
 *     <Slideover.FilterItem checked={checked} text={itemText} onChange={onChange}>
 *   </Slideover.Filter>
 *   <Divider />
 *   <Slideover.Filter>
 *     <Slideover.Item>
 *       <Typography>Some content</Typography>
 *     </Slideover.Item>
 *   </Slideover.Filter>
 * </Slideover>
 *```
 */

export const Slideover = Object.assign(BaseSlideover, {
  Filter: BaseSlideoverFilter,
  FilterHeader: BaseSlideoverFilterHeader,
  FilterItem: BaseSlideoverFilterItem,
  Item: BaseSlideoverItem,
  Root: BaseSlideoverRoot,
})
