import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

function IllowBlocker({ active }) {
  const widgetId = "illow-banner-widget"

  const listToAtrributeChanges = (element) => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes") {
          if (mutation.attributeName === "style") {
            if (element && element.style.left === "10px") {
              document.getElementById(widgetId).style.display = "none"
            }
          }
        }
      })
    })

    const config = { attributes: true, childList: true, subtree: true }
    observer.observe(element, config)
    return observer
  }

  useEffect(() => {
    if (!active) return null

    let attributeObserver = null
    let pageObserver = null
    const illow = document.getElementById(widgetId)

    if (illow) {
      if (illow.style.left === "10px") {
        illow.style.display = "none"
      }
      attributeObserver = listToAtrributeChanges(illow)
    } else {
      pageObserver = new MutationObserver(async (records) => {
        records.forEach((record) => {
          record.addedNodes.forEach((node) => {
            if (node.tagName === "IFRAME" && node.id === widgetId) {
              const banner = document.getElementById(widgetId)
              if (banner && banner.style.left === "10px") {
                banner.style.display = "none"
              }
              attributeObserver = listToAtrributeChanges(banner)
            }
          })
        })
      }).observe(document.body, { childList: true, subtree: true })
    }

    return () => {
      if (attributeObserver) {
        attributeObserver.disconnect()
      }
      if (pageObserver) {
        pageObserver.disconnect()
      }
    }
  }, [active])

  return <Box id={`${active ? "no" : "allow"}-illow-banner-widget`} />
}

IllowBlocker.propTypes = {
  active: PropTypes.bool,
}

IllowBlocker.defaultProps = {
  active: false,
}

export { IllowBlocker }
