import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import styles from "./BadgeCount.module.scss"

function BadgeCount({ absolute, count, color, size }) {
  return (
    <div
      className={clsx(styles.container, styles[color], styles[size], {
        [styles.absolute]: absolute,
      })}
    >
      <span>{count}</span>
    </div>
  )
}

BadgeCount.propTypes = {
  absolute: PropTypes.bool,
  count: PropTypes.number.isRequired,
  color: PropTypes.oneOf(["black", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
}

BadgeCount.defaultProps = {
  absolute: true,
  color: "black",
  size: "small",
}

export { BadgeCount }
