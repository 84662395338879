import { ReducerFactory } from "../../../util/redux-helpers"
import {
  MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
  MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_ERROR,
  MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_SUCCESS,
  MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS,
  MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS_ERROR,
  MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS_SUCCESS,
} from "./action-types"
import {
  handleGetOneMatchedProductById,
  handleGetOneMatchedProductByIdError,
  handleGetOneMatchedProductByIdSuccess,
  handleGetMatchedProductByIds,
  handleGetMatchedProductByIdsSuccess,
  handleGetMatchedProductByIdsError,
} from "./handlers"

const initialState = {
  documents: {},
}

const reducer = ReducerFactory.create(initialState, {
  [MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID]: handleGetOneMatchedProductById,
  [MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_ERROR]:
    handleGetOneMatchedProductByIdError,
  [MATCHED_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_SUCCESS]:
    handleGetOneMatchedProductByIdSuccess,
  [MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS]: handleGetMatchedProductByIds,
  [MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS_ERROR]: handleGetMatchedProductByIdsSuccess,
  [MATCHED_PRODUCTS_REPOSITORY_GET_BY_IDS_SUCCESS]: handleGetMatchedProductByIdsError,
})

export default reducer
