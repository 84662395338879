import React from "react"

import ArrowUpward from "@material-ui/icons/ArrowUpward"

import { AccessibleIcon } from "./AccessibleIcon"

export function IconSortASC(props) {
  return (
    <AccessibleIcon
      label="sort ascending"
      border="none"
      color="neutral"
      shape="circle"
      size="xs"
      {...props}
    >
      <ArrowUpward fontSize="inherit" />
    </AccessibleIcon>
  )
}
