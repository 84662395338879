import PropTypes from "prop-types"
import React, { forwardRef } from "react"
import clsx from "clsx"

import styles from "./TableContainer.module.scss"

const TableContainer = forwardRef(({ children, className, id }, ref) => (
  <div id={id} className={clsx(styles.container, className)} ref={ref}>
    {children}
  </div>
))

TableContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
}

TableContainer.defaultProps = {
  className: "",
  id: "",
}

export default TableContainer
