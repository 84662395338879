import React from "react"
import ReactPlaceholder from "react-placeholder"
import { IconTableExport } from "@tabler/icons-react"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  useProductGroupExport,
  useProductGroupsOutline,
  useProductGroupFilter,
} from "./hooks"

const ButtonPalceHolder = (
  <ReactPlaceholder
    className="rounded"
    showLoadingAnimation
    style={{ width: 128, height: 32, margin: "0" }}
    type="rect"
  />
)

function AssortmentAnalysisExportCategoryButton() {
  const { categoryExport, exportCategory } = useProductGroupExport()
  const { data: productGroups, isIdle, isFetching, isError } = useProductGroupsOutline()
  const [productGroupId] = useProductGroupFilter()

  if (isIdle || isFetching) {
    return ButtonPalceHolder
  }

  if (isError) {
    return null
  }

  if (!productGroups || (productGroups && productGroups.length === 0)) {
    return (
      <LoadingButton
        size="small"
        color="primary"
        variant="outlined"
        startIcon={<IconTableExport size={16} />}
        disabled
      >
        Export Category
      </LoadingButton>
    )
  }

  const productGroup =
    productGroups.find((opt) => opt.id === productGroupId) || productGroups[0]

  const { categoryId, categoryName } = productGroup

  async function handleExportClick() {
    exportCategory(categoryId)
  }

  return (
    <LoadingButton
      size="small"
      onClick={handleExportClick}
      color="primary"
      variant="outlined"
      loading={categoryExport.loading}
      loadingPosition="start"
      startIcon={<IconTableExport size={16} />}
      data-testid="assortment-export-category"
    >
      Export &quot;{categoryName}&quot;
    </LoadingButton>
  )
}

export { AssortmentAnalysisExportCategoryButton }
