const MATCH_OVERVIEW_COMPARISON_WIDGET = "DP_MT_FE_MATCH_OVERVIEW_COMPARISON_WIDGET"

const ASSORTMENT_ANALYSIS_CUSTOMER =
  "DP_MT_FE_ASSORTMENT_ANALYSIS_OVERVIEW_VISIBLE_BY_CUSTOMER"

const PRODUCT_SEARCH_WIDGET = "DP_MT_FE_PRODUCT_SEARCH_WIDGET"

const MATCH_OVERVIEW_FLAGS = "DP_MT_FE_MATCH_OVERVIEW_FLAGS"
const PRODUCT_SEARCH_WIDGET_IN_REVIEW = "DP_MT_FE_PRODUCT_SEARCH_WIDGET_REVIEW"
const MATCH_TYPE_STATUS_CHIP = "DP_MT_FE_MATCH_TYPE_STATUS_CHIP"
const ASSORTMENT_ANALYSIS_SHOP_FILTER = "DP_MT_FE_ASSORTMENT_ANALYSIS_SHOP_FILTER"

const featureNamesMap = {
  ASSORTMENT_ANALYSIS_CUSTOMER,
  MATCH_OVERVIEW_COMPARISON_WIDGET,
  PRODUCT_SEARCH_WIDGET,
  MATCH_OVERVIEW_FLAGS,
  PRODUCT_SEARCH_WIDGET_IN_REVIEW,
  MATCH_TYPE_STATUS_CHIP,
  ASSORTMENT_ANALYSIS_SHOP_FILTER,
}

export { featureNamesMap }
