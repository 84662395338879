export function handleMatchesOverviewPageChange(state, { page }) {
  if (page) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page,
      },
    }
  }

  return state
}

export function handleMatchesOverviewPageSizeChange(state, { pageSize }) {
  if (pageSize) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        pageSize,
      },
    }
  }

  return state
}
