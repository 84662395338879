import React, { useEffect, useState } from "react"
import useInterval from "../../hooks/use-interval"
import "./style.sass"

function NewAddressInfo({ destination }) {
  const [secsToRedirect, setSecsToRedirect] = useState(10)
  const [counterInterval, setCounterInterval] = useState(1000)

  useInterval(() => {
    setSecsToRedirect(secsToRedirect - 1)
  }, counterInterval)

  useEffect(() => {
    if (secsToRedirect === 0) {
      setCounterInterval(null)
      window.location = destination
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secsToRedirect])

  return (
    <div className="redirection-info">
      <div className="logo">
        <img
          alt="Daltix Navigator"
          src="https://daltix-public.s3.eu-west-1.amazonaws.com/static/DaltixNavigator-logo.png"
        />
      </div>
      <div className="message">
        Welcome! We moved to a new domain:
        <br />
        <a href={destination}>{destination}</a>
        Make sure you update your bookmark!
      </div>
      <div className="auto-redirect-message">
        You will be automatically redirected in {secsToRedirect} second
        {secsToRedirect !== 1 ? "s" : ""}.
      </div>
    </div>
  )
}

export default NewAddressInfo
