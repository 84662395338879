import PropTypes from "prop-types"
import React from "react"

import clsx from "clsx"

import styles from "./BaseDialogBody.module.scss"

function BaseDialogBody({ children, className, frame, mat }) {
  return (
    <section
      className={clsx(
        styles.body,
        {
          [styles["window-mat"]]: mat,
          [styles["window-frame"]]: frame,
        },
        className,
      )}
    >
      {children}
    </section>
  )
}

BaseDialogBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  frame: PropTypes.bool,
  mat: PropTypes.bool,
}

BaseDialogBody.defaultProps = {
  className: "",
  frame: true,
  mat: true,
}

export { BaseDialogBody }
