import SearchOutlined from "@mui/icons-material/SearchOutlined"
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { CloseRounded } from "@mui/icons-material"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import React, { useEffect, useMemo, useState } from "react"
import { PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS } from "../../../../ducks/widgets/product-search/constants"
import { useProductSearch } from "../../../../ducks/widgets/product-search/hooks"
import useShops from "../../../../hooks/use-shops"
import { STATUS_UNMATCHED } from "../../../../util/match-status"
import { titleCase } from "../../../../util/strings"

function DaltixProductAutocomplete() {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState("")
  const {
    state: { [PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS]: filters },
    listProductSuggestions,
    suggestions,
    setQuery,
    referenceProduct,
    query,
    setFilters,
  } = useProductSearch()
  const referenceProductName = referenceProduct?.data?.name
  const { items: shopsLookup } = useShops()

  const formattedSuggestions = useMemo(
    () =>
      suggestions.map((option, index) => {
        const brand = option.brand || ""
        const ean = option.eans?.join(", ") || ""
        const shop = option.shop || ""
        const title = option.name || ""

        const friendlyShopName = shopsLookup[shop]?.name || titleCase(shop)

        return {
          index,
          friendlyShopName,
          title,
          brand,
          ean,
          shop,
        }
      }),
    [suggestions, shopsLookup],
  )

  useEffect(() => {
    if (input === "" || input.length < 3 || query === input) {
      return undefined
    }

    return listProductSuggestions(input)
  }, [listProductSuggestions, input, query])

  useEffect(() => {
    if (!referenceProductName) {
      return
    }

    setInput((state) => {
      if (state !== referenceProductName) {
        listProductSuggestions(referenceProductName)
      }
      return referenceProductName
    })
  }, [listProductSuggestions, referenceProductName])

  const closePopper = () => setOpen(false)
  const openPopper = () => setOpen(true)

  const handleSearch = (searchText, unmatched = true) => {
    setQuery(searchText)
    setInput(searchText)

    const newStatuses = { ...filters.status, [STATUS_UNMATCHED]: unmatched }
    setFilters({ ...filters, status: newStatuses })
  }

  const onInputChange = (_, newValue, reason) => {
    if (reason !== "input") {
      return
    }
    setInput(newValue)
  }

  const onSelectOption = (_, newValue) => {
    handleSearch(newValue.title)
  }

  const handleEnterPressed = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value, event.target.value?.length > 0)
      closePopper()
    }
  }

  const clearInput = () => {
    handleSearch("", false)
    closePopper()
  }

  const renderOption = (props, option) => {
    if (!option) {
      return null
    }
    const { friendlyShopName, title, brand, ean, shop } = option

    const titleMatches = match(title, input)
    const titleParts = parse(titleCase(title), titleMatches)
    const brandMatches = match(brand, input)
    const brandParts = parse(titleCase(brand), brandMatches)
    const eanMatches = match(ean, input)
    const eanParts = parse(ean, eanMatches)

    return (
      <MenuItem {...props}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography>
            {titleParts.map((part, i) => {
              const key = `${part.text}-${i}-title`
              return (
                <Typography
                  key={key}
                  variant="span"
                  fontWeight={part.highlight ? 500 : 300}
                >
                  {part.text}
                </Typography>
              )
            })}
          </Typography>

          <Box display="flex" gap={1} alignItems="center">
            {brandParts.map((part, i) => {
              const key = `${part.text}-${i}-brand`
              return (
                <Typography
                  variant="span"
                  key={key}
                  fontWeight={part.highlight ? 500 : 300}
                >
                  {part.text}
                </Typography>
              )
            })}

            {brand && shop && <span> - </span>}

            {shop && (
              <Typography variant="body2" fontWeight={300}>
                {friendlyShopName}
              </Typography>
            )}
          </Box>

          <Typography
            variant="body2"
            overflow="auto !important"
            textAlign="center"
            fontSize="0.8rem"
          >
            {eanParts.map((part, i) => {
              const key = `${part.text}-${i}-ean`
              return (
                <Typography
                  variant="span"
                  key={key}
                  fontWeight={part.highlight ? 500 : 300}
                >
                  {part.text}
                </Typography>
              )
            })}
          </Typography>
        </Box>
      </MenuItem>
    )
  }

  return (
    <Autocomplete
      data-testid="daltix-product-autocomplete"
      autoComplete
      disableClearable
      loading={false}
      loadingText="Loading..."
      filterOptions={(x) => x}
      filterSelectedOptions
      forcePopupIcon={false}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.title)}
      includeInputInList
      isOptionEqualToValue={(option, selected) => option.index === selected.index}
      getOptionDisabled={(option) => option.title === query}
      noOptionsText={
        input && formattedSuggestions.length === 0
          ? "No products found"
          : "Type to search"
      }
      onChange={onSelectOption}
      onInputChange={onInputChange}
      open={open}
      onOpen={openPopper}
      onClose={closePopper}
      inputValue={input}
      value={query}
      onKeyUp={handleEnterPressed}
      options={input === "" ? [] : formattedSuggestions}
      renderOption={renderOption}
      size="small"
      sx={{ width: 375, backgroundColor: "white" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            sx: {
              ...params.InputProps.sx,
              fontSize: theme.typography.body2.fontSize,
            },
            startAdornment: (
              <InputAdornment position="start" sx={{ pl: "0.125rem" }}>
                <SearchOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disableRipple edge="end" onClick={clearInput}>
                  {input?.length > 0 ? (
                    <CloseRounded
                      fontSize="small"
                      sx={{ color: `${theme.palette.text.secondary} !important` }}
                    />
                  ) : null}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}

export { DaltixProductAutocomplete }
