import PropTypes from "prop-types"
import React from "react"

import ExpandMore from "@material-ui/icons/ExpandMore"

import { BaseSelectTrigger } from "./BaseSelectTrigger"
import { BaseSelectIcon } from "./BaseSelectIcon"
import { BaseSelectRoot } from "./BaseSelectRoot"
import { BaseSelectValue } from "./BaseSelectValue"
import { BaseSelectContent } from "./BaseSelectContent"
import { BaseScrollDownButton, BaseScrollUpButton } from "./BaseScrollButton"
import { BaseSelectViewport } from "./BaseSelectViewport"
import { BaseSelectPortal } from "./BaseSelectPortal"

const BaseSelect = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <BaseSelectRoot {...props}>
    <BaseSelectTrigger ref={forwardedRef}>
      <BaseSelectValue />
      <BaseSelectIcon>
        <ExpandMore />
      </BaseSelectIcon>
    </BaseSelectTrigger>
    <BaseSelectPortal>
      <BaseSelectContent position="popper" sideOffset={2} alignOffset={2}>
        <BaseScrollUpButton />
        <BaseSelectViewport>{children}</BaseSelectViewport>
        <BaseScrollDownButton />
      </BaseSelectContent>
    </BaseSelectPortal>
  </BaseSelectRoot>
))

BaseSelect.displayName = "BaseSelect"

BaseSelect.propTypes = {
  children: PropTypes.node.isRequired,
}

export { BaseSelect }
