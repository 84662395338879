import PropTypes from "prop-types"
import React, { forwardRef } from "react"
import * as RUIDialog from "@radix-ui/react-dialog"

import clsx from "clsx"

import "../../../../styles/_animations.scss"
import styles from "./BaseDialogContent.module.scss"

const BaseDialogContent = forwardRef(({ children, height, width, ...props }, ref) => {
  const widthClasss = `w-${width}`
  const heightClass = `h-${height}`

  return (
    <RUIDialog.Portal>
      <RUIDialog.Overlay className={clsx(styles.overlay, "fade-in")} />
      <RUIDialog.Content
        {...props}
        className={clsx(
          styles.content,
          styles[widthClasss],
          styles[heightClass],
          "content-in",
        )}
        ref={ref}
      >
        {children}
      </RUIDialog.Content>
    </RUIDialog.Portal>
  )
})

BaseDialogContent.displayName = RUIDialog.Content.displayName

BaseDialogContent.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.oneOf(["short", "normal", "tall", "tallest"]),
  width: PropTypes.oneOf(["thin", "normal", "wide", "widest"]),
}

BaseDialogContent.defaultProps = {
  height: "short",
  width: "thin",
}

export { BaseDialogContent }
