import React, { useCallback } from "react"
import PropTypes from "prop-types"
import mixpanel from "mixpanel-browser"
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  Typography,
  Divider,
} from "@material-ui/core"
import "./style.sass"
import { diffChange } from "../../util/array"

const DEFAULT_OPTION_KEY = (item) => item
const DEFAULT_OPTION_LABEL_RENDERER = (item) => item
const DEFAULT_GROUP_KEY = ({ group }) => group
const DEFAULT_GROUP_LABEL_RENDERER = ({ group }) => group
const DEFAULT_GROUP_SEPARATOR = ":"
const DEFAULT_ON_CHANGE = () => undefined
const KEY_ALL = "__all"

function countOptions(total, opt) {
  if (opt.children) {
    return total + opt.children.length
  }
  return total + 1
}

function defaultValueRender(label, selected, options) {
  let suffix = ""

  if (selected && selected.length > 0) {
    suffix = `(${
      selected.length === options.reduce(countOptions, 0) ? "all" : selected.length
    })`
  }

  return (
    <Typography color="primary">
      {label} {suffix}
    </Typography>
  )
}

function MultiSelect({
  InputProps,
  className,
  displaySelectAllOption,
  groupHeadClassName,
  groupItemsClassName,
  groupKey,
  groupLabelRenderer,
  groupSeparator,
  itemsClassName,
  label,
  onChange,
  optionKey,
  optionLabelRenderer,
  options,
  renderValue,
  selectClassName,
  selectProps,
  style,
  analyticsEventName,
  value,
  ...props
}) {
  const totalOptionsCount = options.reduce(countOptions, 0)
  const allItemsSelected = totalOptionsCount === value.length

  function track(eventValue, isAllClick) {
    const [action, diff] = diffChange(value, eventValue)

    const payload = {
      value: diff,
      action,
      label,
    }

    if (isAllClick && allItemsSelected) {
      payload.action = "added"
    } else if (isAllClick) {
      payload.action = "removed"
    }

    mixpanel.track(analyticsEventName, payload)
  }

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value: eventValue },
      } = event

      const allSelected = eventValue.includes(KEY_ALL)

      if (typeof analyticsEventName === "string" && analyticsEventName !== "") {
        track(eventValue, allSelected)
      }

      let finalValue = eventValue

      if (allSelected) {
        if (allItemsSelected) {
          finalValue = []
        } else {
          finalValue = options.reduce((result, option) => {
            const { children } = option

            if (children) {
              const groupId = groupKey(option)

              const childOptions = children.map(
                (childOption) => `${groupId}${groupSeparator}${optionKey(childOption)}`,
              )

              result.push(...childOptions)
            } else {
              result.push(optionKey(option))
            }

            return result
          }, [])
        }
      }

      const newEvent = {
        ...event,
        target: {
          ...event.target,
          value: finalValue,
        },
      }

      onChange(newEvent)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allItemsSelected,
      optionKey,
      groupKey,
      groupSeparator,
      onChange,
      options,
      analyticsEventName,
    ],
  )

  return (
    <FormControl
      {...props}
      style={style}
      className={`multi-select-container ${className}`}
    >
      <Select
        {...selectProps}
        className={`multi-select ${selectClassName}`}
        multiple
        displayEmpty
        value={value}
        onChange={handleChange}
        input={<Input {...InputProps} />}
        renderValue={(selected) => renderValue(label, selected, options)}
      >
        {totalOptionsCount > 1 && displaySelectAllOption && (
          <MenuItem
            className={`item ${itemsClassName}`}
            key="select-all"
            value={KEY_ALL}
          >
            <Checkbox
              className="checkbox"
              color="primary"
              checked={allItemsSelected}
              indeterminate={value.length > 0 && value.length < totalOptionsCount}
            />
            <ListItemText
              className="text"
              classes={{
                primary: "select-all",
              }}
              primary="Select All"
            />
          </MenuItem>
        )}
        {totalOptionsCount > 1 && displaySelectAllOption && <Divider />}
        {options.map((option) => {
          const { children } = option
          const optionId = optionKey(option)
          let result
          if (children) {
            const groupId = groupKey(option)

            result = [
              <MenuItem
                disabled
                className={`group-head ${groupHeadClassName}`}
                key={groupId}
              >
                <ListItemText
                  className="group-title"
                  primary={groupLabelRenderer(option)}
                />
              </MenuItem>,
              ...children.map((childOption) => (
                <MenuItem
                  key={`${groupId}${groupSeparator}${optionKey(childOption)}`}
                  className={`group-item ${groupItemsClassName}`}
                  value={`${groupId}${groupSeparator}${optionKey(childOption)}`}
                >
                  <Checkbox
                    className="checkbox"
                    color="primary"
                    checked={value.some(
                      (item) =>
                        item === `${groupId}${groupSeparator}${optionKey(childOption)}`,
                    )}
                  />
                  <ListItemText
                    className="text"
                    primary={optionLabelRenderer(childOption)}
                  />
                </MenuItem>
              )),
            ]
          } else {
            result = (
              <MenuItem
                className={`item ${itemsClassName}`}
                key={optionId}
                value={optionId}
              >
                <Checkbox
                  className="checkbox"
                  color="primary"
                  checked={value.includes(optionId)}
                />
                <ListItemText className="text" primary={optionLabelRenderer(option)} />
              </MenuItem>
            )
          }
          return result
        })}
      </Select>
    </FormControl>
  )
}

MultiSelect.propTypes = {
  className: PropTypes.string,
  displaySelectAllOption: PropTypes.bool,
  groupHeadClassName: PropTypes.string,
  groupItemsClassName: PropTypes.string,
  groupKey: PropTypes.func,
  groupLabelRenderer: PropTypes.func,
  groupSeparator: PropTypes.string,
  InputProps: PropTypes.shape({
    disabled: PropTypes.bool,
  }),
  itemsClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  optionKey: PropTypes.func,
  optionLabelRenderer: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      }).isRequired,
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
          }),
        ).isRequired,
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  ]),
  renderValue: PropTypes.func,
  selectClassName: PropTypes.string,
  selectProps: PropTypes.shape({
    readOnly: PropTypes.bool,
  }),
  style: PropTypes.shape({
    minWidth: PropTypes.string,
  }),
  analyticsEventName: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
}

MultiSelect.defaultProps = {
  InputProps: {
    disabled: false,
  },
  className: "",
  displaySelectAllOption: true,
  groupHeadClassName: "",
  groupItemsClassName: "",
  groupKey: DEFAULT_GROUP_KEY,
  groupLabelRenderer: DEFAULT_GROUP_LABEL_RENDERER,
  groupSeparator: DEFAULT_GROUP_SEPARATOR,
  itemsClassName: "",
  onChange: DEFAULT_ON_CHANGE,
  optionKey: DEFAULT_OPTION_KEY,
  optionLabelRenderer: DEFAULT_OPTION_LABEL_RENDERER,
  options: [],
  renderValue: defaultValueRender,
  selectClassName: "",
  selectProps: {
    readOnly: false,
  },
  style: {},
  analyticsEventName: "",
  value: [],
}

export default MultiSelect
