export const handleListReferenceProductAutoCompleteSuggestions = (
  state,
  { query = "" },
) => {
  const { productAutoCompleteSuggestionsQuery: prevQuery = "" } = state

  if (query !== prevQuery) {
    return {
      ...state,
      loadingAutoCompleteSuggestions: true,
      productAutoCompleteSuggestionsQuery: query,
      productAutoCompleteSuggestionsQueryChanged: true,
    }
  }

  return {
    ...state,
    productAutoCompleteSuggestionsQueryChanged: false,
  }
}

export const handleListReferenceProductAutoCompleteSuggestionsSuccess = (
  state,
  {
    payload: {
      products: { names, articleNrs, eans, brands },
    },
  },
) => ({
  ...state,
  loadingAutoCompleteSuggestions: false,
  autoCompleteSuggestions: [
    ...articleNrs.map((value) => ({
      type: "ref",
      title: value,
    })),
    ...eans.map((value) => ({
      type: "ean",
      title: value,
    })),
    ...names.map((value) => ({
      type: "name",
      title: value,
    })),
    ...brands.map((value) => ({
      type: "brand",
      title: value,
    })),
  ].slice(0, 15),
})

export const handleListReferenceProductAutoCompleteSuggestionsError = (state) => ({
  ...state,
  autoCompleteSuggestions: [],
  loadingAutoCompleteSuggestions: false,
})
