import React, { memo } from "react"
import Toolbar from "@material-ui/core/Toolbar"
import Icon from "@material-ui/core/Icon"
import SearchIcon from "@material-ui/icons/Search"
import { Button as DxButton, Icon as DxIcon } from "@daltix/ui-react"
import { countries } from "countries-list"
import ReactCountryFlag from "react-country-flag"
import MatchesBox from "../matches-box/MatchesBox"
import AddMatchesPanel from "../add-matches-panel/AddMatchesPanel"
import MultiSelect from "../multi-select/MultiSelect"
import { NULL } from "../../util/constants"
import { titleCase } from "../../util/strings"
import { ORIGIN_EXISTING } from "../../util/match-origin"
import RestrictedContent from "../restricted-content/RestrictedContent"
import { PERM_CREATE_MATCHES } from "../../util/permissions"
import useShops from "../../hooks/use-shops"
import { matchProductAvailabilityOptionsList } from "../../util/product-availability"
import { matchProductFactorOptionsList } from "../../util/product-factor"
import { matchTypOptionsList } from "../../util/match-type"
import { useHasMatchTypeStatusChip } from "../../integrations/split"

function CountryItem({ code, ...props }) {
  if (!code) {
    return (
      <div>
        Unknown
        {code}
      </div>
    )
  }

  const country = countries[code.toUpperCase()]

  return (
    <div>
      <span style={{ marginLeft: "-1.1em" }}>
        <ReactCountryFlag
          styleProps={{ marginBottom: ".2em" }}
          title={titleCase(country ? country.name : code)}
          code={code}
          svg
        />
      </span>{" "}
      {titleCase(country ? country.name : code)}
      <Counters {...props} />
    </div>
  )
}

function Counters({ matches = 0, searchResults = 0, showSearch }) {
  return (
    <span className="counters">
      <span title={`${matches} existing match${matches !== 1 ? "es" : ""}`}>
        {matches}
        <Icon className="fa fa-sm fa-puzzle-piece" />
      </span>
      {showSearch && (
        <span title={`${searchResults} search result${searchResults !== 1 ? "s" : ""}`}>
          {searchResults}
          <SearchIcon fontSize="inherit" />
        </span>
      )}
    </span>
  )
}

function FilterItem({ name, ...props }) {
  return (
    <div>
      {name === NULL ? "<Unknown>" : titleCase(name)}
      <Counters {...props} />
    </div>
  )
}

const MIXPANEL_MAKE_MATCHES_FILTER_CHANGE = "MT - Make Matches Filter Change"

function MatchesPanelPresenter({
  productId,
  product,
  addingMatches,
  existingMatchesList,
  loadingExistingMatches,
  existingMatchesError,
  noFilterTotalMatches,
  shopsList,
  selectedMatchedShops,
  brandsList,
  selectedMatchedBrands,
  countryList,
  selectedMatchedCountries,
  noFilterTotalSearchResults,
  selectedMatchedProductsAvailability,
  selectedMatchedProductsFactor,
  selectedMatchedProductsType,
  // events
  onAccordionChange,
  onMatchedShopsChange,
  onMatchedBrandsChange,
  onMatchedCountriesChange,
  onMatchedProductsAvailabilityChange,
  onMatchedProductsFactorChange,
  onMatchedProductTypeChange,
  onClearFilters,
}) {
  const loading = loadingExistingMatches
  const showSearchResults = noFilterTotalSearchResults !== undefined
  const { getByCode } = useShops()
  const hasMatchTypeStatusChip = useHasMatchTypeStatusChip()

  return (
    <div className="matches-panel fill-height-container">
      <Toolbar color="default" className="dx-permanent-filter-area">
        <form onSubmit={(e) => e.preventDefault()}>
          <MultiSelect
            className={`dx-form-group ${
              !shopsList || shopsList.length === 0 || loading ? "disabled" : ""
            }`}
            style={{ minWidth: "10rem" }}
            itemsClassName="filter-item"
            label="Shops"
            options={shopsList}
            value={selectedMatchedShops}
            onChange={onMatchedShopsChange}
            optionKey={({ name }) => name}
            optionLabelRenderer={({ name: code, ...shop }) => (
              <FilterItem
                {...shop}
                name={getByCode(code).name}
                showSearch={showSearchResults}
              />
            )}
            InputProps={{
              disabled: !shopsList || shopsList.length === 0 || loading,
            }}
            analyticsEventName={MIXPANEL_MAKE_MATCHES_FILTER_CHANGE}
          />
          <MultiSelect
            className={`dx-form-group ${
              !brandsList || brandsList.length === 0 || loading ? "disabled" : ""
            }`}
            style={{ minWidth: "10rem" }}
            itemsClassName="filter-item"
            label="Brands"
            options={brandsList}
            value={selectedMatchedBrands}
            onChange={onMatchedBrandsChange}
            optionKey={({ name }) => name}
            optionLabelRenderer={(brand) => (
              <FilterItem {...brand} showSearch={showSearchResults} />
            )}
            InputProps={{
              disabled: !brandsList || brandsList.length === 0 || loading,
            }}
            analyticsEventName={MIXPANEL_MAKE_MATCHES_FILTER_CHANGE}
          />
          <MultiSelect
            className={`dx-form-group ${
              !countryList || countryList.length === 0 || loading ? "disabled" : ""
            }`}
            style={{ minWidth: "10rem" }}
            itemsClassName="filter-item"
            label="Countries"
            options={countryList}
            value={selectedMatchedCountries}
            onChange={onMatchedCountriesChange}
            optionKey={({ name }) => name}
            optionLabelRenderer={({ name, ...country }) => (
              <CountryItem {...country} code={name} showSearch={showSearchResults} />
            )}
            InputProps={{
              disabled: !countryList || countryList.length === 0 || loading,
            }}
            analyticsEventName={MIXPANEL_MAKE_MATCHES_FILTER_CHANGE}
          />
          <MultiSelect
            className={`dx-form-group ${
              !brandsList || brandsList.length === 0 || loading ? "disabled" : ""
            }`}
            style={{ minWidth: "10rem" }}
            itemsClassName="filter-item"
            label="Availability"
            options={matchProductAvailabilityOptionsList}
            value={selectedMatchedProductsAvailability}
            onChange={onMatchedProductsAvailabilityChange}
            optionKey={({ value }) => value}
            optionLabelRenderer={({ label }) => label}
            InputProps={{
              disabled: !brandsList || brandsList.length === 0 || loading,
            }}
            analyticsEventName={MIXPANEL_MAKE_MATCHES_FILTER_CHANGE}
          />
          <MultiSelect
            className={`dx-form-group ${
              !brandsList || brandsList.length === 0 || loading ? "disabled" : ""
            }`}
            style={{ minWidth: "10rem" }}
            itemsClassName="filter-item"
            label="Factor"
            options={matchProductFactorOptionsList}
            value={selectedMatchedProductsFactor}
            onChange={onMatchedProductsFactorChange}
            optionKey={({ value }) => value}
            optionLabelRenderer={({ label }) => label}
            InputProps={{
              disabled: !brandsList || brandsList.length === 0 || loading,
            }}
            analyticsEventName={MIXPANEL_MAKE_MATCHES_FILTER_CHANGE}
          />
          {hasMatchTypeStatusChip && (
            <MultiSelect
              className={`dx-form-group ${
                !brandsList || brandsList.length === 0 || loading ? "disabled" : ""
              }`}
              style={{ minWidth: "10rem" }}
              itemsClassName="filter-item"
              label="Match Type"
              options={matchTypOptionsList}
              value={selectedMatchedProductsType}
              onChange={onMatchedProductTypeChange}
              optionKey={({ value }) => value}
              optionLabelRenderer={({ label }) => label}
              InputProps={{
                disabled: !brandsList || brandsList.length === 0 || loading,
              }}
              analyticsEventName={MIXPANEL_MAKE_MATCHES_FILTER_CHANGE}
            />
          )}
          <DxButton
            type="button"
            className="dx-form-group"
            size="s"
            iconRight={<DxIcon type="eraser" />}
            onClick={onClearFilters}
          >
            Reset Filters
          </DxButton>
        </form>
      </Toolbar>

      <MatchesBox
        productId={productId}
        product={product}
        className="acordion-item-first existing-matches-panel dx-panel"
        loading={loadingExistingMatches}
        matchesLabel="Existing"
        matchesList={existingMatchesList}
        noFilterTotalMatches={noFilterTotalMatches}
        source={ORIGIN_EXISTING}
        error={existingMatchesError}
        expanded={!addingMatches}
        onChange={onAccordionChange}
      />

      <RestrictedContent requiredPermissions={PERM_CREATE_MATCHES}>
        <AddMatchesPanel
          expanded={addingMatches}
          onChange={onAccordionChange}
          productId={productId}
        />
      </RestrictedContent>
    </div>
  )
}

export default memo(MatchesPanelPresenter)
