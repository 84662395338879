import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {{ dimension: string | undefined, setDimension: ({ dimension }) => void}}
 *  the currently selected dimension and a setter function
 */
export function useDimensionFilter() {
  const dispatch = useDispatch()

  const dimension = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
      ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
    ]),
  )

  function setDimension({ dimension: newDimension }) {
    dispatch(Actions.setAssortmentAnalysisDimensionFilter({ dimension: newDimension }))
  }

  return { dimension, setDimension }
}
