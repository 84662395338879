import { Box, Checkbox, Typography } from "@mui/material"
import React from "react"
import { STATUS_UNMATCHED } from "../../../../util/match-status"
import { DXIcon } from "../../../dx-icon/DXIcon"
import { MatchStatusIcon } from "../../../match-status-icon/MatchStatusIcon"
import { EANS } from "../EANS"
import { ProductTitle } from "./ProductTitle"
import { AdjustedPrice } from "./AdjustedPrice"
import { Comment } from "./Comment"
import { Content } from "./Content"
import { Country } from "./Country"
import { EditableCell } from "./EditableCell"
import { MatchActions } from "./MatchActions"
import { MatchLabel } from "./MatchLabel"
import { MatchSourceBadge } from "./MatchSourceBadge"
import { Price } from "./Price"
import { Retailer } from "./Retailer"
import { XFactor } from "./XFactor"

import StatusChip from "../../../status-chip"

function showStatusChip(row, newMatches, hasMatchTypeStatusChip = false) {
  if (hasMatchTypeStatusChip) {
    return (
      <StatusChip
        status={row.original.matched?.status || STATUS_UNMATCHED}
        matchId={row.original.matched?.id || newMatches[row.original.id]}
        outdated={row.original.outdated}
        source={row.original.source}
        matchType={row.original.matched?.match_type}
        matchSource={row.original.matched?.match_source}
      />
    )
  }
  return (
    <Box display="flex" gap={1.5} alignItems="center">
      <MatchStatusIcon
        status={row.original.matched?.status || STATUS_UNMATCHED}
        matchId={row.original.matched?.id || newMatches[row.original.id]}
      />
      <DXIcon
        tooltip={row.original.outdated ? "Inactive" : "Active"}
        type={row.original.outdated ? "inactive" : "active"}
      />
    </Box>
  )
}

/**
 * @type {import("@tanstack/react-table").Column[]}
 */
export const columns = [
  {
    // reserved to checkbox
    id: "select",
    size: 24,
    header: ({ table }) => (
      <Checkbox
        color="default"
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        size="small"
        sx={{ padding: "5px" }}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        color="default"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
        size="small"
        sx={{ padding: "5px" }}
      />
    ),
  },
  {
    accessorKey: "name",
    header: "Product",
    size: 320,
    cell: ({ row, size }) => (
      <ProductTitle
        name={row.original.name}
        url={row.original.display_url}
        images={row.original.images}
        size={size}
      />
    ),
  },
  {
    accessorKey: "status",
    header: "Status",
    enableSorting: false,
    size: 80,
    cell: ({ row, newMatches, hasMatchTypeStatusChip }) =>
      showStatusChip(row, newMatches, hasMatchTypeStatusChip),
  },
  {
    accessorKey: "source",
    header: "Source",
    enableSorting: false,
    size: 80,
    cell: ({ row, newMatches }) => (
      <MatchSourceBadge
        matchId={row.original.matched?.id || newMatches[row.original.id]}
        source={row.original.matched?.match_source}
      />
    ),
  },
  {
    accessorKey: "shop",
    header: "Retailer",
    size: 160,
    cell: ({ row }) => <Retailer shop={row.original.shop} />,
  },
  {
    accessorKey: "country",
    header: "Country",
    enableSorting: false,
    size: 160,
    cell: ({ row }) => <Country code={row.original.country} />,
  },
  {
    accessorKey: "brand",
    header: "Brand",
    enableSorting: false,
    size: 160,
    cell: ({ row }) => (
      <Typography textTransform="capitalize" variant="body2">
        {row.original.brand}
      </Typography>
    ),
  },
  {
    accessorKey: "ean",
    header: "EAN",
    enableSorting: false,
    size: 178,
    cell: ({ row }) => <EANS eans={row.original.eans} />,
  },
  {
    accessorKey: "content",
    header: "Content",
    enableSorting: false,
    size: 96,
    cell: ({ row }) => {
      if (!row.original.contents_v1) {
        return null
      }

      return <Content content={row.original.contents_v1} />
    },
  },
  {
    accessorKey: "price",
    header: "Price",
    size: 72,
    align: "right",
    cell: ({ row, newMatches }) => (
      <Price
        price={row.original.price}
        multFactor={row.original.matched?.mult_factor}
        matchId={row.original.matched?.id || newMatches[row.original.id]}
      />
    ),
  },
  {
    accessorKey: "factor",
    header: "MF",
    enableSorting: true,
    size: 110,
    align: "right",
    cell: ({ row, referenceProduct, newMatches }) => (
      <XFactor
        referenceProduct={referenceProduct}
        daltixProduct={row.original}
        matchId={row.original.matched?.id || newMatches[row.original.id]}
      />
    ),
  },
  {
    accessorKey: "adjustedPrice",
    header: "Adjusted",
    enableSorting: true,
    size: 95,
    align: "right",
    cell: ({ row, newMatches }) => (
      <AdjustedPrice
        price={row.original.price}
        multFactor={row.original.matched?.mult_factor}
        matchId={row.original.matched?.id || newMatches[row.original.id]}
      />
    ),
  },
  {
    accessorKey: "labels",
    header: "Match label",
    enableSorting: false,
    size: 160,
    cell: ({ row, referenceProduct, newMatches }) => {
      const matchId = row.original.matched?.id || newMatches[row.original.id]
      return (
        <EditableCell isDisabled={!matchId}>
          <MatchLabel
            daltixProductId={row.original.id}
            labels={row.original.matched?.labels}
            referenceProductId={referenceProduct?.id}
            matchId={matchId}
          />
        </EditableCell>
      )
    },
  },
  {
    accessorKey: "comments",
    header: "Comments",
    enableSorting: false,
    size: 300,
    cell: ({ row, referenceProduct, newMatches }) => {
      const matchId = row.original.matched?.id || newMatches[row.original.id]

      return (
        <EditableCell isDisabled={!matchId}>
          <Comment
            comment={row.original.matched?.comment}
            daltixProductId={row.original.id}
            matchId={matchId}
            referenceProductId={referenceProduct?.id}
          />
        </EditableCell>
      )
    },
  },
  {
    id: "actions",
    header: <Box pr={1}>Actions</Box>,
    align: "right",
    size: 120,
    cell: ({ row, referenceProduct, newMatches, onCompare }) => (
      <MatchActions
        daltixProductId={row.original.id}
        matchStatus={row.original.matched?.status}
        referenceProductId={referenceProduct?.id}
        matchId={row.original.matched?.id || newMatches[row.original.id]}
        onCompare={() => onCompare(row.original.id)}
      />
    ),
  },
]
