import { Typography } from "@mui/material"
import { number, string } from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

function AdjustedPrice({ multFactor = 1, price, matchId }) {
  const updatedMultFactor = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.mult_factor,
  )

  if (price === null || price === undefined) {
    return null
  }

  const effectiveMultFactor = updatedMultFactor || multFactor
  const ajustedPrice = price * effectiveMultFactor

  return (
    <Typography data-testid="adjusted-price" fontWeight={600} variant="body2">
      €{Number(ajustedPrice).toFixed(2)}
    </Typography>
  )
}

AdjustedPrice.propTypes = {
  multFactor: number,
  price: number,
  matchId: string,
}

AdjustedPrice.defaultProps = {
  multFactor: undefined,
  matchId: "",
  price: undefined,
}

export { AdjustedPrice }
