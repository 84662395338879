import { Tooltip, Typography } from "@mui/material"
import { string } from "prop-types"
import React, { memo } from "react"
import { getCountryName } from "../../../../util/intl"

const Country = memo(({ code }) => {
  if (!code) {
    return null
  }

  const countryName = getCountryName(code)

  return (
    <Tooltip
      title={countryName.length > 12 ? countryName : ""}
      placement="bottom"
      arrow
    >
      <Typography display="block" variant="body2" noWrap textOverflow="ellipsis">
        {countryName}
      </Typography>
    </Tooltip>
  )
})

Country.propTypes = {
  code: string.isRequired,
}

export { Country }
