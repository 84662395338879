import React, { useMemo } from "react"
import { Card, Icon } from "@daltix/ui-react"
import MatchesReviewItem from "../matches-review-item/MatchesReviewItem"
import { PAGE_SIZE } from "../../ducks/matches/common"
import "./MatchesReviewList.sass"

function MatchesReviewListPresenter({
  page,
  list,
  total,
  loadingMatchesList,
  loadingCategories,
  matchListError,
  filtered,
}) {
  const loading = useMemo(
    () =>
      loadingCategories ||
      (loadingMatchesList && total === undefined) ||
      (!matchListError && total === undefined),
    [loadingCategories, loadingMatchesList, total, matchListError],
  )

  if (matchListError) {
    return (
      <Card full>
        <div className="dx-error-message">
          <Icon type="warning" className="dx-color-red" />
          <div>
            <h2>Oops! Something went wrong! :(</h2>
            Sorry about this. Please try again. If the problem persists, contact us.
          </div>
        </div>
      </Card>
    )
  }

  if (total === 0 && !loadingMatchesList) {
    return (
      <Card full>
        <div className="dx-error-message">
          <Icon type="search" className="dx-color-green" />
          <div>
            <h2>Oops! We did not find any results for that set of filters/search!</h2>
            <p>Please try a different set of filters or a new search.</p>
          </div>
        </div>
      </Card>
    )
  }

  const firstItem = (page - 1) * PAGE_SIZE + 1
  const lastIndex = Math.min((page - 1) * PAGE_SIZE + PAGE_SIZE, total)

  if (loading) {
    return (
      <div className="matches-review-list loading-area">
        <Card
          waiting
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            height: "4rem",
            minHeight: "4rem",
            overflow: "visible",
          }}
        />
        Loading matches list...
      </div>
    )
  }

  return (
    <>
      {total !== undefined && (
        <div className="dx-results">
          <strong>{filtered && "Filtered"} Matches:</strong> {firstItem} to {lastIndex}{" "}
          of {total}
        </div>
      )}

      <Card
        full
        waiting={loading}
        style={
          loading
            ? {
                height: "calc(100vh - 30rem)",
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
              }
            : undefined
        }
      >
        <div className="dx-table-line dx-table-head">
          <div style={{ flexGrow: 1 }}>
            <div className="dx-table-cell dx-text-center" style={{ width: "50%" }}>
              Reference Product
            </div>
            <div className="dx-table-cell dx-text-center" style={{ width: "50%" }}>
              Match
            </div>
          </div>
          <div
            className="dx-table-cell dx-text-center"
            style={{ width: "195px", flexGrow: 0 }}
          >
            Actions
          </div>
        </div>

        <div>
          {list.map(({ ref_product_id, daltix_id, ...props }, idx, all) => (
            <MatchesReviewItem
              key={ref_product_id ? `${ref_product_id}:${daltix_id}` : idx}
              last={idx === list.length - 1}
              referenceProductGroupId={ref_product_id}
              daltixProductId={daltix_id}
              sameReferenceProduct={
                idx > 0 && all[idx - 1].ref_product_id === ref_product_id
              }
              {...props}
            />
          ))}
        </div>
      </Card>
    </>
  )
}

export default MatchesReviewListPresenter
