import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"
import { SORT_ASC, SORT_DESC } from "../../../util/sorting"

/**
 * @returns {{ dimensionDirection: 'asc' | 'desc', setDimensionDirection: () => void}}
 *  the currently selected dimension sort direction and a setter function
 */
export function useDimensionSort() {
  const dispatch = useDispatch()

  const dimensionDirection = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY,
      ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
    ]),
  )

  function setDimensionDirection() {
    const direction = dimensionDirection === SORT_ASC ? SORT_DESC : SORT_ASC

    dispatch(Actions.setAssortmentAnalysisDimensionSort({ direction }))
  }

  return { dimensionDirection, setDimensionDirection }
}
