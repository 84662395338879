export function customMatch(text, query) {
  if (!query) {
    return []
  }

  const results = []
  const trimmedQuery = query.trim().toLowerCase()
  const textLower = text.toLowerCase()
  const queryLength = trimmedQuery.length

  let indexOf = textLower.indexOf(trimmedQuery)

  while (indexOf > -1) {
    results.push([indexOf, indexOf + queryLength])
    indexOf = textLower.indexOf(query, indexOf + queryLength)
  }

  return results
}
