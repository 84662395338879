import { useRef } from "react"

function decimalToHexadecimal(dec) {
  return dec.toString(16).padStart(2, "0")
}

function generateId(len = 16) {
  const intArray = new Uint8Array(len / 2)

  window.crypto.getRandomValues(intArray)

  return Array.from(intArray, decimalToHexadecimal).join("")
}

export default function useStableId() {
  const ref = useRef(generateId)

  return ref.current
}
