import React from "react"

import MatchesOverviewTable from "./MatchesOverviewTable"
import MatchesOverviewToolbar from "./MatchesOverviewToolbar"

import "./MatchesOverviewPanel.scss"
import MatchesOverviewInfobar from "./MatchesOverviewInfobar"
import HtmlTitle from "../html-title/HtmlTitle"
import { ProductComparisonDialog } from "../product-comparison/ProductComparisonDialog"
import { ProductSearchDialog } from "../product-search/ProductSearch"

function MatchesOverviewPanel() {
  return (
    <>
      <HtmlTitle>Matches Overview</HtmlTitle>
      <ProductComparisonDialog />
      <ProductSearchDialog />
      <MatchesOverviewToolbar />
      <MatchesOverviewInfobar />
      <MatchesOverviewTable />
    </>
  )
}

MatchesOverviewPanel.propTypes = {}

export default MatchesOverviewPanel
