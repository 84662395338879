import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import resources from "./translations"

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: true,
    fallbackLng: "en",
  })

export default i18n
