import {
  STATUS_APPROVED,
  STATUS_REVIEW,
  STATUS_DISCARDED,
} from "../../../util/match-status"

/* eslint-disable import/prefer-default-export */

export const handleGetMatchStatusSummary = (state) => ({
  ...state,
  loadingStatusSummary: true,
})

export const handleGetMatchStatusSummarySuccess = (state, { payload }) => {
  const {
    [STATUS_APPROVED]: approved = 0,
    [STATUS_REVIEW]: inReview = 0,
    [STATUS_DISCARDED]: discarded = 0,
  } = payload

  return {
    ...state,
    statusSummary: {
      [STATUS_APPROVED]: approved,
      [STATUS_REVIEW]: inReview,
      [STATUS_DISCARDED]: discarded,
    },
    loadingStatusSummary: false,
    statusSummaryError: undefined,
  }
}

export const handleGetMatchStatusSummaryError = (state, action) => {
  const { error } = action

  return {
    ...state,
    statusSummary: undefined,
    loadingStatusSummary: false,
    statusSummaryError: error,
  }
}
