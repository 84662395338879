import React from "react"
import { connect } from "react-redux"
import MatchesReviewListPresenter from "./MatchesReviewListPresenter"
import { STATUS_DISCARDED, matchStatusOptionsList } from "../../util/match-status"
import { NULL } from "../../util/constants"

function MatchesReviewList(props) {
  const { list, matches, selectedMatchStatuses } = props

  const filteredList = list.filter(({ id }) => {
    const match = matches[id]

    return (
      !match ||
      match.pending ||
      !match.ready ||
      selectedMatchStatuses.includes(match.status) ||
      (selectedMatchStatuses.length === 0 && match.status !== STATUS_DISCARDED)
    )
  })

  return <MatchesReviewListPresenter {...props} list={filteredList} />
}

const mapStateToProps = ({
  matches: {
    list,
    matches,
    total,
    loadingMatchesList,
    availableMatchCountries,
    availableMatchShops,
    selectedMatchStatuses,
    selectedMatchSources,
    selectedMatchCountries,
    selectedMatchShops,
    selectedL1Categories,
    selectedL2Categories,
    selectedL3Categories,
    selectedL4Categories,
    matchListError,
    validSources,
    referenceProductCategories: { loadingCategoriesTree: loadingCategories },
  },
  refProduct: { referenceProductsQuery },
}) => {
  const nonNullSelectedMatchStatuses = selectedMatchStatuses.filter(
    (status) => status !== NULL,
  )

  return {
    matches,
    list,
    total,
    loadingMatchesList,
    selectedMatchStatuses: nonNullSelectedMatchStatuses,
    loadingCategories,
    matchListError,
    filtered:
      selectedL1Categories.length > 0 ||
      selectedL2Categories.length > 0 ||
      selectedL3Categories.length > 0 ||
      selectedL4Categories.length > 0 ||
      (nonNullSelectedMatchStatuses.length > 0 &&
        nonNullSelectedMatchStatuses.length < matchStatusOptionsList.length) ||
      (selectedMatchSources.length > 0 &&
        selectedMatchSources.length < validSources.length) ||
      (selectedMatchCountries.length > 0 &&
        selectedMatchCountries.length < availableMatchCountries.length) ||
      (selectedMatchShops.length > 0 &&
        selectedMatchShops.length < availableMatchShops.length) ||
      (referenceProductsQuery && referenceProductsQuery.length > 0),
  }
}

export default connect(mapStateToProps)(MatchesReviewList)
