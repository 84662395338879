/* eslint-disable import/prefer-default-export */

import { PAGE_SIZE } from "../common"

export const handleSetMatchListPage = (state, action) => {
  const { page } = action

  const { total } = state

  const pageSize = PAGE_SIZE + Math.min(total - page * PAGE_SIZE, 0)

  return {
    ...state,
    page,
    list: total !== undefined ? new Array(pageSize).fill({}) : [],
  }
}
