import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
} from "../../../../util/match-status"

function mutateMatchStatusFilter(state, status) {
  const nextMatchStatusFilter = state.filters.matches.status.includes(status)
    ? state.filters.matches.status.filter((s) => s !== status)
    : state.filters.matches.status.concat(status)

  return {
    ...state,
    filters: {
      ...state.filters,
      matches: {
        ...state.filters.matches,
        status: nextMatchStatusFilter,
      },
    },
  }
}

/** @deprecated */
export function handleMatchesOverviewMatchStatusApprovedFilterChange(state, _payload) {
  return mutateMatchStatusFilter(state, STATUS_APPROVED)
}

/** @deprecated */
export function handleMatchesOverviewMatchStatusDiscardedFilterChange(state, _payload) {
  return mutateMatchStatusFilter(state, STATUS_DISCARDED)
}

/** @deprecated */
export function handleMatchesOverviewMatchStatusInReviewFilterChange(state, _payload) {
  return mutateMatchStatusFilter(state, STATUS_REVIEW)
}

export function handleMatchesOverviewMatchStatusFilterChange(state, payload) {
  const { matchStatuses } = payload

  return {
    ...state,
    filters: {
      ...state.filters,
      matches: {
        ...state.filters.matches,
        status: matchStatuses,
      },
    },
  }
}
