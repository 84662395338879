import React, { memo } from "react"
import Typography from "@material-ui/core/Typography"
import LinearProgress from "@material-ui/core/LinearProgress"
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied"
import WarningIcon from "@material-ui/icons/WarningRounded"

import "./SearchPanel.sass"
import Matches from "../matches/Matches"
import { ORIGIN_SEARCH } from "../../util/match-origin"
import { ProductAutocompleteV1 } from "../product-autocomplete"

function SearchPanelPresenter({
  product,
  searchTerm,
  suggestions,
  suggestionsTotal,
  searchResults,
  totalSearchResults,
  noFilterTotalSearchResults,
  hasMoreResults,
  loadingResults,
  // handlers
  onSearchByReferenceProductName,
  onSearchTermChange,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  onSuggestionSelected,
  onLoadMoreResults,
}) {
  let content = null

  if (loadingResults && searchResults.length === 0) {
    content = (
      <div className="search-results loading-container suggested-matches-list flex v center grow">
        <Typography component="span" style={{ marginRight: 20 }}>
          Loading results...
        </Typography>
        <LinearProgress variant="query" style={{ width: "80%" }} />
      </div>
    )
  } else if (totalSearchResults === 0) {
    let resultsText = null
    let icon = null
    if (noFilterTotalSearchResults > 0) {
      resultsText = (
        <span>
          <strong>{noFilterTotalSearchResults}</strong> result
          {noFilterTotalSearchResults > 1 ? "s" : ""} hidden by selected filters
        </span>
      )
      icon = <WarningIcon />
    } else {
      resultsText = "No results found"
      icon = <SentimentVeryDissatisfiedIcon />
    }

    content = (
      <div className="search-results flex v center suggested-matches-list aux-text">
        {icon}
        <Typography className="aux-text">{resultsText}</Typography>
      </div>
    )
  } else if (totalSearchResults > 0) {
    let resultsText = null
    if (totalSearchResults < noFilterTotalSearchResults) {
      resultsText = (
        <span>
          showing <strong>{totalSearchResults}</strong> filtered result
          {totalSearchResults > 1 ? "s" : ""}
          &nbsp; (<strong>{noFilterTotalSearchResults}</strong> found)
        </span>
      )
    } else {
      resultsText = (
        <span>
          <strong>{noFilterTotalSearchResults}</strong> result
          {noFilterTotalSearchResults > 1 ? "s" : ""} found
        </span>
      )
    }

    content = (
      <div className="grow">
        <Matches
          className="search-results"
          referenceProductId={product.id}
          source={ORIGIN_SEARCH}
          matches={searchResults}
          scrollContainerClass={`search-scroll-container-${product.id}`}
          hasMore={hasMoreResults}
          onLoadMoreResults={onLoadMoreResults}
        />
        <span className="result-count" data-testid="results-count">
          {resultsText}
        </span>
      </div>
    )
  } else if (product.name) {
    content = (
      <div className="search-results fast-search-container">
        <button
          className="fast-search link-button"
          onClick={onSearchByReferenceProductName}
          type="button"
        >
          Search for &quot;
          <strong>{product.name}</strong>
          &quot;
        </button>
      </div>
    )
  }

  return (
    <div className="search-panel">
      <ProductAutocompleteV1
        className="search-field"
        placeholder="Search for product name, brand, description, ingredients, EAN or Daltix ID..."
        disabled={!product.id}
        value={searchTerm}
        suggestions={suggestions}
        suggestionsTotal={suggestionsTotal}
        onValueChange={onSearchTermChange}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSelected={onSuggestionSelected}
      />

      {content}
    </div>
  )
}

export default memo(SearchPanelPresenter)
