import React, { useState, useEffect, useMemo, useCallback } from "react"
import mixpanel from "mixpanel-browser"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import ListItem from "@material-ui/core/ListItem"

import { actionCreators as daltixProductActions } from "../../ducks/daltix-product"
import { actionCreators as matchProductActions } from "../../ducks/matches"
import { MatchFallbackPresenter } from "./MatchPresenter"
import MatchItem from "../match-item"

import { normalizeUnitValue, getBestProductContentV1 } from "../../util/volumes"
import { Logger } from "../../util/log"
import { withErrorBoundary } from "../../hocs/error-boundary"
import useFeatures from "../../hooks/use-features"
import { FEAT_MATCH_MUST_REVIEW } from "../../util/features"
import { STATUS_APPROVED, STATUS_REVIEW } from "../../util/match-status"
import { isDebug } from "../../util/env"
import { getValue, KEY_INTERNAL_USER } from "../../util/local-storage"
import { diffChange } from "../../util/array"

const log = new Logger("ui:Match")

const INTERNAL_USER = getValue(KEY_INTERNAL_USER)

const features = [FEAT_MATCH_MUST_REVIEW]

function Match(props) {
  const dispatch = useDispatch()

  const {
    referenceProductId,
    source,
    mult_factor: factor,
    labels,
    comment,
    daltix_id: daltixId,
    error,
    loading,
    last,
    daltixProduct,
    match_ids: matchIds,
    mult_factor: multFactor,
    daltixProduct: { shop, country, contents_v1: contentsV1 } = {},
  } = props

  const content = contentsV1 ? getBestProductContentV1(contentsV1) : {}
  const {
    multiplier: matchContentMultiplier = 1,
    value: matchContentValue,
    unit: matchContentUnit,
  } = content

  const {
    product: { contents_v1: refProdContentsV1 } = {},
    product,
    daltixProducts,
  } = useSelector(
    ({
      daltixProduct: daltixProductStore,
      refProduct: { detailedProductIndex, referenceProducts, offset, list },
    }) => ({
      product:
        detailedProductIndex !== undefined
          ? referenceProducts[list[detailedProductIndex - offset]]
          : undefined,
      daltixProducts: daltixProductStore.daltixProducts,
    }),
    shallowEqual,
  )

  const refProdContent = refProdContentsV1
    ? getBestProductContentV1(refProdContentsV1)
    : {}

  const {
    multiplier: productContentMultiplier = 1,
    value: productContentValue,
    unit: productContentUnit,
  } = refProdContent

  const [comparisonOpen, setComparisonOpen] = useState(false)

  const handleComparisonOpen = useCallback(() => {
    mixpanel.track("MT - Product Zoom In")

    setComparisonOpen(true)
  }, [])

  const handleComparisonClose = useCallback(() => {
    setComparisonOpen(false)
  }, [])

  const [mustReviewBeforeApproving = false] = useFeatures({ names: features })

  const initialMatchStatus = mustReviewBeforeApproving ? STATUS_REVIEW : STATUS_APPROVED

  useEffect(() => {
    log.debug("daltix ID: ", daltixId, daltixProducts[daltixId])

    if (!daltixProducts[daltixId] || error) {
      log.debug("missing product for daltix ID: ", daltixId)
      dispatch(daltixProductActions.getDxProductsByIds([daltixId]))
    }
  }, [dispatch, loading, daltixId, daltixProducts, error])

  const calculatedFactor = useMemo(() => {
    if (!factor) {
      const calcUnit = productContentUnit || matchContentUnit

      let computedFactor

      let normalizedMatchContentValueCalc
      const normalizedForCalc = normalizeUnitValue(
        matchContentUnit,
        matchContentValue,
        calcUnit,
      )
      if (normalizedForCalc) {
        ;[normalizedMatchContentValueCalc] = normalizedForCalc
      }

      if (
        productContentValue &&
        productContentMultiplier &&
        normalizedMatchContentValueCalc &&
        matchContentMultiplier
      ) {
        const dividend = productContentValue * productContentMultiplier
        const divisor = normalizedMatchContentValueCalc * matchContentMultiplier
        computedFactor = dividend / divisor
      }

      return computedFactor
    }

    return undefined
  }, [
    factor,
    matchContentUnit,
    matchContentMultiplier,
    matchContentValue,
    productContentMultiplier,
    productContentUnit,
    productContentValue,
  ])

  const handleNewMatch = useCallback(() => {
    dispatch(
      matchProductActions.createManualMatch({
        source,
        referenceProductId,
        daltixProductId: daltixId,
        shop,
        country,
        status: initialMatchStatus,
        factor: factor || calculatedFactor || 1,
      }),
    )
  }, [
    dispatch,
    source,
    referenceProductId,
    daltixId,
    calculatedFactor,
    country,
    factor,
    shop,
    initialMatchStatus,
  ])

  const handleFactorChange = useCallback(
    (newFactor) => {
      dispatch(
        matchProductActions.updateMatchBasicInfo({
          daltixProductId: daltixId,
          factor: newFactor,
          comment,
          labels,
          referenceProductId,
          source,
        }),
      )
    },
    [dispatch, source, referenceProductId, daltixId, labels, comment],
  )

  const handleLabelsChange = useCallback(
    ({ target: { value: newLabels } }) => {
      const [action, label] = diffChange(labels, newLabels)

      mixpanel.track("MT - Make Matches Labels Changed", {
        action,
        label,
      })

      dispatch(
        matchProductActions.updateMatchBasicInfo({
          source,
          referenceProductId,
          daltixProductId: daltixId,
          factor,
          labels: newLabels,
          comment,
        }),
      )
    },
    [dispatch, source, referenceProductId, daltixId, labels, factor, comment],
  )

  const handleCommentChange = useCallback(
    (newComment) => {
      if (!comment && newComment !== "") {
        mixpanel.track("MT - Add Comment")
      }

      dispatch(
        matchProductActions.updateMatchBasicInfo({
          comment: newComment,
          daltixProductId: daltixId,
          factor,
          labels,
          referenceProductId,
          source,
        }),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, source, referenceProductId, daltixId, labels, factor],
  )

  const handleUnmatch = useCallback(() => {
    dispatch(
      matchProductActions.discardMatch({
        source,
        referenceProductId,
        daltixProductId: daltixId,
      }),
    )
  }, [dispatch, source, referenceProductId, daltixId])

  const handleRestore = useCallback(() => {
    dispatch(
      matchProductActions.restoreMatch({
        source,
        referenceProductId,
        daltixProductId: daltixId,
        status: initialMatchStatus,
      }),
    )
  }, [dispatch, source, referenceProductId, daltixId, initialMatchStatus])

  const handleApprove = useCallback(() => {
    if (multFactor === undefined || multFactor === null) {
      // if the factor is not set, set it to 1 when it's approved
      handleFactorChange(1)
    }
    dispatch(
      matchProductActions.approveMatch({
        source,
        referenceProductId,
        daltixProductId: daltixId,
      }),
    )
  }, [multFactor, dispatch, source, referenceProductId, daltixId, handleFactorChange])

  const handleUndoApproval = useCallback(() => {
    dispatch(
      matchProductActions.removeMatchApproval({
        source,
        referenceProductId,
        daltixProductId: daltixId,
      }),
    )
  }, [dispatch, source, referenceProductId, daltixId])

  const handleNavigateToStore = useCallback(({ url, name }) => {
    mixpanel.track("See Product in Store", {
      origin: "make matches",
      type: "daltix product",
      url,
      name,
    })
  }, [])

  if (error) {
    throw error
  }

  const debugProps = {}

  if (isDebug() || INTERNAL_USER) {
    debugProps["data-ref-product-id"] = referenceProductId
    debugProps["data-dx-product-id"] = daltixId
    debugProps["data-match-ids"] = matchIds
  }

  return (
    <ListItem
      data-testid="matches-list-item"
      divider={!last}
      className="suggested-match-item match-item"
      {...debugProps}
    >
      <MatchItem
        {...props}
        referenceProduct={product}
        id={daltixId}
        daltixProduct={daltixProduct}
        comparisonOpen={comparisonOpen}
        onComparisonOpen={handleComparisonOpen}
        onComparisonClose={handleComparisonClose}
        onMatch={handleNewMatch}
        onFactorChange={handleFactorChange}
        onApprove={handleApprove}
        onUndoApproval={handleUndoApproval}
        onUnmatch={handleUnmatch}
        onRestore={handleRestore}
        onSuggestionRestored={handleRestore}
        onNavigateToStore={handleNavigateToStore}
        onLabelsChange={handleLabelsChange}
        onCommentChange={handleCommentChange}
      />
    </ListItem>
  )
}

export { Loader } from "./MatchPresenter"

export default withErrorBoundary(Match, MatchFallbackPresenter)
