import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import AppPropTypes from "../../../prop-shapes"
import styles from "./TableColumnHeader.module.scss"

function TableColumnHeader({ children, className, ...props }) {
  return (
    <div className={clsx(styles.container, className)} {...props}>
      {children}
    </div>
  )
}

TableColumnHeader.propTypes = {
  children: AppPropTypes.childrenElements.isRequired,
  className: PropTypes.string,
}

TableColumnHeader.defaultProps = {
  className: "",
}

export default TableColumnHeader
