import React from "react"
import PropTypes from "prop-types"
import {
  Badge,
  Chip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import "./nav-logo.scss"

function NavItem({ title, icon, mini, onClick, labels, selected, ...rest }) {
  const theme = useTheme()

  return (
    <ListItem disablePadding sx={{ px: 1 }} {...rest}>
      <Tooltip title={mini ? title : ""} placement="right" arrow>
        <ListItemButton
          selected={selected}
          onClick={onClick}
          sx={[
            {
              minHeight: 40,
              px: 1,
              "&.Mui-selected,  &.Mui-selected:hover": {
                backgroundColor: theme.palette.common.white,
                borderRadius: 1,
                "*.MuiTypography-root": {
                  color: theme.palette.primary.main,
                },
                "*.MuiListItemIcon-root": {
                  color: theme.palette.primary.main,
                },
                ".fallback-icon": {
                  filter:
                    "invert(23%) sepia(45%) saturate(1283%) hue-rotate(219deg) brightness(46%) contrast(80%)",
                },
              },
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.04)",
                borderRadius: 1,
                "*.MuiTypography-root": {
                  color: theme.palette.common.white,
                },
                "*.MuiListItemIcon-root": {
                  color: theme.palette.common.white,
                },
                ".fallback-icon": {
                  filter:
                    "invert(86%) sepia(99%) saturate(2%) hue-rotate(112deg) brightness(220%) contrast(101%)",
                },
              },
            },
            mini
              ? {
                  justifyContent: "center",
                }
              : {
                  justifyContent: "initial",
                },
          ]}
        >
          <ListItemIcon
            sx={[
              {
                minWidth: 0,
                justifyContent: "center",
                color: selected
                  ? theme.palette.primary.main
                  : theme.palette.primary.contrastText,
                ml: 0.5,
              },
              mini
                ? {
                    mr: "auto",
                  }
                : {
                    mr: 1,
                  },
            ]}
          >
            <Badge color="secondary" variant="dot" invisible={!labels || !mini}>
              {icon}
            </Badge>
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <Typography
                  color={
                    selected
                      ? theme.palette.primary.main
                      : theme.palette.secondary.contrastText
                  }
                  sx={{ paddingRight: 1 }}
                  variant="body1"
                >
                  {title}
                </Typography>
                {labels &&
                  !mini &&
                  labels.map((label) => (
                    <Tooltip
                      title={label.length > 3 ? label : ""}
                      placement="right"
                      arrow
                    >
                      <Chip
                        key={label}
                        color="secondary"
                        label={label}
                        size="small"
                        sx={{
                          color: "rgba(0, 0, 0, 0.87)",
                          maxWidth: 56,
                        }}
                      />
                    </Tooltip>
                  ))[0]}
              </>
            }
            sx={{
              opacity: mini ? 0 : 1,
            }}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  )
}

NavItem.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  mini: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.bool,
}

NavItem.defaultProps = {
  onClick: () => {},
  labels: null,
  selected: false,
}

export { NavItem }
