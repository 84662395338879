import PropTypes from "prop-types"
import React from "react"
import * as RUIDialog from "@radix-ui/react-dialog"

import styles from "./BaseDialogTrigger.module.scss"

function BaseDialogTrigger({ children, ...props }) {
  return (
    <RUIDialog.Trigger {...props} asChild className={styles.trigger}>
      {children}
    </RUIDialog.Trigger>
  )
}

BaseDialogTrigger.propTypes = {
  children: PropTypes.node.isRequired,
}

export { BaseDialogTrigger }
