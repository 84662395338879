import React from "react"
import { Box } from "@mui/material"

import styles from "./AssortmentAnalysisToolbar.module.scss"
import { AssortmentAnalysisControl } from "./AssortmentAnalysisControl"
import { AssortmentAnalysisProductSegmentAutocomplete } from "./AssortmentAnalysisProductSegmentAutocomplete"
import { AssortmentAnalysisDimensionSelector } from "./AssortmentAnalysisDimensionSelector"
import { AssortmentAnalysisExportButton } from "./AssortmentAnalysisExportButton"
import { AssortmentAnalysisExportCategoryButton } from "./AssortmentAnalysisExportCategoryButton"
import { AssortmentAnalysisFilters } from "./AssortmentAnalysisFilters"

function AssortmentAnalysisToolbar() {
  return (
    <div className={styles["toolbar-root"]} data-testid="matches-overview-toolbar">
      <ul className={styles["control-list"]} style={{ justifyContent: "start" }}>
        <AssortmentAnalysisControl justify="start">
          <AssortmentAnalysisProductSegmentAutocomplete />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl>
          <AssortmentAnalysisDimensionSelector />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl>
          <AssortmentAnalysisFilters />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl grows justify="end">
          <AssortmentAnalysisExportCategoryButton />
          <Box ml={1} />
          <AssortmentAnalysisExportButton />
        </AssortmentAnalysisControl>
      </ul>
    </div>
  )
}

AssortmentAnalysisToolbar.propTypes = {}

AssortmentAnalysisToolbar.defaultProps = {}

export default AssortmentAnalysisToolbar
