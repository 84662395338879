import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import mixpanel from "mixpanel-browser"
import MatchesPanelPresenter from "./MatchesPanelPresenter"
import { actionCreators as referenceProductsActions } from "../../ducks/reference-products"
import { actionCreators as daltixProductActions } from "../../ducks/daltix-product"
import { combineFilters, countFilterOptions } from "../../util/filter"
import { Logger } from "../../util/log"
import { label2shop, NULL } from "../../util/constants"

import "./MatchesPanel.sass"
import { mergeSettings } from "../../util/local-storage"
import { sanitizeEan } from "../../util/strings"
import {
  STATUS_REVIEW,
  STATUS_APPROVED,
  STATUS_DISCARDED,
} from "../../util/match-status"
import { ORIGIN_SEARCH } from "../../util/match-origin"
import { sameBrand } from "../../util/brands"
import {
  AVAILABILITY_ACTIVE,
  AVAILABILITY_INACTIVE,
  defaultProductAvailabilityValues,
} from "../../util/product-availability"
import { defaultProductFactorValues } from "../../util/product-factor"
import { allMatchTypeValues } from "../../util/match-type"

const log = new Logger("ui:MatchesPanel")

function highlightEanMatches(matches, productEan) {
  const eanMatches = matches
    .filter(
      ({ eans }) =>
        productEan &&
        eans &&
        eans.map((ean) => sanitizeEan(ean)).includes(sanitizeEan(productEan)),
    )
    .map((match) => ({
      ...match,
      eanMatch: true,
    }))

  const otherMatches = matches.filter(
    ({ eans }) =>
      !eans ||
      !productEan ||
      !eans.map((ean) => sanitizeEan(ean)).includes(sanitizeEan(productEan)),
  )

  return [...eanMatches, ...otherMatches]
}

function MatchesPanel({
  daltixProducts,
  acceptedSearchResults,
  referenceProducts,
  loadingExistingMatches,
  existingMatchesList,
  selectedMatchedShops,
  selectedMatchedBrands,
  selectedMatchedCountries,
  showDiscardedMatches,
  selectedMatchedProductsAvailability,
  selectedMatchedProductsFactor,
  selectedMatchedProductsType,
  searchTerm,
  searchFilterOptions = {},
  noFilterTotalSearchResults,
  // actions
  searchDxProducts,
  getDxProductsByIds,
  selectMatchedShops,
  selectMatchedBrands,
  selectMatchedCountries,
  setShowDiscardMatches,
  selectMatchedProductsAvailability,
  selectMatchedProductsFactor,
  selectMatchedProductsType,
  // rest
  ...props
}) {
  const [addingMatches, setAddingMatches] = useState(false)
  const [autoFilterBrands, setAutoFilterBrands] = useState(false)

  const toogleAddingMatches = useCallback(() => {
    setAddingMatches(!addingMatches)
  }, [addingMatches])

  const handleAutoFilterBrandsChange = ({ target: { checked } }) => {
    setAutoFilterBrands(checked)
    mergeSettings({
      defaultAutoFilterBrands: checked,
    })
  }

  const handleMatchedShopsChange = ({ target: { value } }) => {
    selectMatchedShops(value)
    if (searchTerm) {
      searchDxProducts(searchTerm)
    }
  }

  const handleMatchedBrandsChange = ({ target: { value } }) => {
    selectMatchedBrands(value)
    if (searchTerm) {
      searchDxProducts(searchTerm)
    }
  }

  const handleMatchedCountriesChange = ({ target: { value } }) => {
    selectMatchedCountries(value)
    if (searchTerm) {
      searchDxProducts(searchTerm)
    }
  }

  const handleMatchedProductsAvailabilityChange = ({ target: { value } }) => {
    selectMatchedProductsAvailability(value)
    if (searchTerm) {
      searchDxProducts(searchTerm)
    }
  }

  const handleMatchedProductsFactorChange = ({ target: { value } }) => {
    selectMatchedProductsFactor(value)
    if (searchTerm) {
      searchDxProducts(searchTerm)
    }
  }

  const handleMatchedProductsTypeChange = ({ target: { value } }) => {
    selectMatchedProductsType(value)
    if (searchTerm) {
      searchDxProducts(searchTerm)
    }
  }

  const handleClearFilters = () => {
    mixpanel.track("Clicked Reset Filters")

    selectMatchedCountries([])
    selectMatchedShops([])
    selectMatchedBrands([])
    selectMatchedProductsAvailability(defaultProductAvailabilityValues)
    selectMatchedProductsFactor(defaultProductFactorValues)
    selectMatchedProductsType(allMatchTypeValues)

    if (searchTerm) {
      searchDxProducts(searchTerm)
    }
  }

  const filterDiscarded = ({ status }) =>
    showDiscardedMatches || status !== STATUS_DISCARDED

  const filterShops = ({ shop }) => {
    if (selectedMatchedShops && selectedMatchedShops.length > 0) {
      return selectedMatchedShops.includes(shop)
    }
    return true
  }

  const { productId } = props
  const product = referenceProducts[productId]

  const filterBrands = ({ daltixProduct: { brand = NULL } }) => {
    if (selectedMatchedBrands && selectedMatchedBrands.length > 0) {
      return selectedMatchedBrands.includes(brand.toLowerCase())
    }
    return true
  }

  const filterCountries = ({ daltixProduct: { country } }) => {
    if (selectedMatchedCountries && selectedMatchedCountries.length > 0) {
      return selectedMatchedCountries.includes(country)
    }
    return true
  }

  const filterAvailability = ({ daltixProduct: { outdated = false } }) => {
    if (
      selectedMatchedProductsAvailability &&
      selectedMatchedProductsAvailability.length > 0
    ) {
      return selectedMatchedProductsAvailability.includes(
        outdated ? AVAILABILITY_INACTIVE : AVAILABILITY_ACTIVE,
      )
    }
    return true
  }

  const filterFactor = ({ mult_factor: factor }) => {
    const evaluatedFactors = []
    if (selectedMatchedProductsFactor.includes("equals")) {
      evaluatedFactors.push(factor === 1)
    }
    if (selectedMatchedProductsFactor.includes("less")) {
      evaluatedFactors.push(factor < 1)
    }
    if (selectedMatchedProductsFactor.includes("more")) {
      evaluatedFactors.push(factor > 1)
    }
    if (selectedMatchedProductsFactor.includes("null")) {
      evaluatedFactors.push(factor === undefined || factor === null)
    }
    return evaluatedFactors.length > 0 ? evaluatedFactors.includes(true) : true
  }

  const filterMatchType = ({ match_type: matchType }) => {
    if (selectedMatchedProductsType && selectedMatchedProductsType.length > 0) {
      return selectedMatchedProductsType.includes(matchType) || matchType === "null"
    }
    return true
  }

  const filterMatchesWithInfo = ({ daltixProduct: { name } }) => name

  const filterMatches = combineFilters(
    filterMatchesWithInfo,
    filterDiscarded,
    filterShops,
    filterBrands,
    filterCountries,
    filterAvailability,
    filterFactor,
    filterMatchType,
  )

  let missingExistingMatches = []
  if (
    !loadingExistingMatches &&
    existingMatchesList &&
    existingMatchesList.length > 0
  ) {
    missingExistingMatches = existingMatchesList.filter(
      ({ daltix_id }) => !daltixProducts[daltix_id],
    )
  }

  const missingExistingHash = missingExistingMatches.reduce(
    (hash, { daltix_id }) => `${hash}|${daltix_id}`,
    "",
  )
  useEffect(() => {
    if (missingExistingMatches.length > 0) {
      getDxProductsByIds(
        missingExistingMatches.map(({ daltix_id }) => daltix_id),
        "matches",
        "Existing Matches",
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingExistingHash, getDxProductsByIds])

  let finalExistingMatchesList
  if (existingMatchesList && missingExistingMatches.length === 0) {
    finalExistingMatchesList = existingMatchesList.map((match) => ({
      ...match,
      daltixProduct: daltixProducts[match.daltix_id],
      // ...daltixProducts[match.daltix_id],
    }))
  }

  log.debug(">>>>>>>>>>>>>> acceptedSearchResults", acceptedSearchResults)

  if (acceptedSearchResults.length > 0) {
    finalExistingMatchesList = [
      ...(finalExistingMatchesList || []),
      ...acceptedSearchResults.map((match) => ({
        ...match,
        daltixProduct: daltixProducts[match.daltix_id],
        // ...daltixProducts[match.daltix_id],
        source: ORIGIN_SEARCH,
      })),
    ]
  }

  // process filter options

  const {
    brands: searchResultBrands = {},
    shops: searchResultShops = {},
    countries: searchResultCountries = {},
  } = searchFilterOptions

  const brands = [...(finalExistingMatchesList || [])].map(({ brand }) => {
    if (brand) {
      return brand.toLowerCase()
    }
    return NULL
  })

  const matchCounters = countFilterOptions(finalExistingMatchesList)

  log.debug("counters match", matchCounters)

  const searchResultBrandNames = Object.keys(searchResultBrands).map((value) => {
    if (value) {
      return value.toLowerCase()
    }
    return NULL
  })

  const allBrandNames = [...brands, ...searchResultBrandNames, ...selectedMatchedBrands]

  if (autoFilterBrands && product && product.brand) {
    allBrandNames.push(product.brand)
  }

  const distinctBrandsNames = [...new Set(allBrandNames)].sort()

  log.debug("distinctBrandsNames", distinctBrandsNames)

  const distinctBrandsList = distinctBrandsNames.map((name) => ({
    name,
    matches: matchCounters.brands[name],
    searchResults: searchResultBrands[name],
  }))

  log.debug("distinctBrandsList", distinctBrandsList)

  const shops = [...(finalExistingMatchesList || [])]
    .filter(({ shop }) => shop)
    .map(({ shop }) => shop)

  const searchResultShopNames = Object.keys(searchResultShops)

  const distinctShopNames = [
    ...new Set([...shops, ...searchResultShopNames, ...selectedMatchedShops]),
  ].sort()

  const distinctShopList = distinctShopNames.map((name) => ({
    name,
    matches: matchCounters.shops[name],
    searchResults: searchResultShops[name],
  }))

  log.debug("shops:", finalExistingMatchesList, distinctShopList)

  const countries = [...(finalExistingMatchesList || [])]
    // .filter(({ country }) => country)
    .map(({ country }) => country)

  const searchResultCountryNames = Object.keys(searchResultCountries)

  const distinctCountryNames = [
    ...new Set([
      ...countries,
      ...searchResultCountryNames,
      ...selectedMatchedCountries,
    ]),
  ].sort()

  const distinctCountryList = distinctCountryNames.map((name) => ({
    name,
    matches: matchCounters.countries[name],
    searchResults: searchResultCountries[name],
  }))

  // auto select filters
  const [autoSelected, setAutoSelected] = useState(false)
  useEffect(() => {
    log.debug("auto selecting brands: 3", "product.id", (product || {}).id)
    setAutoSelected(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(product || {}).id, autoFilterBrands])

  useEffect(() => {
    log.debug(
      "auto selecting brands: 1",
      "autoFilterBrands:",
      autoFilterBrands,
      "selectedMatchedBrands:",
      selectedMatchedBrands,
      "distinctBrandsList:",
      distinctBrandsList,
      "finalExistingMatchesList:",
      finalExistingMatchesList,
      "autoSelected:",
      autoSelected,
    )

    if (autoFilterBrands && finalExistingMatchesList && !autoSelected) {
      setAutoSelected(true)
      if (product && product.brand) {
        const { brand: refProductBrand } = product

        log.debug(
          "auto selecting brands: 2",
          "label",
          refProductBrand,
          "label2shop[label]",
          label2shop[refProductBrand],
        )

        if (label2shop[refProductBrand]) {
          log.debug("auto selecting house brand")
          selectMatchedBrands([
            ...distinctBrandsList
              .filter(
                ({ name }) => label2shop[name] && !selectedMatchedBrands.includes(name),
              )
              .map(({ name }) => name),
            ...selectedMatchedBrands,
          ])
          if (searchTerm) {
            searchDxProducts(searchTerm)
          }
        } else if (refProductBrand) {
          log.debug("auto selecting global brand")
          selectMatchedBrands([
            ...distinctBrandsList
              .filter(
                ({ name }) =>
                  sameBrand(name, refProductBrand) &&
                  !selectedMatchedBrands.includes(name),
              )
              .map(({ name }) => name),
            ...selectedMatchedBrands,
          ])
          if (searchTerm) {
            searchDxProducts(searchTerm)
          }
        }
      } else {
        // selectMatchedBrands([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoSelected,
    autoFilterBrands,
    distinctBrandsList,
    finalExistingMatchesList,
    selectedMatchedBrands,
  ])

  // applying filters

  let filteredExistingMatches

  if (finalExistingMatchesList) {
    filteredExistingMatches = finalExistingMatchesList.filter(filterMatches)
  }

  // highlight EAN matches

  if (product) {
    if (filteredExistingMatches) {
      filteredExistingMatches = highlightEanMatches(
        filteredExistingMatches,
        product.ean,
      )
    }
  }

  let noFilterTotalMatches = 0

  if (product && finalExistingMatchesList) {
    noFilterTotalMatches = finalExistingMatchesList.filter(
      ({ daltix_id, status }) =>
        daltixProducts[daltix_id] &&
        daltixProducts[daltix_id].name &&
        (status === STATUS_REVIEW || status === STATUS_APPROVED),
    ).length
  }

  return (
    <MatchesPanelPresenter
      {...props}
      product={product}
      loadingExistingMatches={
        loadingExistingMatches || missingExistingMatches.length > 0
      }
      existingMatchesList={filteredExistingMatches}
      noFilterTotalMatches={noFilterTotalMatches}
      onAccordionChange={toogleAddingMatches}
      addingMatches={addingMatches}
      shopsList={distinctShopList}
      selectedMatchedShops={selectedMatchedShops}
      onMatchedShopsChange={handleMatchedShopsChange}
      brandsList={distinctBrandsList}
      selectedMatchedBrands={selectedMatchedBrands}
      onMatchedBrandsChange={handleMatchedBrandsChange}
      countryList={distinctCountryList}
      selectedMatchedCountries={selectedMatchedCountries}
      onMatchedCountriesChange={handleMatchedCountriesChange}
      autoFilterBrands={autoFilterBrands}
      onAutoFilterBrandsChange={handleAutoFilterBrandsChange}
      noFilterTotalSearchResults={noFilterTotalSearchResults}
      selectedMatchedProductsAvailability={selectedMatchedProductsAvailability}
      onMatchedProductsAvailabilityChange={handleMatchedProductsAvailabilityChange}
      selectedMatchedProductsFactor={selectedMatchedProductsFactor}
      onMatchedProductsFactorChange={handleMatchedProductsFactorChange}
      selectedMatchedProductsType={selectedMatchedProductsType}
      onMatchedProductTypeChange={handleMatchedProductsTypeChange}
      onClearFilters={handleClearFilters}
    />
  )
}

const mapStateToProps = ({
  refProduct: {
    referenceProducts,
    list,
    offset,
    loadingExistingMatches,
    existingMatchesError,
    detailedProductIndex,
    selectedMatchedShops,
    selectedMatchedBrands,
    selectedMatchedCountries,
    showDiscardedMatches,
    selectedMatchedProductsAvailability,
    selectedMatchedProductsFactor,
    selectedMatchedProductsType,
  },
  daltixProduct: {
    daltixProducts,
    searchResultsMatchingStatus,
    searchFilterOptions,
    searchTerm,
    noFilterTotalSearchResults,
  },
}) => {
  const productId =
    detailedProductIndex !== undefined &&
    list.length > 0 &&
    list[detailedProductIndex - offset]

  let existingMatchesList

  if (productId && referenceProducts[productId]) {
    existingMatchesList = referenceProducts[productId].existingMatches
  }

  const productNewMatches = searchResultsMatchingStatus[productId] || {}

  const acceptedSearchResults = Object.keys(productNewMatches)
    .map((id) => ({
      daltix_id: id,
      ...searchResultsMatchingStatus[productId][id],
    }))
    .filter(({ status }) => status === STATUS_REVIEW || status === STATUS_APPROVED)

  return {
    daltixProducts,
    acceptedSearchResults,
    referenceProducts,
    loadingExistingMatches: loadingExistingMatches.includes(productId),
    productIndex: detailedProductIndex,
    productId,
    existingMatchesList,
    existingMatchesError,
    selectedMatchedShops,
    selectedMatchedBrands,
    selectedMatchedCountries,
    showDiscardedMatches,
    selectedMatchedProductsAvailability,
    selectedMatchedProductsFactor,
    selectedMatchedProductsType,
    searchTerm,
    searchFilterOptions,
    noFilterTotalSearchResults,
  }
}

export default connect(mapStateToProps, {
  ...referenceProductsActions,
  ...daltixProductActions,
})(MatchesPanel)
