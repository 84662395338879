/* eslint-disable import/prefer-default-export */

export const handleGetMatchesById = (state) => ({
  ...state,
})

export const handleGetMatchesByIdSuccess = (state, action) => {
  const { payload: matches } = action

  const { matches: currentMatches } = state

  const newMatches = {}

  matches.forEach((match) => {
    newMatches[match.id] = {
      ...match,
      ready: true,
      error: undefined,
    }
  })

  return {
    ...state,
    matches: {
      ...currentMatches,
      ...newMatches,
    },
  }
}

export const handleGetMatchesByIdError = (state, action) => {
  const {
    parent: { ids },
  } = action

  const { matches: currentMatches } = state

  const newMatches = {}

  ids.forEach((matchId) => {
    newMatches[matchId] = {
      id: matchId,
      ready: false,
      error: new Error("Error loading this match"),
    }
  })

  return {
    ...state,
    matches: {
      ...currentMatches,
      ...newMatches,
    },
  }
}
