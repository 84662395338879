import Close from "@material-ui/icons/Close"
import React from "react"
import { string } from "prop-types"
import { AccessibleIcon } from "./AccessibleIcon"

function CrossIcon(props) {
  return (
    <AccessibleIcon border="solid" color="danger" shape="circle" {...props}>
      <Close fontSize="inherit" />
    </AccessibleIcon>
  )
}

CrossIcon.propTypes = {
  testID: string,
  size: string,
}

CrossIcon.defaultProps = {
  testID: undefined,
  size: "lg",
}

export { CrossIcon }
