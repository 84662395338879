import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY,
} from "../constants"

export function handleDimensionSortChanged(state, payload) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY]: {
      ...state[ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY],
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: payload.direction,
    },
  }
}
