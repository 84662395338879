import { get } from "lodash"
import useAsyncSlice from "./use-async-slice"

import { getOneDaltixProductById } from "../ducks/repositories/daltix-products/action-creators"

export default function useDaltixProduct(id, invalidateCache = false) {
  const action = getOneDaltixProductById({ id, invalidateCache })

  function selector(state) {
    return get(state, ["repositories", "DaltixProductsRepository", "documents", id])
  }

  return useAsyncSlice({
    action,
    selector,
  })
}
