/* -------------------------------------------------------------------------- */
/*                                    async                                   */
/* -------------------------------------------------------------------------- */

import { errorType, successType } from "../../../util/redux-observable-helpers"

export const PRODUCT_COMPARISON_ENQUEUE_GET_REF_PRODUCT_MATCHES =
  "@product-comparison-widget/ENQUEUE_GET_REF_PRODUCT_MATCHES"

/* -------------------------------------------------------------------------- */
/*                                  fetchers                                  */
/* -------------------------------------------------------------------------- */

export const PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES =
  "@product-comparison-widget/GET_REF_PRODUCT_MATCHES"

export const PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES_ERROR = errorType(
  PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES,
)

export const PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES_SUCCESS = successType(
  PRODUCT_COMPARISON_GET_REF_PRODUCT_MATCHES,
)

/* -------------------------------------------------------------------------- */
/*                                  mutations                                 */
/* -------------------------------------------------------------------------- */

export const PRODUCT_COMPARISON_SET_DALTIX_PRODUCT_STATUS_FILTERS =
  "@product-comparison-widget/SET_DALTIX_PRODUCT_STATUS_FILTERS"

export const PRODUCT_COMPARISON_SET_COMPARABLE_PRODUCTS =
  "@product-comparison-widget/PRODUCT_COMPARISON_SET_COMPARABLE_PRODUCTS"

/* -------------------------------------------------------------------------- */
/*                                    state                                   */
/* -------------------------------------------------------------------------- */

export const PRODUCT_COMPARISON_CLOSE = "@product-comparison-widget/CLOSE"
export const PRODUCT_COMPARISON_OPEN = "@product-comparison-widget/OPEN"

/* -------------------------------------------------------------------------- */
/*                                  triggers                                  */
/* -------------------------------------------------------------------------- */

export const PRODUCT_COMPARISON_TRIGGER_GET_REF_PRODUCT_MATCHES =
  "@product-comparison-widget/TRIGGER_GET_REF_PRODUCT_MATCHES"
