import React from "react"
import { contentString, formatEan } from "./strings"
import NutriScore from "../components/nutri-score"
import { NutrientsList } from "../components/nutrients"
import { getContentsV1AsArray } from "./volumes"

export function daltixProductToComparableTradeItem({
  contents_v1: contentsV1,
  eans,
  labels: { nutriscore } = {},
  nutrients_v1: nutrients,
  ...rest
}) {
  return {
    contents:
      contentsV1 && getContentsV1AsArray(contentsV1).map(contentString).join(" / "),
    eans: eans && eans.map((ean) => `EAN ${formatEan(ean)}`).join("\n"),
    nutriscore: nutriscore && <NutriScore value={nutriscore} />,
    nutrients: nutrients && <NutrientsList value={nutrients} />,
    ...rest,
  }
}

export function referenceProductToComparableTradeItem({
  contents_v1: contentsV1,
  eans,
  nutriscore,
  nutrients_v1: nutrients,
  ...rest
}) {
  return {
    contents:
      contentsV1 && getContentsV1AsArray(contentsV1).map(contentString).join(" / "),
    eans: eans && eans.map((ean) => `EAN ${formatEan(ean)}`).join("\n"),
    nutriscore: nutriscore && <NutriScore value={nutriscore} />,
    nutrients: nutrients && <NutrientsList value={nutrients} />,
    ...rest,
  }
}

export const tradeItemComparableFields = [
  {
    name: "contents",
    label: <span className="material-symbols-rounded">weight</span>,
  },
  {
    name: "eans",
    label: <span className="material-symbols-rounded">barcode</span>,
    title: "EAN Codes",
  },
  {
    name: "description",
    label: <span className="material-symbols-rounded">notes</span>,
  },
  {
    name: "ingredients",
    label: <span className="material-symbols-rounded">receipt_long</span>,
  },
  {
    name: "nutriscore",
    label: <span className="material-symbols-rounded">eco</span>,
    title: "Nutri-Score",
  },
  {
    name: "nutrients",
    label: <span className="material-symbols-rounded">toc</span>,
    style: {
      verticalAlign: "top",
    },
  },
]
