import { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import useMatchesOverviewFilters from "./use-matches-overview-filters"
import { stageFilterMatchShop } from "../../../ducks/pages/matches-overview/action-creators"
import {
  filterSearchParamsApplied,
  getActiveSearchParams,
} from "../../../ducks/pages/matches-overview/helpers"
import useShops from "../../../hooks/use-shops"
import { MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY } from "../../../ducks/pages/matches-overview/constants"

export default function useMatchesOverviewMatchShops() {
  const dispatch = useDispatch()
  const { getByCode } = useShops()

  const { data = [] } = useMatchesOverviewFilters(["outline", "shops"])

  const filters = useMatchesOverviewFilters([
    MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
    "shops",
  ])
  const active = useMemo(() => getActiveSearchParams(filters), [filters])
  const applied = useMemo(() => filterSearchParamsApplied(filters), [filters])

  const shops = useMemo(
    () =>
      data.map((shopCode) => ({
        display: getByCode(shopCode).name,
        ticked: active.includes(shopCode),
        value: shopCode,
      })),
    [active, data, getByCode],
  )

  const setShops = useCallback(
    (...shopsArgs) => {
      shopsArgs.forEach(({ value, state }) => {
        dispatch(stageFilterMatchShop({ value, state }))
      })
    },
    [dispatch],
  )

  return { appliedShops: applied, shops, setShops }
}
