import React, { Component } from "react"
import { reportError } from "../api/service"

function DefaultFallback({ error }) {
  const styles = {
    error: {
      backgroundColor: "#f98e7e",
      borderTop: "1px solid #777",
      borderBottom: "1px solid #777",
      padding: "12px",
    },
  }

  if (process.env.NODE_ENV === "production") {
    return null
  }

  return (
    <div style={styles.error}>
      <h3
        style={{
          fontSize: "1.17em",
          marginBefore: "1em",
          marginAfter: "1em",
        }}
      >
        ERROR:
        {error && error.toString()}
      </h3>
      <details style={{ whiteSpace: "pre-wrap" }}>{error && error.stack}</details>
    </div>
  )
}

export const withErrorBoundary = (
  WrappedComponent,
  FallbackComponent = DefaultFallback,
) =>
  class extends Component {
    constructor(props) {
      super(props)

      this.state = {}
    }

    static getDerivedStateFromError(error) {
      return {
        error,
      }
    }

    componentDidCatch(error, errorInfo) {
      reportError({
        error,
        errorInfo,
      })
    }

    render() {
      const { error } = this.state

      if (error) {
        return <FallbackComponent {...this.props} error={error} />
      }

      return <WrappedComponent {...this.props} />
    }
  }
