import MoreHorizSharp from "@material-ui/icons/MoreHorizSharp"
import React from "react"

import { string } from "prop-types"
import { AccessibleIcon } from "./AccessibleIcon"

function EllipsisIcon(props) {
  return (
    <AccessibleIcon border="solid" color="warn" shape="circle" {...props}>
      <MoreHorizSharp fontSize="inherit" />
    </AccessibleIcon>
  )
}

EllipsisIcon.propTypes = {
  testID: string,
  size: string,
}

EllipsisIcon.defaultProps = {
  testID: undefined,
  size: "lg",
}

export { EllipsisIcon }
