export const AVAILABILITY_ACTIVE = "active"
export const AVAILABILITY_INACTIVE = "inactive"

export const matchProductAvailabilityOptionsList = [
  {
    label: "Active",
    value: AVAILABILITY_ACTIVE,
  },
  {
    label: "Inactive",
    value: AVAILABILITY_INACTIVE,
  },
]

export const validProductAvailabilities = matchProductAvailabilityOptionsList.map(
  ({ value }) => value,
)

export const defaultProductAvailabilityValues = [AVAILABILITY_ACTIVE]
