/* eslint-disable import/prefer-default-export */

export const handleSetReviewPageSettings = (state, action) => {
  const { settings } = action
  const { reviewPageSettings } = state

  return {
    ...state,
    reviewPageSettings: {
      ...reviewPageSettings,
      ...settings,
    },
  }
}
