import mixpanel from "mixpanel-browser"
import React, { useCallback, useMemo, useState } from "react"
import { RectShape, TextRow } from "react-placeholder/lib/placeholders"
import { Link } from "react-router-dom"
import * as productSearch from "../../ducks/widgets/product-search/hooks"
import useShop from "../../hooks/use-shop"
import {
  useHasProductSearchWidget,
  useHasProductSearchWidgetInReview,
} from "../../integrations/split"
import { EUR_SYMBOL, HELP_CENTER_URL } from "../../util/constants"
import { isDebug } from "../../util/env"
import { KEY_INTERNAL_USER, getValue } from "../../util/local-storage"
import { contentString, titleCase } from "../../util/strings"
import { getContentsV1AsArray } from "../../util/volumes"
import CountryFlag from "../country-flag/CountryFlag"
import EANList from "../ean-list/EANList"
import Image from "../img/Image"
import MatchItem from "../match-item"
import { LoaderSpinner } from "../ui"
import "./MatchesReviewItem.scss"

const INTERNAL_USER = getValue(KEY_INTERNAL_USER)

function Listing({
  name,
  shop,
  country,
  currency = EUR_SYMBOL,
  price,
  url,
  normalizedUnit,
  normalizedPrice,
  onNavigateToStore,
}) {
  const mainPrice = price || normalizedPrice
  const { name: friendlyShopName } = useShop(shop)

  return (
    <div className="product-listing">
      {mainPrice && (
        <div className="dx-reference-price">
          <span className="currency">{currency}</span>
          {mainPrice.toFixed(2)}
          {price ? "" : `/${normalizedUnit}`}
        </div>
      )}

      {normalizedPrice && normalizedUnit && normalizedPrice !== mainPrice && (
        <div className="dx-reference-unit-price">
          (<span className="currency">{currency}</span>
          {normalizedPrice.toFixed(2)}/
          {normalizedUnit.toLowerCase() === "su" ? "unit" : normalizedUnit})
        </div>
      )}
      <div className="dx-reference-article-link">
        {country && (
          <span className="dx-match-store">
            {friendlyShopName}
            <span className="dx-match-country">
              {" "}
              ({country}) <CountryFlag code={country} />{" "}
            </span>
          </span>
        )}
        {url && (
          <>
            <a
              href={url}
              target="product_source"
              onClick={() =>
                onNavigateToStore({ type: "reference product", url, name })
              }
            >
              View on website
            </a>{" "}
            <i className="dx-icon-external-link" />
          </>
        )}
      </div>
    </div>
  )
}

function MatchSourcePlaceHolder() {
  return (
    <TextRow
      color="#eee"
      style={{
        width: 110,
        height: 16,
        position: "absolute",
        bottom: 0,
        left: "1em",
        right: "1em",
        opacity: 0.9,
      }}
    />
  )
}

function ProductPlaceHolder({ displaySource = false }) {
  const randomWith = (min, max) =>
    `${Math.round(100 * (Math.random() * (max - min) + min))}%`

  const nameWidth = useMemo(() => randomWith(0.5, 0.5), [])
  const contentsWidth = useMemo(() => randomWith(0.3, 0.3), [])
  const shopWidth = useMemo(() => randomWith(0.25, 0.25), [])

  return (
    <div className="dx-reference-summary grow show-loading-animation">
      <div className="main-info">
        <div className="dx-match-image">
          <RectShape
            color="#ccc"
            style={{ width: 120, height: 120, display: "inline-block" }}
          />
          {displaySource && <MatchSourcePlaceHolder />}
        </div>
        <div className="dx-reference-info grow">
          <TextRow color="#888" style={{ width: nameWidth, height: 17 }} />
          <TextRow color="#eee" style={{ width: contentsWidth, height: 9 }} />
          <br />
          <div>
            <TextRow color="#bbb" style={{ width: "15%", display: "inline-block" }} />{" "}
            <TextRow
              color="#ccc"
              style={{ width: "15%", display: "inline-block", heigh: 10 }}
            />
          </div>
          <RectShape color="transparent" style={{ width: 120, height: 5 }} />
          <TextRow
            color="#eee"
            style={{ width: "10%", display: "inline-block", height: 9 }}
          />
          <div>
            <TextRow
              color="#aaa"
              style={{ width: shopWidth, display: "inline-block", height: 9 }}
            />{" "}
            <TextRow
              color="#eee"
              style={{ width: "35%", display: "inline-block", height: 9 }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function MatchesReviewItemPresenter({
  loadingRefProd,
  loadingDxProd,
  refProdError,
  dxProdError,
  daltixProduct,
  daltixProduct: { id: dxProdId } = {},
  referenceProduct,
  referenceProduct: {
    listings: refProdListings = [],
    name: refProdName,
    id: refProdId,
    eans: refProdEans = [],
    article_nr: refProdArticleNr,
    images: refProdImages,
    brand: refProdBrand,
    contents_v1: refProdContentsV1,
  } = {},
  match_ids: matchIds,
  sameReferenceProduct,
  mult_factor: multFactor,
  status,
  match_source: matchSource,
  id: matchId,
  matched_on: matchedOn,
  match_type: matchType,
  validated_on: validatedOn,
  validated_by: validatedBy,
  pending,
  labels,
  comment,
  // events
  onFactorChange,
  onApprove,
  onUndoApproval,
  onUnmatch,
  onRestore,
  onReferenceProductClick,
  onNavigateToStore,
  onLabelsChange,
  onCommentChange,
}) {
  const openProductSearch = productSearch.useOpen()
  const hasProductSearchWidget = useHasProductSearchWidget()
  const hasProductSearchWidgetInReview = useHasProductSearchWidgetInReview()

  if (refProdError) {
    throw refProdError
  }

  if (dxProdError) {
    throw dxProdError
  }

  const debugProps = {}

  if (isDebug() || INTERNAL_USER) {
    debugProps["data-ref-product-id"] = refProdId
    debugProps["data-dx-product-id"] = dxProdId
    debugProps["data-match-ids"] = matchIds
  }

  const [comparisonOpen, setComparisonOpen] = useState(false)

  const handleComparisonOpen = useCallback(() => {
    mixpanel.track("MT - Product Zoom In")

    setComparisonOpen(true)
  }, [])

  const handleComparisonClose = useCallback(() => {
    setComparisonOpen(false)
  }, [])

  const refProdContentsToDisplay = useMemo(() => {
    if (refProdContentsV1) {
      return getContentsV1AsArray(refProdContentsV1)
    }

    return []
  }, [refProdContentsV1])

  const refProdContentsText = useMemo(
    () => refProdContentsToDisplay.map(contentString).join(" / "),
    [refProdContentsToDisplay],
  )

  function onClickProduct(e) {
    if (hasProductSearchWidget && hasProductSearchWidgetInReview) {
      e.preventDefault()
      e.stopPropagation()
      openProductSearch(refProdId, {})
      return
    }

    onReferenceProductClick({
      refProdId,
      refProdName,
      refProdBrand,
    })
  }

  return (
    <div
      className={`dx-table-line match ${status}`}
      {...debugProps}
      data-feature-flag="product-search"
      data-enable-product-search={
        hasProductSearchWidget && hasProductSearchWidgetInReview
      }
    >
      {pending && <LoaderSpinner />}
      <div style={{ flexGrow: 1 }}>
        <div
          className="dx-table-cell dx-align-top reference-product"
          style={{ width: "45%" }}
        >
          {!loadingRefProd && (
            <div className="dx-reference-summary">
              {sameReferenceProduct && <div className="dx-reference-equal">=</div>}
              <div className="main-info">
                <div className="dx-reference-image">
                  <Image
                    src={refProdImages}
                    className="picture expandable"
                    title={`${titleCase(refProdName)} (click to zoom-in)`}
                    onClick={handleComparisonOpen}
                    style={{
                      cursor: "zoom-in",
                    }}
                  />
                </div>
                <div className="dx-reference-info">
                  <h3>
                    <Link
                      data-testid="open-product-search"
                      to={`/matches/match?id=${refProdId}`}
                      onClick={onClickProduct}
                    >
                      {titleCase(refProdName)}
                    </Link>
                  </h3>
                  <div className="dx-reference-additional-info">
                    {refProdContentsText}
                    &nbsp;&nbsp;&nbsp; <i className="dx-icon-label" />
                    {titleCase(refProdBrand || "Unknown")}
                  </div>

                  <div className="dx-reference-ref">
                    <EANList eans={refProdEans} />
                    {refProdArticleNr && <div>Ref: {refProdArticleNr}</div>}
                  </div>

                  {refProdListings.map((listing) => (
                    <Listing
                      key={listing.id}
                      name={refProdName}
                      onNavigateToStore={onNavigateToStore}
                      {...listing}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          {loadingRefProd && <ProductPlaceHolder prodId={refProdId} />}
        </div>
        <div
          className="dx-table-cell dx-align-top daltix-product"
          style={{ width: "55%" }}
        >
          <MatchItem
            loading={loadingDxProd || !status}
            loadingRefProd={loadingRefProd}
            refProdError={refProdError}
            dxProdError={dxProdError}
            daltixProduct={daltixProduct}
            referenceProduct={referenceProduct}
            mult_factor={multFactor}
            status={status}
            match_source={matchSource}
            match_type={matchType}
            matchId={matchId}
            matched_on={matchedOn}
            validated_on={validatedOn}
            validated_by={validatedBy}
            pending={pending}
            labels={labels}
            comment={comment}
            comparisonOpen={comparisonOpen}
            // events
            onFactorChange={onFactorChange}
            onApprove={onApprove}
            onUndoApproval={onUndoApproval}
            onUnmatch={onUnmatch}
            onRestore={onRestore}
            onReferenceProductClick={onReferenceProductClick}
            onNavigateToStore={onNavigateToStore}
            onLabelsChange={onLabelsChange}
            onCommentChange={onCommentChange}
            onComparisonOpen={handleComparisonOpen}
            onComparisonClose={handleComparisonClose}
          />
        </div>
      </div>
    </div>
  )
}

export function MatchesReviewItemFallbackPresenter({
  error,
  referenceProductGroupId,
  daltixProductId,
}) {
  const debugProps = {}

  if (isDebug() || INTERNAL_USER) {
    debugProps["data-ref-product-id"] = referenceProductGroupId
    debugProps["data-dx-product-id"] = daltixProductId
    debugProps.error = error
  }

  return (
    <div className="dx-table-line" {...debugProps}>
      <div className="dx-table-cell">
        <div className="dx-match-error">
          <i className="dx-icon-warning" />
          <p>
            Oops! For some reason we were not able to load this match!
            <br />
            <strong>Try to refresh the page</strong> and, if this situation persists,
            please{" "}
            <a href={HELP_CENTER_URL} target="help_center">
              contact us
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

export default MatchesReviewItemPresenter
