import { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import { get, groupBy } from "lodash"
import useMatchesOverviewFilters from "../use-matches-overview-filters"
import { stageFilterReferenceProductsCategory } from "../../../../ducks/pages/matches-overview/action-creators"
import { DEFAULT_PATH_DELIMITER } from "../../../../util/tree"
import {
  filterSearchParamsApplied,
  getActiveSearchParams,
} from "../../../../ducks/pages/matches-overview/helpers"

function groupCategoriesByAncestor(categories) {
  return groupBy(categories, (category) => {
    const valueParts = category.value.split(DEFAULT_PATH_DELIMITER)

    if (valueParts.length > 1) {
      const ancestorKey = valueParts.slice(0, -1).join(" > ")

      return ancestorKey
    }

    // No ancestors, implies that all categories are stored under the root key.
    return ""
  })
}

/**
 * Given a category path and the currently selected paths it creates an object
 * compatible with a components/ui/Checkbox
 *
 * Improvements:
 * - selected could be an Set instead of a array
 *
 * @param {string} categoryPath
 * @param {string[]} selectedPaths
 *
 * @returns the optionized category path
 */
function optionizeCategoryPath(categoryPath, selectedPaths) {
  return {
    display: categoryPath.split(DEFAULT_PATH_DELIMITER).at(-1),
    ticked: selectedPaths.includes(categoryPath),
    value: categoryPath,
  }
}

/**
 * Given an array of category paths and currently selected paths, it creates an array of
 * objects compatible with components/ui/Checkbox.
 *
 * @param {string[]} categoryPaths
 * @param {string[]} selectedPaths
 *
 * @returns the optionized category paths array
 */
function optionizeCategoryPaths(categoryPaths, selectedPaths) {
  return categoryPaths.map((p) => optionizeCategoryPath(p, selectedPaths))
}

/**
 * Given an array of optionized paths, it selects the path value of ticked options
 *
 * @param {{display: string, ticked: boolean | 'indeterminate', value: string}} currentOptions
 *  the optionized paths that were last processed
 * @returns {string[]} the ticked options' path value
 */
function getTickedPaths(currentOptions) {
  return currentOptions
    .filter((option) => option.ticked === true)
    .map((option) => option.value)
}

/**
 * Given an array of processed paths, e.g.: at level N, it returns the paths for level N + 1
 *
 * @typedef {{ count: number, children: {[keys: string]: Category}}} Category
 *
 * @param {string[]} currentLevelKeys the keys that were last optionized by the algorithm
 * @param {{[rootKeys: string]: Category}} outline the categories hierarchy object
 *
 * @returns {string[]} the keys that should be processed next, suffixed by their ancestor
 */
function generateNextLevelPaths(currentLevelKeys, outline) {
  const next = []
  const keyToOutlinePathRegex = new RegExp(DEFAULT_PATH_DELIMITER, "g")

  currentLevelKeys.forEach((ancestorKey) => {
    const categoryPath = ancestorKey.replace(keyToOutlinePathRegex, ".children.")
    const category = get(outline, categoryPath)

    const childKeys = category?.children ? Object.keys(category.children) : []

    const nextLevelKeys = childKeys.map(
      (childKey) => `${ancestorKey}${DEFAULT_PATH_DELIMITER}${childKey}`,
    )

    next.push(nextLevelKeys)
  })

  return next.flat()
}

export default function useMatchesOverviewReferenceProductCategories() {
  const dispatch = useDispatch()

  const { data: categoriesOutline } = useMatchesOverviewFilters([
    "outline",
    "categories",
  ])

  const filters = useMatchesOverviewFilters(["referenceProducts", "categories"])
  const active = getActiveSearchParams(filters)
  const applied = useMemo(() => filterSearchParamsApplied(filters), [filters])

  const categories = useMemo(() => {
    const categoriesOutput = []

    if (!categoriesOutline) {
      return categoriesOutput
    }

    // always output the outline root nodes
    let nextLevelKeys = Object.keys(categoriesOutline)
    let currentOptions = optionizeCategoryPaths(nextLevelKeys, active)
    categoriesOutput.push([...currentOptions])

    // for every ticked node, process child nodes until exhausted
    let tickedOptions = getTickedPaths(currentOptions).reverse()

    while (tickedOptions.length > 0) {
      nextLevelKeys = generateNextLevelPaths(tickedOptions, categoriesOutline)
      currentOptions = optionizeCategoryPaths(nextLevelKeys, active)

      categoriesOutput.push([...currentOptions])

      tickedOptions = getTickedPaths(currentOptions).reverse() // process children
    }

    return categoriesOutput
  }, [active, categoriesOutline])

  const setCategories = useCallback(
    (...categoriesArgs) => {
      categoriesArgs.forEach(({ value, state }) => {
        dispatch(stageFilterReferenceProductsCategory({ value, state }))
      })
    },
    [dispatch],
  )

  return {
    appliedCategories: applied,
    categories,
    groupCategoriesByAncestor,
    setCategories,
  }
}
