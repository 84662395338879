import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import createAuth0Client from "@auth0/auth0-spa-js"
import * as Sentry from "@sentry/react"

let client

const getClient = () => client

const createClient = async (initOptions) => {
  client = await createAuth0Client(initOptions)
  return client
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

const Auth0Context = React.createContext()
Auth0Context.displayName = "Auth0Context"

function useAuth0() {
  const context = useContext(Auth0Context)

  if (context === undefined) {
    throw new Error("useAuth0 must be used within a Auth0Context")
  }

  return context
}

function Auth0Provider({ children, onRedirectCallback, ...initOptions }) {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0Client] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createClient(initOptions)

      setAuth0Client(auth0FromHook)

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const authData = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(authData)

      if (authData) {
        const userData = await auth0FromHook.getUser()
        setUser(userData)
      }

      setLoading(false)
    }

    initAuth0()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loginWithPopup(params = {}) {
    setPopupOpen(true)

    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setPopupOpen(false)
    }

    const userData = await auth0Client.getUser()

    setUser(userData)
    setIsAuthenticated(true)
  }

  async function handleRedirectCallback() {
    setLoading(true)

    await auth0Client.handleRedirectCallback()

    const userData = await auth0Client.getUser()

    setLoading(false)
    setIsAuthenticated(true)
    setUser(userData)
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...args) => auth0Client.getIdTokenClaims(...args),
        loginWithRedirect: (...args) => auth0Client.loginWithRedirect(...args),
        getTokenSilently: (...args) => auth0Client.getTokenSilently(...args),
        getTokenWithPopup: (...args) => auth0Client.getTokenWithPopup(...args),
        logout: (...args) => auth0Client.logout(...args),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

Auth0Provider.propTypes = {
  children: PropTypes.node.isRequired,
  onRedirectCallback: PropTypes.func,
}

Auth0Provider.defaultProps = {
  onRedirectCallback: DEFAULT_REDIRECT_CALLBACK,
}

export { Auth0Context, Auth0Provider, getClient, useAuth0 }
