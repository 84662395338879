/* eslint-disable import/prefer-default-export */

export const handleGetReferenceProductsSummarySuccess = (state, action) => {
  const { payload } = action

  return {
    ...state,
    summary: payload,
  }
}
