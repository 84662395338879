import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { clearUnstagedMatchesOverviewFilters } from "../../../ducks/pages/matches-overview/action-creators"

export default function useMatchesOverviewFiltersUnstage() {
  const dispatch = useDispatch()

  const run = useCallback(() => {
    dispatch(clearUnstagedMatchesOverviewFilters())
  }, [dispatch])

  return run
}
