import PropTypes from "prop-types"
import React from "react"

import { DXIcon } from "../../dx-icon/DXIcon"
import { AccessibleIcon } from "./AccessibleIcon"

function ForbiddenIcon({ size, ...props }) {
  return (
    <AccessibleIcon border="none" color="neutral" shape="circle" size="lg" {...props}>
      <DXIcon size="1.125rem" type="inactive" />
    </AccessibleIcon>
  )
}

ForbiddenIcon.propTypes = {
  size: PropTypes.string,
}

ForbiddenIcon.defaultProps = {
  size: "lg",
}

export { ForbiddenIcon }
