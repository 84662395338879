export const ANONYMOUS_ID = "anonymous"

export const ON = "on"
export const OFF = "off"

export const TRAFFIC_TYPE = {
  user: "user",
  account: "account",
  anonymous: "anonymous",
}
