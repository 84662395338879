import React from "react"
import PropTypes from "prop-types"
import { NULL } from "../../util/constants"

function CategoryItemWithCount({ count, name }) {
  const text = name === NULL ? "Unknown" : name

  return (
    <span className="category-item">
      <span className="name">{text}</span>
      <span
        className="count"
        title={`${count} product${count === 1 ? "" : "s"} available in this category`}
      >
        ({count})
      </span>
    </span>
  )
}

CategoryItemWithCount.propTypes = {
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
}

export default CategoryItemWithCount
