export const EUR_SYMBOL = "€"
export const INFINITY_SYMBOL = "∞"
export const NULL = "___null___"

export const shopNameMap = {
  ah: "Albert Heijn",
  aldi: "Aldi",
  amazon: "Amazon",
  amazonde: "Amazon Germany",
  auchan: "Auchan",
  bol: "Bol",
  brico: "Brico",
  crf: "Carrefour",
  cevo: "Cevo",
  cg: "Collect & Go",
  colshop: "Collishop",
  clp: "Colruyt",
  coolblue: "Coolblue",
  cora: "Cora",
  dll: "Delhaize",
  dirkbroek: "Dirk",
  dreamland: "Dreamland",
  fun: "Fun",
  gall: "Gall & Gall",
  gamma: "Gamma",
  hornbach: "Hornbach",
  hubo: "Hubo",
  hoogvliet: "Hoogvliet",
  intermar: "Intermarche",
  jumbo: "Jumbo",
  karwei: "Karwei",
  klium: "Klium",
  krefel: "Krefel",
  kwantum: "Kwantum",
  leenbakker: "Leenbakker",
  lidl: "Lidl",
  lidlsimpl: "Lidl",
  makro: "Makro",
  match: "Match",
  mrbric: "Mr Bricolage (BE)",
  okay: "Okay",
  planit: "Planit",
  praxis: "Praxis",
  solucious: "Solucious",
  suprabazar: "Supra Bazar",
  toonat: "Toolnation",
  vdborr: "Vandenborre",
  zooplus: "Zooplus",
  bartsmit: "Bartsmit",
  medmar: "Media Market",
  fnac: "Fnac",
  fundoo: "Fundoo",
  gamemania: "Game Mania",
  action: "Action",
  maxitoys: "Maxitoys",
  kruidvat: "Kruidvat",
  bioplanet: "Bio Planet",
  plus: "Plus",
  verfwinkel: "Verfwinkel",
}

export const shop2label = {
  action: [
    "wreckmann",
    "spectrum",
    "teddycare",
    "freija",
    "candra",
    "skyler",
    "subliem",
    "a-force",
    "lsc",
    "home essentials",
    "ziki",
    "spilbergen",
    "office essentials",
  ],
  ah: ["ah basic", "ah excellent", "ah", "allerhande"],
  aldi: [
    "albona",
    "all seasons",
    "ambiente",
    "arioso",
    "asis green garden",
    "bebino",
    "biocura",
    "bistro'mix",
    "bistro'vite",
    "bon-ri",
    "bratella",
    "buttella",
    "buval",
    "cabre d'or",
    "caramba",
    "caramia",
    "cartoonies",
    "casale antico",
    "casale fresco",
    "casa morando",
    "chateau",
    "chevrefin",
    "clovis lefranc",
    "condor",
    "delicato",
    "delifin",
    "delinut",
    "delipain",
    "destrier",
    "el cultivador",
    "expert+",
    "fee",
    "folia",
    "fore",
    "fou d'o",
    "freshlife",
    "gala",
    "golden bridge",
    "golden power",
    "golden seafood",
    "honeysun",
    "hopvil",
    "huggy bears",
    "jacks farm",
    "jot nuts",
    "juicy",
    "juliet",
    "kempi",
    "king's crown",
    "kyrell",
    "lavex",
    "le cavalier",
    "loft",
    "lucky cat",
    "lucky dog",
    "lys de france",
    "mallo",
    "mamie poule",
    "man",
    "marc guiselin",
    "markus",
    "merlo",
    "menito",
    "mildeen",
    "mllville",
    "milsa",
    "milsactiv",
    "milsani",
    "monlenland",
    "monarc",
    "mont merveille",
    "moreno",
    "mountain",
    "mr. fresh",
    "mucci",
    "nono",
    "ole",
    "optifit",
    "orabella",
    "pilazzo",
    "pallas",
    "panda",
    "pasta mare",
    "patissier gaulthier",
    "pirato",
    "portland",
    "primo fries",
    "primo fries®",
    "primo snacks",
    "proformance",
    "pure fruit",
    "queens jewel",
    "quick and easy",
    "race",
    "regalo",
    "regalo tradition",
    "river",
    "rocheval",
    "roi de trefle",
    "romeo",
    "siro",
    "soypro",
    "sublimo",
    "summa",
    "swing",
    "tamara",
    "tandil",
    "tartichoc",
    "top craft",
    "trader joe's",
    "trader joe's",
    "tupelo farm",
    "twindo",
    "una",
    "ursi",
    "valblanc",
    "valerie",
    "vieux bruxxelles",
    "viofour",
    "vitalis",
    "westminster",
    "zapi",
  ],
  cg: [
    "boni selection",
    "everyday",
    "boni s.",
    "boni s. bio",
    "boni selection bio",
    "boni s",
  ],
  clp: ["boni selection", "everyday", "boni s.", "boni s"],
  colruyt_group: [
    "boni selection",
    "everyday",
    "graindor (coffee)",
    "spar",
    "okay",
    "dats24",
    "kangourou",
    "dreambee",
    "dreamland toys",
    "cru",
    "beenhouwerij",
    "eco-select",
    "culino",
    "econom",
  ],
  cora: ["cora", "cora."],
  crf: ["carrefour", "not provided"],
  dirk: ["g'woon"],
  dll: [
    "delhaize",
    "365",
    "delhaize 365",
    "delhaize - bio",
    "365 - delhaize",
    "delhaize selection",
  ],
  hoogvliet: ["g'woon"],
  intermache: [
    "chabrior",
    "paquito",
    "monique ranou / claude léger",
    "itinéraire des saveurs",
    "paturages",
    "saint eloi",
    "look",
    "apta",
    "elodie",
  ],
  intermar: [
    "bouton d'or",
    "top budget",
    "adélie",
    "chabrior",
    "monique ranou",
    "paturages",
    "paquito",
    "saint eloi",
    "odyssée",
    "odyssee",
    "bouton d'or",
    "fiorini",
    "planteur des tropiques",
    "pommette",
    "capitaine cook",
    "aix",
    "labell",
  ],
  jumbo: ["jumbo"],
  kruidvat: ["kruidvat", "europrofit"],
  lidl: [
    "crivit",
    "esmara",
    "livergy",
    "ernesto",
    "lupilu",
    "silver crest",
    "parkside",
    "florabest",
    "powerfix",
    "meradiso",
  ],
  match: ["cora", "match"],
  plus: ["g'woon"],
}

const label2shop = {}

Object.keys(shop2label).forEach((shop) => {
  shop2label[shop].forEach((label) => {
    label2shop[label] = [...(label2shop[label] || []), ...[shop]]
  })
})

export { label2shop }

export const MATCHES_PREFETCHING_DELAY = 1500
export const SELECTED_PRODUCT_MATCHES_PREFETCHING_DELAY = 400

export const PRODUCT_SUGGESTIONS_LIMIT = 10

export const HELP_CENTER_URL =
  "https://daltix.atlassian.net/servicedesk/customer/portals"
