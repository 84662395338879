import Add from "@material-ui/icons/Add"
import React from "react"

import { AccessibleIcon } from "./AccessibleIcon"

function AddIcon(props) {
  return (
    <AccessibleIcon
      border="solid"
      color="secondary"
      shape="circle"
      size="lg"
      {...props}
    >
      <Add fontSize="inherit" />
    </AccessibleIcon>
  )
}

export { AddIcon }
