import { SORT_ASC, SORT_DELIMITER, SORT_DESC } from "./constants"

const directions = Object.freeze([SORT_ASC, SORT_DESC])

export function createSortProperty(property, direction) {
  if (directions.includes(direction)) {
    return `${property}${SORT_DELIMITER}${direction.toLowerCase()}`
  }

  throw new Error(
    `Unsupported sort direction. Expected: ${directions.toString()}. Got ${direction}.`,
  )
}
