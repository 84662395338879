import CssBaseline from "@material-ui/core/CssBaseline"
import {
  MuiThemeProvider,
  createGenerateClassName,
  createMuiTheme,
  jssPreset,
} from "@material-ui/core/styles"
import { ThemeProvider } from "@mui/material/styles"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ConnectedRouter } from "connected-react-router"
import { create } from "jss"
import throttle from "lodash/throttle"
import mixpanel from "mixpanel-browser"
import { SnackbarProvider } from "notistack"
import React, { Suspense, useEffect } from "react"
import JssProvider from "react-jss/lib/JssProvider"
import { Provider } from "react-redux"
import { Route } from "react-router-dom"
import Notifier from "./components/Notifier"
import HtmlTitle from "./components/html-title/HtmlTitle"
import history from "./conf/browser-history"
import configureStore from "./conf/redux-store"
import { Auth0Provider } from "./hooks/use-authentication"
import ExternalScripts from "./integrations/ExternalScripts"
import { FeatureFlagProvider } from "./integrations/split"
import Landing from "./routes/landing"
import { latestTheme } from "./theme"
import { auth0 as authConfig } from "./util/env"
import { loadState, saveState } from "./util/local-storage"
import {
  getPersistableReferenceProductsFields,
  getPersistableReviewMatchesFields,
} from "./util/persistable-state"
import themeOptions from "./util/theme.json"

const queryClient = new QueryClient()

const theme = createMuiTheme({
  ...themeOptions,
  typography: {
    useNextVariants: true,
  },
})

const generateClassName = createGenerateClassName()
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById("jss-insertion-point"),
})

const persistedState = loadState()

const _state = persistedState && {
  matches: getPersistableReviewMatchesFields(persistedState.matches),
  refProduct: getPersistableReferenceProductsFields(persistedState.refProduct),
}

const store = configureStore()

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? `${appState.targetUrl}${appState.search ? appState.search : ""}`
      : window.location.pathname,
    {
      fromSso: true,
    },
  )
}

store.subscribe(
  throttle(() => {
    const { matches: matchesState, refProduct: refProductState } = store.getState()

    saveState({
      matches: getPersistableReviewMatchesFields(matchesState),
      refProduct: getPersistableReferenceProductsFields(refProductState),
    })
  }, 1000),
)

function App() {
  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      mixpanel.track("Close/Reload Tab")
    })
  }, [])

  return (
    <Auth0Provider
      domain={authConfig.domain}
      client_id={authConfig.clientId}
      redirect_uri={window.location.origin}
      scope={authConfig.scope}
      audience={authConfig.audience}
      onRedirectCallback={onRedirectCallback}
    >
      <HtmlTitle />
      <Provider store={store}>
        <FeatureFlagProvider>
          <ExternalScripts>
            <ConnectedRouter history={history}>
              <JssProvider jss={jss} generateClassName={generateClassName}>
                <MuiThemeProvider theme={theme}>
                  <ThemeProvider theme={latestTheme}>
                    <SnackbarProvider
                      classes={{
                        variantSuccess: "snackbar success",
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <QueryClientProvider client={queryClient}>
                        <div className="root">
                          <CssBaseline />
                          <Notifier />
                          <Suspense fallback={<div />}>
                            <Route
                              path="/"
                              component={(props) => <Landing {...props} />}
                            />
                          </Suspense>
                        </div>
                      </QueryClientProvider>
                    </SnackbarProvider>
                  </ThemeProvider>
                </MuiThemeProvider>
              </JssProvider>
            </ConnectedRouter>
          </ExternalScripts>
        </FeatureFlagProvider>
      </Provider>
    </Auth0Provider>
  )
}

App.propTypes = {
  // classes: PropTypes.shape({}).isRequired,
}

export default App
