import { featureNamesMap } from "./featureNamesMap"
import { useAccountTreatments } from "./useAccountTreatments"

export function useHasProductSearchWidget() {
  const { has: canAccessFeature } = useAccountTreatments()

  return canAccessFeature(featureNamesMap.PRODUCT_SEARCH_WIDGET)
}

export function useHasProductSearchWidgetInReview() {
  const { has: canAccessFeature } = useAccountTreatments()

  return canAccessFeature(featureNamesMap.PRODUCT_SEARCH_WIDGET_IN_REVIEW)
}
