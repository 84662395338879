import { SORT_DELIMITER } from "./constants"

export function extractSortPropertyValue(property) {
  if (typeof property === "string") {
    const [_propertyName, direction] = property.split(SORT_DELIMITER)

    if (direction) {
      return direction
    }
  }

  throw new TypeError(
    `Can not extract sort property. Expected: string in format '<key>:<value>'. Got type ${typeof property} with value ${property}.`,
  )
}
