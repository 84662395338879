import { get } from "lodash"
import { useSelector } from "react-redux"
import {
  PRODUCT_COMPARISON_WIDGET_KEY,
  PRODUCT_COMPARISON_WIDGET_FILTERS_KEY,
  PRODUCT_COMPARISON_WIDGET_FILTERS_STATUS_KEY,
} from "../../../ducks/widgets/product-comparison/constants"

/**
 * Gets the product comparison widget status filters.
 *
 * @returns {Array<'approved' | 'discarded' | 'in_review' | 'unmatched'>} the status filters being applied to matches
 */
export default function useProductComparisonStatusFilter() {
  return useSelector((state) =>
    get(state, [
      "widgets",
      PRODUCT_COMPARISON_WIDGET_KEY,
      PRODUCT_COMPARISON_WIDGET_FILTERS_KEY,
      PRODUCT_COMPARISON_WIDGET_FILTERS_STATUS_KEY,
    ]),
  )
}
