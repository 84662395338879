import { Typography } from "@mui/material"
import { string } from "prop-types"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { sourceCode2data } from "../../../../util/match-source"

function MatchSourceBadge({ source, matchId }) {
  const updatedSource = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.match_source,
  )
  const label = useMemo(
    () => sourceCode2data(updatedSource || source).badgeCode,
    [source, updatedSource],
  )

  if (!label) {
    return null
  }

  return (
    <Typography
      display="block"
      color="white"
      fontWeight={500}
      fontSize={12}
      px={1}
      width={46}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      textTransform="uppercase"
      textAlign="center"
      boxSizing="border-box"
      borderRadius={1}
      lineHeight="1.5rem"
      sx={{ backgroundColor: "#444444" }}
    >
      {label}
    </Typography>
  )
}

MatchSourceBadge.propTypes = {
  source: string.isRequired,
  matchId: string.isRequired,
}

export { MatchSourceBadge }
