import React from "react"
import PropTypes from "prop-types"

import styles from "./AutocompleteFacetChip.module.scss"

const ARTICLE_NUMBER = "ref"
const BRAND = "brand"
const EAN = "ean"
const NAME = "name"

const colorLookup = {
  [ARTICLE_NUMBER]: "#F0A",
  [BRAND]: "#FA0",
  [EAN]: "#00b627",
  [NAME]: "#2AF",
}

const labelLookup = {
  [ARTICLE_NUMBER]: "Ref",
  [BRAND]: "Brand",
  [EAN]: "EAN",
  [NAME]: "Name",
}

function AutocompleteFacetChip({ type }) {
  return (
    <span className={styles.chip} style={{ backgroundColor: colorLookup[type] }}>
      {labelLookup[type]}
    </span>
  )
}

AutocompleteFacetChip.propTypes = {
  type: PropTypes.oneOf([ARTICLE_NUMBER, BRAND, EAN, NAME]).isRequired,
}

export default AutocompleteFacetChip
