/* eslint-disable react/prop-types, import/no-unresolved */

import { Component } from "react"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { actionCreators } from "../ducks/notification"

class Notifier extends Component {
  displayed = []

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props
    let notExists = false

    for (let i = 0; i < newSnacks.length; i += 1) {
      const exists = !notExists

      if (exists) {
        notExists =
          notExists ||
          !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length
      }
    }

    return notExists
  }

  componentDidUpdate() {
    const { notifications = [], enqueueSnackbar, clearNotification } = this.props

    notifications.forEach((notification) => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return
      // Display snackbar using notistack
      enqueueSnackbar(notification.message, notification.options)
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key)
      // Dispatch action to remove snackbar from redux store
      clearNotification(notification.key)
    })
  }

  storeDisplayed = (id) => {
    this.displayed = [...this.displayed, id]
  }

  render() {
    return null
  }
}

const mapStateToProps = ({ notification: { queue } }) => ({
  notifications: queue,
})

export default connect(mapStateToProps, actionCreators)(withSnackbar(Notifier))
