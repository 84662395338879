import React from "react"
import ListItem from "@material-ui/core/ListItem"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorIcon from "@material-ui/icons/ErrorOutlineRounded"
import { isDebug } from "../../util/env"

import "./Match.sass"

import { KEY_INTERNAL_USER, getValue } from "../../util/local-storage"

const INTERNAL_USER = getValue(KEY_INTERNAL_USER)

export function Loader() {
  return (
    <ListItem
      style={{ height: 200 }}
      className="flex v center suggested-match-item match-item"
    >
      <LinearProgress variant="query" style={{ width: "60%" }} />
    </ListItem>
  )
}

export function MatchFallbackPresenter({
  error,
  last,
  referenceProductId,
  daltix_id: daltixId,
  match_ids: matchIds,
}) {
  const debugProps = {}

  if (isDebug() || INTERNAL_USER) {
    debugProps["data-ref-product-id"] = referenceProductId
    debugProps["data-dx-product-id"] = daltixId
    debugProps["data-match-ids"] = matchIds
    debugProps["data-error"] = error
  }

  return (
    <ListItem
      divider={!last}
      style={{ height: 200 }}
      className="flex v center match-item"
      {...debugProps}
    >
      <span className="match-error" title="Something went wrong here :(">
        <ErrorIcon color="secondary" /> Error loading this item. Try to refresh the
        page.
      </span>
    </ListItem>
  )
}
