import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { push } from "connected-react-router"

function useFeatureRoute({ condition, fallbackRoute = "/" }) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!condition) dispatch(push(fallbackRoute))
  }, [dispatch, condition, fallbackRoute])
}

export default useFeatureRoute
