import React from "react"
import AppPropTypes from "../../../prop-shapes"

import styles from "./TableCell.module.scss"

function TableCell({ children, ...props }) {
  return (
    <div {...props} className={styles.container}>
      {children}
    </div>
  )
}

TableCell.propTypes = {
  children: AppPropTypes.childrenNodes,
}

TableCell.defaultProps = {
  children: null,
}

export default TableCell
