import PropTypes from "prop-types"
import React from "react"

import styles from "./AssortmentAnalysisColumnHeader.module.scss"

export function AssortmentAnalysisColumnHeader({ children }) {
  return <div className={styles.container}>{children}</div>
}

AssortmentAnalysisColumnHeader.propTypes = {
  children: PropTypes.node.isRequired,
}
