import { createSearchParams } from "./createSearchParams"

export function arrayQuery(key, fallback = []) {
  const values = createSearchParams()
    .getAll(key)
    .map((value) => decodeURIComponent(value))

  if (Array.isArray(values) && values.length > 0) {
    return values
  }

  return fallback
}
