import {
  MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
} from "../constants"
import {
  filterSearchParamsActive,
  filterSearchParamsApplied,
  isSearchParamActive,
  isStatefulSearchParam,
  setSearchParamApplied,
  setSearchParamStagedForAddition,
  setSearchParamStagedForRemoval,
} from "../helpers"

function template(run, state, _payload) {
  const currentFiltersGroups = state.filters
  const currentFiltersGroupKeys = [
    MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
    MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
  ]

  const nextFiltersByGroupKey = currentFiltersGroupKeys.reduce((acc, groupKey) => {
    acc[groupKey] = { ...currentFiltersGroups[groupKey] }

    const childKeys = Object.keys(currentFiltersGroups[groupKey])

    childKeys.forEach((childKey) => {
      const childValue = currentFiltersGroups[groupKey][childKey]

      if (!Array.isArray(childValue)) {
        acc[groupKey][childKey] = childValue
      } else if (childValue.some((x) => !isStatefulSearchParam(x))) {
        acc[groupKey][childKey] = [...childValue]
      } else {
        acc[groupKey][childKey] = run(childValue)
      }
    })

    return acc
  }, {})

  return {
    ...state,
    filters: {
      ...currentFiltersGroups,
      ...nextFiltersByGroupKey,
    },
  }
}

function apply(state, payload) {
  const runner = (currentFilterValues) =>
    filterSearchParamsActive(currentFilterValues).map((activeSearchParam) =>
      setSearchParamApplied(activeSearchParam.value),
    )

  return template(runner, state, payload)
}

function clear(state, payload) {
  const { filterGroupKey } = payload

  const currentFilters = state[MATCHES_OVERVIEW_PAGE_FILTERS_KEY][filterGroupKey]

  return {
    ...state,
    [MATCHES_OVERVIEW_PAGE_FILTERS_KEY]: {
      ...state.filters,
      [filterGroupKey]: {
        ...Object.keys(currentFilters).reduce((acc, currentKey) => {
          acc[currentKey] = []

          return acc
        }, {}),
      },
    },
  }
}

function unstage(state, payload) {
  const runner = (currentFilterValues) => filterSearchParamsApplied(currentFilterValues)

  return template(runner, state, payload)
}

function stage(removeCondition, groupKey, filterKey, state, { value }) {
  const current = state.filters[groupKey][filterKey]
  const isUncheck = current.some((x) => x.value === value && isSearchParamActive(x))

  let next

  if (isUncheck) {
    next = current.reduce((acc, searchParam) => {
      if (removeCondition(searchParam, value)) {
        acc.push(setSearchParamStagedForRemoval(searchParam.value))
      } else {
        acc.push(searchParam)
      }

      return acc
    }, [])
  } else {
    next = current
      .filter((x) => x.value !== value)
      .concat(setSearchParamStagedForAddition(value))
  }

  return {
    ...state,
    filters: {
      ...state.filters,
      [groupKey]: {
        ...state.filters[groupKey],
        [filterKey]: next,
      },
    },
  }
}

function stageCategories(state, payload) {
  /**
   * When categories includes the value, we must remove not only the value but all
   * children that of value. For example, removing `foo` should imply the removal of
   * `foo.bar`
   */
  return stage(
    (currentSearchParam, inputValue) => currentSearchParam.value.startsWith(inputValue),
    MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
    "categories",
    state,
    payload,
  )
}

function stageCountry(state, payload) {
  return stage(
    (currentSearchParam, inputValue) => currentSearchParam.value === inputValue,
    MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
    "countries",
    state,
    payload,
  )
}

function stageFamilies(state, payload) {
  return stage(
    (currentSearchParam, inputValue) => currentSearchParam.value === inputValue,
    MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
    "families",
    state,
    payload,
  )
}

function stageShop(state, payload) {
  return stage(
    (currentSearchParam, inputValue) => currentSearchParam.value === inputValue,
    MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
    "shops",
    state,
    payload,
  )
}

export {
  apply as handleMatchesOverviewFiltersApply,
  clear as handleMatchesOverviewFiltersClear,
  unstage as handleMatchesOverviewFiltersUnstage,
  stageCategories as handleMatchesOverviewReferenceProductCategoryFilterStaged,
  stageCountry as handleMatchesOverviewMatchCountryFilterStaged,
  stageFamilies as handleMatchesOverviewReferenceProductFamilyFilterStaged,
  stageShop as handleMatchesOverviewMatchShopFilterStaged,
}
