import { BaseScrollDownButton, BaseScrollUpButton } from "./partials/BaseScrollButton"
import { BaseSelect } from "./partials/BaseSelect"
import { BaseSelectArrow } from "./partials/BaseSelectArrow"
import { BaseSelectContent } from "./partials/BaseSelectContent"
import { BaseSelectGroup } from "./partials/BaseSelectGroup"
import { BaseSelectIcon } from "./partials/BaseSelectIcon"
import { BaseSelectItem } from "./partials/BaseSelectItem"
import { BaseSelectLabel } from "./partials/BaseSelectLabel"
import { BaseSelectPortal } from "./partials/BaseSelectPortal"
import { BaseSelectRoot } from "./partials/BaseSelectRoot"
import { BaseSelectSeparator } from "./partials/BaseSelectSeparator"
import { BaseSelectTrigger } from "./partials/BaseSelectTrigger"
import { BaseSelectValue } from "./partials/BaseSelectValue"
import { BaseSelectViewport } from "./partials/BaseSelectViewport"

/**
 * TODO: the styles of the Select component are still not uniform with other components, e.g.: Button and ToggleGroup
 *
 * Simple usage, ideal for use-cases where the BaseSelect is enough:
 *
 * ```jsx
 * <Select defaultValue="2">
 *   <Select.Item value="1">Item 1</Select.Item>
 *   <Select.Item value="2">Item 2</Select.Item>
 *   <Select.Item value="3">Item 3</Select.Item>
 * </Select>
 * ```
 *
 * Power mode usage for complex use-cases where fine-grained control is required
 *
 * ```jsx
 * <Select.Root {...props}>
 *   <Select.Trigger ref={forwardedRef}>
 *     <Select.Value />
 *     <Select.Icon>
 *       <ExpandLessIcon />
 *     </Select.Icon>
 *   </Select.Trigger>
 *   <Select.Portal>
 *     <Select.Content>
 *       <Select.ScrollUpButton>
 *         <ExpandMoreIcon />
 *       </Select.ScrollUpButton>
 *       <Select.Viewport>
 *          {children}
 *       </Select.Viewport>
 *       <Select.ScrollDownButton>
 *         <ExpandLessIcon />
 *       </Select.ScrollDownButton>
 *     </Select.Content>
 *   </Select.Portal>
 * </Select.Root>
 * ```
 */
export const Select = Object.assign(BaseSelect, {
  Arrow: BaseSelectArrow,
  Content: BaseSelectContent,
  Group: BaseSelectGroup,
  Icon: BaseSelectIcon,
  Item: BaseSelectItem, // Abstracts Item, ItemText and ItemIndicator
  Label: BaseSelectLabel,
  Portal: BaseSelectPortal,
  Root: BaseSelectRoot,
  ScrollDownButton: BaseScrollDownButton,
  ScrollUpButton: BaseScrollUpButton,
  Separator: BaseSelectSeparator,
  Trigger: BaseSelectTrigger,
  Value: BaseSelectValue,
  Viewport: BaseSelectViewport,
})
