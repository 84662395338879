import { Box, CircularProgress, useTheme } from "@mui/material"
import { bool } from "prop-types"
import React, { memo } from "react"

const TableLoading = memo(({ isLoading }) => {
  const theme = useTheme()

  return (
    <Box
      position="fixed"
      bottom="3.5rem"
      left="50%"
      padding={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="white"
      borderRadius="50%"
      border={`1px solid ${theme.palette.borderColor}`}
      visibility={isLoading ? "visible" : "hidden"}
      boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
      sx={{
        transitionDelay: ".2s",
        transform: isLoading ? "translateY(0)" : "translateY(110%)",
        transition: "transform .5s, visibility .2s",
      }}
    >
      <CircularProgress size={24} />
    </Box>
  )
})

TableLoading.displayName = "TableLoading"

TableLoading.propTypes = {
  isLoading: bool,
}

TableLoading.defaultProps = {
  isLoading: false,
}

export { TableLoading }
