import React from "react"
import PropTypes from "prop-types"
import parse from "autosuggest-highlight/parse"
import clsx from "clsx"
import AutocompleteFacetChip from "./AutocompleteFacetChip"

import styles from "./AutocompleteOption.module.scss"
import { customMatch } from "../helpers"
import { titleCase } from "../../../../../util/strings"

function AutocompleteOption({ search, facet, text }) {
  const titleMatches = customMatch(text, search)
  const titleParts = parse(titleCase(text), titleMatches)

  return (
    <div>
      {!!facet && (
        <span className={styles.chip}>
          <AutocompleteFacetChip type={facet} />
        </span>
      )}
      <span className={styles["text-container"]}>
        {titleParts.map((part, index) => (
          <span
            className={clsx({ [styles.highlighted]: part.highlight })}
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${part.text}`}
          >
            {part.text}
          </span>
        ))}
      </span>
    </div>
  )
}

AutocompleteOption.propTypes = {
  facet: PropTypes.string,
  search: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

AutocompleteOption.defaultProps = {
  facet: "",
}

export default AutocompleteOption
