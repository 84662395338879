export default {
  de: {
    translation: {
      "Apps & Reports": "Apps & Berichte",
      "<0>All your Daltix apps and reports in one place.</0> Gain insights on your products through Daltix apps, export your data or download your latest updated reports.":
        "<0>Alle Daltix-Anwendungen und Berichte an einem Ort.</0> Nutzen Sie die Apps für Analysen, exportieren Sie die Daten oder laden Sie die neuesten Berichte herunter.",
      "View Apps and Reports": "Apps & Berichte ansehen",
      "Match your reference products with those in the market. <2>Review, adjust and make matches</2> to get the most complete and best quality data into your apps and reports.":
        "Vergleichen und matchen Sie Ihre Referenzprodukte mit denen auf dem Markt. <2>Überprüfen und erstellen Sie neue Referenzierungen</2> an, um die bestmögliche Datenqualität für Ihre Apps und Berichte zu erhalten.",
      "Review Matches": "Matches überprüfen",
      "Make Matches": "Matches erstellen",
      "Matches Overview": "Match-Übersicht",
      "Assortment Analysis": "Warengruppenanalysetool",
      "Visualise and <2>compare your assortment with the competition</2> on Price, Format and other dimensions":
        "<2>Vergleichen und visualisieren Sie Ihr Sortiment</2> mit dem der Wettbewerber hinsichtlich Preis, Format und anderer Merkmale.",
      "Grid view": "Zum Tool",

      "A³ Tool": "Warengruppenanalysetool",
      "Analyse product data across different retailers and categories.":
        "Analysieren Sie Daten über verschiedene Einzelhändler und Warengruppen.",
      "View": "Ansicht",
      "Price overview": "Preisübersicht",
      "View matched products across the market.":
        "Sehen Sie sich die referenzierten Produkte auf dem Markt an.",
      "Price data on demand": "Preisdaten on demand",
      "Get an Excel export of Daltix price data based on your set filters.":
        "Erhalten Sie einen Excel-Export von Daltix-Preisdaten auf der Grundlage der von Ihnen festgelegten Filter.",
      "Product price evolution": "Preisentwicklung je Produkt",
      "Check the price evolution across the market for one of your products.":
        "Überprüfen Sie die Preisentwicklung auf dem Markt für einen Ihrer Artikel.",
      "Regular price changes": "Reguläre Preisänderungen",
      "App listing articles that changed in regular price versus last week.":
        "Auflistung von Artikeln, deren regulärer Preis sich im Vergleich zur letzten Woche geändert hat.",
      "RSP Heatmap": "UVP-Heatmap",
      "Compare Retailer prices against RSPs.":
        "Vergleichen Sie die Preise der Einzelhändler mit den UVPs.",
      "Match review": "Matches überprüfen",
      "Review and adjust matches.": "Überprüfen und passen Sie Referenzierungen an.",
      "Matches need to be approved": "Matches bedürfen einer Bestätigung",
      "Match need to be approved": "Match benötigt eine Bestätigung",
      "Make matches": "Matches erstellen",
      "Make matches to get the best quality data.":
        "Referenzieren Sie Ihre Artikel, um die beste Datenqualität zu erhalten.",
      "Match overview": "Match-Übersicht",
      "Overview of all matched products, listed by retailer.":
        "Übersicht über alle Referenzierungen, aufgelistet nach Einzelhändlern.",
      "Reports": "Berichte",
      "Download all reports here.": "Laden Sie hier alle Berichte herunter.",
      "Help": "Hilfe",
      "Account": "Konto",
      "User": "Benutzer",
      "Cookies": "Cookies",
      "Switch account": "Konto wechseln",
      "Sign out": "Abmelden",
      "Search": "Suche",
      "No options": "Keine Optionen",

      // AATool Categories
      "Sweet Spreads > Chocolate Spreads": "Süße Aufstriche > Schokoladenaufstriche",
      "Sweet Spreads > Honey": "Süße Aufstriche > Honig",
      "Sweet Spreads > Jam": "Süße Aufstriche > Fruchtaufstriche",
      "Sweet Spreads > Nut Spreads": "Süße Aufstriche > Nussaufstriche",
      "Sweet Spreads > Other Spreads": "Süße Aufstriche > Sonstige Aufstriche",
      "Sweet Spreads > Speculoos Spreads": "Süße Aufstriche > Spekulatiusaufstriche",
      "Sweet Spreads > Syrup": "Süße Aufstriche > Sirup",
      "Sweet spreads > Chocolate spreads": "Süße Aufstriche > Schokoladenaufstriche",
      "Sweet spreads > Honey": "Süße Aufstriche > Honig",
      "Sweet spreads > Jam": "Süße Aufstriche > Fruchtaufstriche",
      "Sweet spreads > Nut spreads": "Süße Aufstriche > Nussaufstriche",
      "Sweet spreads > Other spreads": "Süße Aufstriche > Sonstige Aufstriche",
      "Sweet spreads > Speculoos spreads": "Süße Aufstriche > Spekulatiusaufstriche",
      "Sweet spreads > Syrup": "Süße Aufstriche > Sirup",
    },
  },
}
