import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import { computeNutritionGroupTitle } from "./helpers"

import "./style.sass"
import { titleCase } from "../../util/strings"

const titlesLookup = Object.freeze({
  kj: "kJ",
})

function NutrientsList({ value, noPadding = false }) {
  if (!value) {
    return null
  }

  const nutritionGroupKeys = Object.keys(value).sort()

  const nutritionGroups = nutritionGroupKeys.reduce((acc, key) => {
    const rawGroup = value[key]

    const rawGroupCopy = { ...rawGroup }
    delete rawGroupCopy.extra_info

    const baseTitle = titlesLookup[key] || titleCase(key).replace("_", " ")

    acc[key] = {
      data: rawGroupCopy,
      title: computeNutritionGroupTitle(baseTitle, rawGroup.extra_info),
    }

    return acc
  }, {})

  return (
    <div className="dx-nutrients">
      <ul className="dx-nutrients-group" data-testid="nutrients-group">
        {nutritionGroupKeys.map((groupKey) => {
          const group = nutritionGroups[groupKey]

          return (
            <li key={groupKey} className="dx-nutrients-group-item">
              <span className="dx-nutrients-group-title">{group.title}</span>
              <ul
                className={clsx(
                  "dx-nutrients-group-list",
                  noPadding && "dx-nutrients-no-padding",
                )}
                data-testid="nutrients-group-list"
              >
                {Object.keys(group.data)
                  .sort()
                  .map((rowKey) => (
                    <li
                      key={`${groupKey}-${rowKey}`}
                      className="dx-nutrients-group-list-item"
                    >
                      <span className="dx-nutrients-group-list-item-label">
                        {titlesLookup[rowKey] || titleCase(rowKey).replace("_", " ")}:
                      </span>{" "}
                      <span className="dx-nutrients-group-list-item-value">
                        {group.data[rowKey]}
                      </span>
                    </li>
                  ))}
              </ul>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

NutrientsList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  noPadding: PropTypes.bool,
}

NutrientsList.defaultProps = {
  value: null,
  noPadding: false,
}

export { NutrientsList }
