import { useDispatch } from "react-redux"
import { useCallback } from "react"
import { clearReferenceProductsMatchesByShopFilters } from "../../../ducks/pages/matches-overview/action-creators"
import {
  MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
} from "../../../ducks/pages/matches-overview/constants"

export default function useMatchesOverviewFiltersClear() {
  const dispatch = useDispatch()

  const run = useCallback(
    (filterGroupKey) => {
      dispatch(clearReferenceProductsMatchesByShopFilters(filterGroupKey))
    },
    [dispatch],
  )

  function clearMatchFilters() {
    run(MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY)
  }

  function clearReferenceProductFilters() {
    run(MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY)
  }

  return {
    clearMatchFilters,
    clearReferenceProductFilters,
  }
}
