export function handleMatchesOverviewReferenceProductMatchCountFilterChange(
  state,
  _payload,
) {
  return {
    ...state,
    filters: {
      ...state.filters,
      matches: {
        ...state.filters.matches,
        count: state.filters.matches.count === 0 ? undefined : 0,
      },
    },
  }
}
