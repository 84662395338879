import { debounce } from "lodash"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import mixpanel from "mixpanel-browser"
import * as api from "../../../api/service"
import { actionCreators as dxProductsActions } from "../../daltix-product"
import { getOneReferenceProductById } from "../../repositories/reference-products/action-creators"
import * as ActionCreators from "./action-creators"
import * as ActionTypes from "./action-types"
import {
  INITIAL_FILTERS,
  PRODUCT_SEARCH_WIDGET_PRODUCTS_QUERY_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY,
  PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS,
} from "./constants"

const IDLE_STATUS = {
  data: undefined,
  error: undefined,
  status: "idle",
}

export function useProductSearch() {
  const dispatch = useDispatch()
  const { state, suggestions, referenceProduct } = useSelector(
    ({ widgets, daltixProduct, repositories }) => {
      const { referenceProductId } = widgets.ProductSearchWidget
      const refProduct =
        repositories.ReferenceProductsRepository?.documents?.[referenceProductId]

      return {
        state: widgets.ProductSearchWidget,
        suggestions: daltixProduct?.autoCompleteSuggestions,
        referenceProduct: refProduct || IDLE_STATUS,
      }
    },
  )
  const referenceProductId = state[PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY]
  const query = state[PRODUCT_SEARCH_WIDGET_PRODUCTS_QUERY_KEY]
  const selectedFilters = state[PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS]

  const setRefProductId = useCallback(
    (newReferenceProductId) =>
      dispatch({
        type: ActionTypes.PRODUCT_SEARCH_SET,
        referenceProductId: newReferenceProductId,
      }),
    [dispatch],
  )

  const setFilters = useCallback(
    (newFilters) => {
      dispatch({
        type: ActionTypes.PRODUCT_SEARCH_SET_SELECTED_FILTERS,
        filters: newFilters,
      })

      mixpanel.track("Product Search - set filters", {
        filters: newFilters,
      })
    },
    [dispatch],
  )

  const clearFilters = useCallback(() => {
    dispatch({
      type: ActionTypes.PRODUCT_SEARCH_CLEAR_SELECTED_FILTERS,
    })

    mixpanel.track("Product Search - clear filters")
  }, [dispatch])

  const close = () => {
    dispatch({ type: ActionTypes.PRODUCT_SEARCH_CLOSE })
    mixpanel.track("Product Search - close")
    clearFilters()
  }

  const getReferenceProductById = useCallback(
    (id) => dispatch(getOneReferenceProductById({ id })),
    [dispatch],
  )

  const getFilters = useCallback(
    (newQuery) => {
      dispatch(ActionCreators.getProductsSearchFilters(newQuery))
      dispatch(ActionCreators.getReferenceProductSummary(referenceProductId))
    },
    [dispatch, referenceProductId],
  )

  const listProductSuggestions = useMemo(() => {
    async function fetch(
      newQuery,
      fields = ["name", "brand", "shop", "eans"],
      pageSize = 6,
    ) {
      dispatch(
        dxProductsActions.getDxProductsAutoCompleteSuggestions(newQuery, {
          pageSize,
          fields,
        }),
      )
    }

    return debounce(fetch, 400)
  }, [dispatch])

  const listReferenceProduct = useCallback(
    (newquery) => {
      dispatch({
        type: ActionTypes.PRODUCT_SEARCH_REF_PRODUCT_LIST_BY_QUERY,
        query: newquery,
      })

      mixpanel.track("Product Search - get reference product/s", {
        query: newquery,
      })
    },
    [dispatch],
  )

  const setQuery = useCallback(
    (newQuery) =>
      dispatch({
        type: ActionTypes.PRODUCT_SEARCH_SET_QUERY,
        query: newQuery,
      }),
    [dispatch],
  )

  const listProducts = useCallback(
    async (id, { query: newQuery, filters: newFilters }) => {
      function adaptFilter(filter) {
        return Object.keys(newFilters[filter]).filter((key) => newFilters[filter][key])
      }

      const adaptedFilters = {
        ...newFilters,
        status: adaptFilter("status"),
        availabilities: adaptFilter("availabilities"),
        matchType: adaptFilter("matchType"),
        brands: adaptFilter("brands"),
        source: adaptFilter("source"),
        shops: adaptFilter("shops"),
        countries: adaptFilter("countries"),
        factors: adaptFilter("factors"),
      }

      let data = {
        rows: [],
        total: 0,
      }

      mixpanel.track("Product Search - search matches", {
        referenceProductId: id,
        query: newQuery,
        filters: adaptedFilters,
      })

      if (id) {
        data = await api.listProductsWithMatches(id, {
          query: newQuery,
          filters: adaptedFilters,
        })
      }

      dispatch({
        type: ActionTypes.PRODUCT_SEARCH_SET_TOTAL,
        total: data.total,
      })
      return data
    },
    [dispatch],
  )

  const setSort = useCallback(
    (field, order) => {
      const newFilters = { ...selectedFilters, sort: field, direction: order }
      setFilters(newFilters)
    },
    [selectedFilters, setFilters],
  )

  return {
    isOpen: !!referenceProductId,
    referenceProductId,
    referenceProduct,
    state,
    close,
    getReferenceProductById,
    getFilters,
    listProducts,
    setFilters,
    setRefProductId,
    setSort,
    listProductSuggestions,
    suggestions,
    setQuery,
    query,
    listReferenceProduct,
    clearFilters,
  }
}

export function useOpen() {
  const dispatch = useDispatch()

  const open = useCallback(
    (referenceProductId, initialFilters) => {
      if (initialFilters) {
        dispatch({
          type: ActionTypes.PRODUCT_SEARCH_SET_SELECTED_FILTERS,
          filters: { ...INITIAL_FILTERS, ...initialFilters },
        })

        mixpanel.track("Product Search - open", {
          referenceProductId,
        })
      }

      setTimeout(() => {
        dispatch({
          type: ActionTypes.PRODUCT_SEARCH_SET,
          referenceProductId,
        })
      }, 100)
    },
    [dispatch],
  )

  return open
}
