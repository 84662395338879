import PropTypes from "prop-types"
import React from "react"

import styles from "./AssortmentAnalysisRowHeader.module.scss"

import {
  DIMENSION_BRAND_TYPE,
  DIMENSION_PRICE,
} from "../../../../ducks/pages/assortment-analysis/constants"
import { mapBrandLayerName } from "../../../../util/brands"
import { EUR_SYMBOL } from "../../../../util/constants"
import { DimensionBrandPropType, DimensionPricePropType } from "../../prop-types"

function PriceDimensionRowHeader({ dimension }) {
  const { start, end } = dimension
  const text = `[${toFriendly(start)} - ${toFriendly(end)}]`

  function toFriendly(val) {
    const maybeNumber = Number(val)

    return `${EUR_SYMBOL}${Number.isNaN(maybeNumber) ? val : maybeNumber.toFixed(2)}`
  }

  return <span className={styles.heading}>{text}</span>
}

PriceDimensionRowHeader.propTypes = {
  dimension: DimensionPricePropType.isRequired,
}

function BrandDimensionRowHeader({ dimension }) {
  const { id } = dimension

  return <span className={styles.heading}>{mapBrandLayerName(id)}</span>
}

BrandDimensionRowHeader.propTypes = {
  dimension: DimensionBrandPropType.isRequired,
}

export function AssortmentAnalysisRowHeader({ dimension }) {
  let Child = null

  switch (dimension.type) {
    case DIMENSION_BRAND_TYPE: {
      Child = <BrandDimensionRowHeader dimension={dimension} />
      break
    }
    case DIMENSION_PRICE: {
      Child = <PriceDimensionRowHeader dimension={dimension} />
      break
    }
    default: {
      throw new Error(
        `AssortmentAnalysisRowHeader received unknown dimension type: '${JSON.stringify(dimension)}'`,
      )
    }
  }

  return <div className={styles.container}>{Child}</div>
}

AssortmentAnalysisRowHeader.propTypes = {
  dimension: PropTypes.oneOfType([DimensionBrandPropType, DimensionPricePropType])
    .isRequired,
}
