import PropTypes from "prop-types"
import React from "react"
import ReactCountryFlag from "react-country-flag"
import { Table } from "../ui"
import useShop from "../../hooks/use-shop"

import styles from "./MatchesOverviewShop.module.scss"

function MatchesOverviewShop({ country, id }) {
  const { logo, name } = useShop(id)

  return (
    <>
      <Table.ColumnHeader>
        <div className={styles.container}>
          {logo ? (
            <img className={styles.logo} src={logo} alt={`${name} logo`} />
          ) : (
            <span className={styles.name}>{name}</span>
          )}
        </div>
      </Table.ColumnHeader>
      <div className={styles.flag}>
        <ReactCountryFlag countryCode={country} />
      </div>
    </>
  )
}

MatchesOverviewShop.propTypes = {
  country: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default MatchesOverviewShop
