export const titleCase = (text = "") =>
  text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ")
    .split("-")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join("-")

export const formatEan = (ean) => {
  const formatted = ean.slice(-13)

  return formatted
}

export const sanitizeEan = (ean) => ean.slice(-13)

export const contentString = ({
  parsed,
  value,
  string,
  unit,
  approximate,
  multiplier = 1,
  userProvided,
}) => {
  if ((!parsed && !userProvided) || !value) {
    return string
  }

  return `${multiplier && multiplier >= 1 ? `${multiplier} x ` : ""}${
    approximate ? "~" : ""
  }${value} ${unit}`
}

export function slugify(str, separator = "-") {
  let transformedStr = str.replace(/^\s+|\s+$/g, "")

  // Make the string lowercase
  transformedStr = transformedStr.toLowerCase()

  // Remove accents, swap ñ for n, etc
  const from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;"
  const to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------"
  for (let i = 0, l = from.length; i < l; i++) {
    transformedStr = transformedStr.replace(
      new RegExp(from.charAt(i), "g"),
      to.charAt(i),
    )
  }

  // Remove invalid chars
  transformedStr = transformedStr
    .replace(/[^a-z0-9 -]/g, " ")
    // Collapse whitespace and replace by {{ separator }}
    .replace(/\s+/g, separator)
    // Collapse dashes
    .replace(/-+/g, separator)

  return transformedStr
}

export function humanFileSize(size = 0) {
  if (!size) return 0

  const i = Math.floor(Math.log(size) / Math.log(1024))
  return `${(size / 1024 ** i).toFixed(2) * 1} ${["B", "kB", "MB", "GB", "TB"][i]}`
}

export function generateId(length) {
  let result = ""
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function isString(value) {
  return typeof value === "string"
}
