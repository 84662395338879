import { Logger } from "../../../../util/log"
import {
  toAsyncErrorSlice,
  toAsyncInitialSlice,
  toAsyncLoadingSlice,
  toAsyncSuccessSlice,
} from "../../../../util/redux-helpers"

const _log = new Logger("ducks:repositories:matched-products:handlers")

export function handleGetMatchedProductByIds(state, { ids }) {
  const products = Object.assign(
    {},
    ...ids.map((id) => ({
      [id]: toAsyncLoadingSlice((state.documents[id] || toAsyncInitialSlice()).data),
    })),
  )

  return {
    ...state,
    documents: {
      ...state.documents,
      ...products,
    },
  }
}

export function handleGetMatchedProductByIdsSuccess(state, { payload }) {
  const products = Object.fromEntries(
    payload.map((product) => [product.id, toAsyncSuccessSlice(product)]),
  )

  return {
    ...state,
    documents: {
      ...state.documents,
      ...products,
    },
  }
}

export function handleGetMatchedProductByIdsError(state, { error }) {
  const products = state.documents || toAsyncInitialSlice()

  return {
    ...state,
    documents: {
      ...state.documents,
      ...toAsyncErrorSlice(products, error),
    },
  }
}
