import {
  toAsyncErrorSlice,
  toAsyncLoadingSlice,
  toAsyncSuccessSlice,
} from "../../../../util/redux-helpers"
import {
  MATCHES_OVERVIEW_PAGE_FLAG_KEY,
  MATCHES_OVERVIEW_PAGE_META_KEY,
  MATCHES_OVERVIEW_PAGE_PAGINATION_KEY,
  MATCHES_OVERVIEW_PAGE_REQUEST_KEY,
} from "../constants"

export function handleGetCustomerReferenceProductsMatchesByShop(state) {
  return {
    ...state,
    [MATCHES_OVERVIEW_PAGE_REQUEST_KEY]: {
      ...state[MATCHES_OVERVIEW_PAGE_REQUEST_KEY],
      ...toAsyncLoadingSlice(state[MATCHES_OVERVIEW_PAGE_REQUEST_KEY].data),
    },
  }
}
export function handleGetCustomerReferenceProductsMatchesByShopSuccess(state, action) {
  const {
    parent,
    payload: { data, currentPage, currentPageSize, pageCount, resultsCount, flags },
  } = action

  const hasProducts = data.length > 0
  const hasShops = hasProducts && Object.keys(data[0].shops).length > 0

  return {
    ...state,
    [MATCHES_OVERVIEW_PAGE_META_KEY]: {
      hasProducts,
      hasShops,
    },
    [MATCHES_OVERVIEW_PAGE_PAGINATION_KEY]: {
      ...state[MATCHES_OVERVIEW_PAGE_PAGINATION_KEY],
      hasNextPage: pageCount > currentPage,
      hasPreviousPage: currentPage > 1,
      page: parent.page ?? state.pagination.page ?? currentPage,
      pageCount: pageCount ?? Number.POSITIVE_INFINITY,
      pageSize: parent.pageSize ?? state.pagination.pageSize ?? currentPageSize,
      resultsCount: resultsCount ?? Number.POSITIVE_INFINITY,
    },
    [MATCHES_OVERVIEW_PAGE_FLAG_KEY]: flags,
    [MATCHES_OVERVIEW_PAGE_REQUEST_KEY]: toAsyncSuccessSlice(data),
  }
}

export function handleGetCustomerReferenceProductsMatchesByShopError(state, { error }) {
  return {
    ...state,
    [MATCHES_OVERVIEW_PAGE_REQUEST_KEY]: toAsyncErrorSlice(
      state[MATCHES_OVERVIEW_PAGE_REQUEST_KEY].data,
      error,
    ),
  }
}
