import { Button, Typography } from "@mui/material"
import { bool, func } from "prop-types"
import React from "react"
import { DXIcon } from "../../../dx-icon/DXIcon"

function TableActions({ disabled, onClick }) {
  return (
    <Button
      variant="text"
      disabled={disabled}
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 64,
        height: 64,
        p: 1,
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "none !important",
      }}
    >
      <DXIcon type="compare" outlined />

      <Typography
        width="100%"
        color="inherit"
        fontSize="10px"
        lineHeight="24px"
        textAlign="center"
      >
        Compare
      </Typography>
    </Button>
  )
}

TableActions.propTypes = {
  disabled: bool.isRequired,
  onClick: func.isRequired,
}

export { TableActions as ProductSearchActions }
