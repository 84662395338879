import * as RUIDialog from "@radix-ui/react-dialog"

import { Button as BaseButton } from "../Button"

import { BaseDialogBody } from "./partials/BaseDialogBody"
import { BaseDialogClose } from "./partials/BaseDialogClose"
import { BaseDialogCloseX } from "./partials/BaseDialogCloseX"
import { BaseDialogContent } from "./partials/BaseDialogContent"
import { BaseDialogDescription } from "./partials/BaseDialogDescription"
import { BaseDialogFooter } from "./partials/BaseDialogFooter"
import { BaseDialogHeader } from "./partials/BaseDialogHeader"
import { BaseDialogTitle } from "./partials/BaseDialogTitle"
import { BaseDialogTrigger } from "./partials/BaseDialogTrigger"

export const Dialog = {
  Button: BaseButton,
  Body: BaseDialogBody,
  Close: BaseDialogClose,
  CloseX: BaseDialogCloseX,
  Content: BaseDialogContent,
  Description: BaseDialogDescription,
  Footer: BaseDialogFooter,
  Header: BaseDialogHeader,
  Root: RUIDialog.Root,
  Title: BaseDialogTitle,
  Trigger: BaseDialogTrigger,
}
