import React, { useState } from "react"
import Autosuggest from "react-autosuggest"
import parse from "autosuggest-highlight/parse"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import { withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import { titleCase } from "../../util/strings"
import "./ProductAutocomplete.legacy.scss"
import { Autocomplete } from "../ui"
import { customMatch } from "../ui/Combobox/Autocomplete/helpers"

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps

  return (
    <TextField
      fullWidth
      color="primary"
      InputProps={{
        inputRef: (node) => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  )
}

function renderSuggestion({ title, type }, { query, isHighlighted }) {
  const titleMatches = customMatch(title, query)
  const titleParts = parse(titleCase(title), titleMatches)

  return (
    <MenuItem selected={isHighlighted} component="div" className="item">
      <div className="card">
        <div className="text">
          <Typography className="title">
            <Autocomplete.Chip type={type} />
            {titleParts.map((part, index) =>
              part.highlight ? (
                // eslint-disable-next-line react/no-array-index-key
                <span key={String(index)} style={{ fontWeight: 500 }}>
                  {part.text}
                </span>
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <strong key={String(index)} style={{ fontWeight: 300 }}>
                  {part.text}
                </strong>
              ),
            )}
          </Typography>
        </div>
      </div>
    </MenuItem>
  )
}

function getSuggestionValue(suggestion) {
  return suggestion && titleCase(suggestion.title)
}

const styles = (theme) => ({
  container: {
    position: "relative",
    flexGrow: 1,
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: 0,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  input: {
    color: theme.palette.primary.main,
  },
})

function ProductAutocompleteV2({
  className,
  value,
  suggestions,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  placeholder,
  classes,
  disabled = false,
  onValueChange,
  onSelected,
  inputProps,
}) {
  const [internalValue, setInternalValue] = useState("")

  function handleChangeInternalValue(_, { newValue, method }) {
    if (method !== "enter") {
      setInternalValue(newValue)
    }
  }

  const computedSuggestionValue = value || internalValue

  function handleKeyPress(event) {
    if (event.key === "Enter" && onSelected) {
      onSelected(event, { suggestionValue: computedSuggestionValue })
    }
  }

  function handleOnSelected(event, { suggestionValue, method }) {
    if (method !== "enter") {
      onSelected(event, { suggestionValue })
    }
  }

  function handleValueChange(event, elementAttributes) {
    if (typeof onValueChange === "function") {
      onValueChange(event, elementAttributes)
    } else {
      handleChangeInternalValue(event, elementAttributes)
    }
  }

  const autosuggestProps = {
    renderInputComponent,
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested:
      !value || value === "" ? onSuggestionsClearRequested : () => null,
    getSuggestionValue,
    renderSuggestion,
  }

  return (
    <Autosuggest
      {...autosuggestProps}
      inputProps={{
        classes,
        className,
        placeholder,
        value: computedSuggestionValue,
        disabled,
        onChange: handleValueChange,
        onKeyPress: handleKeyPress,
        ...inputProps,
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={(options) => (
        <Paper
          {...options.containerProps}
          square
          className={`${options.containerProps.className} auto-complete-container`}
        >
          {options.children}
        </Paper>
      )}
      onSuggestionSelected={handleOnSelected}
    />
  )
}

export default withStyles(styles)(ProductAutocompleteV2)
