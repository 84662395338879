import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { replace } from "connected-react-router"
import { actionCreators as refProdActions } from "../../ducks/reference-products"
import { actionCreators as matchActions } from "../../ducks/matches"
import MatchingEnginePresenter from "./MatchingEnginePresenter"
import useCustomer from "../../hooks/use-customer"

const matchingEngineRoutes = Object.freeze({
  match: "/matches/match",
  overview: "/matches/overview",
  review: "/matches/review",
})

const DEFAULT_PANEL = matchingEngineRoutes.review

function MatchingEngine({ match: { path }, location, ...rest }) {
  const dispatch = useDispatch()

  /* eslint-disable */
  const {
    loadingRefProdCategoriesTree,
    loadingRefProdCategoriesFromMatchesTree,
    categoriesTree,
    categoriesFromMatchesTree,
    referenceProductSummary,
  } = useSelector(
    ({
      refProduct: {
        categories: { loadingCategoriesTree: loadingRefProdCategoriesTree },
        categoriesTree: referenceProductCategoriesTree,
        summary: referenceProductSummary,
      },
      matches: {
        referenceProductCategories: {
          loadingCategoriesTree: loadingRefProdCategoriesFromMatchesTree,
          categoriesTree: referenceProductCategoriesFromMatchesTree,
        },
      },
    }) => ({
      loadingRefProdCategoriesTree,
      loadingRefProdCategoriesFromMatchesTree,
      referenceProductCategoriesTree,
      referenceProductCategoriesFromMatchesTree,
      referenceProductSummary,
    }),
    shallowEqual,
  )

  const { db } = useCustomer()

  const { pathname } = location

  useEffect(() => {
    if (["/matches", "/matches/"].includes(pathname)) {
      dispatch(replace(DEFAULT_PANEL))
    }
  }, [dispatch, pathname])

  useEffect(() => {
    if (
      pathname.startsWith(matchingEngineRoutes.review) ||
      pathname.startsWith(matchingEngineRoutes.match)
    ) {
      if (
        pathname.startsWith(matchingEngineRoutes.review) &&
        !categoriesFromMatchesTree
      ) {
        dispatch(matchActions.getReferenceProductsCategoriesFromMatches())
      }
      if (pathname.startsWith(matchingEngineRoutes.match) && !categoriesTree) {
        dispatch(refProdActions.getReferenceProductCategories())
      }
      if (!referenceProductSummary) {
        dispatch(refProdActions.getReferenceProductsSummary())
      }
    }
  }, [dispatch, pathname])

  const gatheringData =
    pathname !== matchingEngineRoutes.overview &&
    ((loadingRefProdCategoriesTree &&
      pathname.startsWith(matchingEngineRoutes.match)) ||
      (loadingRefProdCategoriesFromMatchesTree &&
        pathname.startsWith(matchingEngineRoutes.review)))

  return (
    <MatchingEnginePresenter
      customerDbName={db}
      location={location}
      loading={gatheringData}
      path={path}
      pathname={pathname}
      routes={matchingEngineRoutes}
      {...rest}
    />
  )
}

MatchingEngine.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default MatchingEngine
export { matchingEngineRoutes }
