import React, { forwardRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Tooltip } from "@material-ui/core"
import clsx from "clsx"
import mixpanel from "mixpanel-browser"
import AppPropTypes from "../../../prop-shapes"
import styles from "./Toggle.module.scss"
import { isValidAnalyticsEventName } from "../../../util/user-monitoring"

const Toggle = forwardRef((props, forwardedRef) => {
  const {
    analyticsEventName,
    className,
    defaultPressed,
    loading,
    pressed,
    tooltip,
    onClick,
    ...buttonProps
  } = props

  const [isControlled] = useState(() => pressed !== undefined)
  const [innerPressed, setInnerPressed] = useState(() =>
    isControlled ? pressed : defaultPressed,
  )

  const computedPress = isControlled ? pressed : innerPressed

  useEffect(() => {
    if (isControlled && pressed !== innerPressed) {
      setInnerPressed(pressed)
    }
  }, [innerPressed, isControlled, pressed])

  function handleClick(e) {
    e.preventDefault()

    setInnerPressed((current) => {
      const nextValue = !current

      if (isValidAnalyticsEventName(analyticsEventName)) {
        mixpanel.track(analyticsEventName, { toggleValue: nextValue })
      }

      return nextValue
    })

    onClick(e)
  }

  return (
    <Tooltip classes={{ tooltip: "tooltip" }} title={tooltip}>
      <button
        aria-busy={loading}
        aria-disabled={props.disabled}
        aria-pressed={computedPress}
        data-disabled={props.disabled ? "true" : undefined}
        data-loading={loading ? "true" : undefined}
        data-state={computedPress ? "on" : "off"}
        type="button"
        onClick={handleClick}
        {...buttonProps}
        ref={forwardedRef}
        className={clsx(styles.button, className)}
      />
    </Tooltip>
  )
})

Toggle.displayName = "Toggle"

Toggle.propTypes = {
  analyticsEventName: PropTypes.string,
  className: PropTypes.string,
  children: AppPropTypes.childrenNodes.isRequired,
  defaultPressed: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  pressed: PropTypes.bool,
  tooltip: PropTypes.string.isRequired,
}

Toggle.defaultProps = {
  analyticsEventName: undefined,
  className: "",
  defaultPressed: false,
  disabled: false,
  loading: false,
  onClick: () => undefined,
  pressed: undefined,
}

export { Toggle }
