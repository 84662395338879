function computeAdditionalTitle(obj, term, terms) {
  const value = obj[term]

  if (value === true) {
    terms.push(term)
  } else if (value === false) {
    terms.push(`not ${term}`)
  }

  return terms
}

export function computeNutritionGroupTitle(baseTitle, extra) {
  if (!extra) {
    return baseTitle
  }

  let additionalTerms = computeAdditionalTitle(extra, "leaked", [])
  additionalTerms = computeAdditionalTitle(extra, "prepared", additionalTerms)

  return `${baseTitle} ${additionalTerms.join(" & ")}`.trim()
}
