import React from "react"
import { countries } from "countries-list"
import ReactCountryFlag from "react-country-flag"
import PropTypes from "prop-types"
import { titleCase } from "../../util/strings"

function CountryItem({ code }) {
  if (!code) {
    return <div>Unknown ({code})</div>
  }

  const country = countries[code.toUpperCase()]

  return (
    <div>
      <span style={{ marginLeft: "-1.1em" }}>
        <ReactCountryFlag
          styleProps={{ marginBottom: ".2em" }}
          title={titleCase(country ? country.name : code)}
          code={code}
          svg
        />
      </span>{" "}
      {titleCase(country ? country.name : code)}
    </div>
  )
}

CountryItem.propTypes = {
  code: PropTypes.string.isRequired,
}

export default CountryItem
