import {
  PRODUCT_COMPARISON_WIDGET_COMPARABLE_KEY,
  PRODUCT_COMPARISON_WIDGET_FILTERS_KEY,
  PRODUCT_COMPARISON_WIDGET_FILTERS_STATUS_KEY,
  PRODUCT_COMPARISON_WIDGET_MATCHES_KEY,
  PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY,
} from "./constants"

import {
  toAsyncErrorSlice,
  toAsyncInitialSlice,
  toAsyncLoadingSlice,
  toAsyncSuccessSlice,
} from "../../../util/redux-helpers"

export function handleProductComparisonWidgetOpened(state, payload) {
  const { referenceProductId } = payload

  return {
    ...state,
    [PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY]: referenceProductId,
  }
}

export function handleProductComparisonWidgetClosed(state, _payload) {
  return {
    ...state,
    [PRODUCT_COMPARISON_WIDGET_MATCHES_KEY]: toAsyncInitialSlice(),
    [PRODUCT_COMPARISON_WIDGET_REF_PRODUCT_KEY]: "",
    [PRODUCT_COMPARISON_WIDGET_FILTERS_KEY]: {
      [PRODUCT_COMPARISON_WIDGET_FILTERS_STATUS_KEY]: [],
    },
  }
}

export function handleProductComparisonDaltixProductStatusFilterChange(state, payload) {
  const { statuses } = payload

  return {
    ...state,
    [PRODUCT_COMPARISON_WIDGET_FILTERS_KEY]: {
      ...state[PRODUCT_COMPARISON_WIDGET_FILTERS_KEY],
      [PRODUCT_COMPARISON_WIDGET_FILTERS_STATUS_KEY]: [...statuses],
    },
  }
}

export function handleProductComparisonWidgetGetRefProductMatches(state) {
  return {
    ...state,
    [PRODUCT_COMPARISON_WIDGET_MATCHES_KEY]: toAsyncLoadingSlice(
      state[PRODUCT_COMPARISON_WIDGET_MATCHES_KEY].data,
    ),
  }
}

export function handleProductComparisonWidgetGetRefProductMatchesSuccess(
  state,
  action,
) {
  const { parent: _, payload } = action

  return {
    ...state,
    [PRODUCT_COMPARISON_WIDGET_MATCHES_KEY]: toAsyncSuccessSlice(
      payload.matches.map((m) => ({
        daltixId: m.daltix_id,
        matchId: m.id,
        status: m.status,
      })),
    ),
  }
}

export function handleProductComparisonWidgetGetRefProductMatchesError(
  state,
  { error },
) {
  return {
    ...state,
    [PRODUCT_COMPARISON_WIDGET_MATCHES_KEY]: toAsyncErrorSlice(
      state[PRODUCT_COMPARISON_WIDGET_MATCHES_KEY].data,
      error,
    ),
  }
}

export function handleProductComparisonWidgetCompare(state, payload) {
  const { ids } = payload

  return {
    ...state,
    [PRODUCT_COMPARISON_WIDGET_COMPARABLE_KEY]: ids,
  }
}
