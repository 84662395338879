import * as DaltixProductsRepositoryActions from "./action-types"

export function getOneDaltixProductById({ id, invalidateCache = false }) {
  return {
    type: DaltixProductsRepositoryActions.DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
    id,
    invalidateCache,
  }
}

export function getDaltixProductsByIds({ ids, invalidateCache = false }) {
  return {
    type: DaltixProductsRepositoryActions.DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS,
    ids,
    invalidateCache,
  }
}
