import {
  toAsyncErrorSlice,
  toAsyncLoadingSlice,
  toAsyncSuccessSlice,
} from "../../../../util/redux-helpers"

export function handleGetCustomerReferenceProductsMatchesByShopMeta(state) {
  return {
    ...state,
    headingCounts: {
      ...toAsyncLoadingSlice(state.headingCounts.data),
    },
    filters: {
      ...state.filters,
      outline: {
        ...state.filters.outline,
        categories: toAsyncLoadingSlice(state.filters.outline.categories.data),
        families: toAsyncLoadingSlice(state.filters.outline.families.data),
      },
    },
  }
}

export function handleGetCustomerReferenceProductsMatchesByShopMetaSuccess(
  state,
  action,
) {
  const {
    payload: { categories, families, matched, total, unmatched },
  } = action

  return {
    ...state,
    headingCounts: {
      ...state.headingCounts,
      ...toAsyncSuccessSlice({ total, matched, unmatched }),
    },
    filters: {
      ...state.filters,
      outline: {
        ...state.filters.outline,
        categories: toAsyncSuccessSlice(categories),
        families: toAsyncSuccessSlice(families),
      },
    },
  }
}

export function handleGetCustomerReferenceProductsMatchesByShopMetaError(
  state,
  { error },
) {
  return {
    ...state,
    headingCounts: {
      ...state.headingCounts,
      ...toAsyncErrorSlice(state.headingCounts.data, error),
    },
    filters: {
      ...state.filters,
      outline: {
        ...state.filters.outline,
        categories: toAsyncErrorSlice(state.filters.outline.categories.data, error),
        families: toAsyncErrorSlice(state.filters.outline.families.data, error),
      },
    },
  }
}
