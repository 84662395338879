import { ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY } from "../constants"

export function handleGetCustomerAssortmentExport(state) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY]: {
      loading: true,
      error: null,
    },
  }
}

export function handleGetCustomerAssortmentExportSuccess(state) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY]: {
      loading: false,
      error: null,
    },
  }
}

export function handleGetCustomerAssortmentExportError(state, { error }) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY]: {
      loading: false,
      error,
    },
  }
}
