import {
  toAsyncErrorSlice,
  toAsyncLoadingSlice,
  toAsyncSuccessSlice,
} from "../../../../util/redux-helpers"
import {
  ASSORTMENT_ANALYSIS_PAGE_META_KEY,
  ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY,
} from "../constants"

export function handleGetCustomerAssortment(state) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY]: {
      ...state[ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY],
      ...toAsyncLoadingSlice(state[ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY].data),
    },
  }
}

export function handleGetCustomerAssortmentSuccess(state, action) {
  const {
    parent,
    payload: { data, currentPage, currentPageSize, pageCount, resultsCount },
  } = action

  const hasArticles = data.length > 0
  const hasShops = hasArticles && Object.keys(data[0].shops).length > 0

  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_META_KEY]: {
      hasArticles,
      hasShops,
    },
    [ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY]: {
      ...state[ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY],
      hasNextPage: pageCount > currentPage,
      hasPreviousPage: currentPage > 1,
      page: parent.page ?? state.pagination.page ?? currentPage,
      pageCount: pageCount ?? Number.POSITIVE_INFINITY,
      pageSize: parent.pageSize ?? state.pagination.pageSize ?? currentPageSize,
      resultsCount: resultsCount ?? Number.POSITIVE_INFINITY,
    },
    [ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY]: toAsyncSuccessSlice(data),
  }
}

export function handleGetCustomerAssortmentError(state, { error }) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY]: toAsyncErrorSlice(
      state[ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY].data,
      error,
    ),
  }
}
