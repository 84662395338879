import React from "react"
import Grid from "@material-ui/core/Grid"
import ReferenceProductSlider from "../reference-product-slider/ReferenceProductSlider"
import MatchesPanel from "../matches-panel/MatchesPanel"
import HtmlTitle from "../html-title/HtmlTitle"

function MatchingPanelPresenter() {
  return (
    <div className="matching-panel main-content-panel fill-height-container">
      <HtmlTitle>Make Matches</HtmlTitle>
      <Grid container spacing={16} className="main-content-grid">
        <Grid item md={6} xs={12}>
          <ReferenceProductSlider />
        </Grid>
        <Grid item md={6} xs={12}>
          <MatchesPanel />
        </Grid>
      </Grid>
    </div>
  )
}

export default MatchingPanelPresenter
