import { successType, errorType, buildEpic } from "../util/redux-observable-helpers"
import * as api from "../api/service"

// Action Types

const REPORT_LIST = "@report/LIST"
const REPORT_LIST_SUCCESS = successType(REPORT_LIST)
const REPORT_LIST_ERROR = errorType(REPORT_LIST)

const REPORT_SET_PAGE = "@report/SET_PAGE"

const actionTypes = {
  REPORT_LIST,
  REPORT_LIST_SUCCESS,
  REPORT_LIST_ERROR,

  REPORT_SET_PAGE,
}

// Action Creators

const listReports = () => ({
  type: REPORT_LIST,
})

const setReportsPage = (page) => ({
  type: REPORT_SET_PAGE,
  page,
})

const actionCreators = {
  listReports,
  setReportsPage,
}

// Epics

const listReportsEpic = buildEpic(REPORT_LIST, () =>
  api.listReports({ pageSize: 1000 }),
)

const epics = [listReportsEpic]

// reducer

const initalState = {
  list: [],
  reports: {},
}

const reducer = (state = initalState, { type, ...action }) => {
  switch (type) {
    case REPORT_LIST:
      return {
        ...state,
        loadingList: true,
      }

    case REPORT_LIST_SUCCESS: {
      const ids = []
      const reports = {}

      action.payload.reports.forEach((report) => {
        ids.push(report.id)
        reports[report.id] = report
      })

      return {
        ...state,
        list: ids,
        reports,
        loadingList: false,
      }
    }

    case REPORT_LIST_ERROR:
      return {
        ...state,
        list: [],
        page: undefined,
        total: undefined,
        loadingList: false,
      }

    case REPORT_SET_PAGE:
      return {
        ...state,
        page: action.page,
      }

    default:
      return state
  }
}

// exports

export { actionTypes, actionCreators, epics }

export default reducer
