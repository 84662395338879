import {
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material"
import { arrayOf, string } from "prop-types"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actionCreators as matchProductActions } from "../../../../ducks/matches"
import useCustomerMatchLabels from "../../../../hooks/use-customer-match-labels"
import useRestricted from "../../../../hooks/use-restricted"
import { ORIGIN_SEARCH } from "../../../../util/match-origin"
import { PERM_UPDATE_MATCHES } from "../../../../util/permissions"

function MatchLabel({ daltixProductId, referenceProductId, labels, matchId }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { hasAccess: isUpdater } = useRestricted({
    requiredPermissions: PERM_UPDATE_MATCHES,
  })
  const isDisabled = !matchId
  const options = useCustomerMatchLabels()
  const [open, setOpen] = useState(false)
  const updatedLabels = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.labels,
  )

  const [selected, setSelected] = useState(() => {
    const effectiveLabels = updatedLabels || labels
    if (!effectiveLabels) {
      return []
    }

    const values = options.map((x) => x.slug)
    return effectiveLabels.filter((label) => values.includes(label))
  })

  const selectedLabels = useMemo(
    () =>
      options
        .filter((opt) => selected.includes(opt.slug))
        .map((opt) => opt.aliasTitleCase)
        .join(", "),
    [options, selected],
  )

  const renderValue = () => {
    if (!selected) {
      return ""
    }

    if (selected.length === 1) {
      return selectedLabels
    }

    return `${selected.length} selected`
  }

  const onChange = ({ target: { value } }) => {
    dispatch(
      matchProductActions.updateMatchBasicInfo({
        source: ORIGIN_SEARCH,
        referenceProductId,
        daltixProductId,
        labels: value,
      }),
    )
    setSelected(value)
  }

  return (
    <Tooltip
      title={!open && selected.length > 1 ? selectedLabels : ""}
      arrow
      placement="bottom"
    >
      <TextField
        disabled={isDisabled}
        select
        value={selected}
        onChange={onChange}
        size="small"
        SelectProps={{
          readOnly: !isUpdater,
          size: "small",
          multiple: true,
          renderValue,
          open,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false),
          sx: { fontSize: "0.875rem" },
        }}
        sx={{ width: "100%", fontSize: theme.typography.body2.fontSize }}
      >
        {options.map(({ aliasTitleCase, slug }) => (
          <MenuItem key={slug} value={slug}>
            <Checkbox color="default" checked={selected.includes(slug)} />
            <ListItemText primary={aliasTitleCase} />
          </MenuItem>
        ))}
      </TextField>
    </Tooltip>
  )
}

MatchLabel.propTypes = {
  daltixProductId: string.isRequired,
  referenceProductId: string.isRequired,
  labels: arrayOf(string),
  matchId: string,
}

MatchLabel.defaultProps = {
  labels: [],
  matchId: "",
}

export { MatchLabel }
