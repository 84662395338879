import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"

import styles from "./TableWrapper.module.scss"

function TableWrapper({
  children,
  justifyContent,
  stripedColumns,
  stripedRows,
  ...props
}) {
  return (
    <div
      {...props}
      className={clsx(styles.wrapper, styles[`justify-${justifyContent}`], {
        [styles["striped-columns"]]: stripedColumns,
        [styles["striped-rows"]]: stripedRows,
      })}
    >
      {children}
    </div>
  )
}

TableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  justifyContent: PropTypes.oneOf(["start", "center"]),
  stripedColumns: PropTypes.bool,
  stripedRows: PropTypes.bool,
}

TableWrapper.defaultProps = {
  justifyContent: "center",
  stripedColumns: false,
  stripedRows: false,
}

export default TableWrapper
