/* eslint-disable import/prefer-default-export */

import { PAGE_SIZE } from "../common"

export const handleSelectReferenceProduct = (state, { idx }) => {
  const { page: currentPage, detailedProductIndex: prevIdx } = state

  let { list, prevPageList, nextPageList } = state

  const page = Math.floor(idx / PAGE_SIZE) + 1
  const offset = Math.max(0, (page - 1) * PAGE_SIZE)

  if (currentPage && prevIdx !== undefined) {
    if (page < currentPage) {
      nextPageList = list.slice()
      list = prevPageList.slice()
      prevPageList = []
    } else if (page > currentPage) {
      prevPageList = list.slice()
      list = nextPageList.slice()
      nextPageList = []
    }
  }

  return {
    ...state,
    list,
    prevPageList,
    nextPageList,
    detailedProductIndex: idx,
    navDirection: idx < prevIdx ? "backward" : "forward",
    page,
    offset,
    productError: undefined,
  }
}

export const handleSelectReferenceProductError = (state, { error }) => ({
  ...state,
  productError: error,
  page: undefined,
  offset: undefined,
})
