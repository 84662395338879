import React, { Suspense, memo } from "react"
import PropTypes from "prop-types"
import { Switch } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import { ContentContainer, SideNavigation } from "../../components/side-navigation"
import HtmlTitle from "../../components/html-title/HtmlTitle"
import AssortmentAnalysisPanel from "../../components/assortment-analysis/AssortmentAnalysisPanel"
import RestrictedRoute from "../../hocs/restricted-route"

function AssortmentToolPresenter({ routes }) {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: "flex" }}>
      <SideNavigation />
      <ContentContainer>
        <HtmlTitle>{t("Assortment Analysis")}</HtmlTitle>
        <Suspense>
          <Switch>
            <RestrictedRoute
              path={routes.analysis}
              component={AssortmentAnalysisPanel}
            />
          </Switch>
        </Suspense>
      </ContentContainer>
    </Box>
  )
}

AssortmentToolPresenter.propTypes = {
  routes: PropTypes.shape({
    analysis: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(AssortmentToolPresenter)
