import React from "react"
import EarthIcon from "@mui/icons-material/Public"
import MinusIcon from "@mui/icons-material/RemoveOutlined"
import PlaceIcon from "@mui/icons-material/PlaceOutlined"
import { STATUS_FLAGGED } from "../../util/match-status"
import { DX_SOURCE_FIELDTAKE, DX_SOURCE_SCRAPING } from "../../util/dx-product-source"

function ArticleSourceIcon({ source, status, className = "" }) {
  let SourceIcon

  if (status === STATUS_FLAGGED) {
    SourceIcon = MinusIcon
  } else {
    switch (source) {
      case DX_SOURCE_SCRAPING:
        SourceIcon = EarthIcon
        break
      case DX_SOURCE_FIELDTAKE:
        SourceIcon = PlaceIcon
        break
      default:
        break
    }
  }

  return SourceIcon ? <SourceIcon className={className} /> : null
}

export function useDataSourceTooltip(source, status) {
  let tooltip
  if (status === STATUS_FLAGGED) {
    tooltip = "None"
  } else {
    switch (source) {
      case DX_SOURCE_SCRAPING:
        tooltip = "Web"
        break
      case DX_SOURCE_FIELDTAKE:
        tooltip = "Field"
        break
      default:
        break
    }
  }

  return tooltip
}

export default ArticleSourceIcon
