import React from "react"

import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

import * as RUISelect from "@radix-ui/react-select"

import styles from "./BaseScrollButton.module.scss"

function BaseScrollDownButton() {
  return (
    <RUISelect.ScrollDownButton className={styles.scroller}>
      <ExpandMore />
    </RUISelect.ScrollDownButton>
  )
}

function BaseScrollUpButton() {
  return (
    <RUISelect.ScrollUpButton className={styles.scroller}>
      <ExpandLess />
    </RUISelect.ScrollUpButton>
  )
}

export { BaseScrollDownButton, BaseScrollUpButton }
