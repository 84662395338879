import TableCell from "./TableCell"
import TableCellImage from "./TableCellImage"
import TableCellItem from "./TableCellItem"
import TableCellItemPrice from "./TableCellItemPrice"
import TableCellLabel from "./TableCellLabel"
import TableColumnHeader from "./TableColumnHeader"
import TableContainer from "./TableContainer"
import TableElement from "./TableElement"
import TablePad from "./TablePad"
import TableRoot from "./TableRoot"
import TableRowHeader from "./TableRowHeader"
import TableWrapper from "./TableWrapper"

// eslint-disable-next-line prefer-object-spread
const Table = Object.assign(
  {},
  {
    Container: TableContainer,
    Cell: TableCell,
    CellImage: TableCellImage,
    CellLabel: TableCellLabel,
    CellItem: TableCellItem,
    CellItemPrice: TableCellItemPrice,
    ColumnHeader: TableColumnHeader,
    Element: TableElement,
    RowHeader: TableRowHeader,
    Root: TableRoot,
    Wrapper: TableWrapper,
    Pad: TablePad,
  },
)

export { Table }
