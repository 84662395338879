import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Box } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import useMatchesOverviewMatchStatusFilter from "./hooks/use-matches-overview-match-status-filter"
import * as refProductActions from "../../ducks/reference-products/action-creators"
import { setFilterMatchStatuses } from "../../ducks/pages/matches-overview/action-creators"
import * as productSearch from "../../ducks/widgets/product-search/hooks"
import { Table } from "../ui"
import MatchesOverviewShopMatchesListItem from "./MatchesOverviewShopMatchesListItem"
import { actionCreators as notificationActions } from "../../ducks/notification"

import {
  useHasProductSearchWidget,
  useHasMatchOverviewFlags,
} from "../../integrations/split"
import AppPropTypes from "../../prop-shapes"
import { DXIcon } from "../dx-icon/DXIcon"
import { createFlag, deleteFlag } from "../../api/service"
import { MATCHES_OVERVIEW_PAGE_FLAG_KEY } from "../../ducks/pages/matches-overview/constants"
import { STATUS_APPROVED, STATUS_FLAGGED } from "../../util/match-status"

const EXPANSION_THRESHOLD = 1

function MatchesOverviewShopMatchesListContainer({
  children,
  setActiveCallback,
  ...props
}) {
  return (
    <div
      {...props}
      onMouseEnter={() => setActiveCallback(true)}
      onMouseLeave={() => setActiveCallback(false)}
      onFocus={() => setActiveCallback(true)}
      onBlur={() => setActiveCallback(false)}
    >
      {children}
    </div>
  )
}

MatchesOverviewShopMatchesListContainer.propTypes = {
  children: AppPropTypes.childrenNodes.isRequired,
  setActiveCallback: PropTypes.func.isRequired,
}

function MatchesOverviewShopMatchesList({
  matches,
  matchedReferenceProductId,
  shopKey,
  shop,
  country,
}) {
  const hasProductSearchWidget = useHasProductSearchWidget()
  const hasMatchOverviewFlags = useHasMatchOverviewFlags()
  const matchStatuses = useMatchesOverviewMatchStatusFilter()
  const history = useHistory()
  const dispatch = useDispatch()
  const openProductSearch = productSearch.useOpen()
  const [flagCached, setFlagCached] = useState()
  const [isHovered, setHovered] = useState(false)
  const [isCollapsed, setCollapsed] = useState(true)
  const isExpandable = matches.length > EXPANSION_THRESHOLD
  const displayedMatches = isCollapsed ? matches.slice(0, EXPANSION_THRESHOLD) : matches
  const makeMatchesReferenceProductPath = `/matches/match?id=${matchedReferenceProductId}`

  const flags = useSelector(
    ({ pages }) => pages?.MatchesOverviewPage?.[MATCHES_OVERVIEW_PAGE_FLAG_KEY],
  )

  const showFlags = useSelector(({ pages }) =>
    pages?.MatchesOverviewPage?.filters?.matches.status.some(
      (x) => x === STATUS_FLAGGED,
    ),
  )

  const hasFlag = useMemo(() => {
    if (flagCached !== undefined) {
      return flagCached
    }

    return flags?.find(
      (e) =>
        e.referenceProductId === matchedReferenceProductId &&
        e.shop === shop &&
        e.country === country,
    )
  }, [country, flagCached, flags, matchedReferenceProductId, shop])

  const activateFlagFilter = () => {
    if (!hasFlag && !matchStatuses?.includes(STATUS_FLAGGED)) {
      const newMatchStatuses = [...matchStatuses, STATUS_FLAGGED]
      dispatch(setFilterMatchStatuses({ matchStatuses: newMatchStatuses }))
    }
  }

  const {
    isError,
    isLoading,
    mutate: onFlag,
  } = useMutation({
    mutationFn: async () => {
      const toggleFlag = hasFlag ? deleteFlag : createFlag
      await toggleFlag({
        referenceProductId: matchedReferenceProductId,
        shop,
        country,
      })
      setFlagCached(!hasFlag)
      activateFlagFilter()
    },
  })

  useEffect(() => {
    if (!isError) {
      return
    }

    const message = hasFlag ? "Failed to remove the flag" : "Failed to flag the product"
    dispatch(
      notificationActions.enqueueNotification({
        message,
        options: {
          variant: "error",
          persist: false,
        },
      }),
    )
  }, [dispatch, isError, hasFlag])

  function toggleCollapsed(event) {
    event.preventDefault()

    if (!isCollapsed) {
      setHovered(false)
    }

    setCollapsed((current) => !current)
  }

  function navigateToReferenceProduct() {
    history.push(makeMatchesReferenceProductPath)

    dispatch(refProductActions.filterReferenceProductsById(matchedReferenceProductId))
  }

  function handleOpenProductSearch() {
    openProductSearch(matchedReferenceProductId, {
      shops: { [shop]: true },
      countries: { [country]: true },
    })
  }

  const referenceProductButton = (
    <button
      data-testid="add-matches-button"
      onClick={
        hasProductSearchWidget ? handleOpenProductSearch : navigateToReferenceProduct
      }
      type="button"
      className="matches-overview-shop-matches-list-add-matches-btn"
    >
      <span>Add matches</span>
    </button>
  )

  const hasApproved = useMemo(
    () => matches.some((match) => match.status === STATUS_APPROVED),
    [matches],
  )

  const flagButton = (
    <button
      data-testid="flag-button"
      type="button"
      className="matches-overview-shop-matches-list-add-matches-btn"
      onClick={onFlag}
      style={{ opacity: isLoading ? 0.5 : 1 }}
    >
      {hasFlag ? "Remove Flag" : "Flag no match"}
    </button>
  )

  if (matches.length === 0) {
    return (
      <MatchesOverviewShopMatchesListContainer
        data-testid="empty-matches-cell"
        setActiveCallback={setHovered}
        data-feature-flag="product-search"
        data-enable-product-search={hasProductSearchWidget}
        data-enable-flags-matches={hasMatchOverviewFlags}
      >
        <Table.Cell>
          {hasMatchOverviewFlags && showFlags && hasFlag && (
            <Box
              minHeight="8.5rem"
              display="flex"
              alignItems="center"
              data-testid="flag-image"
              sx={{ opacity: isLoading ? 0.5 : 1, transition: "opacity 0.3s" }}
            >
              <DXIcon size="64px" opacity={0.56} type="flag" filled onClick={onFlag} />
            </Box>
          )}

          {isHovered && (
            <Box height="fit-content">
              <div className="matches-overview-table-cell-empty">
                {referenceProductButton}
                {hasMatchOverviewFlags && flagButton}
              </div>
            </Box>
          )}
        </Table.Cell>
      </MatchesOverviewShopMatchesListContainer>
    )
  }

  return (
    <MatchesOverviewShopMatchesListContainer
      setActiveCallback={setHovered}
      data-testid="matches-cell"
    >
      <Table.Cell>
        {displayedMatches.map(({ daltixId, matchId }) => (
          <MatchesOverviewShopMatchesListItem
            key={`${shopKey}-${daltixId}-${matchId}`}
            daltixId={daltixId}
            matchId={matchId}
            referenceProductId={matchedReferenceProductId}
          />
        ))}

        {isHovered && (
          <>
            {referenceProductButton}
            {!hasApproved && flagButton}
          </>
        )}

        {isExpandable && (
          <button
            onClick={toggleCollapsed}
            type="button"
            className="matches-overview-shop-matches-list-expand-matches-btn"
          >
            {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </button>
        )}
      </Table.Cell>
    </MatchesOverviewShopMatchesListContainer>
  )
}

MatchesOverviewShopMatchesList.propTypes = {
  matchedReferenceProductId: PropTypes.string.isRequired,
  matches: PropTypes.array.isRequired,
  shopKey: PropTypes.string.isRequired,
  shop: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}

export default MatchesOverviewShopMatchesList
