import { Logger } from "../../../../util/log"
import {
  toAsyncErrorSlice,
  toAsyncInitialSlice,
  toAsyncLoadingSlice,
  toAsyncSuccessSlice,
} from "../../../../util/redux-helpers"

const _log = new Logger("ducks:repositories:matched-products:handlers")

export function handleGetOneMatchedProductById(state, { id }) {
  const product = (state.documents[id] || toAsyncInitialSlice()).data

  return {
    ...state,
    documents: {
      ...state.documents,
      [id]: toAsyncLoadingSlice(product),
    },
  }
}

export function handleGetOneMatchedProductByIdSuccess(state, { payload }) {
  const products = Object.fromEntries(
    payload.map((product) => [product.id, toAsyncSuccessSlice(product)]),
  )

  return {
    ...state,
    documents: {
      ...state.documents,
      ...products,
    },
  }
}

export function handleGetOneMatchedProductByIdError(state, { error, parent }) {
  const productId = parent.id
  const product = (state.documents[productId] || toAsyncInitialSlice()).data

  return {
    ...state,
    documents: {
      ...state.documents,
      [productId]: toAsyncErrorSlice(product, error),
    },
  }
}
