import { featureNamesMap } from "./featureNamesMap"
import { useAccountTreatments } from "./useAccountTreatments"

export function useHasAssortmentAnalysisTool() {
  const accountTreatments = useAccountTreatments()

  return accountTreatments.has(featureNamesMap.ASSORTMENT_ANALYSIS_CUSTOMER)
}

export function useHasAAToolShopFilter() {
  const accountTreatments = useAccountTreatments()

  return accountTreatments.has(featureNamesMap.ASSORTMENT_ANALYSIS_SHOP_FILTER)
}
