import React from "react"
import { connect } from "react-redux"
import MatchesPresenter from "./MatchesPresenter"

function Matches(props) {
  const { matches } = props
  const { onLoadMoreResults = () => matches } = props

  return <MatchesPresenter {...props} onLoadMoreResults={onLoadMoreResults} />
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(Matches)
