import { ReducerFactory, toAsyncInitialSlice } from "../../../util/redux-helpers"
import {
  getUrlCategories,
  getUrlCountries,
  getUrlFamilies,
  getUrlMatchStatus,
  getUrlPage,
  getUrlPageSize,
  getUrlReferenceProductMatchesCount,
  getUrlSearchFacet,
  getUrlSearchTerm,
  getUrlShops,
} from "./helpers"
import * as Action from "./action-types"
import * as Handler from "./handlers"
import { STATUS_APPROVED } from "../../../util/match-status"
import { actionTypes as UserActions } from "../../user"

import {
  MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_OUTLINE_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
} from "./constants"

const initialState = {
  [MATCHES_OVERVIEW_PAGE_FILTERS_KEY]: {
    /** Raw backend data defining all the filters the user has access to. */
    [MATCHES_OVERVIEW_PAGE_FILTERS_OUTLINE_KEY]: {
      categories: toAsyncInitialSlice(),
      countries: toAsyncInitialSlice(),
      families: toAsyncInitialSlice(),
      shops: toAsyncInitialSlice(),
    },
    /** Currently active matched product filters (in synch with the URL) */
    [MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY]: {
      count: getUrlReferenceProductMatchesCount(),
      countries: getUrlCountries(),
      shops: getUrlShops(),
      status: getUrlMatchStatus([STATUS_APPROVED]),
    },
    /** Currently active reference product filters (in synch with the URL) */
    [MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY]: {
      categories: getUrlCategories(),
      families: getUrlFamilies(),
      searchFacet: getUrlSearchFacet(),
      searchTerm: getUrlSearchTerm(),
    },
  },
  headingCounts: toAsyncInitialSlice(),
  meta: {
    hasProducts: false,
    hasShops: false,
  },
  pagination: {
    hasNextPage: undefined,
    hasPreviousPage: undefined,
    page: getUrlPage(),
    pageCount: undefined,
    pageSize: getUrlPageSize(),
    resultsCount: undefined,
  },
  request: {
    ...toAsyncInitialSlice(),
  },
}

const reducer = ReducerFactory.create(initialState, {
  /* -------------------------------------------------------------------------- */
  /*                          internal event handlers                           */
  /* -------------------------------------------------------------------------- */
  [Action.CLEAR_MATCHES_OVERVIEW_APPLIED_FILTERS]:
    Handler.handleMatchesOverviewFiltersClear,
  [Action.CLEAR_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH]:
    Handler.handleMatchesOverviewReferenceProductSearchClear,
  [Action.CLEAR_MATCHES_OVERVIEW_UNSTAGED_FILTERS]:
    Handler.handleMatchesOverviewFiltersUnstage,
  [Action.GET_REF_PRODUCTS_MATCHES_BY_SHOP]:
    Handler.handleGetCustomerReferenceProductsMatchesByShop,
  [Action.GET_REF_PRODUCTS_MATCHES_BY_SHOP_ERROR]:
    Handler.handleGetCustomerReferenceProductsMatchesByShopError,
  [Action.GET_REF_PRODUCTS_MATCHES_BY_SHOP_SUCCESS]:
    Handler.handleGetCustomerReferenceProductsMatchesByShopSuccess,
  [Action.GET_REF_PRODUCTS_MATCHES_BY_SHOP_META]:
    Handler.handleGetCustomerReferenceProductsMatchesByShopMeta,
  [Action.GET_REF_PRODUCTS_MATCHES_BY_SHOP_META_ERROR]:
    Handler.handleGetCustomerReferenceProductsMatchesByShopMetaError,
  [Action.GET_REF_PRODUCTS_MATCHES_BY_SHOP_META_SUCCESS]:
    Handler.handleGetCustomerReferenceProductsMatchesByShopMetaSuccess,
  [Action.MATCHES_OVERVIEW_REHYDRATE_FROM_PREVIOUS_STATE]:
    Handler.handleReadMatchesOverviewFromPreviousState,
  [Action.READ_MATCHES_OVERVIEW_URL_STATE]: Handler.handleReadMatchesOverviewURLState,
  [Action.SET_MATCHES_OVERVIEW_PAGE]: Handler.handleMatchesOverviewPageChange,
  [Action.SET_MATCHES_OVERVIEW_PAGE_SIZE]: Handler.handleMatchesOverviewPageSizeChange,
  [Action.SET_MATCHES_OVERVIEW_MATCH_STATUSES_FILTER]:
    Handler.handleMatchesOverviewMatchStatusFilterChange,
  [Action.SET_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH]:
    Handler.handleMatchesOverviewReferenceProductSearchChange,
  [Action.STAGE_FILTER_MATCH_COUNTRY]:
    Handler.handleMatchesOverviewMatchCountryFilterStaged,
  [Action.STAGE_FILTER_MATCH_SHOP]: Handler.handleMatchesOverviewMatchShopFilterStaged,
  [Action.STAGE_FILTER_REF_PRODUCTS_CATEGORY]:
    Handler.handleMatchesOverviewReferenceProductCategoryFilterStaged,
  [Action.STAGE_FILTER_REF_PRODUCTS_FAMILY]:
    Handler.handleMatchesOverviewReferenceProductFamilyFilterStaged,
  [Action.TOGGLE_FILTER_APPROVED_MATCHES]:
    Handler.handleMatchesOverviewMatchStatusApprovedFilterChange,
  [Action.TOGGLE_FILTER_DISCARDED_MATCHES]:
    Handler.handleMatchesOverviewMatchStatusDiscardedFilterChange,
  [Action.TOGGLE_FILTER_IN_REVIEW_MATCHES]:
    Handler.handleMatchesOverviewMatchStatusInReviewFilterChange,
  [Action.TOGGLE_FILTER_REF_PRODUCTS_WITHOUT_MATCHES]:
    Handler.handleMatchesOverviewReferenceProductMatchCountFilterChange,
  [Action.TRIGGER_APPLY_MATCHES_OVERVIEW_FILTERS]:
    Handler.handleMatchesOverviewFiltersApply,

  /* -------------------------------------------------------------------------- */
  /*                          external event handlers                           */
  /* -------------------------------------------------------------------------- */
  [UserActions.USER_GET_PROFILE_SUCCESS]: Handler.handleUserProfileRetrieved,
})

export default reducer
