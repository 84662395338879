import React, { useCallback } from "react"
import mixpanel from "mixpanel-browser"

import PorductCardPresenter from "./ProductCardPresenter"

import noPicture from "../../no-image.png"

function ProductCard(props) {
  let { images: [picture] = [] } = props

  if (!picture) {
    picture = noPicture
  }

  const handleNavigateToStore = useCallback(({ url, name }) => {
    mixpanel.track("See Product in Store", {
      origin: "make matches",
      type: "reference product",
      url,
      name,
    })
  }, [])

  return (
    <PorductCardPresenter
      {...props}
      images={picture}
      onNavigateToStore={handleNavigateToStore}
    />
  )
}

export default ProductCard
