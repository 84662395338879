/* eslint-disable global-require */
import { createStore, applyMiddleware } from "redux"
import { createEpicMiddleware } from "redux-observable"
import { routerMiddleware } from "connected-react-router"
import rootEpic from "./redux-root-epic"
import createRootReducer from "./redux-root-reducer"
import { isDebug, isDevelopment } from "../util/env"
import history from "./browser-history"

const epicMiddleware = createEpicMiddleware()
const routesMiddleware = routerMiddleware(history)

const middleware = [routesMiddleware, epicMiddleware]

if (isDebug() || isDevelopment) {
  const { createLogger } = require("redux-logger")

  middleware.push(createLogger())
}

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore)

export default function configureStore(preloadState) {
  const rootReducer = createRootReducer(history)
  const store = createStoreWithMiddleware(rootReducer, preloadState)

  epicMiddleware.run(rootEpic)

  return store
}
