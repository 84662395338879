import { errorType, successType } from "../../../util/redux-observable-helpers"

export const DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID =
  "@daltix-products-repository/GET_ONE_BY_ID"
export const DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_SUCCESS = successType(
  DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
)
export const DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_ERROR = errorType(
  DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
)

export const DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS =
  "@daltix-products-repository/GET_PRODUCTS_BY_IDS"

export const DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS_SUCCESS = successType(
  DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS,
)
export const DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS_ERROR = errorType(
  DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS,
)
