import { Box, Link, Tooltip, Typography } from "@mui/material"
import { arrayOf, oneOfType, node, string } from "prop-types"
import React from "react"
import { DXIcon } from "../../../dx-icon/DXIcon"
import { ProductImage } from "../ProductImage"

const RenderLink = ({ url, children }) => {
  if (!url) {
    return children
  }

  return (
    <Link href={url} rel="noreferrer" target="_blank" underline="hover">
      {children}
    </Link>
  )
}

RenderLink.propTypes = {
  url: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
}

RenderLink.defaultProps = {
  url: undefined,
}

function ProductTitle({ name, url, images, size }) {
  return (
    <Box display="flex" gap={2} alignItems="center">
      <ProductImage size={size} src={images} alt={name} />
      <RenderLink url={url}>
        <Box
          display="inline-flex"
          width={size === "small" ? 250 : "auto"}
          gap={0}
          flexWrap={size === "small" ? "nowrap" : "wrap"}
          component="span"
        >
          <Tooltip
            componentsProps={{ tooltip: { sx: { textTransform: "capitalize" } } }}
            title={name?.length >= 33 && size === "small" ? name : ""}
            arrow
            placement="bottom"
          >
            <Typography
              noWrap={size === "small"}
              textOverflow={size === "small" ? "ellipsis" : null}
              textTransform="capitalize"
              variant="body2"
            >
              {name}
            </Typography>
          </Tooltip>
          {url && (
            <DXIcon
              color="primary"
              style={{ paddingBottom: "2px" }}
              type="openInNew"
              size="small"
            />
          )}
        </Box>
      </RenderLink>
    </Box>
  )
}

ProductTitle.propTypes = {
  name: string.isRequired,
  url: string.isRequired,
  images: arrayOf(string).isRequired,
  size: string.isRequired,
}

export { ProductTitle }
