import mixpanel from "mixpanel-browser"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"
import { Combobox as HUICombobox, Transition } from "@headlessui/react"
import { IconCheck, IconSelector } from "@tabler/icons-react"
import styles from "./Autocomplete.module.scss"
import useStableId from "../../../../hooks/use-stable-id"
import { isValidAnalyticsEventName } from "../../../../util/user-monitoring"
import AutocompleteOption from "./partials/AutocompleteOption"
import AutocompleteFacetChip from "./partials/AutocompleteFacetChip"

function AutocompleteCombobox({
  analyticsEventName,
  children,
  compareBy,
  onChange,
  ...props
}) {
  return (
    <HUICombobox
      {...props}
      onChange={(value) => {
        if (isValidAnalyticsEventName(analyticsEventName)) {
          mixpanel.track(analyticsEventName, { value })
        }

        onChange(value)
      }}
      by={compareBy}
    >
      <search className={styles.combobox}>{children}</search>
    </HUICombobox>
  )
}

AutocompleteCombobox.propTypes = {
  analyticsEventName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  compareBy: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
}

AutocompleteCombobox.defaultProps = {
  analyticsEventName: undefined,
  compareBy: undefined,
}

const AutocompleteInput = forwardRef(
  ({ id, label, placeholder, ...rest }, forwardedRef) => {
    const innerId = useStableId()

    const inputId = id || innerId.current

    return (
      <>
        <label className="sr-only" htmlFor={inputId}>
          {label}
        </label>
        <HUICombobox.Input
          {...rest}
          autoComplete="off"
          id={inputId}
          ref={forwardedRef}
          className={styles.input}
          placeholder={placeholder}
          type="text"
        />
      </>
    )
  },
)

AutocompleteInput.displayName = "AutocompleteInput"

AutocompleteInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}

AutocompleteInput.defaultProps = {
  id: undefined,
}

function AutocompleteExpander({ label }) {
  return (
    <HUICombobox.Button className={styles.expander} tabIndex={0}>
      <span className="sr-only">{label}</span>
      <IconSelector size={24} aria-hidden />
    </HUICombobox.Button>
  )
}

AutocompleteExpander.propTypes = {
  label: PropTypes.string.isRequired,
}

function AutocompleteOptions({ items, onShrink }) {
  if (!Array.isArray(items) || items.length === 0) {
    return null
  }

  return (
    <Transition as={React.Fragment} leave="fade-out" afterLeave={onShrink}>
      <HUICombobox.Options className={styles.options}>
        {items.map((item) => (
          <HUICombobox.Option as={React.Fragment} key={item.id} value={item.value}>
            {({ active: _active, selected }) => (
              <li className={styles.option}>
                {item.displayValue}
                {selected && <IconCheck size={16} />}
              </li>
            )}
          </HUICombobox.Option>
        ))}
      </HUICombobox.Options>
    </Transition>
  )
}

AutocompleteOptions.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      displayValue: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onShrink: PropTypes.func,
}

AutocompleteOptions.defaultProps = {
  onShrink: () => undefined,
}

export const Autocomplete = {
  Chip: AutocompleteFacetChip,
  Expander: AutocompleteExpander,
  Input: AutocompleteInput,
  Option: AutocompleteOption,
  Options: AutocompleteOptions,
  Root: AutocompleteCombobox,
}
