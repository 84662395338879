/**
 * Given an array of paths with disjoint path segments it joins them together to and
 * returns an object compatible with legacy category APIs.
 */
export function toLevelArraysFromDisjointSegments(paths) {
  const visited = new Set()

  return paths.reduce(
    (acc, path) => {
      path.forEach((_, i) => {
        const level = i + 1
        const levelKey = level === 1 ? "categories" : `l${level}Categories`

        const subpath = path.slice(0, level).join(":")

        if (!visited.has(subpath)) {
          visited.add(subpath)

          acc[levelKey].push(subpath)
        }
      })

      return acc
    },
    {
      "categories": [],
      "l2Categories": [],
      "l3Categories": [],
      "l4Categories": [],
      "l5Categories": [],
      "l6Categories": [],
    },
  )
}
