export function buildErrorNotification({ message }) {
  const notification = {
    message,
    options: {
      variant: "error",
    },
  }

  return notification
}
