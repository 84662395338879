import React, { createElement, useEffect, useState } from "react"
import axios from "axios"
import PropTypes from "prop-types"
import { CircularProgress, SvgIcon } from "@mui/material"

function NavIcon({ url, className = "" }) {
  const [svg, setSvg] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    axios({ url, responseType: "text", timeout: 1000 })
      .then((response) => response.data)
      .then((res) => {
        if (!res) {
          throw new Error("Failed to fetch SVG")
        }
        const colorCorrected = res.replace(
          /fill="#([0-9a-f]{6})"/gi,
          'fill="currentColor"',
        )
        return new DOMParser().parseFromString(colorCorrected, "image/svg+xml")
          .documentElement
      })
      .then(setSvg)
      .catch(setIsError)
      .then(() => setIsLoaded(true))
  }, [url])

  const convertDocEleToReact = (element, props) => {
    const { tagName } = element
    const _props = props || {}

    for (let i = 0; i < element.attributes.length; i++) {
      _props[element.attributes[i].nodeName] = element.attributes[i].nodeValue
    }

    const children = Array.from(element.children).map((item) =>
      convertDocEleToReact(item),
    )

    return createElement(tagName, _props, children)
  }

  return isLoaded && !isError ? (
    <SvgIcon sx={{ p: "3px" }}>{convertDocEleToReact(svg)}</SvgIcon>
  ) : isError ? (
    <img
      className={`fallback-icon ${className}`}
      src={url}
      alt={url.split("/").pop()}
      loading="lazy"
    />
  ) : (
    <CircularProgress color="inherit" size="1.5em" />
  )
}

NavIcon.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
}

NavIcon.defaultProps = {
  className: "",
}

export { NavIcon }
