import mixpanel from "mixpanel-browser"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { IconCheck, IconMinus } from "@tabler/icons-react"
import * as RadixCheckbox from "@radix-ui/react-checkbox"

import styles from "./Checkbox.module.scss"
import { isValidAnalyticsEventName } from "../../../util/user-monitoring"

function Checkbox({
  analyticsEventName,
  id,
  display,
  ticked: initialTicked,
  value,
  onToggle,
  ...rest
}) {
  const [checked, setChecked] = useState(initialTicked)

  function handleToggle(state) {
    setChecked(() => state)

    if (isValidAnalyticsEventName(analyticsEventName)) {
      mixpanel.track(analyticsEventName, { checkboxId: id, checkboxValue: state })
    }

    onToggle({ display, value, state })
  }

  return (
    <div className={styles.container}>
      <RadixCheckbox.Root
        checked={checked}
        className={styles.root}
        id={id}
        name={id}
        onCheckedChange={handleToggle}
        value={value}
        {...rest}
      >
        <RadixCheckbox.Indicator className={styles.indicator}>
          {checked === true && <IconCheck size={16} />}
          {checked === "indeterminate" && <IconMinus size={16} />}
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <label className={styles.label} htmlFor={id}>
        {display}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  analyticsEventName: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  display: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  ticked: PropTypes.oneOf([false, true, "indeterminate"]).isRequired,
  value: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
}

Checkbox.defaultProps = {
  analyticsEventName: undefined,
  disabled: false,
}

export { Checkbox }
