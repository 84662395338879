/* eslint-disable import/prefer-default-export */

import queryString from "query-string"

export function updateLocationQuery(location, updates, state = {}) {
  const { search, query: wrongLocationQuery, ...restLocation } = location

  const query = queryString.parse(search)

  let finalUpdates

  if (typeof updates === "function") {
    finalUpdates = updates(query)
  } else {
    finalUpdates = updates
  }

  return {
    ...restLocation,
    key: undefined,
    search: queryString.stringify({
      ...query,
      ...finalUpdates,
    }),
    state,
  }
}
