import React from "react"
import { Box, Typography, Chip, Tooltip } from "@mui/material"
import { DXIcon } from "../dx-icon/DXIcon"
import { MatchStatusIcon, useStatusTooltip } from "../match-status-icon/MatchStatusIcon"

import "./style.sass"
import ArticleSourceIcon, { useDataSourceTooltip } from "../article-source-icon"
import MatchTypeIcon, { useMatchTypeTooltip } from "../match-type-icon"
import MatchSourceChip, { useMatchSourceTooltip } from "../match-source-chip"

function StatusChip({ status, matchId, outdated, source, matchType, matchSource }) {
  const tooltipTokens = []

  const statusTooltip = useStatusTooltip(status, matchId)
  if (statusTooltip) {
    tooltipTokens.push(statusTooltip)
  }

  const matchSourceTooltip = useMatchSourceTooltip(matchSource, matchId)
  if (matchSourceTooltip) {
    tooltipTokens.push(matchSourceTooltip)
  }

  const matchTypeTooltipo = useMatchTypeTooltip(matchType)
  if (matchTypeTooltipo) {
    tooltipTokens.push(matchTypeTooltipo)
  }

  const sourceTooltip = useDataSourceTooltip(source, status)
  if (sourceTooltip) {
    tooltipTokens.push(sourceTooltip)
  }

  const availabilityTooltip = outdated ? "Inactive" : "Active"
  tooltipTokens.push(availabilityTooltip)

  const tooltip = tooltipTokens.join(" | ")

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      <Tooltip title={tooltip} placement="bottom">
        <Chip
          variant="outlined"
          sx={{
            height: 24,
          }}
          classes={{
            label: "dx-status-chip-label",
          }}
          label={
            <>
              <MatchStatusIcon status={status} matchId={matchId} />

              <MatchSourceChip source={matchSource} matchId={matchId} />

              <MatchTypeIcon type={matchType} className="dx-circled-icon" />

              <ArticleSourceIcon
                source={source}
                matchType={matchType}
                className="dx-circled-icon"
              />

              <DXIcon type={outdated ? "inactive" : "active"} />
            </>
          }
        />
      </Tooltip>
    </Box>
  )
}

export default StatusChip
