export const MULTIFACTOR_EQUALS = "equals"
export const MULTIFACTOR_LESS_THAN = "less"
export const MULTIFACTOR_MORE_THAN = "more"
export const MULTIFACTOR_UNDEFINED = "null"

export const matchProductFactorOptionsList = [
  {
    label: "MF = 1",
    value: MULTIFACTOR_EQUALS,
  },
  {
    label: "MF < 1",
    value: MULTIFACTOR_LESS_THAN,
  },
  {
    label: "MF > 1",
    value: MULTIFACTOR_MORE_THAN,
  },
  {
    label: "MF not set",
    value: MULTIFACTOR_UNDEFINED,
  },
]

export const validProductFactors = matchProductFactorOptionsList.map(
  ({ value }) => value,
)

export const defaultProductFactorValues = [
  MULTIFACTOR_EQUALS,
  MULTIFACTOR_LESS_THAN,
  MULTIFACTOR_MORE_THAN,
  MULTIFACTOR_UNDEFINED,
]
