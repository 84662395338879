import { ReducerFactory } from "../../../util/redux-helpers"
import {
  DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID,
  DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_ERROR,
  DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_SUCCESS,
  DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS,
  DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS_ERROR,
  DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS_SUCCESS,
} from "./action-types"
import {
  handleGetOneDaltixProductById,
  handleGetOneDaltixProductByIdError,
  handleGetOneDaltixProductByIdSuccess,
  handleGetDaltixProductByIds,
  handleGetDaltixProductByIdsError,
  handleGetDaltixProductByIdsSuccess,
} from "./handlers"

const initialState = {
  documents: {},
}

const reducer = ReducerFactory.create(initialState, {
  [DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID]: handleGetOneDaltixProductById,
  [DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_ERROR]: handleGetOneDaltixProductByIdError,
  [DALTIX_PRODUCTS_REPOSITORY_GET_ONE_BY_ID_SUCCESS]:
    handleGetOneDaltixProductByIdSuccess,
  [DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS]: handleGetDaltixProductByIds,
  [DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS_ERROR]: handleGetDaltixProductByIdsError,
  [DALTIX_PRODUCTS_REPOSITORY_GET_BY_IDS_SUCCESS]: handleGetDaltixProductByIdsSuccess,
})

export default reducer
