import PropTypes from "prop-types"
import React from "react"

import { AccessibleIcon } from "./AccessibleIcon"

const sizes = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  xxl: 22,
  base: 16,
}

function EmptyIcon({ size, ...props }) {
  const sizePx = sizes[size]

  return (
    <AccessibleIcon
      border="dashed"
      color="neutral"
      shape="circle"
      size={size}
      {...props}
    >
      <span style={{ width: sizePx, height: sizePx }} />
    </AccessibleIcon>
  )
}

EmptyIcon.propTypes = {
  size: PropTypes.string,
}

EmptyIcon.defaultProps = {
  size: "lg",
}

export { EmptyIcon }
