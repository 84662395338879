/* eslint-disable import/prefer-default-export */

const unitMap = {
  ml: ["l", 0.001],
  cl: ["l", 0.01],
  ctl: ["l", 0.01],
  dl: ["l", 0.1],
  l: ["l", 1],
  ltr: ["l", 1],
  mg: ["Kg", 0.000001],
  mgr: ["Kg", 0.000001],
  cg: ["Kg", 0.00001],
  dg: ["Kg", 0.0001],
  g: ["Kg", 0.001],
  gr: ["Kg", 0.001],
  grm: ["Kg", 0.001],
  kg: ["Kg", 1],
  kgm: ["Kg", 1],
  pc: ["su", 1],
  st: ["su", 1],
  su: ["su", 1],
  stuks: ["su", 1],
  m: ["m", 1],
  mtr: ["m", 1],
  mm: ["m", 0.001],
  mmt: ["m", 0.001],
}

const suUnitKeys = ["pc", "st", "su", "stuks", "unit"]

export const getBestProductContentV1 = (contentsV1) => {
  const nonSuKey = Object.keys(contentsV1).find((unit) => !suUnitKeys.includes(unit))
  const unit = nonSuKey || Object.keys(contentsV1)[0]
  return {
    ...contentsV1[unit],
    unit,
  }
}

export const getContentsV1AsArray = (contentsV1) =>
  Object.keys(contentsV1).map((unit) => ({
    ...contentsV1[unit],
    unit,
  }))

export const normalizeUnit = (unit = "", value, targetUnit) => {
  if (unitMap[unit]) {
    const [base, factor] = unitMap[unit.toLowerCase()]
    const [targetBase, targetFactor] = unitMap[(targetUnit || base).toLowerCase()] || []

    if (base === targetBase) {
      return value * (factor / targetFactor)
    }
  }
  return false
}

export const normalizeUnitValue = (unit = "", value, targetUnit) => {
  let resultUnit = targetUnit
  if (unitMap[unit.toLowerCase()]) {
    const [base, factor] = unitMap[unit.toLowerCase()]
    resultUnit = resultUnit || base
    const [targetBase, targetFactor] = unitMap[targetUnit.toLowerCase()] || []

    if (base === targetBase) {
      return [value * (factor / targetFactor), resultUnit]
    }
  }
  return false
}
