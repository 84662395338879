import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"

import styles from "./BaseDialogFooter.module.scss"

function BaseDialogFooter({ className, children }) {
  return (
    <footer className={clsx(styles.footer, className)}>
      <div>{children}</div>
    </footer>
  )
}

BaseDialogFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

BaseDialogFooter.defaultProps = {
  className: "",
}

export { BaseDialogFooter }
