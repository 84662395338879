import React from "react"
import PropTypes from "prop-types"
import useSearch from "../../hooks/use-search"
import { Autocomplete } from "../ui/Combobox"

export function ProductAutocompleteV3({ delay, fetcher, selected, onSelect }) {
  const { data, loading: _, search, setSearch } = useSearch(fetcher, delay)

  const products = (data || []).map(({ type, title }) => ({
    id: `${type}-${title}`,
    value: { type, title },
    displayValue: <Autocomplete.Option facet={type} search={search} text={title} />,
  }))

  const filteredProducts =
    search === ""
      ? products
      : products.filter((p) =>
          p.value.title.toLowerCase().includes(search.toLowerCase()),
        )

  return (
    <Autocomplete.Root
      analyticsEventName="MT - Autocomplete Search Suggestion Submitted as Filter"
      value={selected}
      onChange={onSelect}
      compareBy="title"
      nullable
    >
      <Autocomplete.Input
        label="search reference products"
        placeholder="Search products by article number, brand, EAN or product name"
        displayValue={(option) => option?.title}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Autocomplete.Expander label="show reference product suggestions" />
      <Autocomplete.Options items={filteredProducts} />
    </Autocomplete.Root>
  )
}

ProductAutocompleteV3.propTypes = {
  delay: PropTypes.number,
  fetcher: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

ProductAutocompleteV3.defaultProps = {
  delay: 250,
  selected: "",
}
