/* eslint-disable import/prefer-default-export */

export const handleListMatchSources = (state) => ({
  ...state,
  loadingMatchSources: true,
})

export const handleListMatchSourcesSuccess = (state, { payload: { sources } }) => ({
  ...state,
  validSources: sources,
  loadingMatchSources: false,
  matchSourcesError: undefined,
})

export const handleListMatchSourcesError = (state, action) => {
  const { error } = action

  return {
    ...state,
    validSources: [],
    loadingMatchSources: false,
    matchSourcesError: error,
  }
}
