import React from "react"
import AppPropTypes from "../../../prop-shapes"

import styles from "./TableRowHeader.module.scss"

function TableRowHeader({ children, ...props }) {
  return (
    <div {...props} className={styles.container}>
      {children}
    </div>
  )
}

TableRowHeader.propTypes = {
  children: AppPropTypes.childrenElements.isRequired,
}

export default TableRowHeader
