import React from "react"
import { Route, Switch } from "react-router-dom"
import DemoUserTracker from "../demo-user-tracker"
import NewAddressRedirector from "../new-address-redirector"

function Landing() {
  return (
    <Switch>
      <Route path="/demo/c/:customer" component={DemoUserTracker} />
      <Route path="/" component={NewAddressRedirector} />
    </Switch>
  )
}

export default Landing
