/* eslint-disable camelcase */
import { Logger } from "../../../util/log"
import { SELECTED_PRODUCT_MATCHES_PREFETCHING_DELAY } from "../../../util/constants"
import { ORIGIN_EXISTING, ORIGIN_SUGGESTION } from "../../../util/match-origin"
import { mergeSettings } from "../../../util/local-storage"
import { validProductAvailabilities } from "../../../util/product-availability"
import { validProductFactors } from "../../../util/product-factor"
import { allMatchTypeValues } from "../../../util/match-type"

const log = new Logger("ducks:reference-products:list-matches")

const handleListMatches =
  (loadingControlField, lastRequestField, modifiedField, errorField) =>
  (state, { type, id, options: { requestedAt } }) => {
    const { referenceProducts } = state

    const product = referenceProducts[id]
    const loadingControl = state[loadingControlField]

    let newProduct = product
    let upToDate = true
    let pendingRequest = false
    if (product) {
      const lastResquest = product[lastRequestField] || 0
      const lastModified = product[modifiedField] || 0
      pendingRequest = lastModified < lastResquest
      upToDate = requestedAt - SELECTED_PRODUCT_MATCHES_PREFETCHING_DELAY > lastModified
      newProduct = {
        ...product,
        [lastRequestField]: requestedAt,
      }
    }

    if (!pendingRequest && upToDate) {
      return {
        ...state,
        referenceProducts: {
          ...referenceProducts,
          [id]: newProduct,
        },
        [loadingControlField]: [...loadingControl, id],
        [errorField]: undefined,
      }
    }

    log.debug(`ignored late request(${requestedAt}) for ${type} product #${id}`)

    return state
  }

const handleListMatchesSuccess =
  (loadingControlField, matchesField, modifiedField, errorField) =>
  (state, { parent: { id: sourceId }, payload: { matches } }) => {
    const { referenceProducts } = state
    const loadingControl = state[loadingControlField]

    const product = referenceProducts[sourceId]
    let newProduct = product
    if (product) {
      newProduct = {
        ...product,
        [matchesField]: matches,
        [modifiedField]: Date.now(),
      }
    }

    return {
      ...state,
      [loadingControlField]: loadingControl.filter((id) => id !== sourceId),
      referenceProducts: {
        ...referenceProducts,
        [sourceId]: newProduct,
      },
      [errorField]: undefined,
    }
  }

const handleListMatchesError =
  (loadingControlField, matchesField, modifiedField, errorField) =>
  (state, { parent: { id: sourceId }, error }) => {
    const { referenceProducts } = state
    const loadingControl = state[loadingControlField]

    const product = referenceProducts[sourceId]
    let newProduct = product
    if (product) {
      newProduct = {
        ...product,
        [matchesField]: undefined,
        [modifiedField]: Date.now(),
      }
    }

    return {
      ...state,
      [loadingControlField]: loadingControl.filter((id) => id !== sourceId),
      eferenceProducts: {
        ...referenceProducts,
        [sourceId]: newProduct,
      },
      [errorField]: error,
    }
  }

const handleListMatchesInvalid =
  (loadingControlField, lastRequestField, modifiedField) =>
  (
    state,
    {
      parent: {
        type,
        id: sourceId,
        options: { requestedAt },
      },
    },
  ) => {
    const { referenceProducts } = state

    const product = referenceProducts[sourceId]

    let newReferenceProducts = referenceProducts
    let upToDate = true

    if (product) {
      const lastResquest = product[lastRequestField] || 0
      log.debug(
        `cancelling ${type} action. lastRequest:`,
        lastResquest,
        "requestedAt:",
        requestedAt,
        "diff:",
        lastResquest - requestedAt,
      )
      upToDate = requestedAt === lastResquest

      if (upToDate) {
        newReferenceProducts = {
          ...referenceProducts,
          [sourceId]: {
            ...product,
            [modifiedField]: requestedAt,
          },
        }
      }
    }

    const loadingControl = state[loadingControlField]

    if (upToDate) {
      return {
        ...state,
        referenceProducts: newReferenceProducts,
        [loadingControlField]: loadingControl.filter((id) => id !== sourceId),
      }
    }

    return state
  }

// existing matches

export const handleListExistingMatches = handleListMatches(
  "loadingExistingMatches",
  "existingMatchesLastRequest",
  "existingMatchesModified",
  "existingMatchesError",
)

export const handleListExistingMatchesSuccess = handleListMatchesSuccess(
  "loadingExistingMatches",
  ORIGIN_EXISTING,
  "existingMatchesModified",
  "existingMatchesError",
)

export const handleListExistingMatchesError = handleListMatchesError(
  "loadingExistingMatches",
  ORIGIN_EXISTING,
  "existingMatchesModified",
  "existingMatchesError",
)

export const handleListExistingMatchesInvalid = handleListMatchesInvalid(
  "loadingExistingMatches",
  "existingMatchesLastRequest",
  "existingMatchesModified",
)

// suggested matches

export const handleListSuggestedMatches = handleListMatches(
  "loadingSuggestedMatches",
  "suggestedMatchesLastRequest",
  "suggestedMatchesModified",
  "suggestedMatchesError",
)

export const handleListSuggestedMatchesSuccess = handleListMatchesSuccess(
  "loadingSuggestedMatches",
  ORIGIN_SUGGESTION,
  "suggestedMatchesModified",
  "suggestedMatchesError",
)

export const handleListSuggestedMatchesError = handleListMatchesError(
  "loadingSuggestedMatches",
  ORIGIN_SUGGESTION,
  "suggestedMatchesModified",
  "suggestedMatchesError",
)

export const handleListSuggestedMatchesInvalid = handleListMatchesInvalid(
  "loadingSuggestedMatches",
  "suggestedMatchesLastRequest",
  "suggestedMatchesModified",
)

// filters

export const handleSelectMatchedShops = (state, { selected }) => ({
  ...state,
  selectedMatchedShops: selected,
})

export const handleSelectMatchedBrands = (state, { selected }) => ({
  ...state,
  selectedMatchedBrands: selected,
})

export const handleSelectMatchedCountries = (state, { selected }) => ({
  ...state,
  selectedMatchedCountries: selected,
})

export const handleSetShowDiscardedMatches = (state, { show }) => ({
  ...state,
  showDiscardedMatches: show,
})

export const handleSelectMatchedProductsAvailability = (state, { selected }) => {
  const validAvailabilities = selected.filter((value) =>
    validProductAvailabilities.includes(value),
  )

  mergeSettings({
    pageMakeMatchesDefaultMatchedProductsAvailability: validAvailabilities,
  })

  return {
    ...state,
    selectedMatchedProductsAvailability: selected,
  }
}

export const handleSelectMatchedProductsFactor = (state, { selected }) => {
  const validFactors = selected.filter((value) => validProductFactors.includes(value))

  return {
    ...state,
    selectedMatchedProductsFactor: validFactors,
  }
}

export const handleSelectMatchedProductsType = (state, { selected }) => {
  const validTypes = selected.filter((value) => allMatchTypeValues.includes(value))

  return {
    ...state,
    selectedMatchedProductsType: validTypes,
  }
}
