import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { sourceCode2data } from "../../util/match-source"

import "./style.sass"

function MatchSourceChip({ source, matchId }) {
  const updatedSource = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.match_source,
  )
  const label = useMemo(
    () => sourceCode2data(updatedSource || source).badgeCode,
    [source, updatedSource],
  )

  let finalLabel = label
  if (!label) {
    finalLabel = "NM"
  }

  return <span className="dx-match-source-chip-label">{finalLabel}</span>
}

export function useMatchSourceTooltip(source, matchId) {
  const updatedSource = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.match_source,
  )

  const tooltipMap = {
    DX: "Daltix",
    MAN: "Manual",
    NM: "No match",
  }
  const label = useMemo(
    () => sourceCode2data(updatedSource || source).badgeCode,
    [source, updatedSource],
  )

  let finalLabel = label
  if (!label) {
    finalLabel = "NM"
  }

  return tooltipMap[finalLabel] ?? finalLabel
}

export default MatchSourceChip
