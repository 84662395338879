import React, { memo } from "react"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Button as DxButton, Icon } from "@daltix/ui-react"
import { countries } from "countries-list"
import ReactCountryFlag from "react-country-flag"
import ProductCard from "../product-card/ProductCard"
import MultiSelect from "../multi-select/MultiSelect"
import MultiCategorySelector from "../matches-make-ref-prod-categories-selector"
import { getMessage } from "../../util/error"
import { Logger } from "../../util/log"
import { titleCase } from "../../util/strings"
import "./ReferenceProductSlider.sass"
import { ProductAutocompleteV2 } from "../product-autocomplete"

const log = new Logger("ui:ReferenceProductSliderPresenter")

const sortByLabelMap = {
  name: "Name",
  article_nr: "Article Nº",
}

function CountryItem({ code }) {
  if (!code) {
    return (
      <div>
        Unknown
        {code}
      </div>
    )
  }

  const country = countries[code.toUpperCase()]

  return (
    <div>
      <span style={{ marginLeft: "-1.1em" }}>
        <ReactCountryFlag
          styleProps={{ marginBottom: ".2em" }}
          title={titleCase(country ? country.name : code)}
          code={code}
          svg
        />
      </span>{" "}
      {titleCase(country ? country.name : code)}
    </div>
  )
}

function ReferenceProductSliderPresenter({
  loading,
  listError,
  productError,
  index,
  total,
  suggestions,
  suggestionsTotal,
  product,
  sortBy,
  availableCountries,
  selectedCountries,
  availableFamilies,
  selectedFamilies,
  slideDirection,
  skipped,
  searchTerm,
  // event handlers
  onClearFilters,
  onSortByChange,
  onSearchTermChange,
  onCountryFilterChange,
  onFamilyFilterChange,
  onPrev,
  onNext,
  onSkip,
  onRestore,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  onSuggestionSelected,
}) {
  log.debug("index:", index, "total:", total, "product exists:", product !== undefined)

  log.debug("loading", loading, "listError:", listError, "productError:", productError)

  let mainContent = null

  if (loading) {
    mainContent = (
      <div className="flex v center grow loading-container">
        <Typography component="span">Loading Product...</Typography>
        <LinearProgress variant="query" style={{ width: "calc(60%)" }} />
      </div>
    )
  } else if (listError || productError) {
    mainContent = (
      <div className="flex v center grow no-products error">
        <SentimentVeryDissatisfiedIcon />
        {getMessage(listError || { message: productError })}
      </div>
    )
  } else if (product) {
    mainContent = (
      <ProductCard
        {...product}
        slideDirection={slideDirection}
        disabled={!onSkip}
        skipped={skipped}
        onSkip={onSkip}
        onRestore={onRestore}
      />
    )
  } else if (total === 0) {
    mainContent = (
      <div className="flex v center grow no-products">
        <SentimentVeryDissatisfiedIcon />
        No products for the selected criteria
      </div>
    )
  }

  return (
    <div className="reference-product-slider fill-height-container">
      <Toolbar color="default" className="dx-permanent-filter-area">
        <form onSubmit={(e) => e.preventDefault()}>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {availableCountries && availableCountries.length > 1 && (
              <MultiSelect
                itemsClassName="filter-item"
                label="Countries"
                options={availableCountries}
                value={selectedCountries}
                onChange={onCountryFilterChange}
                optionKey={(country) => country}
                optionLabelRenderer={(country) => (
                  <span>
                    <CountryItem code={country} />
                  </span>
                )}
              />
            )}

            {availableFamilies && availableFamilies.length > 1 && (
              <MultiSelect
                itemsClassName="filter-item"
                label="Labels"
                options={availableFamilies}
                value={selectedFamilies}
                onChange={onFamilyFilterChange}
                optionKey={(country) => country}
                optionLabelRenderer={(label) => <span>{label}</span>}
              />
            )}

            <MultiCategorySelector />

            <ProductAutocompleteV2
              placeholder="Search for product name, brand, article nº or EAN..."
              className="dx-form-group long-input"
              suggestions={suggestions}
              suggestionsTotal={suggestionsTotal}
              value={searchTerm}
              onValueChange={onSearchTermChange}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSelected={onSuggestionSelected}
            />

            <FormControl className="dx-form-group dx-selector">
              <Select
                style={{ minWidth: "10rem" }}
                displayEmpty
                value={sortBy}
                onChange={onSortByChange}
                label="Sort By"
                renderValue={(selected) => `Sort by: ${sortByLabelMap[selected]}`}
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="article_nr">Article Nº</MenuItem>
              </Select>
            </FormControl>

            <DxButton
              className="dx-form-group"
              type="button"
              size="s"
              iconRight={<Icon type="eraser" />}
              onClick={onClearFilters}
            >
              Clear Filters
            </DxButton>
          </div>
        </form>
      </Toolbar>

      {mainContent}

      <Toolbar color="default" className="action-bar">
        {total > 1 && index !== undefined && (
          <>
            <Button className="button previous" onClick={onPrev} disabled={!onPrev}>
              <KeyboardArrowLeftIcon />
              Previous
            </Button>

            <Typography className="center grow aux-text fade-in">
              {index + 1} of {total}
            </Typography>

            <Button className="button next" onClick={onNext} disabled={!onNext}>
              Next
              <KeyboardArrowRightIcon />
            </Button>
          </>
        )}
      </Toolbar>
    </div>
  )
}

export default memo(ReferenceProductSliderPresenter)
