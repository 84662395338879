export const MATCH_TYPE_IDENTICAL = "identical"
export const MATCH_TYPE_MULTIPACK = "multipack"
export const MATCH_TYPE_VOLUME_VARIANT = "volume_variant"
export const MATCH_TYPE_MULTIPACK_VOLUME_VARIANT = "multipack_volume_variant"
export const MATCH_TYPE_PRIVATE_LABEL = "private_label"
export const MATCH_TYPE_FRESH = "fresh"
export const MATCH_TYPE_MANUAL = "manual"
export const MATCH_TYPE_UNKNOWN_XFACTOR = "unknown_xfactor"

export const matchTypOptionsList = [
  {
    label: "Identical",
    value: MATCH_TYPE_IDENTICAL,
  },
  {
    label: "Multipack",
    value: MATCH_TYPE_MULTIPACK,
  },
  {
    label: "Volume Variant",
    value: MATCH_TYPE_VOLUME_VARIANT,
  },
  {
    label: "Private Label",
    value: MATCH_TYPE_PRIVATE_LABEL,
  },
  {
    label: "Fresh",
    value: MATCH_TYPE_FRESH,
  },
  {
    label: "Unknown xFactor",
    value: MATCH_TYPE_UNKNOWN_XFACTOR,
  },
  {
    label: "Not Defined",
    value: "undefined",
  },
]

export const allMatchTypeValues = matchTypOptionsList.map((option) => option.value)
