import { KEY_DEMO_USER, getSetting } from "./local-storage"

export function buildDemoUserEmail(id) {
  return `${id.toLocaleLowerCase()}@demo-daltix.com`
}

export function getUserTypeFromEmail(email) {
  return email.endsWith("@daltix.com") ? "daltix" : "customer"
}

export function getUiUser(user) {
  const demoUserId = getSetting(KEY_DEMO_USER)

  const { email: realEmail, name: realName } = user

  const email = demoUserId ? buildDemoUserEmail(demoUserId) : realEmail
  const name = demoUserId ? "Demo User" : realName

  return {
    ...user,
    email,
    name,
    demo: demoUserId !== undefined,
    realEmail: demoUserId && realEmail,
    realName: demoUserId && realName,
  }
}
