export const ASSORTMENT_ANALYSIS_PAGE_KEY = "AssortmentAnalysisPage"

export const ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY = "filters"
export const ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY = "filterOptions"
export const ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY = "selectedFilters"
export const ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY = "filtersOutline"

export const ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY = "dimension"
export const ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY = "productGroup"

export const ASSORTMENT_ANALYSIS_PAGE_META_KEY = "meta"
export const ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY = "pagination"
export const ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY = "request"
export const ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY = "sorts"

export const ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY = "exportData"

export const DIMENSION_PRICE = "price"
export const DIMENSION_BRAND_TYPE = "brand-type"
export const STEP_PRICE = "2.5"

export const ASSORTMENT_ANALYSIS_PAGE_STEP_KEY = "step"

export const SUPPORTED_DIMENSIONS = Object.freeze([
  DIMENSION_PRICE,
  DIMENSION_BRAND_TYPE,
])
