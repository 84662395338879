import { useEffect, useReducer, useState } from "react"

import useAsyncDebounce from "./use-async-debounce"

const SearchActionTypes = {
  SET_PENDING: "pending",
  SET_SUCCESS: "success",
  SET_FAILURE: "failure",
}

const initialState = Object.freeze({
  data: null,
  error: "",
  loading: false,
})

function reducer(state, action) {
  const { type } = action

  switch (type) {
    case SearchActionTypes.SET_PENDING: {
      return { ...state, error: "", loading: true }
    }
    case SearchActionTypes.SET_SUCCESS: {
      return { ...state, data: action.data, loading: false }
    }
    case SearchActionTypes.SET_FAILURE: {
      return { ...state, error: action.message, loading: false }
    }
    default:
      throw new Error(`Search reducer does not support action of type '${type}'`)
  }
}

/**
 * Given an asynchronous remote fetching function, it executes that function with or
 * without debouncing.
 *
 * Depends on `useDebounce` hook to achieve the functionality.
 *
 * @param fetcher the sync or async function to be executed
 * @param delay invocation by specified milliseconds, defaults to zero
 * @returns `[{ data: any, error: string, loading: boolean }, search: string, setSearch: Function]`
 */
export default function useSearch(fetcher, delay = 0) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [search, setSearch] = useState("")

  const { data, error, loading } = state
  const debouncedFn = useAsyncDebounce(fetcher, delay)

  useEffect(() => {
    let didCancel = false

    if (search) {
      dispatch({ type: SearchActionTypes.SET_PENDING })

      debouncedFn(search)
        .then((newData) => {
          if (didCancel) return

          dispatch({
            type: SearchActionTypes.SET_SUCCESS,
            data: newData,
          })
        })
        .catch((e) => {
          if (didCancel) return

          dispatch({
            type: SearchActionTypes.SET_FAILURE,
            message: e.message || "Unexpected Error",
          })

          console.error("Unexpected error during execution of debounced function", e)
        })
    }

    return () => {
      didCancel = true
    }
  }, [debouncedFn, search])

  return { data, error, loading, search, setSearch }
}
