import * as Sentry from "@sentry/react"
import { Logger } from "./log"

const log = new Logger("util:local-storage-settings")

export const KEY_APP_STATE = "dx-app-state"
export const KEY_SETTINGS = "settings"
export const KEY_INTERNAL_USER = "dx-internal-user"
export const KEY_CUSTOMER = "dx-current-customer"
export const KEY_DEMO_USER = "demo-user"
export const KEY_NO_CACHE = "dx-disable-cache"

/* -------------------------------------------------------------------------- */
/*                                  settings                                  */
/* -------------------------------------------------------------------------- */

let settings = {}

function fetchSettings() {
  try {
    const localSettings = localStorage[KEY_SETTINGS] || "{}"

    settings = { ...JSON.parse(localSettings) }

    if (settings["0"]) {
      throw new Error("broken settings")
    }
  } catch (e) {
    log.error("Error reading local storage", e)

    Sentry.captureException(e)

    localStorage[KEY_SETTINGS] = "{}"
    settings = {}
  }
}

fetchSettings()

export function getSetting(key, defaultValue) {
  return settings[key] || defaultValue
}

export function getSettings() {
  return settings
}

export function getValue(key, defaultValue) {
  return localStorage.getItem(key) || defaultValue
}

export function mergeSettings(newSettings) {
  fetchSettings()
  setSettings({
    ...settings,
    ...newSettings,
  })
}

function setSettings(newSettings) {
  try {
    settings = newSettings
    localStorage[KEY_SETTINGS] = JSON.stringify(settings)
  } catch (e) {
    Sentry.captureException(e)
    log.error("Error writing to local storage", e)
  }
  fetchSettings()
}

/* -------------------------------------------------------------------------- */
/*                                    state                                   */
/* -------------------------------------------------------------------------- */

export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY_APP_STATE)

    if (serializedState) {
      return JSON.parse(serializedState)
    }
  } catch (e) {
    Sentry.captureException(e)
  }
  return undefined
}

export function saveState(state) {
  try {
    const serializedState = JSON.stringify(state)

    localStorage.setItem(KEY_APP_STATE, serializedState)
  } catch (e) {
    Sentry.captureException(e)
  }
}
