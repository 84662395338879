import {
  MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
} from "../constants"

export function handleMatchesOverviewReferenceProductSearchClear(state) {
  return {
    ...state,
    [MATCHES_OVERVIEW_PAGE_FILTERS_KEY]: {
      ...state.filters,
      [MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY]: {
        ...state[MATCHES_OVERVIEW_PAGE_FILTERS_KEY][
          MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY
        ],
        searchFacet: "",
        searchTerm: "",
      },
    },
  }
}

export function handleMatchesOverviewReferenceProductSearchChange(state, payload) {
  const { searchFacet, searchTerm } = payload

  return {
    ...state,
    [MATCHES_OVERVIEW_PAGE_FILTERS_KEY]: {
      ...state.filters,
      [MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY]: {
        ...state[MATCHES_OVERVIEW_PAGE_FILTERS_KEY][
          MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY
        ],
        searchFacet,
        searchTerm,
      },
    },
  }
}
