import { Box, CircularProgress, useTheme } from "@mui/material"
import { arrayOf, bool, oneOf, oneOfType, string } from "prop-types"
import React from "react"
import Image from "../../img/Image"

const sizes = {
  small: 40,
  medium: 88,
  large: 136,
}

function ProductImage({ src, alt, size, isLoading }) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.borderColor,
        backgroundColor: "white",
        width: sizes[size] + 8,
        height: sizes[size] + 8,
        p: 0.5,
      }}
    >
      {isLoading && (
        <Box
          data-testid="product-image-loading"
          display="flex"
          alignItems="center"
          height="100%"
          justifyContent="center"
        >
          <CircularProgress size={24} />
        </Box>
      )}
      {!isLoading && (
        <Image
          style={{ objectFit: "contain" }}
          data-testid="product-image"
          width={sizes[size]}
          height={sizes[size]}
          src={src}
          alt={alt}
        />
      )}
    </Box>
  )
}

ProductImage.propTypes = {
  size: oneOf(["small", "medium", "large"]),
  src: oneOfType([string, arrayOf(string)]).isRequired,
  alt: string.isRequired,
  isLoading: bool,
}

ProductImage.defaultProps = {
  size: "small",
  isLoading: false,
}

export { ProductImage }
