import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
} from "../constants"

export function handleDimensionFilterChanged(state, payload) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY]: {
      ...state[ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY],
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: payload.dimension,
    },
  }
}
