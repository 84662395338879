// Action Types
const TOGGLE_SIDE_NAVIGATION = "@side-navigation/TOGGLE"
const actionTypes = {
  TOGGLE_SIDE_NAVIGATION,
}

// Action Creators
const toggleSideNavigation = (open) => ({
  type: TOGGLE_SIDE_NAVIGATION,
  open,
})
const actionCreators = {
  toggleSideNavigation,
}

// Epics
const epics = []

// reducer
const initalState = {
  isSideNavOpen: false,
}

const reducer = (state = initalState, { type, ...action }) => {
  switch (type) {
    case TOGGLE_SIDE_NAVIGATION:
      return {
        ...state,
        isSideNavOpen: action.open,
      }

    default:
      return state
  }
}

// exports
export { actionTypes, actionCreators, epics }

export default reducer
