/* eslint-disable react/forbid-prop-types */
import Pagination from "material-ui-flat-pagination"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import clsx from "clsx"
import styles from "./TableRoot.module.scss"
import { isDevelopment } from "../../../util/env"
import { computeVirtualRowFillerPaddings } from "../../../util/virtualization"
import { Table } from "."

const debugTable = isDevelopment

function TableRoot({
  className,
  columns,
  data,
  justifyContent,
  pageCount,
  paginationState,
  paginationStateSetter,
  resultsCount,
  stickyXHeader,
  stickyYHeader,
  xHeaderId,
  ...props
}) {
  const containerRef = useRef(null)
  const isControlledPagination = !!paginationState && !!paginationStateSetter

  const table = useReactTable({
    columns,
    data,
    debugTable,
    getCoreRowModel: getCoreRowModel(),
    ...(isControlledPagination
      ? {
          pageCount,
          manualPagination: true,
          onPaginationChange: paginationStateSetter,
          state: { pagination: paginationState },
        }
      : {}),
  })

  const { rows } = table.getRowModel()

  /**
   * When cells have one item they have 144px in height
   * When cells have multiple items they have 170px in height.
   * When hovered they have slightly more (due to the rendered button)
   *
   * Tanstack recommends that estimateSize is the largest possible (predictable height)
   *
   * @see https://tanstack.com/virtual/v3/docs/api/virtualizer
   */
  const virtualizer = useVirtualizer({
    count: rows.length,
    debug: debugTable,
    overscan: 20,
    horizontal: false,
    estimateSize: () => 170,
    getScrollElement: () => containerRef.current,
  })

  const totalSize = virtualizer.getTotalSize()
  const virtualRows = virtualizer.getVirtualItems()

  const [paddingTop, paddingBottom] = computeVirtualRowFillerPaddings(
    totalSize,
    virtualRows,
  )

  return (
    <Table.Wrapper justifyContent={justifyContent} {...props}>
      <Table.Container className={className} ref={containerRef}>
        <Table.Element>
          <thead className={styles.head}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={clsx(styles.row, {
                  [styles.ybackdrop]: stickyYHeader,
                  [styles.ysticky]: stickyYHeader,
                })}
              >
                {headerGroup.headers.map(({ isPlaceholder, ...header }) => {
                  const HeaderComponent = isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())

                  return (
                    <th key={header.id} className={styles["column-header"]}>
                      {HeaderComponent}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingBottom > 0 && <Table.Pad height={paddingTop} />}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index]

              return (
                <tr key={row.id} className={styles.row}>
                  {row.getVisibleCells().map((cell) => {
                    const isSticky = stickyXHeader && cell.column.id === xHeaderId

                    return (
                      <td
                        key={cell.id}
                        className={clsx(styles.cell, {
                          [styles.xbackdrop]: isSticky,
                          [styles.xsticky]: isSticky,
                        })}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {paddingBottom > 0 && <Table.Pad height={paddingBottom} />}
          </tbody>
        </Table.Element>
      </Table.Container>
      {pageCount > 0 && (
        <div className={clsx(styles.pagination, "pagination")}>
          <Pagination
            classes={{
              root: "dx-pagination",
              text: "dx-page-item",
              rootCurrent: "dx-active-page",
              disabled: "dx-inactive-page",
            }}
            limit={paginationState.pageSize}
            offset={paginationState.pageIndex * paginationState.pageSize}
            page={paginationState.pageIndex + 1}
            total={resultsCount}
            onClick={(_event, _offset, page) => table.setPageIndex(page - 1)}
            size="large"
            innerButtonCount={3}
            outerButtonCount={3}
          />
        </div>
      )}
    </Table.Wrapper>
  )
}

TableRoot.propTypes = {
  justifyContent: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pageCount: PropTypes.number,
  paginationState: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }),
  paginationStateSetter: PropTypes.func,
  resultsCount: PropTypes.number,
  stickyXHeader: PropTypes.bool,
  stickyYHeader: PropTypes.bool,
  xHeaderId: PropTypes.string,
}

TableRoot.defaultProps = {
  justifyContent: "center",
  className: "",
  pageCount: -1,
  paginationState: undefined,
  paginationStateSetter: undefined,
  resultsCount: -1,
  stickyXHeader: false,
  stickyYHeader: false,
  xHeaderId: "",
}

export default TableRoot
