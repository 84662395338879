import {
  handleListCategories,
  handleListCategoriesError,
  handleListCategoriesSuccess,
} from "../../category"

export const handleGetCategories = (state, action) => {
  const { categories } = state
  return {
    ...state,
    categories: handleListCategories(categories, action),
  }
}

export const handleGetCategoriesSuccess = (state, action) => {
  const { categories } = state
  return {
    ...state,
    categories: handleListCategoriesSuccess(categories, action),
  }
}

export const handleGetCategoriesError = (state, action) => {
  const { categories } = state
  return {
    ...state,
    categories: handleListCategoriesError(categories, action),
  }
}
