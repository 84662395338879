import { LOCATION_CHANGE } from "connected-react-router"

// Action Types

const actionTypes = {}

// Action Creators

const actionCreators = {}

// reducer

const initalState = {
  lastLocation: undefined,
}

const reducer = (state = initalState, { type, ...action }) => {
  switch (type) {
    case LOCATION_CHANGE: {
      const { location: prevLocation = {} } = state

      return {
        ...state,
        prevLocation,
        location: action.payload.location,
      }
    }
    default:
      return state
  }
}

// exports

export { actionTypes, actionCreators }

export default reducer
