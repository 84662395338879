import React from "react"

export default function MatchesReviewFilterBarFallbackRenderer() {
  return (
    <div className="dx-match-error">
      <i className="dx-icon-warning" />
      <p>Unknown Error loading filters.</p>
    </div>
  )
}
