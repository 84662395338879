import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import ReactPlaceholder from "react-placeholder"
import { ProductComparisonTableHeaderContainer } from "./ProductComparisonTableHeaderContainer"

import styles from "./ProductComparisonTableHeaderImageAndTitle.module.scss"

import { ComparisonData } from "./ComparisonDataPropType"
import Image from "../../img/Image"

function Placeholder(props) {
  return (
    <ReactPlaceholder
      className="rounded"
      color="#f5f5f5"
      showLoadingAnimation
      style={{ width: "6rem", height: "1rem" }}
      type="rect"
      {...props}
    />
  )
}

function MatchTitle({ matchData }) {
  const isReady = matchData?.isReady

  if (!isReady) {
    return <Placeholder />
  }

  const data = matchData?.data?.match

  return <p>{data?.name}</p>
}

MatchTitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matchData: PropTypes.object.isRequired,
}

function ReferenceProductTitle({ refData }) {
  const isReady = refData?.isReady

  return (
    <Placeholder ready={isReady}>
      <p>{refData?.data?.name}</p>
    </Placeholder>
  )
}

ReferenceProductTitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  refData: PropTypes.object.isRequired,
}

function ProductImage({ productData, isMatch }) {
  const isReady = productData?.isReady

  if (!isReady) {
    return <Placeholder />
  }

  const data = isMatch ? productData?.data?.match : productData?.data

  return (
    <div className={styles.frame}>
      <Image className={styles.image} alt={data?.name} src={data?.images} />
    </div>
  )
}

ProductImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  productData: PropTypes.object.isRequired,
  isMatch: PropTypes.bool.isRequired,
}

function ProductComparisonTableHeaderImageAndTitle({ matchData: data, isMatch }) {
  const productData = isMatch ? data?.match : data?.ref
  const outdated = isMatch && productData?.data?.match?.outdated

  return (
    <ProductComparisonTableHeaderContainer>
      <main className={clsx(styles.container, outdated && styles.outdated)}>
        <ProductImage productData={productData} isMatch={isMatch} />
        <h1 className={styles.title}>
          {isMatch ? (
            <MatchTitle matchData={productData} />
          ) : (
            <ReferenceProductTitle refData={productData} />
          )}
        </h1>
      </main>
    </ProductComparisonTableHeaderContainer>
  )
}

ProductComparisonTableHeaderImageAndTitle.propTypes = {
  matchData: ComparisonData.isRequired,
  isMatch: PropTypes.bool.isRequired,
}

export { ProductComparisonTableHeaderImageAndTitle }
