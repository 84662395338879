import React from "react"
import imageNutricoreA from "../../images/nutriscore-a.svg"
import imageNutricoreB from "../../images/nutriscore-b.svg"
import imageNutricoreC from "../../images/nutriscore-c.svg"
import imageNutricoreD from "../../images/nutriscore-d.svg"
import imageNutricoreE from "../../images/nutriscore-e.svg"

import "./style.sass"

const imageMap = {
  A: imageNutricoreA,
  B: imageNutricoreB,
  C: imageNutricoreC,
  D: imageNutricoreD,
  E: imageNutricoreE,
}

function NutriScore({ value }) {
  if (!value) return null

  return (
    <div className="dx-nutri-score">
      <img src={imageMap[value]} alt={`Nutri-Score: ${value}`} />
    </div>
  )
}

export default NutriScore
