import { errorType, successType } from "../../../util/redux-observable-helpers"

export const REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID =
  "@reference-products-repository/GET_ONE_BY_ID"
export const REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID_SUCCESS = successType(
  REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID,
)
export const REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID_ERROR = errorType(
  REF_PRODUCT_REPOSITORY_GET_ONE_BY_ID,
)
