import Check from "@mui/icons-material/Check"
import React from "react"
import { string } from "prop-types"

import { AccessibleIcon } from "./AccessibleIcon"

function CheckIcon(props) {
  return (
    <AccessibleIcon border="solid" color="success" shape="circle" {...props}>
      <Check fontSize="inherit" />
    </AccessibleIcon>
  )
}

CheckIcon.propTypes = {
  testID: string,
  size: string,
}

CheckIcon.defaultProps = {
  testID: "",
  size: "lg",
}

export { CheckIcon }
