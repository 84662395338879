import { useSelector, shallowEqual } from "react-redux"

function usePermissions() {
  return useSelector(
    ({ user: { user: { permissions = [] } = {} } = {} } = {}) => permissions,
    shallowEqual,
  )
}

export default usePermissions
