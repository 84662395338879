import React from "react"

const initialState = Object.freeze({
  id: 0,
})

/**
 * A debounce hook that supports synchronous and asynchronous function alike.
 *
 * The debounce effect can be ignored by using the default delay of zero.
 *
 * Based on:
 *  - https://www.youtube.com/watch?v=EXpLFRGM8kg
 *  - https://codesandbox.io/s/dazzling-rain-ri4em?file=/src/index.js:2580-2632
 *
 * @param fn the sync or async function to be executed
 * @param delay invocation by specified milliseconds, defaults to zero
 * @returns the memoized version of the provided function
 */
export default function useAsyncDebounce(fn, delay = 0) {
  const state = React.useRef({ ...initialState })

  // Always use the last received function (simplifies async debounce for consumers)
  state.current.fn = fn

  // Create the debounced function
  const bouncer = React.useCallback(
    // Proxy all arguments to our debounced function
    (...args) => {
      // Create a new promise for each of the provided functions and track resolve/reject
      state.current.promise = new Promise((resolve, reject) => {
        state.current.resolve = resolve
        state.current.reject = reject
      })

      // Clear the old timeout if one exists
      if (state.current.timeout) {
        clearTimeout(state.current.timeout)
      }

      // Set a new timeout for the current function
      state.current.timeout = setTimeout(async () => {
        // Reset the timeout once setTimeout timeout completes
        state.current.timeout = undefined

        // Get a new ID for this async request and update the hook ref with that ID
        const id = ++state.current.id
        const isLatestFunction = () => id === state.current.id

        const currentRef = state.current

        // Run the debounced function and await resolution (even if not an async fn)
        currentRef
          .fn?.(...args)
          .then((response) => {
            if (isLatestFunction()) {
              state.current.resolve?.(response)
            }
          })
          .catch((e) => {
            if (isLatestFunction()) {
              state.current.reject?.(e)
            }
          })
      }, delay)

      // Always return the promise!
      return state.current.promise
    },
    [delay],
  )

  return bouncer
}
