import { useMemo } from "react"
import useCustomer from "./use-customer"

function useFeatures({ names }) {
  const customer = useCustomer({ defaultValue: {} })
  const value = useMemo(() => names.map((name) => customer[name]), [customer, names])

  return value
}

export default useFeatures
