import React from "react"
import { countries } from "countries-list"
import ReactCountryFlag from "react-country-flag"
import PropTypes from "prop-types"
import { titleCase } from "../../util/strings"

function CountryFlag({ code }) {
  if (!code) {
    return null
  }

  const country = countries[code.toUpperCase()]

  return (
    <span title={titleCase(country ? country.name : code)}>
      <ReactCountryFlag
        styleProps={{ marginBottom: ".2em" }}
        title={titleCase(country ? country.name : code)}
        code={code}
        svg
      />
    </span>
  )
}

CountryFlag.propTypes = {
  code: PropTypes.string,
}

CountryFlag.defaultProps = {
  code: undefined,
}

export default CountryFlag
